import { IonCard, IonContent, IonPage, IonText } from "@ionic/react";
import { createContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import ChangeCardNamePopup from "../../../components/pop-ups/ChangeCardNamePopup";
import RavkavCards from "../../../components/RavkavCards";

import "./ravkavCardsPage.scss";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.ravkavCards";
export const SelectedCardContext = createContext<any>(null);

const RavkavCardsPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-ravkav-cards-page">
          <div className="mobile-title-container">
            <MobileBackIcon />
            <IonText className="mobile-header text">{t("header")}</IonText>
          </div>
          <IonCard className="mobile-page-card">
            <RavkavCards mobile={true} />
          </IonCard>
          <ChangeCardNamePopup mobile />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RavkavCardsPageMobile;
