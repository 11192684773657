import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "..";
import { sendToSDK } from "./nfc/useVendorSDK";
import { sdkCommands, sdkEvents } from "./types";
import * as yup from "yup";
import { customPOST } from "../services/customApi/fetch";
import { POPUPS, ROUTES, STORAGE_KEYS } from "../store/Globals";
import { saveVendorUserData } from "../store/action-creators/User";
import { changePopup } from "../store/action-creators/App";
import { getCardDetailsSuccess } from "../store/action-creators/Nfc";
const userInfoSchema = yup.object({
  phone_number: yup.string().min(9).max(15).required(),
});

const getVendorUser = async (phoneNumber: string, vendor: string) => {
  const rsp = await customPOST("auth/vendor-user", {
    vendor,
    phoneNumber,
  });
  return rsp.data;
};

type useVendorUserProps = {
  enabled?: boolean;
  isCardRequired?: boolean;
};

export const useVendorUser = (props?: useVendorUserProps) => {
  const { enabled = true } = props || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const sdkEventData = useSelector(
    (state: RootState) => state.app.sdkEventData
  );
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const isVendor = useSelector((state: RootState) => state.app.isVendor);
  const userDetails = useSelector(
    (state: RootState) => state.user.GetUserData.data
  );
  const card = useSelector((state: RootState) => state.nfc.card);
  const vendorUserData = useSelector(
    (state: RootState) => state.user.vendorUserData
  );
  const [isLoading, setIsLoading] = useState(isVendor);
  const [isWaitingForSdk, setIsWaitingForSdk] = useState(false);

  useEffect(() => {
    if (!enabled) return;
    if (!isVendor) return;
    if (userDetails || isWaitingForSdk || vendorUserData) {
      setIsLoading(false);
      return;
    }
    if (card.data)
      window.localStorage.setItem(
        STORAGE_KEYS.cardImage,
        JSON.stringify(card.data)
      );

    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("card", JSON.stringify(card.data));

    history.location.search = searchParams.toString();

    const pathname = history.location.pathname + "?" + searchParams.toString();

    sendToSDK({
      action: sdkCommands.getUserInfo,
      data: { route: { pathname, search: "" } },
    });
    setIsWaitingForSdk(true);
  }, []);
  useEffect(() => {
    if (!vendor) return;
    if (!card.data && props?.isCardRequired) {
      // try to get card from local storage
      const localStoredCard = window.localStorage.getItem(
        STORAGE_KEYS.cardImage
      );
      if (!localStoredCard) {
        // try to get card from url search params
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        console.log("params.card", params.card);

        if (params.card) {
          dispatch(getCardDetailsSuccess(JSON.parse(params.card)));
          // remove card from url search params but keep the rest
          urlSearchParams.delete("card");
          history.replace({ search: urlSearchParams.toString() });
        } else {
          history.push(ROUTES.mobileNfcRead);
          return;
        }
      } else {
        dispatch(getCardDetailsSuccess(JSON.parse(localStoredCard)));
      }
    }
    if (!sdkEventData) return;
    window.localStorage.removeItem(STORAGE_KEYS.cardImage);
    const { action, data } = sdkEventData;
    if (action === sdkEvents.userInfo && data) {
      setIsWaitingForSdk(false);
      if (data.error) {
        dispatch(changePopup(POPUPS.vendorLoginError));
        return;
      }
      const { phone_number } = userInfoSchema.validateSync(data);
      setIsLoading(true);
      getVendorUser(phone_number, vendor)
        .then((user) => {
          dispatch(saveVendorUserData(user));
        })
        .catch((err) => {
          console.log("getVendorUser error");

          console.log(err);
          dispatch(changePopup(POPUPS.vendorLoginError));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [sdkEventData]);
  return {
    userDetails,
    isLoading: isVendor && isLoading,
    isWaitingForSdk: isVendor && isWaitingForSdk,
  };
};
