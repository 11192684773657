import {
  IonButton,
  IonCol,
  IonRouterLink,
  IonRow,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import PaymentMethodCard from "../PaymentMethodCard";

import "./PaymentCard.scss";
import { useEffect, useState } from "react";
import { useUserCreditCard } from "../../hooks/useUserCreditCard";
import { TCreditCard } from "../../services/models/users";
import { J5PaymentForm, SinglePaymentForm } from "../forms/PaymentForm";
import { TContractDetails } from "../../hooks/types";
import { formatDateShort } from "../../utils/helper-functions";
import { t } from "i18next";

type TPaymentCard = {
  priceInAgorot: number;
  mobile?: boolean;
  purchaseDetails?: {
    contract?: TContractDetails;
    ravKavNumber?: number;
  };
  nextStep: (paymentParams: {
    expiration: string;
    paymentUid: string;
    last4Digits: string;
    topcardId: string;
  }) => void;
  onError?: (error: any) => void;
};
const langConfigPath = "paymentMethodCard";

const PaymentCard: React.FC<TPaymentCard> = ({
  mobile,
  priceInAgorot,
  nextStep,
  onError,
  purchaseDetails,
}) => {
  const [selectedCard, setSelectedCard] = useState<TCreditCard>();
  const creditCard = useUserCreditCard();
  useEffect(() => {
    if (!creditCard) setShowForm(true);
  }, [creditCard]);
  const [showForm, setShowForm] = useState(false);
  const { i18n, t } = useTranslation("", { keyPrefix: langConfigPath });

  const labels = {
    otherCard: t("otherCard"),
    sum: t("sumForPay"),
    pay: t("pay"),
  };
  if (showForm)
    return (
      <J5PaymentForm
        amount={priceInAgorot}
        ccToken={selectedCard?.ccToken}
        cardExpiration={selectedCard?.ccExpires}
        purchaseDetails={purchaseDetails}
        onFinish={(payParams) => nextStep(payParams)}
        onError={onError}
      />
    );

  return (
    <IonCol>
      {creditCard && (
        <PaymentMethodCard
          selected={creditCard.ccToken === selectedCard?.ccToken}
          onClick={() => setSelectedCard(creditCard)}
          lastDigits={creditCard.ccNumber}
          lastCharged={formatDateShort(creditCard.lastCharge)}
        />
      )}
      <IonRow>
        <IonRouterLink
          onClick={() => {
            setSelectedCard(undefined);
            setShowForm(true);
          }}
          className="under_line bold clickable text-replace"
        >
          {labels.otherCard}
        </IonRouterLink>
      </IonRow>
      <IonRow className="flex justify-content-center mTop15">
        <IonRow className="flex">
          <IonText className="bold">{labels.sum}</IonText> &nbsp;
          <IonText className="bold color-dark-blue font-size-16">
            {(priceInAgorot / 100).toFixed(2)} ₪
          </IonText>
        </IonRow>
      </IonRow>
      <IonRow className="justify-content-center">
        <IonButton
          className="main-blue-buttons"
          disabled={!selectedCard}
          onClick={() => {
            setShowForm(true);
          }}
        >
          {labels.pay}
        </IonButton>
      </IonRow>
    </IonCol>
  );
};

export default PaymentCard;
