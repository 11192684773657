import { IonButton, IonLabel, IonList, IonRouterLink } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import { useForm, FormProvider } from "react-hook-form";
import "./RegisterForm.scss";
import authService from "../../../services/AuthService";
import { useEffect, useState } from "react";
import { REG_EXP, ROUTES } from "../../../store/Globals";
import FormInput from "../form-components/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Checkbox from "../form-components/Checkbox";

const langConfigPath = "forms.register";
const initialValues = {
  registrationSource: "web",
};

type TRegisterForm = {
  mobile?: boolean;
  onSuccess?: (userData: any) => void;
};

const RegisterForm: React.FC<TRegisterForm> = ({
  mobile = false,
  onSuccess,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const [confirmedConditions, setConfirmedConditions] = useState(false);
  const register = authService.register();
  const history = useHistory();
  const { t } = useTranslation();
  const [isFinished, setIsFinished] = useState(false);

  const errorMessages = {
    chrecterErrorMessage: t(`${langConfigPath}.chrecterErrorMessage`),
    firstName: t(`${langConfigPath}.firstNameField.errorMessage`),
    lastName: t(`${langConfigPath}.lastNameField.errorMessage`),
    errorMessageLanth: t(`${langConfigPath}.lastNameField.errorMessageLanth`),
    phoneNumber: t(`${langConfigPath}.phoneNumberField.errorMessage`),
    email: t(`${langConfigPath}.emailField.errorMessage`),
    phoneExist: t(`${langConfigPath}.phoneNumberField.existErrorMessage`),
  };

  const schema = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .required(errorMessages.firstName)
        .min(2, errorMessages.firstName)
        .max(10, errorMessages.errorMessageLanth)
        .matches(
          REG_EXP.special_characters,
          errorMessages.chrecterErrorMessage
        ),
      registrationSource: yup.string().default("web"),
      lastName: yup
        .string()
        .required(errorMessages.lastName)
        .min(2, errorMessages.lastName)
        .max(10, errorMessages.errorMessageLanth)
        .matches(
          REG_EXP.special_characters,
          errorMessages.chrecterErrorMessage
        ),
      email: yup
        .string()
        .required(errorMessages.email)
        .max(50, errorMessages.email)
        .matches(REG_EXP.email, errorMessages.email),
      phoneNumber: yup
        .string()
        .required(errorMessages.phoneNumber)
        .min(3, errorMessages.lastName)
        .max(10, errorMessages.errorMessageLanth)
        .matches(REG_EXP.phone, errorMessages.phoneNumber),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: any) => {
    register.execute(data);
    setIsFinished(true);
  };
  useEffect(() => {
    if (register.state.error) {
      const error = JSON.parse(register.state.error);
      if (error.message === "user already exsits")
        methods.setError("phoneNumber", { message: errorMessages.phoneExist });
    }
    if (
      !register.state.error &&
      !register.state.loading &&
      register.state.data &&
      isFinished &&
      onSuccess
    )
      onSuccess({ ...register.state.data });
  }, [register.state]);

  return (
    <div className="form" data-is-mobile={mobile} dir={appDirection}>
      <FormProvider {...methods}>
        <form
          noValidate
          className="register-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <IonList className="form-item-list min-height-form">
            <FormInput
              name="firstName"
              label={t(`${langConfigPath}.firstNameField.placeholder`)}
              showRequired
              showV={false}
            />

            <FormInput
              name="lastName"
              label={t(`${langConfigPath}.lastNameField.placeholder`)}
              showRequired
              showV={false}
            />

            <FormInput
              name="email"
              type="email"
              label={t(`${langConfigPath}.emailField.placeholder`)}
              showRequired
              showV={false}
            />

            <FormInput
              name="phoneNumber"
              type="tel"
              label={t(`${langConfigPath}.phoneNumberField.placeholder`)}
              showRequired
              showV={false}
            />
          </IonList>

          <div className="flex ion-align-items-center">
            <Checkbox
              onCheckChange={() => {
                setConfirmedConditions(!confirmedConditions);
              }}
            />
            <IonLabel className="text">
              {t(`${langConfigPath}.confirmConditions.text`)}
            </IonLabel>
            &nbsp;&nbsp;
            <IonRouterLink
              className="link text"
              onClick={() => {
                history.push(mobile ? ROUTES.mobileFAQ : ROUTES.desktopTerms);
              }}
              target={mobile ? ROUTES.mobileFAQ : ROUTES.desktopTerms}
            >
              {t(`${langConfigPath}.confirmConditions.link`)}
            </IonRouterLink>
          </div>

          <IonButton
            className="button-primary"
            type="submit"
            disabled={!confirmedConditions}
          >
            {t(`${langConfigPath}.submitButton.label`)}
          </IonButton>
        </form>
      </FormProvider>
    </div>
  );
};

export default RegisterForm;
