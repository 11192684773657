import { IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { POPUPS } from "../../../store/Globals";
import AddNewCardForChargeForm from "../../forms/AddNewCardForChargeForm";
import Popup from "../Popup";
import "./addNewCardForChargePopup.scss";

type TAddNewCardForChargePopup = {
  mobile?: boolean;
  add: (card: { number: number }) => void;
};
const langConfigPath = "popUps.addNewCardForCharge";

const AddNewCardForChargePopup: React.FC<TAddNewCardForChargePopup> = ({
  mobile = false,
  add,
}) => {
  const { t } = useTranslation();

  return (
    <Popup popUpId={POPUPS.addNewCardForCharge} mobile={mobile}>
      <div
        className="flex flex-direction-column padding-10"
        data-is-mobile={mobile}
      >
        <IonText className="pop-up-header text">
          {t(`${langConfigPath}.header`)}
        </IonText>

        <AddNewCardForChargeForm mobile={mobile} add={add} />
      </div>
    </Popup>
  );
};

export default AddNewCardForChargePopup;
