import { IonCard, IonContent, IonPage, IonText } from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import FAQItem from "../../../components/FAQItem";

import "./faqPage.scss";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.faq";

const FAQPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  const headers = {
    main: t("header"),
    ravkavService: t("ravkavService.header"),
    contracts: t("contracts.header"),
  };

  const ravkavServiceQAs = [
    {
      q: t("ravkavService.howToCharge.header"),
      a: t("ravkavService.howToCharge.content"),
    },
    {
      q: t("ravkavService.whereCanYouSeeReceipt.header"),
      a: t("ravkavService.whereCanYouSeeReceipt.content"),
    },
    {
      q: t("ravkavService.whatIsOrderCharge.header"),
      a: t("ravkavService.whatIsOrderCharge.content"),
    },
    {
      q: t("ravkavService.canYouAnonymousCharge.header"),
      a: t("ravkavService.canYouAnonymousCharge.content"),
    },
  ];

  const contractsQAs = [
    {
      q: t("ravkavService.howToCharge.header"),
      a: t("ravkavService.howToCharge.content"),
    },
    {
      q: t("ravkavService.whereCanYouSeeReceipt.header"),
      a: t("ravkavService.whereCanYouSeeReceipt.content"),
    },
    {
      q: t("ravkavService.whatIsOrderCharge.header"),
      a: t("ravkavService.whatIsOrderCharge.content"),
    },
  ];

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-faq-page">
          {/* <div className="mobile-title-container">
            <MobileBackIcon />
            <IonText className="mobile-header text">{headers.main}</IonText>
          </div> */}

          <IonCard className="mobile-page-card flex flex-direction-column">
            <div className="section">
              <IonText className="section-header text font-size-22 weight-900">
                {headers.ravkavService}
              </IonText>
              <div>
                {ravkavServiceQAs.map(({ q, a }, index) => (
                  <FAQItem header={q} mobile key={index}>
                    {a}
                  </FAQItem>
                ))}
                <FAQItem header={t("ravkavService.howToCharge.header")} mobile>
                  <Trans>{t("ravkavService.howToCharge.content")}</Trans>
                </FAQItem>
              </div>
            </div>

            <div className="section">
              <IonText className="section-header text font-size-22 weight-900">
                {headers.contracts}
              </IonText>
              <div>
                {contractsQAs.map(({ q, a }, index) => (
                  <FAQItem header={q} mobile key={index}>
                    {a}
                  </FAQItem>
                ))}
              </div>
            </div>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FAQPageMobile;
