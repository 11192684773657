import { 
  IonContent,
  IonPage,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import SearchResultsCard from '../../../components/SearchResultCard';
import './SearchResultsPage.scss';


const langConfigPath = 'pages.mobile.searchResults'

const SearchResultsPageMobile: React.FC = () => {
  const {t} = useTranslation()

  return (
    <IonPage className="mobile-page" >
      <IonContent className="mobile-page-content">
          <div className="mobile-search-results-page">

            <div className="mobile-title-container">
              <IonText className="mobile-header text">{t(`${langConfigPath}.title`)}</IonText>
            </div>
            
            <div className="content-container">
              <SearchResultsCard mobile/>
            </div>
          </div>
      </IonContent>
    </IonPage>
  )
}

export default SearchResultsPageMobile;
