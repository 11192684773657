import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonPage,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import DocumentsSentErrorPopup from "../../../components/pop-ups/DocumentsSentErrorPopup";
import DocumentsSentSuccessPopup from "../../../components/pop-ups/DocumentsSentSuccessPopup";
import ReUploadDocuments from "../../../components/StepsCardSections/ReUploadDocuments";
import { ROUTES } from "../../../store/Globals";

import "./reUploadFilesPage.scss";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.reUploadFiles";

const ReUplaodFilesPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-reupload-files-page width-100">
          <div className="mobile-title-container">
            <div className="mobile-header-container width-100 position-relative">
              <MobileBackIcon
                onClick={() => history.push(ROUTES.mobileProfiles)}
              />

              <IonText className="mobile-header text ">
                {t(`${langConfigPath}.header`)}
              </IonText>
            </div>
            <div className="mobile-sub-header-container width-100 position-relative">
              {/* <IonText className="mobile-back-button"/>
                            <IonText className="mobile-back-button"/> */}
              <IonText className="mobile-sub-header text">
                {t(`${langConfigPath}.subHeader`)}
              </IonText>
            </div>
          </div>

          <IonCard className="mobile-page-card ">
            <IonCardContent className="mobile-card-content reupload-files-card-content">
              <DocumentsSentSuccessPopup mobile />
              <DocumentsSentErrorPopup
                mobile
                info={t(`${langConfigPath}.errorPopupText`)}
              />
              <ReUploadDocuments mobile />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReUplaodFilesPageMobile;
