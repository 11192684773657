import { IonCard, IonContent, IonIcon, IonPage, IonText } from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import ChargeHistoryCard from "../../../components/ChargeHistoryCard";

import "./chargeHistoryPageDesktop.scss";
import emptyIcon from "../../../assets/icons-empty.svg";
import { Charge } from "../../../hooks/types";
import { useChargeHistory } from "../../../hooks/useChargeHistory";
import { CenteredSpinner } from "../../../components/Spinner";
import { BackIcon } from "../../../components/BackIcon";
import backIconWhite from "../../../assets/icons-arrow-back-white.svg";
const langConfigPath = "topMenu.desktop.personalAreaDropDown.chargingHistory";

function EmptyChargeHistory() {
  const { t } = useTranslation();
  return (
    <div className="flex ion-align-items-center flex-direction-column">
      <IonIcon className="empty-icon" src={emptyIcon} />
      <IonText className="text font-size-18 weight-500 text-align-center">
        <Trans>{t("pages.mobile.chargeHistory.noHistoryFoundText")}</Trans>
      </IonText>
    </div>
  );
}

const ChargeHistoryPageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const { t } = useTranslation();
  const { chargeHistoryGrouped, isLoading } = useChargeHistory({
    type: "contract",
  });

  const DateSection = ({
    date,
    values,
  }: {
    date: string;
    values: Charge[];
  }) => {
    return (
      <div className="date-section padding-15">
        <IonText className="date text weight-500">{date}</IonText>
        <div className="line  margin-auto"></div>
        {values.map((charge) => (
          <ChargeHistoryCard charge={charge} key={charge._id} />
        ))}
      </div>
    );
  };

  return (
    <IonPage
      className="desktop-page desktop-scan-results-page-container"
      dir={appDirection}
    >
      <IonContent
        scrollY
        className={`desktop-page-content  desktop-background-${vendor}`}
      >
        <div className="desktop-charge-history-page card-700 ">
          <div className="desktop-title-container flex-direction-row">
            <BackIcon closeIcon={backIconWhite} />
            <IonText className="desktop-header text">
              {t(`${langConfigPath}.label`)}
            </IonText>
          </div>

          <IonCard className="desktop-page-card padding-15">
            {isLoading ? (
              <CenteredSpinner />
            ) : chargeHistoryGrouped.length ? (
              chargeHistoryGrouped.map(([date, values]) => (
                <DateSection date={date} values={values} key={date} />
              ))
            ) : (
              <EmptyChargeHistory />
            )}
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChargeHistoryPageDesktop;
