import { IonButton, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./VendorLoginErrorPopup.scss";

type VendorLoginErrorPopupProps = {
  mobile?: boolean;
};

const VendorLoginErrorPopup: React.FC<VendorLoginErrorPopupProps> = ({
  mobile = false,
}) => {
  const { t } = useTranslation("", { keyPrefix: "popUps.vendorLoginError" });
  const dispatch = useDispatch();
  const onOk = () => {
    dispatch(changePopup(POPUPS.clear));
  };
  const labels = {
    text1: t("text1"),
    text2: t("text2"),
    registerButton: {
      label: t("backToScanButton.label"),
    },
  };
  return (
    <Popup
      popUpId={POPUPS.vendorLoginError}
      mobile={mobile}
      routeOnDismiss={ROUTES.mobileNfcRead}
    >
      <div
        className="pop-up login-error-pop-up flex ion-align-items-center"
        data-is-mobile={mobile}
      >
        <div className="text-container flex">
          <IonText className="pop-up-header text">{labels.text1}</IonText>
          <IonText className="pop-up-header text">{labels.text2}</IonText>
        </div>
        <div className="buttons-container flex flex-direction-column">
          <IonButton className="button-primary pop-up-button" onClick={onOk}>
            {labels.registerButton.label}
          </IonButton>
        </div>
      </div>
    </Popup>
  );
};

export default VendorLoginErrorPopup;
