import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

interface FetchOptions extends RequestInit {
  params?: { name: string; value: string }[];
}

const formatUrlParams = (
  params: { name: string; value: string }[] | undefined
) => {
  let stringForUrl = "?";

  if (params !== undefined) {
    params.forEach((param) => {
      stringForUrl = `${stringForUrl}${param.name}=${param.value}&`;
    });
  }

  return stringForUrl.slice(0, -1);
};

const useFetch = <T>(
  url: string,
  onStart: () => void,
  onSuccess: <T>(data: T) => void,
  onError: (error: string) => void
) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchData = async (
    options?: FetchOptions,
    redirectOnSuccess?: string,
    redirectOnError?: string
  ) => {
    try {
      dispatch(onStart());
      const res = await fetch(
        `${url}${formatUrlParams(options?.params)}`,
        options
      );
      if (res.status !== 200) {
        throw await res.text();
      }
      const data = await res.json();
      console.log({ apiFetch: data });
      if (redirectOnSuccess) {
        history.push(redirectOnSuccess);
      }
      dispatch(onSuccess<T>(data));
    } catch (error: any) {
      if (error instanceof Error) {
        error = error.message;
      }
      if (redirectOnError) {
        history.push(redirectOnError);
      }
      dispatch(onError(error));
    }
  };

  return fetchData;
};

export default useFetch;

export const useSimpleQuery = <T extends any>(
  queryFn: () => Promise<{ data: any; status: number }>,
  validateFn?: (data: unknown) => Promise<T>
) => {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    setIsError(false);
    queryFn()
      .then(async ({ data }) => {
        if (validateFn) {
          const validatedData = await validateFn(data);
          setData(validatedData);
        } else setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return { data, isError, isLoading, error };
};
