import { customPOST } from "../customApi/fetch";

export const cardGreenList = async (cardImage: any) => {
  const rsp = await customPOST("card/greenList", cardImage, {
    credentials: "include",
  });
  return rsp.data;
};

export const checkCardBlocked = async (
  cardImage: any
): Promise<{
  isBlackListed?: boolean;
  isBlocked?: boolean;
}> => {
  const rsp = await customPOST("card/checkCardBlock", cardImage, {
    credentials: "include",
  });
  return rsp.data;
};
