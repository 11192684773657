import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonMenuToggle,
  IonLabel,
  IonNavLink,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonImg,
  IonPopover,
  IonInput,
  IonMenuButton,
} from "@ionic/react";

import { createContext, useContext, useEffect, useState } from "react";
import "./MainMenuItem.scss";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import upIcon from "../../../assets/icons-small-arrow-up.svg";
import leftIcon from "../../../assets/icons-small-arrow-left.svg";





type MainMenuItem = {
  itemId?: string | undefined;
  icon: string | undefined;
  label: string;
  page?: string;
  with_icon?:boolean;
  open_icon?:boolean;
  onClick?: () => void;
};

const MainMenuItem: React.FC<MainMenuItem> = ({
  icon,
  label,
  itemId,
  with_icon,
  open_icon,
  page,
  onClick
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const topMenuState = useSelector((state: RootState)=>state.topMenu)

  const action=()=>{
    if(onClick){
     onClick()
    }else if(page){
      history.push(page)
    }
  }

  return (
    <IonCol
      id={itemId}
      className="main-menu-item flex ion-align-items-center clickable"
      onClick={action}
    >
      <div className="flex">
       
        {with_icon ?
          open_icon?
          <IonIcon className="item-icon-arrow" src={upIcon} />
          :
          <IonIcon className="item-icon-arrow" src={leftIcon} />
        :null}

        <IonIcon className="item-icon" src={icon} />
      </div>
   
    

      <IonLabel className="item-label text">{label}</IonLabel>
    </IonCol>
  );
};

export default MainMenuItem;
