import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import moovitErrorIcon from "../../../assets/icons-90-px-error-moovit.svg";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./NfcCardBlocked.scss";
import {
  clearNfcError,
  saveNfcAction,
} from "../../../store/action-creators/Nfc";
import { nfcActions } from "../../../hooks/types";

type NfcCardBlockedPopupProps = {
  mobile?: boolean;
  moovit?: boolean;
  cssClass?: string;
};
const langConfigPath = "popUps.nfcCardBlocked";

const NfcCardBlockedPopup: React.FC<NfcCardBlockedPopupProps> = ({
  cssClass,
  mobile = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const onOk = () => {
    console.log("onOk");
    dispatch(clearNfcError());
    dispatch(changePopup(POPUPS.clear));
  };
  const labels = {
    title: t("title"),
    description: t("description"),
    btnLabel: t("btnLabel"),
  };

  return (
    <Popup
      popUpId={POPUPS.nfcCardBlocked}
      mobile={mobile}
      onDismiss={() => {
        dispatch(clearNfcError());
        dispatch(saveNfcAction({ action: nfcActions.none }));
      }}
      cssClass={cssClass}
      routeOnDismiss={mobile ? ROUTES.home : ROUTES.home}
    >
      <div
        className="pop-up nfc-read-failed-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={moovitErrorIcon} />
        <div className="text-container flex flex-direction-column">
          <IonText className="pop-up-header text">{labels.title}</IonText>
          <IonText className="text sub-text">{labels.description}</IonText>
        </div>
        <IonButton onClick={onOk} className="main-blue-buttons pop-up-botton">
          {labels.btnLabel}
        </IonButton>
      </div>
    </Popup>
  );
};

export default NfcCardBlockedPopup;
