import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonText,
} from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import DiscountProfileCard from "../../../components/DiscountProfileCard";
import DocumentsSentErrorPopup from "../../../components/pop-ups/DocumentsSentErrorPopup";
import SignInBeforeContinuingPopup from "../../../components/pop-ups/SignInBeforeContinuingPopup";
import { nfcActions } from "../../../hooks/types";
import { useCardEnv } from "../../../hooks/useCard";
import {
  CreateOrUpdateProfileTypeEnum,
  ProfileStatus,
} from "../../../services/models/profile";
import ProfileService from "../../../services/ProfileService";
import { changePopup } from "../../../store/action-creators/App";
import { saveNfcAction } from "../../../store/action-creators/Nfc";
import {
  changeCreateProfileReqType,
  changeSelectedProfile,
  createProfileError,
  getSingleProfileSuccess,
} from "../../../store/action-creators/profile";
import { POPUPS, ROUTES } from "../../../store/Globals";
import "./profilesPage.scss";
import { customDELETE } from "../../../services/customApi/fetch";
import classNames from "classnames";
import { BackIcon } from "../../../components/BackIcon";
import backIconWhite from "../../../assets/icons-arrow-back-white.svg";

const deleteProfileRequest = async (reqId: string) => {
  const rsp = await customDELETE(`profiles/deleteProfileRequest/${reqId}`);
  return rsp.data;
};

const langConfigPath = "pages.desktop.profiles";

const ProfilesPageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const user: any = useSelector((state: RootState) => state.user);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userProfiles = ProfileService.getUserProfiles();
  const getAllProfilesList = ProfileService.getAllProfilesList();
  const { cardNumber, cardName, cardDetails } = useCardEnv();

  useEffect(() => {
    getAllProfilesList.execute();
  }, [i18n.language]);

  useEffect(() => {
    if (!userProfiles.state.loading)
      userProfiles.execute({ reqType: "profiles" });
    if (history.location.pathname.includes("sms")) {
      dispatch(createProfileError(""));
    } else if (!user.GetUserData.loading && !user.Login) {
      dispatch(changePopup(POPUPS.signInBeforeContinuingPopup));
    }
  }, [user.GetUserData, history.location]);

  useEffect(() => {
    const profiles = userProfiles.state.data as any[];
    if (!profiles || !Array.isArray(profiles)) return;
    console.log("profiles", profiles);

    if (profiles.some((p) => p.status === ProfileStatus.denied_request)) {
      dispatch(changePopup(POPUPS.documentsSentError));
    }
  }, [userProfiles.state.data]);

  const handelDocumentStatusClick = (profile: any) => {
    dispatch(changeSelectedProfile(profile));
    dispatch(getSingleProfileSuccess(profile));
    history.push(ROUTES.desktopUploadFiles);
  };
  const labels = {
    noProfiles: t(`${langConfigPath}.noProfiles`),
    profile1Name: cardDetails?.Environment.HolderProf1Str,
    profile2Name:
      cardDetails?.Environment.HolderProf1Str ===
      cardDetails?.Environment.HolderProf2Str
        ? null
        : cardDetails?.Environment.HolderProf2Str,
  };

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <SignInBeforeContinuingPopup />

        <DocumentsSentErrorPopup info={t(`popUps.documentsSentError.text`)} />

        <div className="desktop-profiles-page">
          <div className="flex">
            <BackIcon closeIcon={backIconWhite} />
            <div className="desktop-profiles-title-container width-100">
              <div className="desktop-header-container width-100 position-relative">
                <IonText className="desktop-header text main-title">
                  {t(`${langConfigPath}.header`)}
                </IonText>
              </div>
              {cardNumber && (
                <div className="sub-header-container">
                  {cardName && (
                    <>
                      <IonText className="desktop-sub-header text">
                        {cardName}
                      </IonText>
                      <IonText className="desktop-sub-header text desktop-sub-header-blue-line"></IonText>
                    </>
                  )}

                  <IonText className="desktop-sub-header text">
                    &nbsp;{cardNumber}
                  </IonText>
                </div>
              )}
            </div>
          </div>

          <IonCard className="desktop-page-card">
            <IonCardContent className="desktop-card-content">
              {labels.profile1Name && (
                <DiscountProfileCard
                  key={Math.random()}
                  name={labels.profile1Name}
                  isRegular
                />
              )}
              {labels.profile2Name && (
                <DiscountProfileCard
                  key={Math.random()}
                  name={labels.profile2Name}
                  isRegular
                />
              )}
              {userProfiles.state.loading ? (
                <div> </div>
              ) : userProfiles.state.data?.length ? (
                <>
                  {userProfiles.state.data.map((profile: any) => {
                    return (
                      <DiscountProfileCard
                        key={Math.random()}
                        onCheckFilesClick={() => {
                          handelDocumentStatusClick(profile);
                        }}
                        onDeleteRequest={() => {
                          deleteProfileRequest(profile._id).then((v) => {
                            userProfiles.execute({ reqType: "profiles" });
                          });
                        }}
                        onLoadProfile={
                          profile.cardNumber === cardNumber?.toString()
                            ? () => {
                                dispatch(
                                  saveNfcAction({
                                    action: nfcActions.loadProfile,
                                    data: { reqId: profile._id },
                                  })
                                );
                                history.push(ROUTES.desktopNfcRead);
                              }
                            : undefined
                        }
                        name={profile.profileName}
                        confirmed={
                          profile?.status === ProfileStatus.approved_request
                        }
                        profile_status={profile?.status}

                        // mobile
                      />
                    );
                  })}
                </>
              ) : (
                <div>{labels.noProfiles}</div>
              )}
            </IonCardContent>
            <div className="desktop-card-action-button-container">
              <IonButton
                disabled={
                  userProfiles.state.loading ||
                  userProfiles.state.data?.length >= 2 ||
                  !cardNumber
                }
                className="main-blue-buttons "
                onClick={() => {
                  dispatch(
                    changeCreateProfileReqType(
                      CreateOrUpdateProfileTypeEnum.update_profile
                    )
                  );
                  history.push(ROUTES.desktopCreateNewProfile);
                }}
              >
                {t(`${langConfigPath}.createNewProfileButton.label`)}
              </IonButton>
            </div>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProfilesPageDesktop;
