import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "../../..";
import StepsCard from "../../../components/StepsCard";
import "./orderChargePage.scss";
import SelectContract from "../../../components/StepsCardSections/SelectContract";
import OrderChargeSummary from "../../../components/StepsCardSections/OrderChargeSummary";
import { nfcActions, TContractDetails } from "../../../hooks/types";
import { TContractType } from "../../../hooks/contracts/useContractsForLoading";
import { clearGlobalContractForRenew, saveNfcAction } from "../../../store/action-creators/Nfc";
import { POPUPS, ROUTES } from "../../../store/Globals";
import SelectContractType from "../../../components/StepsCardSections/SelectContractType";
import PaymentCard from "../../../components/PaymentCard";
import PaymentSuccessPopup from "../../../components/pop-ups/PaymentSuccessPopup";
import { changePopup } from "../../../store/action-creators/App";

const langConfigPath = "pages.mobile.orderCharge";

const PurchaseContractDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  const [contractType, setContractType] = useState<TContractType>();
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const cardData = useSelector((state: RootState) => state.nfc.card.data);
  const cardNumber = useSelector(
    (state: RootState) => state.nfc.card.cardNumber
  );
  const [selectedContract, setSelectedContract] = useState<TContractDetails>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (cardData) return;
    // go to scan if no card is saved
    dispatch(
      saveNfcAction({
        action: nfcActions.readCard,
        route: location.pathname,
      })
    );
    history.push(ROUTES.desktopNfcRead);
  }, [cardData, dispatch]);
  const contractForRenew = useSelector(
    (state: RootState) => state.nfc.renewContract
  );

  useEffect(() => {
    console.log("contractForRenew", contractForRenew);

    if (contractForRenew) {
      setSelectedContract(contractForRenew);
      dispatch(clearGlobalContractForRenew())
      setStep(2);
    }
  }, [contractForRenew]);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   searchParams.set("step", step.toString());
  //   if (step === 0) {
  //     setContractType(undefined);
  //     setSelectedContract(undefined);
  //     searchParams.delete("contractType");
  //   } else if (step === 1 && contractType) {
  //     searchParams.set("contractType", contractType);
  //   }
  //   history.push({ search: searchParams.toString() });
  // }, [step]);
  // useEffect(() => {
  //   // if loading page with search params -> interpolate state
  //   const urlParams = new URLSearchParams(location.search);
  //   const step = urlParams.get("step");
  //   if (step === "0") setStep(0);
  // }, [location]);

  const pages = [
    {
      header: t("header"),
      subHeader: t("pages.contractTypes.header"),
      component: (
        <SelectContractType
          nextStep={(contractType: TContractType) => {
            setContractType(contractType);
            setStep((prev) => prev + 1);
          }}
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.selectContract.header2", {
        type: t("contractTypes." + contractType),
      }),
      component: (
        <SelectContract
          nextStep={(contract: TContractDetails) => {
            setSelectedContract(contract);
            setStep((prev) => prev + 1);
          }}
          contractType={contractType}
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.summary.header"),
      component: (
        <OrderChargeSummary
          contract={selectedContract}
          nextStep={(changedContract) => {
            setSelectedContract(changedContract);
            setStep((prev) => prev + 1);
          }}
        />
      ),
    },
    {
      header: t("pages.payment.header"),
      subHeader: t("pages.payment.subHeader", {
        contractName: selectedContract?.ETTStr,
      }),
      component: (
        <PaymentCard
          priceInAgorot={selectedContract?.PriceInAgorot ?? 0}
          purchaseDetails={{
            contract: selectedContract,
            ravKavNumber: cardNumber,
          }}
          nextStep={(paymentInfo) => {
            if (!selectedContract) return;
            dispatch(
              saveNfcAction({
                action: nfcActions.loadContract,
                data: { contract: selectedContract, ...paymentInfo },
              })
            );
            dispatch(changePopup(POPUPS.paymentSuccess));
          }}
          onError={(err) => {
            console.log({ err });
            setStep((s) => s - 1);
          }}
        />
      ),
    },
  ];

  const handleBackButton = () => {
    if (selectedContract && !contractType && step === 2) {
      history.goBack();
      return;
    }
    if (step > 0) setStep(step - 1);
    else history.goBack();
  };

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <PaymentSuccessPopup routeOnDismiss={ROUTES.desktopNfcRead} />
        <div className="desktop-profiles-page">
          <StepsCard
            onBack={handleBackButton}
            cssClass={`order-charge-steps-card step${step}`}
            step={step}
            pages={pages}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PurchaseContractDesktop;
