import { IonContent, IonPage, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import SearchResultsCard from "../../../components/SearchResultCard";
import "./SearchResultsPage.scss";
import { BackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.desktop.searchResults";

const SearchResultsPageDekstop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const { t } = useTranslation();

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-search-results-page">
          <div className="desktop-title-container flex flex-direction-row">
            <BackIcon />

            <IonText className="desktop-header text">
              {t(`${langConfigPath}.title`)}
            </IonText>
          </div>

          <SearchResultsCard />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SearchResultsPageDekstop;
