import { createBrowserHistory } from "history";
import { applyMiddleware, createStore, EmptyObject } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import createRootReducer from "./Reducers";
import { routerMiddleware } from "connected-react-router";
import logger from "redux-logger";
import { nfcSocketMiddleware } from "./middleware/NfcSocketMiddleWare";

export const history = createBrowserHistory();

export default function configureStore(preloadedState: any) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        nfcSocketMiddleware,
        routerMiddleware(history),
        thunk,
        logger
      )
    )
  );

  return store;
}
