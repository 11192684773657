import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonText,
} from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";

import arrowIcon from "../../../assets/icons-go-search-arrow.svg";
import arrowIconPango from "../../../assets/pango_arrow.svg";
import barcodekIcon from "../../../assets/top-card-url-qr.svg";

import { ROUTES } from "../../../store/Globals";

import "./supportPage.scss";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.support";

const SupportPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const arrow = vendor === "pango" ? arrowIconPango : arrowIcon;

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-support-page">
          <div className="mobile-title-container">
            <MobileBackIcon />
            <IonText className="mobile-header text">{t("header")}</IonText>
          </div>

          <IonCard
            className="mobile-page-card flex flex-direction-column
                    justify-content-space-between"
          >
            <IonCardContent className="flex flex-direction-column">
              <div className="flex flex-direction-column">
                <IonText className="text color-dark-sub-text font-size-14">
                  {t("programVersion")}
                </IonText>
                <IonText className="text">1.23.1</IonText>
              </div>

              <div className="flex flex-direction-column">
                <IonText className="text color-dark-sub-text font-size-14">
                  {t("appVersion")}
                </IonText>
                <IonText className="text">1.213.2</IonText>
              </div>

              <div className="flex flex-direction-column">
                <IonText className="text color-dark-sub-text font-size-14">
                  {t("operator")}
                </IonText>
                <IonText className="text">
                  <Trans>{t("operatorInfo")}</Trans>
                </IonText>
              </div>

              <div className="flex">
                <IonText className="text font-size-14">
                  <Trans>{t("extraInfo")}</Trans>
                </IonText>
              </div>

              <div className="flex">
                <IonIcon className="barcode-icon" src={barcodekIcon} />
              </div>
            </IonCardContent>

            <IonList lines="full" className="link-list">
              <IonItem
                onClick={() => {
                  history.push(ROUTES.home);
                }}
              >
                <IonText className="text">{t("links.website")}</IonText>
                <IonIcon slot="end" className="arrow-icon" src={arrow} />
              </IonItem>

              <IonItem
                onClick={() => {
                  history.push(ROUTES.home);
                }}
              >
                <IonText className="text">{t("links.faq")}</IonText>
                <IonIcon slot="end" className="arrow-icon" src={arrow} />
              </IonItem>

              <IonItem
                onClick={() => {
                  history.push(ROUTES.home);
                }}
              >
                <IonText className="text">{t("links.contact")}</IonText>
                <IonIcon slot="end" className="arrow-icon" src={arrow} />
              </IonItem>
            </IonList>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SupportPageMobile;
