import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import documentsSentErrorIcon from "../../../assets/icons-documents-sent-error.svg";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";

import "./documentsSentErrorPopup.scss";

type DocumentsSentErrorPopup = {
  mobile?: boolean;
  cssClass?: string;
  info?: string;
};
const langConfigPath = "popUps.documentsSentError";

const DocumentsSentErrorPopup: React.FC<DocumentsSentErrorPopup> = ({
  info,
  cssClass,
  mobile = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [route, setRoute] = useState("");

  const onWatchDocuments = () => {
    setRoute(mobile ? ROUTES.mobileProfiles : ROUTES.desktopProfiles);
    dispatch(changePopup(POPUPS.clear));
  };

  return (
    <Popup
      routeOnDismiss={route}
      popUpId={POPUPS.documentsSentError}
      mobile={mobile}
      cssClass={`documents-sent-error-pop-up-container ${cssClass}`}
    >
      <div
        className="pop-up documents-sent-error-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={documentsSentErrorIcon} />

        <IonText className="text weight-500 info">
          {info ? info : t(`${langConfigPath}.text`)}
        </IonText>

        <IonButton
          onClick={onWatchDocuments}
          className="main-blue-buttons pop-up-botton"
        >
          {t(`${langConfigPath}.watchButton.label`)}
        </IonButton>
      </div>
    </Popup>
  );
};

export default DocumentsSentErrorPopup;
