import { IonContent, IonPage } from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import GreenListPopup from "../../../components/pop-ups/GreenListPopup";
import ScanResultsCard from "../../../components/ScanResultsCard";
import { useCardInfo } from "../../../hooks/useCard";
import { ROUTES } from "../../../store/Globals";
import "./ScanResultsPage.scss";
import { formatDateShort } from "../../../utils/helper-functions";
import { BackIcon } from "../../../components/BackIcon";
import backIconWhite from "../../../assets/icons-arrow-back-white.svg";
const langConfigPath = "pages.desktop.scanResults";

const ScanResultsPageDesktop: React.FC = () => {
  const {
    cardDetails,
    cardNumber,
    contracts,
    cardName,
    history: cardHistory,
    isLoadingContracts,
    isLoadingHistory,
  } = useCardInfo();

  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const { t } = useTranslation();
  const isCardExpiresInMonth = useMemo(() => {
    if (!cardDetails) return false;
    const monthInms = 1000 * 60 * 60 * 24 * 30;
    return (
      cardDetails?.Environment.EnvEndDate.getTime() <
      new Date().getTime() + monthInms
    );
  }, [cardDetails]);

  const labels = useMemo(() => {
    let cardNameStr = cardName;
    if (!cardNameStr && cardDetails) {
      if (
        cardDetails.Environment.HolderIdNumber &&
        cardDetails.Environment.HolderIdNumber !== "0"
      )
        cardNameStr = t(`${langConfigPath}.header`, {
          cardName: cardDetails.Environment.HolderIdNumber,
        });
    }
    return {
      cardName: cardNameStr,
      cardExpires: t(`${langConfigPath}.date`, {
        expDate: cardDetails
          ? formatDateShort(cardDetails?.Environment.EnvEndDate)
          : "",
      }),
      profile1: cardDetails?.Environment.HolderProf1Str,
      profile1Date: t(`${langConfigPath}.date`, {
        expDate: cardDetails
          ? formatDateShort(cardDetails.Environment.HolderProf1Date)
          : "",
      }),
      profile2:
        cardDetails?.Environment.HolderProf2Str !==
        cardDetails?.Environment.HolderProf1Str
          ? cardDetails?.Environment.HolderProf2Str
          : "",
      profile2Date: t(`${langConfigPath}.date`, {
        expDate: cardDetails
          ? formatDateShort(cardDetails.Environment.HolderProf2Date)
          : "",
      }),
    };
  }, [cardDetails, cardName, t, langConfigPath]);
  return (
    <IonPage
      className="desktop-page desktop-scan-results-page-container"
      dir={appDirection}
    >
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <GreenListPopup />
        <div className="desktop-scan-results-page">
          <div className="flex flex-direction-row">
            <BackIcon closeIcon={backIconWhite} />
            <div>
              <div className="desktop-title-container ">
                {cardDetails && (
                  <>
                    {labels.cardName && (
                      <>
                        <span className="font-size-20 white text bold">
                          {labels.cardName}
                        </span>
                        <span className="font-size-20 white text">|</span>
                      </>
                    )}
                    <span className="font-size-18 flex align-end white text">
                      {cardNumber}
                    </span>
                  </>
                )}
              </div>

              <div className="card-and-current-contracts-container">
                {cardDetails && isCardExpiresInMonth && (
                  <>
                    <span className="text">{labels.cardExpires}</span>
                    <span className="text">|</span>
                  </>
                )}

                <div className="current-profiles-container">
                  {labels.profile1 && (
                    <div className="profile">
                      <span className="text bold">{labels.profile1}</span>
                      <span className="text">{labels.profile1Date}</span>
                    </div>
                  )}
                  {labels.profile2 && <span className="text">|</span>}

                  {labels.profile2 && (
                    <div className="profile">
                      <span className="text bold">{labels.profile2}</span>
                      <span className="text">{labels.profile2Date}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ScanResultsCard
            contracts={contracts}
            cardHistory={cardHistory}
            isContractsLoading={isLoadingContracts}
            isHistoryLoading={isLoadingHistory}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ScanResultsPageDesktop;
