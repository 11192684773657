import { IonCard, IonContent, IonText } from "@ionic/react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../..";

import "./stepsCard.scss";
import { useHistory } from "react-router";
import { BackIcon, MobileBackIcon } from "../BackIcon";
import backIcon from "../../assets/icons-arrow-back-white.svg";

export type stepPage = {
  header?: string;
  subHeader?: string;
  component?: React.ReactNode;
};

const DesktopHeaders = ({
  header,
  subHeader,
  onBack,
}: {
  header?: string;
  subHeader?: string;
  onBack: () => void;
}) => {
  if (!header && !subHeader) return null;
  return (
    <div className="desktop-headers">
      <BackIcon onClick={onBack} closeIcon={backIcon} />
      <div className="flex flex-direction-column desktop-headers-container">
        <IonText className="desktop-header text">{header}</IonText>
        <IonText className="desktop-sub-header text">{subHeader}</IonText>
      </div>
    </div>
  );
};
const MobileHeaders = ({
  header,
  subHeader,
  onBack,
}: {
  header?: string;
  subHeader?: string;
  onBack?: () => void;
}) => {
  return (
    <div className="mobile-step-headers-container">
      <MobileBackIcon onClick={onBack} />
      <div className="mobile-step-headers">
        <IonText className="mobile-header text">{header}</IonText>
        <IonText className="mobile-sub-header text">{subHeader}</IonText>
      </div>
    </div>
  );
};
type TStepsCard = {
  pages: stepPage[];
  step: number;
  onBack: () => void;
  mobile?: boolean;
  cssClass?: string;
};

const StepsCard: React.FC<TStepsCard> = ({
  step,
  pages,
  mobile,
  cssClass,
  onBack,
}) => {
  const isVendor = useSelector((state: RootState) => state.app.isVendor);

  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  return (
    <>
      {!mobile && (
        <DesktopHeaders
          header={pages[step].header}
          subHeader={pages[step].subHeader}
          onBack={step !== 0 ? onBack : () => history.goBack()}
        />
      )}
      <IonCard
        className={classNames({
          ...(cssClass ? { [cssClass]: true } : {}),
          "steps-card": true,
          "mobile-page-card": mobile,
          "desktop-page-card": !mobile,
          "vendor-page-card": isVendor,
        })}
        dir={appDirection}
        data-is-mobile={mobile}
      >
        {mobile && (
          <MobileHeaders
            header={pages[step].header}
            subHeader={pages[step].subHeader}
            onBack={step !== 0 ? onBack : () => history.goBack()}
          />
        )}
        <IonContent>{pages[step].component}</IonContent>
      </IonCard>
    </>
  );
};

export default StepsCard;
