import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { useTranslation } from "react-i18next";
import { IonButton, IonText } from "@ionic/react";
import { useMemo } from "react";
type OrderChargeSummaryProps = {
  mobile?: boolean;
  issueCost?: number;
  deliveryCost?: number;
  nextStep: () => void;
};

const PaymentDesription: React.FC<OrderChargeSummaryProps> = ({
  mobile = false,
  issueCost = 10,
  deliveryCost = 10,
  nextStep,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", {
    keyPrefix: "pages.desktop.order-card.paymentDescription",
  });

  const lables = useMemo(() => {
    return {
      issueCost: t("issueCost", { cost: issueCost }),
      deliveryCost: t("deliveryCost", { cost: deliveryCost }),
      oldToBlackList: t("oldToBlackList"),
      contractsToNewCard: t("contractsToNewCard"),
      freeIssueIfNew: t("freeIssueIfNew"),
      pay: t("pay"),
    };
  }, [t, issueCost, deliveryCost]);

  return (
    <div dir={appDirection} className="order-charge-summary height-100">
      <div className="flex flex-direction-column justify-content-space-between height-100 padding-10">
        <div className="flex flex-direction-column padding-10">
          <div className="text color-dark-sub-text item-info">
            {lables.issueCost}
          </div>

          <div className="text color-dark-sub-text item-info">
            {lables.deliveryCost}
          </div>

          <div className="text color-dark-sub-text item-info">
            {lables.oldToBlackList}
          </div>

          <div className="text color-dark-sub-text item-info">
            {lables.contractsToNewCard}
          </div>

          <div className="text color-dark-sub-text item-info">
            {lables.freeIssueIfNew}
          </div>
        </div>
        <div className="flex width-100 justify-content-center ">
          <IonButton
            onClick={nextStep}
            className="button"
            color="primary"
            expand="block"
          >
            {lables.pay}
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default PaymentDesription;
