import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "..";
import {
  getCardContracts,
  getCardHistory,
  getCardInfo,
} from "../services/CardService";
import { customGET, customPATCH } from "../services/customApi/fetch";
import {
  getCardDetailsSuccess,
  saveCardContracts,
  saveCardHistory,
} from "../store/action-creators/Nfc";
import { ROUTES, STORAGE_KEYS } from "../store/Globals";
import { isHiddenOnMobile } from "../utils/helper-functions";
import { useTempCardPayload } from "./nfc/UseNfc";

export type TCardData = {
  IsSCValid: boolean;
  IsSCLocked: boolean;
  IsLastEventValid: boolean;
  BlacklistAnswer?: number;
  CardAcountType?: number;
  IsEnvironmentValid: boolean;
  IsCardGoingToExpire: boolean;
  Environment: {
    EnvApplicationVersionNumber: number;
    EnvCountryId: number;
    EnvIssuerId: number;
    EnvApplicationNumber: number;
    EnvIssuingDate: Date;
    EnvEndDate: Date;
    EnvPayMethod: number;
    HolderBirthDate: Date;
    HolderCompany: number;
    HolderCompanyID: number;
    HolderIdNumber: string;
    HolderProf1Code: number;
    HolderProf1Date: Date;
    HolderProf2Code: number;
    HolderProf2Date: Date;
    HolderLanguage: number;
    HolderProf1Str: string;
    HolderProf2Str: string;
  };
  NumberOfContracts: number;
};

export const CardAccountTypes = {
  unknown: 0,
  anonymous: 1,
  halfAnonymous: 2,
  personal: 3,
} as const;

export type Tcard = {
  IsValid: boolean;
  IsSuccess: boolean;
  Data: TCardData;
};

export const dateToOrtTimeStamp = (date: Date) => {
  const dateStrArr = date.toISOString().split("T");
  const dateStr = dateStrArr[0].replaceAll("-", "");
  const hour = dateStrArr[1]
    .replaceAll(":", "")
    .replaceAll(".", "")
    .replaceAll("Z", "");
  return dateStr + hour;
};

export const ortTimestampToDate = (ts: string) => {
  if (ts.length !== 17) return;
  if (ts === "00000000000000000") return;

  const dateStr = `${ts.substring(0, 4)}-${ts.substring(4, 6)}-${ts.substring(
    6,
    8
  )}`;
  const hourStr = `${ts.substring(8, 10)}:${ts.substring(
    10,
    12
  )}:${ts.substring(12, 14)}.${ts.substring(14, 17)}`;
  return new Date(`${dateStr}T${hourStr}`);
};

export const parseCard = (card: any) => {
  const {
    HolderProf1Date,
    HolderProf2Date,
    EnvEndDate,
    EnvIssuingDate,
    HolderBirthDate,
  } = card.Data.Environment;
  const Environment = {
    ...card.Data.Environment,
    HolderProf1Date: ortTimestampToDate(HolderProf1Date),
    HolderProf2Date: ortTimestampToDate(HolderProf2Date),
    EnvEndDate: ortTimestampToDate(EnvEndDate),
    EnvIssuingDate: ortTimestampToDate(EnvIssuingDate),
    HolderBirthDate: ortTimestampToDate(HolderBirthDate),
  };
  return {
    ...card,
    Data: { ...card.Data, Environment },
  };
};

export const parsePrice = (price?: number) => {
  if (!price) return "0.00";
  return `${Math.floor(price / 100)}.${(price % 100)
    .toString()
    .padStart(2, "0")}`;
};

const getSavedCard = async (SCNumber: string) => {
  const rsp = await customGET(`card/savedCard/${SCNumber}`);
  return await rsp.data;
};

export const useCardEnv = () => {
  const nfc = useSelector((state: RootState) => state.nfc);

  const { tempCardPayload } = useTempCardPayload();

  const cardNumber = nfc.card.cardNumber;
  const [cardDetails, setCardDetails] = useState<TCardData>();
  const [isLoading, setIdLoading] = useState(false);
  const [cardName, setCardName] = useState<string>();
  useEffect(() => {
    const cardImage = nfc.card.data || tempCardPayload;
    console.log("cardImage", cardImage);
    if (!cardImage) return;
    setIdLoading(true);
    getCardInfo(cardImage)
      .then((scData) => {
        console.log("scData", parseCard(scData)?.Data);
        
        setCardDetails(parseCard(scData)?.Data);
        setIdLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIdLoading(false);
      });
    getSavedCard(cardImage.SCNumber).then((v) => {
      if (!v?.name) return;
      setCardName(v.name);
    });
  }, [nfc.card.data, tempCardPayload]);
  return { cardNumber, cardDetails, isLoading, cardName };
};

export const useCardProfiles = () => {
  const { cardDetails } = useCardEnv();

  if (!cardDetails)
    return { isLoading: true, profile1: undefined, profile2: undefined };
  const {
    HolderProf1Code,
    HolderProf1Date,
    HolderProf1Str,
    HolderProf2Date,
    HolderProf2Str,
    HolderProf2Code,
  } = cardDetails.Environment;
  const profile1 = {
    id: HolderProf1Code,
    name: HolderProf1Str,
    expiresAt: HolderProf1Date,
  };
  const profile2 = {
    id: HolderProf2Code,
    name: HolderProf2Str,
    expiresAt: HolderProf2Date,
  };
  return { profile1, profile2, isLoading: false };
};

export const useCardInfo = () => {
  const nfc = useSelector((state: RootState) => state.nfc);
  const { tempCardPayload } = useTempCardPayload();
  const dispatch = useDispatch();
  const { contracts, history } = nfc.card;
  const routerHistory = useHistory();

  const { cardDetails, cardNumber, cardName, isLoading } = useCardEnv();
  const [isLoadingContracts, setIsLoadingContracts] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  useEffect(() => {
    let cardImage = nfc.card.data || tempCardPayload;
    const savedCardImage = localStorage.getItem(STORAGE_KEYS.cardImage);
    localStorage.removeItem(STORAGE_KEYS.cardImage);

    if (!cardImage && savedCardImage) {
      try {
        const savedCardObj = JSON.parse(savedCardImage);
        if (savedCardObj) {
          dispatch(getCardDetailsSuccess(savedCardObj));
          cardImage = savedCardObj;
        }
      } catch {}
    }
    if (!cardImage) {
      const isMobile = isHiddenOnMobile();
      routerHistory.push(
        isMobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcRead
      );
      return;
    }
    setIsLoadingContracts(true);
    getCardContracts(cardImage)
      .then((contractData) => {
        if (Array.isArray(contractData))
          dispatch(saveCardContracts(contractData));
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoadingContracts(false));
    setIsLoadingHistory(true);
    getCardHistory(cardImage)
      .then((historyData) => {
        if (Array.isArray(historyData)) dispatch(saveCardHistory(historyData));
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoadingHistory(false));
  }, [nfc.card.data, tempCardPayload, dispatch]);

  return {
    cardNumber,
    cardDetails,
    isLoadingCard: !!cardDetails,
    contracts,
    history,
    cardName,
    isLoadingContracts,
    isLoadingHistory,
  };
};

const getUserCards = async () => {
  const rsp = await customGET("card/mycards");
  // return [];
  return await rsp.data;
};
export type TSavedRavkavCard = {
  _id: string;
  lastScan: Date;
  lastLoad: Date;
  expiresAt: Date;
  name: string;
  SCNumber: string;
  payload: { SCNumber: string; SCImage: any };
};
export const useCards = () => {
  const [cards, setCards] = useState<TSavedRavkavCard[]>();
  const [localCards, setLocalCards] = useState<{ cardNumber: number }[]>([]);

  const refetch = () => {
    getUserCards()
      .then((v) => {
        if (!v) return;
        setCards(
          v.map((c: any) => ({
            ...c,
            lastScan: c.lastScan ? new Date(c.lastScan) : undefined,
            lastLoad: c.lastLoad ? new Date(c.lastLoad) : undefined,
            expiresAt: c.expiresAt ? new Date(c.expiresAt) : undefined,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  const addCard = ({ cardNumber }: { cardNumber: number }) => {
    setLocalCards((p) => {
      if (p.find((c) => c.cardNumber === cardNumber)) return p;
      return [...p, { cardNumber }];
    });
  };
  useEffect(() => {
    refetch();
  }, []);
  return { cards, refetch, addCard, localCards };
};

type TRenamePayload = { userId?: string; cardId: string; name: string };
const renameCard = async ({ cardId, ...payload }: TRenamePayload) => {
  const rsp = await customPATCH(`card/rename/${cardId}`, payload);
  return await rsp.data;
};

export const useRenameCard = ({
  onFinish,
}: {
  onFinish?: (result: any) => void;
}) => {
  const [isRenaming, setIsLoading] = useState(false);
  const rename = async (payload: TRenamePayload) => {
    setIsLoading(true);
    const renamed = await renameCard(payload);
    setIsLoading(false);
    onFinish?.(renamed);
    return renamed;
  };
  return { rename, isRenaming };
};
