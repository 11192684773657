import { TContractType } from "../hooks/contracts/useContractsForLoading";
import { TProfile } from "../hooks/useAllProfiles";

const remoteContract = (search: string) => {
  const searchParams = new URLSearchParams(search);
  let step = +(searchParams.get("step") ?? "0");
  const cardNumber = searchParams.get("cardNumber");
  const profileId = searchParams.get("profileId");
  const profileName = searchParams.get("profileName");
  const contractType = searchParams.get("contractType");

  if (step >= 3) {
    if (cardNumber && profileId && profileName && contractType)
      return {
        step,
        cardNumber: +cardNumber,
        profileType: { id: +profileId, name: profileName } as TProfile,
        contractType: +contractType as TContractType,
      };
    else step--;
  }
  if (step === 2) {
    if (cardNumber && profileId && profileName)
      return {
        step,
        cardNumber: +cardNumber,
        profileType: { id: +profileId, name: profileName } as TProfile,
      };
    else step--;
  }
  if (step === 1) {
    if (cardNumber)
      return {
        step,
        cardNumber: +cardNumber,
      };
    else step--;
  }
  return { step: 0 };
};

const searchParser = {
  remoteContract,
};

export default searchParser;
