import { IonContent, IonPage } from "@ionic/react";
import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import DocumentsSentErrorPopup from "../../../components/pop-ups/DocumentsSentErrorPopup";
import DocumentsSentSuccessPopup from "../../../components/pop-ups/DocumentsSentSuccessPopup";
import StepsCard from "../../../components/StepsCard";
import { POPUPS, ROUTES } from "../../../store/Globals";
import "./createProfilePage.scss";
import { TProfiles } from "../OrderCardPage";
import CardOrderDocuments from "../../../components/StepsCardSections/CardOrderDocuments";
import { changePopup } from "../../../store/action-creators/App";
import SelectProfile from "../../../components/StepsCardSections/SelectProfile";

const langConfigPath = "pages.desktop.createProfile";

export const CreateProfileNavigationContext = createContext<any>([]);

const CreateProfilePageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const { selectedProfile } = useSelector((state: RootState) => state.profile);
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [selectedProfiles, setSelectedProfiles] = useState<TProfiles>();

  const pages = [
    {
      header: t(`header`),
      subHeader: t(`subHeader`),
      component: (
        <SelectProfile
          mobile={false}
          nextStep={(profiles) => {
            setSelectedProfiles(profiles);
            setStep((s) => s + 1);
          }}
        />
      ),
    },
    {
      header: t(`header`),
      subHeader:
        selectedProfiles?.profile1 && selectedProfiles.profile2
          ? t("documents.multiProfile.subHeader")
          : t("pages.uploadDocuments.subHeader", {
              profileName: selectedProfiles?.profile1?.name,
            }),
      component: (
        <CardOrderDocuments
          profiles={selectedProfiles}
          nextStep={() => {
            dispatch(changePopup(POPUPS.documentsSentSuccess));
          }}
          isCreateProfile
        />
      ),
    },
  ];

  const handleBackButton = () => {
    if (step !== 0) {
      setStep(step - 1);
    } else {
      history.push(ROUTES.desktopProfiles);
    }
  };

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div
          className={"desktop-create-profile-page width-100"}
          style={step ? { width: "610px" } : { width: "420px" }}
        >
          <StepsCard
            step={step}
            pages={pages}
            cssClass="create-profile-steps-card"
            mobile={false}
            onBack={handleBackButton}
          />

          <DocumentsSentSuccessPopup
            mobile={false}
            info={
              selectedProfile && selectedProfile.files.length
                ? ""
                : t(`popupMsgSuccess`)
            }
          />
          <DocumentsSentErrorPopup mobile={false} info={t(`errorPopupText`)} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateProfilePageDesktop;
