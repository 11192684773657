import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonText,
} from "@ionic/react";
import classNames from "classnames";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../..";
import {
  nfcActions,
  CardEvent,
  Contract,
  GreenContract,
  TContractDetails,
} from "../../hooks/types";
import { changePopup } from "../../store/action-creators/App";
import {
  saveNfcAction,
  setGlobalContractForRenew,
} from "../../store/action-creators/Nfc";
import { POPUPS, ROUTES, SCAN_CARD_RESULTS_TABS } from "../../store/Globals";
import CancelAndRefundPopup from "../pop-ups/CancelAndRefundPopup";
import ContractCard from "./ContractCard";
import HistoryCard from "./HistoryCard";
import "./ScanResultsCard.scss";
import NoData from "../NoData";
import noContractsIcon from "../../assets/no-contracts.svg";
import noHistoryIcon from "../../assets/no-history.svg";
import { CenteredSpinner } from "../Spinner";
import arrowUp from "../../assets/icons-small-arrow-up.svg";
import arrowDown from "../../assets/icons-small-arrow-down.svg";
import RenewContractPopup from "../pop-ups/RenewContractPopup";
import { convertSumByFrofile } from "../../services/CardService";
import PaymentSuccessPopup from "../pop-ups/PaymentSuccessPopup";
import ExchangePopup from "../pop-ups/ExchangePopup";
import { CardAccountTypes, TCardData, useCardEnv } from "../../hooks/useCard";
import { useVendorUser } from "../../hooks/useVendorUser";

const langConfigPath = "scanResultsCard";

type ScanResultsCardProps = {
  mobile?: boolean;
  initialTab?: string;
  contracts?: Contract[];
  cardHistory?: CardEvent[];
  isHistoryLoading?: boolean;
  isContractsLoading?: boolean;
};

const NoContractData = ({ mobile }: { mobile?: boolean }) => {
  const { t } = useTranslation("", {
    keyPrefix: `${langConfigPath}.contracts`,
  });

  const labels = {
    toContractPurchase: t("toContractPurchase"),
    noContracts: t("noContracts"),
  };
  return (
    <NoData
      image={noContractsIcon}
      btnText={labels.toContractPurchase}
      title={labels.noContracts}
      btnLink={
        mobile ? ROUTES.mobilePurchaseContract : ROUTES.desktopPurchaseContract
      }
    />
  );
};

// same as above but for history
const NoHistoryData = () => {
  const { t } = useTranslation("", {
    keyPrefix: `${langConfigPath}.history`,
  });

  const labels = {
    noHistory: t("noHistory"),
    toContractPurchase: t("toContractPurchase"),
  };
  return (
    <NoData
      image={noHistoryIcon}
      title={labels.noHistory}
      btnText={labels.toContractPurchase}
      btnLink={ROUTES.mobilePurchaseContract}
    />
  );
};

async function alterContractForRenew(c: Contract, cardDetails: TCardData) {
  const { Contract: contract, ExtendedInfo } = c;
  const { Sum2Renew: renewSum = 0 } = ExtendedInfo ?? {};
  if (
    contract.Profile !== cardDetails?.Environment.HolderProf1Code &&
    contract.Profile !== cardDetails?.Environment.HolderProf2Code
  ) {
    if (
      cardDetails?.Environment.HolderProf1Code &&
      cardDetails.Environment.HolderProf1Code > 1
    ) {
      contract.Profile = cardDetails.Environment.HolderProf1Code;
    }
    if (
      cardDetails?.Environment.HolderProf2Code &&
      cardDetails.Environment.HolderProf2Code > 1
    ) {
      contract.Profile = cardDetails.Environment.HolderProf2Code;
    }
  }
  const { Units } = await convertSumByFrofile({
    PriceInAgorot: renewSum,
    Profile: contract.Profile,
  });
  const alteredContract: TContractDetails = {
    ...contract,
    PriceInAgorot: renewSum,
    PriceInAgorotByCash: renewSum,
    SpecificContractData: {
      ...contract.SpecificContractData,
      Units,
    },
  };
  return { ...c, Contract: alteredContract };
}

const ContractTab: FC<{
  contracts?: Contract[];
  mobile?: boolean;
}> = ({ contracts, mobile }) => {
  const { cardDetails } = useCardEnv();
  const [contractForRefund, setContractForRefund] = useState<Contract>();
  // const [contractForRenew, setContractForRenew] = useState<Contract>();
  const [contractIndexForConvert, setContractIndexForConvert] =
    useState<number>();
  const [isInactiveOpen, setIsInactiveOpen] = useState(true);
  const [isVendorUserRequired, setIsVendorUserRequired] = useState(false);
  const vendorUser = useVendorUser({ enabled: isVendorUserRequired });
  const { t } = useTranslation("", {
    keyPrefix: `${langConfigPath}.contracts`,
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const labels = useMemo(
    () => ({
      activeTitle: t("activeContracts"),
      inActiveTitle: t("inactiveContracts"),
      greenTitle: t("greenContracts"),
      loadOtherBtn: t("loadContractNotOnCardButton.label"),
      toContractPurchase: t("toContractPurchase"),
      newContract: t("newContract"),
      card: {
        refund: t("card.refund"),
        renew: (price: number) => t("card.renew", { price }),
        convert: t("card.convert"),
        loadGreen: t("card.load"),
      },
    }),
    [t]
  );
  const greenContracts =
    useSelector((state: RootState) => state.nfc.card.greenList) ?? [];
  const actions = useMemo(
    () => ({
      refund: (c: Contract) => {
        setIsVendorUserRequired(true);
        setContractForRefund(c);
        dispatch(changePopup(POPUPS.cancelAndRefund));
      },
      renew: async (c: Contract) => {
        // setIsVendorUserRequired(true);
        console.log("cardDetails", cardDetails);

        if (!cardDetails) return;
        const alteredContract = await alterContractForRenew(c, cardDetails);
        dispatch(setGlobalContractForRenew(alteredContract.Contract));
        if (mobile) {
          history.push(ROUTES.mobilePurchaseContract);
        } else {
          history.push(ROUTES.desktopPurchaseContract);
        }
        // setContractForRenew(alteredContract);
        // dispatch(changePopup(POPUPS.renewContract));
      },
      convert: (c: Contract) => {
        // TODO: implement
        setContractIndexForConvert(c.IndexOnSC);
        dispatch(changePopup(POPUPS.exchange));
      },
      loadGreen: (c: GreenContract) => {
        const { Contract, GreenListConfirmationCode, J5_ConfirmationId } = c;
        dispatch(
          saveNfcAction({
            action: nfcActions.loadContract,
            data: {
              contract: Contract,
              GreenListConfirmationCode,
              paymentUid: J5_ConfirmationId,
              payForFlexible: Contract.SpecificContractData.Units,
            },
          })
        );
        history.push(mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcRead);
      },
    }),
    [history, dispatch, mobile, cardDetails]
  );

  useEffect(() => {
    if (!contracts) return;
    const imprisonedContract = contracts.find((c) => c.IsImprisonedBalance);
    if (imprisonedContract) {
      actions.convert(imprisonedContract);
    }
  }, [contracts]);
  if (!contracts || contracts.length === 0) {
    return <NoContractData mobile={mobile} />;
  }

  const validContracts = contracts?.filter((c) => c.IsContractValid) ?? [];
  const invalidContracts = contracts?.filter((c) => !c.IsContractValid) ?? [];
  return (
    <>
      {greenContracts && greenContracts.length > 0 && (
        <div className="green-contracts-container contracts-container">
          <div className="contract-section-header">
            <IonText className="text">{labels.greenTitle}</IonText>
          </div>
          {greenContracts.map((c) => (
            <ContractCard
              key={c.GreenListConfirmationCode}
              mobile={mobile}
              contractDetails={c.Contract}
              loadGreen={() => actions.loadGreen(c)}
            />
          ))}
        </div>
      )}
      <div className="active-contracts-container contracts-container">
        {validContracts.length > 0 && (
          <div className="contract-section-header">
            <IonText className="text">{labels.activeTitle}</IonText>
          </div>
        )}
        {validContracts.map((c) => {
          const isRefundable =
            c.CancellationData?.IsPossible2Cancel &&
            cardDetails?.CardAcountType === CardAccountTypes.personal;

          return (
            <ContractCard
              contract={c}
              key={c.IndexOnSC}
              indexOnSC={c.IndexOnSC}
              mobile={mobile}
              contractDetails={c.Contract}
              renewSum={c.ExtendedInfo?.Sum2Renew}
              renew={
                c.ExtendedInfo?.IsPossible2Renew
                  ? () => actions.renew(c)
                  : undefined
              }
              convert={
                c.ExtendedInfo?.IsPossible2Convert2Better
                  ? () => actions.convert(c)
                  : undefined
              }
              refund={isRefundable ? () => actions.refund(c) : undefined}
            />
          );
        })}
      </div>

      <div className="inactive-contracts-container contracts-container">
        {invalidContracts.length > 0 && (
          <div
            className="contract-section-header"
            onClick={() => setIsInactiveOpen((v) => !v)}
          >
            <IonText className="text">{labels.inActiveTitle} </IonText>
            <IonIcon
              icon={isInactiveOpen ? arrowUp : arrowDown}
              className="size-20"
            />
          </div>
        )}
        {isInactiveOpen &&
          invalidContracts.map((c) => (
            <ContractCard
              contract={c}
              key={c.IndexOnSC}
              indexOnSC={c.IndexOnSC}
              mobile={mobile}
              contractDetails={c.Contract}
              convert={
                c.ImprisonedBalanceSum ? () => actions.convert(c) : undefined
              }
              renew={
                c.ExtendedInfo?.IsPossible2Renew
                  ? () => actions.renew(c)
                  : undefined
              }
              renewSum={c.ExtendedInfo?.Sum2Renew}
            />
          ))}
      </div>
      {/* <RenewContractPopup contract={contractForRenew} mobile={mobile} /> */}
      <PaymentSuccessPopup
        routeOnDismiss={mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcRead}
      />
      <CancelAndRefundPopup contract={contractForRefund} mobile={mobile} />
      <ExchangePopup contractIndex={contractIndexForConvert} mobile={mobile} />
    </>
  );
};
const HistoryTab: FC<{ mobile?: boolean; cardHistory?: CardEvent[] }> = ({
  mobile = false,
  cardHistory,
}) => {
  if (!cardHistory || cardHistory.length === 0) {
    return <NoHistoryData />;
  }
  return (
    <div className="history-tab-cards-container">
      {cardHistory.map((cardEv) => (
        <HistoryCard
          key={cardEv.EventDateTime + cardEv.EventType}
          mobile={mobile}
          cardEv={cardEv}
        />
      ))}
    </div>
  );
};

const ScanResultsCard: React.FC<ScanResultsCardProps> = ({
  mobile = false,
  initialTab = SCAN_CARD_RESULTS_TABS.contracts,
  contracts,
  cardHistory,
  isContractsLoading,
  isHistoryLoading,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const [selectedTab, setSelectedTab] = useState(initialTab);

  return (
    <IonCard
      dir={appDirection}
      className={classNames("scan-results-card", {
        "desktop-page-card": !mobile,
      })}
      data-is-mobile={mobile}
    >
      <IonCardHeader className="scan-results-card-header">
        <div
          data-is-mobile={mobile}
          className={classNames("header-button", {
            "selected-tab": selectedTab === SCAN_CARD_RESULTS_TABS.contracts,
          })}
          onClick={() => {
            setSelectedTab(SCAN_CARD_RESULTS_TABS.contracts);
          }}
        >
          <IonText className="text">{t("header.contracts")}</IonText>
        </div>
        <div
          data-is-mobile={mobile}
          className={classNames("header-button", {
            "selected-tab": selectedTab === SCAN_CARD_RESULTS_TABS.history,
          })}
          onClick={() => {
            setSelectedTab(SCAN_CARD_RESULTS_TABS.history);
          }}
        >
          <IonText className="text">{t("header.history")}</IonText>
        </div>
      </IonCardHeader>
      <IonCardContent className="scan-results-card-content">
        {selectedTab === SCAN_CARD_RESULTS_TABS.contracts ? (
          isContractsLoading ? (
            <CenteredSpinner />
          ) : (
            <ContractTab contracts={contracts} mobile={mobile} />
          )
        ) : isHistoryLoading ? (
          <CenteredSpinner />
        ) : (
          <HistoryTab mobile={mobile} cardHistory={cardHistory} />
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default ScanResultsCard;
