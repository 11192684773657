import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "../../..";
import StepsCard from "../../../components/StepsCard";
import "./orderChargePage.scss";
import SelectContract from "../../../components/StepsCardSections/SelectContract";
import OrderChargeSummary from "../../../components/StepsCardSections/OrderChargeSummary";
import { TContractDetails } from "../../../hooks/types";
import { TContractType } from "../../../hooks/contracts/useContractsForLoading";
import SelectContractType from "../../../components/StepsCardSections/SelectContractType";
import SelectCard from "../../../components/StepsCardSections/SelectCard";
import { TProfile } from "../../../hooks/useAllProfiles";
import SelectAllProfile from "../../../components/StepsCardSections/SelectAllProfile";
import PaymentCard from "../../../components/PaymentCard";
import {
  CardLoadLocation,
  saveContractToGreenList,
} from "../../../hooks/contracts/useContractGreenList";
import { useTempCardPayload } from "../../../hooks/nfc/UseNfc";
import PaymentSuccessPopup from "../../../components/pop-ups/PaymentSuccessPopup";
import { POPUPS, ROUTES } from "../../../store/Globals";
import { changePopup } from "../../../store/action-creators/App";
import { SelectGreenListLocation } from "../../../components/StepsCardSections/SelectContract/GreenListLocation";
import searchParser from "../../../services/searchParser";

const langConfigPath = "pages.mobile.orderCharge";

const PurchaseContractRemoteDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { tempCardPayload } = useTempCardPayload();

  useEffect(() => {
    if (tempCardPayload) {
      setCardNumber(parseInt(tempCardPayload.SCNumber, 16));
      setStep(2);
    }
  }, [tempCardPayload]);

  const [cardNumber, setCardNumber] = useState<number>();

  const [profileType, setProfileType] = useState<TProfile>();
  const [contractType, setContractType] = useState<TContractType>();
  const [loadingLocation, setLoadingLocation] = useState<CardLoadLocation>();
  const [selectedContract, setSelectedContract] = useState<TContractDetails>();
  const [step, setStep] = useState(0);
  const nextStep = () => setStep((s) => s + 1);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);

  //   searchParams.set("step", step.toString());
  //   if (step === 0) {
  //     setContractType(undefined);
  //     setSelectedContract(undefined);
  //   } else if (step === 1 && cardNumber) {
  //     searchParams.set("cardNumber", cardNumber.toString());
  //   } else if (step === 2 && profileType) {
  //     searchParams.set("profileId", profileType.id);
  //     searchParams.set("profileName", profileType.name);
  //   } else if (step === 3 && contractType) {
  //     searchParams.set("contractType", contractType);
  //   }
  //   history.push({ search: searchParams.toString() });
  // }, [step]);
  // useEffect(() => {
  //   const params = searchParser.remoteContract(location.search);

  //   if (params.step !== step) {
  //     setStep(params.step);
  //     setCardNumber(params.cardNumber);
  //     setProfileType(params.profileType);
  //     setContractType(params.contractType);
  //   }
  // }, [location.search]);

  const pages = [
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.pages.selectCard.header`),
      component: (
        <SelectCard
          nextStep={(cardNumber) => {
            setCardNumber(cardNumber);
            nextStep();
          }}
        />
      ),
    },
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.pages.selectProfile.header`),
      component: (
        <SelectAllProfile
          nextStep={(profile) => {
            setProfileType(profile);
            nextStep();
          }}
          buttonLabel={t(`${langConfigPath}.pages.selectProfile.button.label`)}
        />
      ),
    },
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.pages.selectContract.header`),
      component: (
        <SelectContractType
          profileId={profileType?.id}
          isRemote
          nextStep={(contractType) => {
            setContractType(contractType);
            nextStep();
          }}
        />
      ),
    },
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.pages.selectContract.header2`, {
        type: t("pages.mobile.orderCharge.contractTypes." + contractType),
      }),
      component: (
        <SelectContract
          nextStep={(contract) => {
            setSelectedContract(contract);
            nextStep();
          }}
          isRemote
          profileType={profileType}
          contractType={contractType}
        />
      ),
    },
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.pages.selectLocation.header`),
      component: (
        <SelectGreenListLocation
          nextStep={(loc) => {
            setLoadingLocation(loc);
            nextStep();
          }}
        />
      ),
    },
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.pages.summary.header`),
      component: (
        <OrderChargeSummary
          contract={selectedContract}
          nextStep={(changedContract) => {
            setSelectedContract(changedContract);
            nextStep();
          }}
          isRemote
        />
      ),
    },
    {
      header: t(`${langConfigPath}.pages.payment.header`),
      subHeader: t(`${langConfigPath}.pages.payment.subHeader`, {
        contractName: selectedContract?.ETTStr,
      }),
      component: (
        <>
          {cardNumber && selectedContract && (
            <PaymentCard
              priceInAgorot={selectedContract.PriceInAgorot}
              purchaseDetails={{
                contract: selectedContract,
                ravKavNumber: cardNumber,
              }}
              nextStep={({ paymentUid, expiration }) => {
                console.log("saveContractToGreenList");

                saveContractToGreenList({
                  location: loadingLocation,
                  cardNumber,
                  contract: selectedContract,
                  notes: "some optional notes",
                  payment: { confirmation: paymentUid, expiration },
                  profileId: selectedContract.Profile,
                }).then((v) => dispatch(changePopup(POPUPS.paymentSuccess)));
              }}
              onError={(err) => {
                console.log({ err });
                setStep((s) => s - 1);
              }}
            />
          )}
        </>
      ),
    },
  ];

  const handleBackButton = () => {
    if (step > 0) setStep(step - 1);
    else history.goBack();
  };

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <PaymentSuccessPopup routeOnDismiss={ROUTES.home} />
        <div className="desktop-profiles-page">
          <StepsCard
            step={step}
            pages={pages}
            cssClass="order-charge-steps-card"
            onBack={handleBackButton}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PurchaseContractRemoteDesktop;
