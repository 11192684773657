import { IonItem, IonIcon, IonLabel } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../..";
import "./LanguagesDropDownItem.scss";
import classNames from "classnames";
import { changeAppDirection } from "../../../../../store/action-creators/App";
import { StorageService } from "../../../../../services/LocalStorageService";
import { STORAGE_KEYS } from "../../../../../store/Globals";
import { changeLangApi } from "../../../../../services/customApi/fetch";

type LanguagesDropDownItem = {
  text: string | undefined;
  icon?: string | undefined;
  lang: string;
  dir: string;
  onClicked?: React.MouseEventHandler;
};

const LanguagesDropDownItem: React.FC<LanguagesDropDownItem> = ({
  text,
  icon,
  lang,
  dir,
  onClicked = () => {},
}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const appDirection = useSelector((state: RootState) => state.app.direction);

  return (
    <IonItem
      dir={appDirection}
      className={classNames({
        "languages-dropdown-item": true,
        "selected-lang": i18n.language === lang,
      })}
      color={i18n.language === lang ? "secondary" : ""}
      lines="full"
      button={true}
      onClick={(e) => {
        changeLangApi(lang);
        i18n.changeLanguage(lang);
        dispatch(changeAppDirection(dir));
        StorageService.set(STORAGE_KEYS.language, lang);

        onClicked(e);
      }}
    >
      <IonIcon className="item-icon" src={icon} />
      <IonLabel className="item-label text">{text}</IonLabel>
    </IonItem>
  );
};

export default LanguagesDropDownItem;
