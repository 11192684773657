import { 
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonItem,
    IonList,
    IonSearchbar,
    IonText,
    } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../..';
import { SearchObject } from '../../../utils/search-config';
    
import clearIcon from '../../assets/icons-hamburger-x.svg'
import searchIcon from '../../assets/icons-search.svg'

import './SearchResultCardItem.scss';
    
type SearchResultsCardItem = {
    result: SearchObject
    mobile?: boolean | undefined
}

const SearchResultsCardItem: React.FC<SearchResultsCardItem> = ({result, mobile=false}) => {
    const appDirection = useSelector((state: RootState)=>state.app.direction)
    const history = useHistory()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    return (
    <IonItem
    data-is-mobile={mobile}
    dir={appDirection}
    className="search-result text clickable"
    onClick={()=>{history.push(mobile? result.route.mobile: result.route.desktop)}}
    >
        <IonText className="result-label">{result.title}</IonText>
        <IonText className="result-text">{result.description}</IonText>
    </IonItem>

    )
}

export default SearchResultsCardItem;
