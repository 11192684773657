import { IonIcon } from "@ionic/react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { useHistory } from "react-router";
import backIcon from "../../assets/icons-arrow-back.svg";
import mobileBackIcon from "../../assets/mobile-icons-arrow-back.svg";
import backIconPango from "../../assets/pangoBack.svg";

type Props = {
  onClick?: () => void;
  closeIcon?: string;
  className?: string;
};

export const BackIcon: React.FC<Props> = ({
  onClick,
  className,
  closeIcon,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();

  const defaultOnClick = () => {
    history.goBack();
  };
  return (
    <IonIcon
      className={classNames(
        "desktop-back-button padding-x-5",
        { "rotate-180": appDirection === "ltr" },
        className
      )}
      src={closeIcon ? closeIcon : backIcon}
      onClick={onClick ?? defaultOnClick}
    />
  );
};

export const MobileBackIcon: React.FC<Props> = ({
  onClick,
  className,
  closeIcon,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const backicon = vendor === "pango" ? backIconPango : backIcon;

  const defaultOnClick = () => {
    history.goBack();
  };

  console.log({ closeIcon });
  return (
    <IonIcon
      className={classNames(
        "mobile-back-arrow",
        { "rotate-180": appDirection === "ltr" },
        className
      )}
      src={closeIcon ? closeIcon : backicon}
      onClick={onClick ?? defaultOnClick}
      size="24"
    />
  );
};
