import { IonButton, IonText } from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./uploadDocuments.scss";
import {
  CreateOrUpdateProfileTypeEnum,
  ProfileDocForRequest,
} from "../../../services/models/profile";
import { RootState } from "../../..";
import UploadDocumentCard from "../../UploadDocumentCard";
import { TProfile } from "../../../hooks/useAllProfiles";
import { POPUPS } from "../../../store/Globals";
import { changePopup } from "../../../store/action-creators/App";
import { customGET, customPOST } from "../../../services/customApi/fetch";

const langConfigPath = "stepsSections.uploadDocuments";

type TUploadDocuments = {
  mobile?: boolean;
  nextStep?: (reqId: string) => void;
  profiles?: { profile1?: TProfile; profile2?: TProfile };
  isCreateProfile?: boolean;
};
type TProfileFile = {
  Description: string;
  FileName: string;
  IsDigitalCertificateAllowed: boolean;
  IsRequired: boolean;
  ProfileId: number;
};
type SelectedFiles = {
  id: string;
  data: ProfileDocForRequest;
  isMandatory: boolean;
};

type TProfileInfo = TProfile & {
  files: TProfileFile[];
};

const getProfileFiles = async (profileId: number) => {
  const rsp = await customGET(`profiles/files/${profileId}`);
  return rsp.data;
};

const createNewCardRequest = async (data: any) => {
  return customPOST("profiles/createCardRequest", data);
};
const createNewProfileRequest = async (data: any) => {
  return customPOST("profiles/createProfileRequest", data);
};

function uploadImage(data: { base64: string; name: string }) {
  return customPOST<{ id: string }>("images/upload", data);
}

const useSelectedProfile = ({
  profiles,
}: {
  profiles?: { profile1?: TProfile; profile2?: TProfile };
}) => {
  const [profile1, setProfile1] = useState<TProfileInfo>();
  const [profile2, setProfile2] = useState<TProfileInfo>();
  useEffect(() => {
    if (profiles?.profile1)
      getProfileFiles(profiles.profile1.id).then(({ files }) => {
        if (profiles?.profile1)
          setProfile1({
            files,
            ...profiles.profile1,
          });
      });
    if (profiles?.profile2)
      getProfileFiles(profiles.profile2.id).then(({ files }) => {
        if (profiles?.profile2)
          setProfile2({
            files,
            ...profiles.profile2,
          });
      });
  }, [profiles]);

  return { profile1, profile2 };
};

const useSelectFiles = () => {
  const [selectedFiles, setSelectedFiles] = useState<SelectedFiles[]>([]);
  const addFile = (file: SelectedFiles) => {
    setSelectedFiles((prev) => [...prev, file]);
  };
  const updateFile = (id: string, file: ProfileDocForRequest) => {
    setSelectedFiles((prev) => {
      const item = prev.find(({ id: inId }) => inId === id);
      if (!item) return prev;
      item.data = file;
      return [...prev];
    });
  };
  const fileExists = (id: string) => selectedFiles.some((v) => v.id === id);
  const upsertFile = (
    id: string,
    file: ProfileDocForRequest,
    isMandatory: boolean
  ) => {
    if (fileExists(id)) updateFile(id, file);
    else addFile({ id, data: file, isMandatory });
  };
  const getFile = (id: string) => {
    return selectedFiles.find((v) => v.id === id);
  };
  return { selectedFiles, fileExists, upsertFile, getFile };
};

const CardOrderDocuments: React.FC<TUploadDocuments> = ({
  mobile,
  profiles,
  nextStep,
  isCreateProfile = false,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const cardNumber = useSelector(
    (state: RootState) => state.nfc.card.cardNumber
  );
  const dispatch = useDispatch();
  const { profile1, profile2 } = useSelectedProfile({ profiles });

  const { fileExists, selectedFiles, upsertFile, getFile } = useSelectFiles();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const handleSendButton = async () => {
    const docs = selectedFiles.map((doc) => doc.data);
    if (isCreateProfile) {
      const promises = [profile1, profile2].map(async (p) => {
        if (!p) return;
        const rsp = await createNewProfileRequest({
          type: CreateOrUpdateProfileTypeEnum.create_new_profile,
          profileName: p.name,
          request_type: p.id,
          cardNumber: cardNumber?.toString(),
          docs: docs.filter((doc) => doc.profileId === Number(p.id)),
        });
        return rsp;
      });
      await Promise.all(promises);
      nextStep && nextStep("");
      return;
    }

    const cardRequest = await createNewCardRequest({
      type: CreateOrUpdateProfileTypeEnum.new_card,
      profileName: "",
      request_type: 0,
      docs: docs.filter((doc) => doc.profileId === 0),
    }).catch((err) => {
      console.error(err);
      dispatch(changePopup(POPUPS.documentsSentError));
    });
    if (!cardRequest) return;
    const promises = [profile1, profile2]
      .filter((p) => p)
      .map(
        async (p) =>
          await createNewProfileRequest({
            type: CreateOrUpdateProfileTypeEnum.new_card,
            profileName: p!.name,
            request_type: Number(p!.id),
            cardNumber: cardNumber?.toString(),
            cardRequest: cardRequest.data._id,
            docs: docs.filter((doc) => doc.profileId === Number(p!.id)),
          })
      );
    await Promise.all(promises);
    // .then(() => dispatch(changePopup(POPUPS.documentsSentSuccess)))
    // .catch(() => dispatch(changePopup(POPUPS.documentsSentError)));

    nextStep && nextStep(cardRequest.data._id);
  };

  const labels = {
    idFileName: t("files.idOrLisence"),
    imageFileName: t("files.image"),
    btnLabel: t("button.label"),
    fileLimit: t("files.limit"),
    generalCategory: t("files.genCategory"),
    fileRequired: t("files.required"),
  };

  const defaultFiles: TProfileFile[] = [
    {
      FileName: labels.imageFileName,
      Description: labels.imageFileName,
      IsDigitalCertificateAllowed: false,
      IsRequired: true,
      ProfileId: 0,
    },
    {
      FileName: labels.idFileName,
      Description: labels.idFileName,
      IsDigitalCertificateAllowed: false,
      IsRequired: true,
      ProfileId: 0,
    },
  ];
  const handleFileSelected = useCallback(
    (
      data: string,
      id: string,
      file: TProfileFile,
      profile: TProfileInfo,
      originalFileName?: string
    ) => {
      const fileData = {
        profileId: Number(profile.id) || 0,
        ortDocName: file.Description,
        url: data,
        doc_type: file.FileName,
        originalFileName,
        fileExtension:
          originalFileName?.split(".")[originalFileName?.split(".").length - 1],
      };
      upsertFile(id, fileData, file.IsRequired);
    },
    []
  );

  const isFileSelected = ({
    file,
    profile,
  }: {
    file: TProfileFile;
    profile: TProfileInfo;
  }) => {
    const id = profile.id + file.FileName;
    return fileExists(id);
  };

  const DocumentCard = ({
    file,
    profile,
  }: {
    file: TProfileFile;
    profile: TProfileInfo;
  }) => {
    const header = file.Description;
    const isSelected = isFileSelected({ file, profile });
    const id = profile.id + file.FileName;
    const sizeInKB = 50 * 10;

    const selectedFile = isSelected && getFile(id);
    const info = (() => {
      if (file.IsDigitalCertificateAllowed) return "";
      if (isSelected && selectedFile)
        return selectedFile.data.originalFileName ?? "";
      return (
        labels.fileLimit + (file.IsRequired ? ` (${labels.fileRequired})` : "")
      );
    })();

    return (
      <UploadDocumentCard
        header={header}
        info={info}
        maxFileSize={sizeInKB * 1024}
        fileTypes={
          !file.IsDigitalCertificateAllowed
            ? ["image/jpeg", "image/png"]
            : undefined
        }
        fileSelected={isSelected}
        onFileSelected={async (data, originalFileName) => {
          const imgRsp = await uploadImage({
            base64: data,
            name: file.FileName,
          });
          if (!imgRsp?.data?.id) return;
          handleFileSelected(
            imgRsp.data.id,
            id,
            file,
            profile,
            originalFileName
          );
        }}
        mobile={mobile}
      />
    );
  };

  const isAllrequiredFilesUploaded = useMemo(() => {
    const isDefaultFilesOk =
      isCreateProfile ||
      defaultFiles.every((f) => {
        if (f.IsRequired) return fileExists("0" + f.FileName);
        return true;
      });
    if (!isDefaultFilesOk) return false;
    const isP1FilesOk =
      !profile1 ||
      profile1?.files.every((f) => {
        if (f.IsRequired) return fileExists(profile1.id + f.FileName);
        return true;
      });
    if (!isP1FilesOk) return false;
    const isP2FilesOk =
      !profile2 ||
      profile2?.files.every((f) => {
        if (f.IsRequired) return fileExists(profile2.id + f.FileName);
        return true;
      });
    if (!isP2FilesOk) return false;
    return true;
  }, [profile1, profile2, fileExists]);

  return (
    <div
      className="upload-documents width-100 position-relative"
      dir={appDirection}
    >
      {!isCreateProfile && (
        <>
          <div className="upload-documents-header">
            <IonText className="text">{labels.generalCategory}</IonText>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {defaultFiles.map((file) => {
              return (
                <DocumentCard
                  key={file.FileName}
                  file={file}
                  profile={{ id: 0, name: "", files: defaultFiles }}
                />
              );
            })}
          </div>
        </>
      )}
      {profile1 && (
        <>
          <div className="upload-documents-header">
            <div className="line"></div>
            <IonText className="text">{profile1.name}</IonText>
            <div className="line"></div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {profile1.files?.map((file) => (
              <DocumentCard
                key={file.FileName}
                file={file}
                profile={profile1}
              />
            ))}
          </div>
        </>
      )}
      {profile2 && (
        <>
          <div className="upload-documents-header">
            <div className="line"></div>
            <IonText className="text">{profile2.name}</IonText>
            <div className="line"></div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {profile2.files?.map((file) => (
              <DocumentCard
                key={file.FileName}
                file={file}
                profile={profile2}
              />
            ))}
          </div>
        </>
      )}
      <div className="padding-15">
        <IonButton
          disabled={!isAllrequiredFilesUploaded}
          className="main-blue-buttons space-down"
          onClick={handleSendButton}
        >
          {labels.btnLabel}
        </IonButton>
      </div>
    </div>
  );
};

export default CardOrderDocuments;
