import { IonButton, IonIcon, IonRouterLink, IonText } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import exchangeIcon from "../../../assets/icons-exchange.svg";
import "./ExchangePopup.scss";
import { ExchangeParams } from "../../../hooks/types";
import { customPOST } from "../../../services/customApi/fetch";
import { RootState } from "../../..";
import { saveImprisonedSum } from "../../../store/action-creators/Nfc";

type TExchangePopup = {
  mobile?: boolean;
  contractIndex?: number;
};
const langConfigPath = "popUps.exchange";

function getExchangeInfo(SCNumber: string, SCImage: any, IndexOnSC: number) {
  return customPOST("card/convertContractInfo", {
    SCNumber,
    SCImage,
    IndexOnSC,
  });
}

const ExchangePopup: React.FC<TExchangePopup> = ({
  mobile = false,
  contractIndex,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const [routeOnDismiss, setRouteOnDismiss] = useState<string>();
  const [exchangeParams, setExchangeParams] = useState<ExchangeParams>();
  const image = useSelector((state: RootState) => state.nfc.card.data);

  const readRoute = useMemo(
    () =>
      mobile ? ROUTES.mobileExchangeProfile : ROUTES.desktopExchangeProfile,
    [mobile]
  );

  const isToBetter = useMemo(() => {
    return exchangeParams?.CurrentInfo.ProfileId === 1;
  }, [exchangeParams]);
  const onSelectProfile = () => {
    if (!exchangeParams) return;
    setRouteOnDismiss(readRoute);
    dispatch(saveImprisonedSum(exchangeParams));
    dispatch(changePopup(POPUPS.clear));
  };

  const labels = useMemo(() => {
    if (!exchangeParams) return {};
    const amount = (
      exchangeParams.SelectedContract.ImprisonedBalanceSum / 100
    ).toFixed(2);
    const profileName = exchangeParams.SelectedContract.Contract.ProfileStr;

    const text = isToBetter
      ? t("to-better-text")
      : t("text", { amount, profileName });
    console.log("isToBetter", isToBetter);

    return {
      text,
      header: t("header"),
      chooseProfileButton: t("chooseProfileButton.label"),
      extraInfoLink: t("extraInfoLink.label"),
    };
  }, [t, exchangeParams, isToBetter]);

  useEffect(() => {
    console.log(image, contractIndex);

    if (!image || !contractIndex) return;
    getExchangeInfo(image?.SCNumber, image?.SCImage, contractIndex).then(
      (res) => setExchangeParams(res.data)
    );
  }, [contractIndex]);

  return (
    <Popup
      popUpId={POPUPS.exchange}
      mobile={mobile}
      routeOnDismiss={routeOnDismiss}
      cssClass="exchange-pop-up-container"
    >
      {exchangeParams && (
        <div
          className="pop-up exchange-pop-up flex ion-align-items-center"
          data-is-mobile={mobile}
        >
          <IonIcon className="pop-up-icon" src={exchangeIcon} />
          <IonText className="pop-up-header text">{labels.header}</IonText>
          <IonText className="text info">{labels.text ?? ""}</IonText>
          <IonButton
            className="button-primary pop-up-button"
            onClick={onSelectProfile}
          >
            {labels.chooseProfileButton}
          </IonButton>

          <IonRouterLink className="link text">
            {labels.extraInfoLink}
          </IonRouterLink>
        </div>
      )}
    </Popup>
  );
};

export default ExchangePopup;
