import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "..";

export const useUserCreditCard = () => {
  const userDetails = useSelector(
    (state: RootState) => state.user.GetUserData.data
  );
  const vendorUserDetails = useSelector(
    (state: RootState) => state.user.vendorUserData
  );

  const creditCardDetails = useMemo(() => {
    if (vendorUserDetails)
      return {
        ...vendorUserDetails,
        lastCharge: vendorUserDetails.lastChargeDate,
      };
    const { ccNumber, ccToken, ccExpires } = userDetails ?? {};
    if (ccNumber && ccToken && ccExpires)
      return {
        ccNumber,
        ccToken,
        ccExpires,
        lastCharge: userDetails?.lastChargeDate,
      };
  }, [userDetails]);
  return creditCardDetails;
};
