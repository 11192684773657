import { IonCard, IonContent, IonPage, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import ChargeHistoryCard from "../../../components/ChargeHistoryCard";
import { Charge } from "../../../hooks/types";
import { useChargeHistory } from "../../../hooks/useChargeHistory";

import "./chargeHistoryPage.scss";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.chargeHistory";

const ChargeHistoryPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const { chargeHistoryGrouped, isLoading } = useChargeHistory({
    type: "contract",
  });

  const DateSection = ({
    date,
    values,
  }: {
    date?: string;
    values?: Charge[];
  }) => {
    return (
      <div className="date-section">
        <IonText className="date text weight-500">{date}</IonText>
        <div className="line  margin-auto"></div>
        {values?.map((charge) => (
          <ChargeHistoryCard mobile charge={charge} />
        ))}
      </div>
    );
  };

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-charge-history-page">
          <div className="mobile-title-container">
            <MobileBackIcon />
            <IonText className="mobile-header text">{t("header")}</IonText>
          </div>

          <IonCard className="mobile-page-card">
            {chargeHistoryGrouped.map(([date, values]) => (
              <DateSection date={date} values={values} />
            ))}

            {/* <div className="flex ion-align-items-center flex-direction-column">
                      <IonIcon className="empty-icon" src={emptyIcon}/>
                      <IonText className="text font-size-18 weight-500 text-align-center">
                        <Trans>
                          {t("noHistoryFoundText")}
                        </Trans>
                      </IonText>
                    </div> */}
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChargeHistoryPageMobile;
