import { useSelector } from "react-redux";
import { RootState } from "../..";
import "./noData.scss";
import {
  IonButton,
  IonCol,
  IonIcon,
  IonImg,
  IonRow,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router";

type NoData = {
  image?: string;
  title?: string;
  btnText?: string;
  btnLink?: string;
};

const NoData: React.FC<NoData> = ({ image, title, btnText, btnLink }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  return (
    <div className="no_data">
      <div dir={appDirection} className="no_data">
        <IonCol>
          <IonRow className="image-container">
            <IonIcon className="no_data_icon" src={image} />
          </IonRow>

          <IonText className="text font-size-18">{title}</IonText>
        </IonCol>
      </div>

      <button
        // expand="full"
        className="main-blue-buttons pop-up-botton width-100 bold font-size-16"
        onClick={() => history.push(btnLink!)}
      >
        {btnText}
      </button>
    </div>
  );
};

export default NoData;
