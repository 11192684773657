import { Socket } from "socket.io-client";
import { socketEvents } from "../../store/action-creators/Nfc";

export const getCardPayloadFromSocket = async (
  socket: Socket,
  userId?: string
) => {
  const asyncPayload = new Promise((resolve, reject) => {
    socket.once(socketEvents.CardPayload, (event) => {
      if (event.error) return reject(event.error);
      resolve(event);
    });
  });
  socket.emit(socketEvents.initCardPayload, { userId });
  return await asyncPayload;
};
