import { IonCard, IonCardContent, IonIcon, IonText } from "@ionic/react";
import classNames from "classnames";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import uplaodDocIcon from "../../assets/icons-upload-document.svg";
import uplaodDocIconSuccess from "../../assets/icons-upload-document-success.svg";
import uplaodDocIconError from "../../assets/icons-upload-document-error.svg";
import { FileType } from "../../services/models/profile";
import FileService from "../../services/FileService";
import "./uploadDocumentCard.scss";
import React from "react";

type DocumentState = "regular" | "success" | "error";

type UploadDocumentCardProps = {
  header: string;
  info: string;
  fileSelected?: boolean;
  fileTypes?: FileType[];
  mobile?: boolean;
  state?: DocumentState;
  hidden?: boolean;
  maxFileSize?: number;
  disabled?: boolean;
  onFileSelected?: (e: any, originalFileName?: string) => {} | void;
};

const returnCorrectIcon = (state: DocumentState) => {
  switch (state) {
    case "regular":
      return uplaodDocIcon;
    case "success":
      return uplaodDocIconSuccess;
    case "error":
      return uplaodDocIconError;
  }
};

const UploadDocumentCard: React.FC<UploadDocumentCardProps> = ({
  header,
  info,
  mobile,
  fileSelected = false,
  hidden,
  maxFileSize,
  fileTypes = [
    //    "/*"
    "image/png",
    "image/jpeg",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  onFileSelected = () => {},
  state = "regular",
  disabled,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const chooseFileRef = useRef<HTMLInputElement | null>(null);
  const [isError, setIsError] = useState(false);

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    if (!e.target.files) {
      setIsError(true);
      return;
    }
    const file = e.target.files.item(0);
    if (!file) {
      setIsError(true);
      return;
    }
    if (!(fileTypes as string[]).includes(file.type)) {
      setIsError(true);
      return;
    }

    if (maxFileSize && file.size > maxFileSize) {
      setIsError(true);
      console.log("maxFileSize", maxFileSize, "file.size", file.size);

      return;
    }

    FileService.getBase64(file, (result) => {
      onFileSelected(result, file.name);
    });
  };

  const handelFileSelectedNative = (result: string) => {
    onFileSelected(result);
  };

  const handleClick = () => {
    if (state !== "success" && !disabled) {
      FileService.chooseFile(
        chooseFileRef,
        FileService.getAllowedTypes(fileTypes),
        handelFileSelectedNative
      );
    }
  };

  return (
    <IonCard
      hidden={hidden}
      disabled={disabled}
      className={"upload-document-card" + (mobile ? " mobile-upload-file" : "")}
      button={state !== "success" && !disabled}
      dir={appDirection}
      data-is-mobile={mobile}
      onClick={handleClick}
    >
      <input
        type="file"
        ref={chooseFileRef}
        accept={FileService.getAllowedTypes(fileTypes)}
        onChange={handleFileSelected}
        hidden
      />
      <IonCardContent>
        <div
          className={classNames("content-inner", {
            "document-success": state === "success" || fileSelected,
            "document-error": (state === "error" && !fileSelected) || isError,
          })}
        >
          <IonIcon
            className="upload-document-icon"
            src={fileSelected ? uplaodDocIconSuccess : returnCorrectIcon(state)}
          />
          <IonText className="text upload-document-card-header">
            {header}
          </IonText>
          <IonText className="text info">{info}</IonText>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default UploadDocumentCard;
