import { IonCard, IonCardContent } from "@ionic/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../..";
import UserService from "../../services/UserService";
import EditUserInfoForm from "../forms/EditUserInfoForm";

import "./PersonalDetailsCard.scss";

type PersonalDetailsCardParams = {
  mobile?: boolean;
};

const PersonalDetailsCard: React.FC<PersonalDetailsCardParams> = ({
  mobile = false,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const getUserDetails = UserService.useGetUserData();
  useEffect(() => {
    getUserDetails.execute();
  }, []);

  return (
    <IonCard
      dir={appDirection}
      className="personal-details-card"
      data-is-mobile={mobile}
    >
      <IonCardContent className="personal-details-card-content">
        {!getUserDetails.state.loading && !getUserDetails.state.error && (
          <EditUserInfoForm user={getUserDetails.state.data} mobile />
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default PersonalDetailsCard;
