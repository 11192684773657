import {
  IonToolbar,
  IonIcon,
  IonMenuToggle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
} from "@ionic/react";
import userIcon from "../../assets/icons-user.svg";
import arrowDownIcon from "../../assets/icons-small-arrow-down3.svg";
import logo from "../../assets/logo.png";
import logo_topcard from "../../assets/logo_topcard.svg";
import hebLangIcon from "../../assets/icons-language-heb-active.svg";
import engLangIcon from "../../assets/icons-language-eng-active.svg";
import rusLangIcon from "../../assets/icons-language-rus-active.svg";
import arbLangIcon from "../../assets/icons-language-arb-active.svg";
import supportIcon from "../../assets/icons-actions-support.svg";
import hamburgerIcon from "../../assets/icons-hamburger.svg";
import PersonalAreaDropDown from "./dropdowns/PersonalAreaDropDown";
import LanguagesDropDown from "./dropdowns/LanguagesDropDown";
import SupportDropDown from "./dropdowns/SupportDropDown";
import { useEffect, useMemo, useState } from "react";
import "./TopMenu.scss";
import TopMenuSearchBar from "./search-bars/TopMenuSearchBar";
import PersonalAreaMobileMenu from "./mobile-menus/PersonalAreaMobileMenu";
import MobileSearchBar from "./search-bars/MobileSearchBar";
import { useHistory } from "react-router";
import { ROUTES } from "../../store/Globals";
import { useDispatch, useSelector } from "react-redux";
import { changePersonalAreaMobileMenuOpenState } from "../../store/action-creators/TopMenu";
import { useTranslation } from "react-i18next";
import { RootState } from "../..";
import { isHiddenOnMobile } from "../../utils/helper-functions";
import UserService from "../../services/UserService";
import { changeLoggedUserName } from "../../store/action-creators/App";

const langConfigPath = "topMenu.desktop";

const TopMenu: React.FC = () => {
  const history = useHistory();
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const loggedUserName = useSelector((state: RootState) => state.app.userName);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);
  const [isMobile, setIsMobile] = useState(isHiddenOnMobile());
  const getUserData = UserService.useGetUserData();
  const firstName = loggedUserName?.firstName;
  const lastName = loggedUserName?.lastName;

  const langIcon = useMemo(() => {
    switch (i18n.language) {
      case "he":
        return hebLangIcon;
      case "en":
        return engLangIcon;
      case "ru":
        return rusLangIcon;
      case "ar":
        return arbLangIcon;
      default:
        return hebLangIcon;
    }
  }, [i18n.language]);

  window.addEventListener("resize", () => {
    setIsMobile(isHiddenOnMobile());
  });

  useEffect(() => {
    if (getUserData.state.data) {
      dispatch(changeLoggedUserName(getUserData.state.data));
    }
  }, [getUserData.state.data]);

  useEffect(() => {
    if (!getUserData.state.data) {
      getUserData.execute();
    }
  }, []);

  return (
    <IonToolbar dir={appDirection} className="top-menu">
      <IonGrid>
        <IonRow>
          {/* start Btns */}
          <IonCol>
            {/* Desktop Menu */}
            <IonCol
              hidden={isMobile}
              className="top-menu-start flex ion-align-items-center ion-align-self-start ion-justify-content-start"
            >
              <div
                style={loggedUserName === null ? { pointerEvents: "none" } : {}}
                id="personal-area-menu-button"
                className="personal-area-icons-container flex ion-align-items-center clickable"
              >
                <IonIcon className="user-icon" src={userIcon} />
                <IonIcon
                  className="arrow-down-icon"
                  src={arrowDownIcon}
                  hidden={loggedUserName === null}
                />
              </div>
              <IonText
                className="login-text text white clickable"
                onClick={() => {
                  if (loggedUserName === null) {
                    history.push(ROUTES.desktopLogIn);
                  }
                }}
              >
                {loggedUserName === null
                  ? t(`${langConfigPath}.personalAreaDropDown.label`)
                  : t(`${langConfigPath}.personalAreaDropDown.loggedLabel`, {
                      firstName,
                      lastName,
                    })}
              </IonText>
              <PersonalAreaDropDown triggerId="personal-area-menu-button" />
            </IonCol>
            {/* Mobile Menu */}
            <IonCol
              hidden={!isMobile}
              className="top-menu-start-mobile flex ion-align-items-center "
            >
              <IonMenuToggle
                className="side-menu-mobile-toggle"
                menu="mobile-side-menu"
              >
                <IonIcon
                  className="hamburger-icon clickable"
                  src={hamburgerIcon}
                />
              </IonMenuToggle>
            </IonCol>
          </IonCol>
          {/* middle Logo */}
          <IonCol
            onClick={() => {
              history.push("/");
            }}
            className="top-menu-center clickable ion-align-items-center flex ion-justify-content-center"
          >
            <IonImg className="top-menu-logo" src={logo_topcard} alt="" />
          </IonCol>
          {/* End Btns */}
          <IonCol>
            <IonCol
              className="top-menu-end ion-align-items-center flex ion-justify-content-end "
              hidden={isMobile}
            >
              <TopMenuSearchBar
                onClose={() => setShowSearchBar(false)}
                className={!showSearchBar && "ion-hide"}
              />

              <IonImg
                id="languages-menu-button"
                className="top-menu-end-icon clickable"
                src={langIcon}
                alt=""
              />
              <LanguagesDropDown triggerId="languages-menu-button" />

              <IonImg
                id="support-menu-button"
                className="top-menu-end-icon clickable"
                src={supportIcon}
                alt=""
              />
              <SupportDropDown triggerId="support-menu-button" />
            </IonCol>
            <IonCol
              className="top-menu-end-mobile ion-align-items-center flex ion-justify-content-end"
              hidden={!isMobile}
            >
              <IonIcon
                className="user-icon clickable"
                src={userIcon}
                onClick={() => {
                  history.push(ROUTES.personalAreaSelection);
                  dispatch(changePersonalAreaMobileMenuOpenState(true));
                }}
              />
              <PersonalAreaMobileMenu />

              <MobileSearchBar
                open={showMobileSearchBar}
                onClose={() => {
                  setShowMobileSearchBar(false);
                }}
              />
            </IonCol>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default TopMenu;
