import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import passwordIcon from "../../../assets/icons-password.svg";
import authService from "../../../services/AuthService";
import { changePopup } from "../../../store/action-creators/App";
import { OTP_EXP, POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./LoginOTPPopup.scss";
type LoginOTPPopup = {
  mobile?: boolean;
};
const langConfigPath = "popUps.loginOTP";

const LoginOTPPopup: React.FC<LoginOTPPopup> = ({ mobile = false }) => {
  const logInSavedData = useSelector(
    (state: RootState) => state.auth.logIn.savedFormData
  );
  const IsValidateError = useSelector(
    (state: RootState) => state.auth.validateOTP.error
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validateOTP = authService.validateOTP();
  const logIn = authService.logIn();
  const [otpCode, setOtpCode] = useState(["", "", "", "", ""]);

  const InputCol = (number: number) => {
    return (
      <IonCol className="number-input-col">
        <IonItem className="number-input-item">
          <IonInput
            dir="ltr"
            value={otpCode[number]}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onConfirm();
              }
              handleKeyPress(e, number);
            }}
            maxlength={1}
            type="number"
            className="number-input text"
          />
        </IonItem>
      </IonCol>
    );
  };

  const handleKeyPress = (event: any, number: number) => {
    event.preventDefault();
    if (/\d/.test(event.key)) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if ([...form].length !== index + 1) {
        form.elements[index + 1].focus();
      }
      let newCode = [...otpCode];
      newCode[number] = event.key;
      setOtpCode(newCode);
    }
  };
  const [routeOnDismiss, setRouteOnDismiss] = useState<string>();

  const onConfirm = () => {
    validateOTP.execute({ ...logInSavedData, otpCode: otpCode.join("") });
    setOtpCode(["", "", "", "", ""]);
  };
  useEffect(() => {
    if (validateOTP.state.data && !validateOTP.state.loading) {
      setRouteOnDismiss(
        mobile ? ROUTES.mobileEditUserInfo : ROUTES.desktopEditUserInfo
      );
      dispatch(changePopup(POPUPS.clear));
    }
  }, [validateOTP.state, history]);

  const onSendAgain = () => {
    logIn.execute(logInSavedData);
  };

  return (
    <Popup
      popUpId={POPUPS.loginOTP}
      mobile={mobile}
      routeOnDismiss={routeOnDismiss}
    >
      <div
        className="pop-up login-otp-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={passwordIcon} />
        <div className="text-container flex flex-direction-column">
          <IonText className="pop-up-header text">
            {t(`${langConfigPath}.header`)}
          </IonText>
          <IonText className="text">{t(`${langConfigPath}.subHeader`)}</IonText>
        </div>

        <form>
          <IonGrid className="input-grid">
            <IonRow className="input-row" dir="rtl">
              {InputCol(0)}
              {InputCol(1)}
              {InputCol(2)}
              {InputCol(3)}
              {InputCol(4)}
            </IonRow>
          </IonGrid>
        </form>

        <IonText
          hidden={!IsValidateError}
          className="pop-up-sub-text text error"
        >
          {t(`${langConfigPath}.errorMessage`)}
        </IonText>
        <IonText className="pop-up-sub-text text">
          {t(`${langConfigPath}.expMessage`, { OTP_EXP })}
        </IonText>

        <IonButton
          disabled={otpCode.indexOf("") !== -1}
          className="button-primary pop-up-botton"
          onClick={onConfirm}
        >
          {t(`${langConfigPath}.confirmButton.label`)}
        </IonButton>

        <div className="flex send-again-container">
          <IonText className="text">
            {t(`${langConfigPath}.sendAgain.text`)}
          </IonText>
          &nbsp;&nbsp;
          <IonText onClick={onSendAgain} className="link text">
            {t(`${langConfigPath}.sendAgain.link`)}
          </IonText>
        </div>
      </div>
    </Popup>
  );
};

export default LoginOTPPopup;
