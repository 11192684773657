import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonRouterLink,
  IonRow,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../";
import arrowOneIcon from "../../../../assets/icons-nfc-order-arrow-1.svg";
import arrowTwoIcon from "../../../../assets/icons-nfc-order-arrow-2.svg";
import arrow3Icon from "../../../../assets/icons-nfc-order-arrow-3.svg";
import arrowFourIcon from "../../../../assets/icons-nfc-order-arrow-4.svg";
import ".././OrderCardPage.scss";
import { ROUTES } from "../../../../store/Globals";

const langConfigPath = "pages.desktop.order-card";
type TParams = {
  nextStep: () => void;
};

const OrderCardStepsDescriptionDesktop: React.FC<TParams> = ({ nextStep }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  const { t } = useTranslation();

  return (
    <IonCard className="desktop-page-card">
      <IonCardContent className="desktop-card-content">
        <IonGrid className="">
          <IonRow className="order-row flex-direction-row" dir={appDirection}>
            <IonCol size="1.55" className="order-col">
              <div className="flex ion-align-items-center">
                {appDirection === "rtl" && (
                  <IonIcon className="arrow-icon arrow-1" src={arrowOneIcon} />
                )}
                <div className="icon-num">1</div>
                {appDirection === "ltr" && (
                  <IonIcon className="arrow-icon arrow-1" src={arrowOneIcon} />
                )}
              </div>

              <IonText className="text font-size-16 weight-500">
                {t(`${langConfigPath}.steps.step1.title`)}
              </IonText>
              <IonText className="text font-size-14 weight-400">
                {t(`${langConfigPath}.steps.step1.description`)}
              </IonText>
            </IonCol>

            <IonCol size="3" className="order-col">
              <div className="flex ion-align-items-center">
                {appDirection === "rtl" && (
                  <IonIcon className="arrow-icon arrow-2" src={arrowTwoIcon} />
                )}
                <div className="icon-num">2</div>
                {appDirection === "ltr" && (
                  <IonIcon className="arrow-icon arrow-2" src={arrowTwoIcon} />
                )}
              </div>
              <IonText className="text font-size-16 weight-500">
                {t(`${langConfigPath}.steps.step2.title`)}
              </IonText>
              <IonText className="text font-size-14 weight-400">
                {t(`${langConfigPath}.steps.step2.description`)}
              </IonText>
            </IonCol>

            <IonCol size="1.5" className="order-col">
              <div className="flex ion-align-items-center">
                {appDirection === "rtl" && (
                  <IonIcon className="arrow-icon arrow-3" src={arrow3Icon} />
                )}
                <div className="icon-num">3</div>
                {appDirection === "ltr" && (
                  <IonIcon className="arrow-icon arrow-3" src={arrow3Icon} />
                )}
              </div>
              <IonText className="text font-size-16 weight-500">
                {t(`${langConfigPath}.steps.step3.title`)}
              </IonText>
              <IonText className="text font-size-14 weight-400">
                {t(`${langConfigPath}.steps.step3.description`)}
              </IonText>
            </IonCol>

            <IonCol className="order-col">
              <div className="flex ion-align-items-center">
                {appDirection === "rtl" && (
                  <IonIcon className="arrow-icon arrow-4" src={arrowFourIcon} />
                )}
                <div className="icon-num">4</div>
                {appDirection === "ltr" && (
                  <IonIcon className="arrow-icon arrow-4" src={arrowFourIcon} />
                )}
              </div>
              <IonText className="text font-size-16 weight-500">
                {t(`${langConfigPath}.steps.step4.title`)}
              </IonText>
              <IonText className="text font-size-14 weight-400">
                {t(`${langConfigPath}.steps.step4.description`)}
              </IonText>
            </IonCol>

            <IonCol className="order-col last-item">
              <div className="icon-num">5</div>

              <IonText className="text font-size-16 weight-500">
                {t(`${langConfigPath}.steps.step5.title`)}
              </IonText>
              <IonText className="text font-size-14 weight-400">
                {t(`${langConfigPath}.steps.step5.description`)}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className="contrast">
          <IonText className="bottom-text text">
            {t(`${langConfigPath}.explanationMessage`)}
          </IonText>
          <IonButton
            className="main-blue-buttons space-down"
            onClick={() => nextStep()}
          >
            {t(`${langConfigPath}.start-order`)}
          </IonButton>
          <IonRouterLink
            routerLink={ROUTES.desktopFAQ}
            className="link under_line mTop15"
          >
            {t(`${langConfigPath}.faq`)}
          </IonRouterLink>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default OrderCardStepsDescriptionDesktop;
