import classNames from 'classnames';
import {  ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../..';
// import checkedIcon from '../../../../assets/icons-checkbox-v.svg'


import './checkbox.scss';

const langConfigPath = 'stepsSections.selectContract.monthlyContract'

type Checkbox = {
    name?: string 
    label?: string,
    onCheckChange?: ChangeEventHandler
}

const Checkbox: React.FC<Checkbox> = ({label, name, onCheckChange}) => {
    const appDirection = useSelector((state: RootState) => state.app.direction)
    const [checked, setChecked] = useState(false)
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        if(onCheckChange){
            onCheckChange(e)
        }
        setChecked(prev=>!prev)
    }

    return (
       
            <div 
            className="checkbox-container flex ion-align-items-center"
            >
                <input
                name={name}
                onChange={handleChange}
                checked={checked} 
                type="checkbox"
                className={classNames({
                    "checkbox": true,
                    "checked-checkbox": checked
                })}
                />
                <label
                htmlFor={name}
                className="text"
                >
                    {label}
                </label>
            </div>

    )
}

export default Checkbox
