import { IonIcon, IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { RootState } from "../../../..";
import { DIRECTIONS } from "../../../../store/Globals";
import vIcon from "../../../../assets/icons-v.svg";
import "./FormInput.scss";
import { TextFieldTypes } from "@ionic/core";
import classNames from "classnames";

type FormInputProps = {
  name: string;
  mobile?: boolean;
  cssClass?: string;
  label?: string;
  type?: TextFieldTypes;
  showV?: boolean;
  showRequired?: boolean;
  readOnly?: boolean;
};

const FormInput = React.forwardRef<HTMLIonInputElement, FormInputProps>(
  (props, ref) => {
    const {
      cssClass = "",
      label,
      name,
      type = "text",
      showV = true,
      showRequired = false,
      readOnly,
    } = props;
    const appDirection = useSelector((state: RootState) => state.app.direction);
    const vendor = useSelector((state: RootState) => state.app.vendor);
    const { control, getFieldState, watch, formState } = useFormContext();
    const [_showRequired, setShowRequired] = useState(showRequired);
    const [value, setValue] = useState("");
    const inputState = getFieldState(name);

    useEffect(() => {
      const subscription = watch((data) => {
        setValue(data[name]);
      });
      return () => {
        subscription.unsubscribe();
      };
    }, [watch, name]);
    console.log("vendor", vendor);

    return (
      <div className="form-input-container" dir={appDirection}>
        <IonItem
          fill={vendor === "moovit" ? "outline" : undefined}
          lines={
            vendor !== "moovit" ? (readOnly ? "none" : "inset") : undefined
          }
          onFocus={() => {
            setShowRequired(false);
          }}
          onBlur={() => {
            if (value === "" && showRequired) {
              setShowRequired(true);
            }
          }}
          className={classNames(cssClass, {
            "form-item": vendor !== "moovit",
            "ion-invalid": inputState.invalid,
          })}
        >
          <IonLabel
            className={classNames("form-item-label text", {
              "form-item-label-ltr": appDirection === DIRECTIONS.ltr,
            })}
            position="floating"
          >
            {_showRequired && (
              <IonText className="required-annotation text">*</IonText>
            )}
            {label}
          </IonLabel>
          <Controller
            render={({ field: { value, onChange } }) => (
              <IonInput
                className="form-input text"
                type={type}
                value={value}
                ref={ref}
                onIonChange={onChange}
                readonly={readOnly}
              />
            )}
            control={control}
            name={name}
          />
          {showV && !inputState.invalid && inputState.isDirty && (
            <IonIcon className="v-icon" src={vIcon} />
          )}
        </IonItem>
        {formState.errors[name]?.message && (
          <IonText className="form-error-message text">
            {formState.errors[name]?.message}
          </IonText>
        )}
      </div>
    );
  }
);

export default FormInput;
