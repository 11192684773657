import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import {
  createNfcConnection,
  openNfcService,
  startExchangeImprisoned,
  startGetCardPayload,
  startLoadContract,
  startLoadProfile,
  startRefundContract,
} from "../../store/action-creators/Nfc";
import {
  RefundContractParams,
  TExchangeImprisonedActionPayload,
} from "../types";
export const useNfcSocket = () => {
  const nfcState = useSelector((state: RootState) => state.nfc);
  const dispatch = useDispatch();
  const connectedToServer = nfcState.socket.serverConnected;
  const loadContract = async (payload: {
    contract: any;
    paymentUid?: string;
    GreenListConfirmationCode?: number;
  }) => {
    dispatch(startLoadContract(payload));
  };
  const exchangeImprisoned = async (
    payload: TExchangeImprisonedActionPayload
  ) => {
    if (!nfcState.connected || !nfcState.card.data) return;
    dispatch(startExchangeImprisoned(payload));
  };

  const loadProfile = async (reqId: string) => {
    dispatch(startLoadProfile({ reqId }));
  };

  const getCardInfo = async () => {
    dispatch(startGetCardPayload());
  };
  const openCardReaderService = async () => {
    dispatch(openNfcService());
  };
  const connectSocket = async () => {
    dispatch(createNfcConnection());
  };
  const refundContract = async (removeContractParams: RefundContractParams) => {
    dispatch(startRefundContract(removeContractParams));
  };
  const init = () => {
    if (!nfcState.socket.serverConnected) connectSocket();
    if (!nfcState.socket.serviceConnected) openCardReaderService();
  };

  return {
    connectedToServer,
    connectSocket,
    exchangeImprisoned,
    openCardReaderService,
    getCardInfo,
    loadProfile,
    loadContract,
    init,
    refundContract,
  };
};
