import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonRouterLink,
  IonRow,
  IonText,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Router, useHistory } from "react-router";
import { RootState } from "../../..";
import signInBeforeContinuingIcon from "../../../assets/icon-signIn-before-continuing.svg";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";

import "./SignInBeforeContinuingPopup.scss";

type SignInBeforeContinuingPopup = {
  mobile?: boolean;
  cssClass?: string;
  info?: string;
};
const langConfigPath = "popUps.signInBeforeContinuing";

const SignInBeforeContinuingPopup: React.FC<SignInBeforeContinuingPopup> = ({
  info,
  cssClass,
  mobile = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [route, setRoute] = useState(
    mobile ? ROUTES.mobileLogIn : ROUTES.desktopLogIn
  );

  const onConfirmBtn = () => {
    setRoute(mobile ? ROUTES.mobileLogIn : ROUTES.desktopLogIn);
    dispatch(changePopup(POPUPS.clear));
  };

  const onLinkClicked = () => {
    setRoute(ROUTES.desktopScanResults);
    dispatch(changePopup(POPUPS.clear));
  };

  return (
    <Popup
      routeOnDismiss={route}
      popUpId={POPUPS.signInBeforeContinuingPopup}
      mobile={mobile}
      cssClass={`sign-in-before-continuing-pop-up-container ${cssClass}`}
    >
      <div
        className="sign-in-before-continuing-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={signInBeforeContinuingIcon} />

        <IonText className="text weight-500 info">
          {info ? info : t(`${langConfigPath}.text`)}
        </IonText>

        <IonButton
          onClick={onConfirmBtn}
          className="main-blue-buttons pop-up-botton"
        >
          {t(`${langConfigPath}.confirmButton.label`)}
        </IonButton>

        <IonText
          onClick={onLinkClicked}
          className="text link weight-500 link-edit"
        >
          {t(`${langConfigPath}.link.label`)}
        </IonText>
      </div>
    </Popup>
  );
};

export default SignInBeforeContinuingPopup;
