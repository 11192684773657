import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "../../../";
import "./OrderCardPage.scss";
import OrderCardStepsDescriptionMobile from "./StepsDescription";
import { useEffect, useState } from "react";
import { TCardType } from "./types";
import StepsCard, { stepPage } from "../../../components/StepsCard";
import CardTypePicker from "../../../components/StepsCardSections/SelectCardOrderType";
import { TProfile } from "../../../hooks/useAllProfiles";
import CardOrderDocuments from "../../../components/StepsCardSections/CardOrderDocuments";
import CardOrderUserInfoForm from "../../../components/StepsCardSections/UserInfoForm";
import CardOrderAddressForm from "../../../components/StepsCardSections/AddressForm";
import PaymentCard from "../../../components/PaymentCard";
import CardOrderSuccessPopup from "../../../components/pop-ups/CardOrderSuccessPopup";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS } from "../../../store/Globals";
import { customPOST } from "../../../services/customApi/fetch";
import SelectProfiles from "../../../components/StepsCardSections/SelectProfiles";
import classNames from "classnames";
import { MobileBackIcon } from "../../../components/BackIcon";
import PaymentDesription from "../../../components/StepsCardSections/PaymentDescription";
type TProfiles = {
  profile1?: TProfile;
  profile2?: TProfile;
};
type TUserInfo = {
  firstName: string;
  lastName: string;
  birthDate: Date;
  personalId: string;
};
type TAddressInfo = {
  city: string;
  street: string;
  houseNumber: string;
  postalCode: number;
};
type TPaymentInfo = {
  last4Digits: string;
  paymentUid: string;
};
type TCardOrderParams = {
  userInfo: TUserInfo;
  address: TAddressInfo;
  paymentInfo?: TPaymentInfo;
  orderType: TCardType;
  reqId: string;
};

const sendOrder = async (params: TCardOrderParams) => {
  const rsp = await customPOST("card/placeCardOrder", params);
  return rsp.data;
};

const defaultIssueCost = 10;
const defaultDeliveryCost = 10;

const OrderCardPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: "pages.desktop.order-card" });
  const [step, setStep] = useState(0);
  const nextStep = () => setStep((s) => (s < pages.length - 1 ? s + 1 : s));
  const [orderType, setOrderType] = useState<TCardType>();
  const [selectedProfiles, setSelectedProfiles] = useState<TProfiles>();
  const [reqId, setReqId] = useState<string>();
  const [userInfo, setUserInfo] = useState<TUserInfo>();
  const [address, setAddress] = useState<TAddressInfo>();
  const isVendor = useSelector((state: RootState) => state.app.isVendor);

  useEffect(() => {
    history.replace({ search: `?step=${step}` });
  }, [step]);

  const pages: stepPage[] = [
    {
      component: (
        <OrderCardStepsDescriptionMobile nextStep={() => nextStep()} />
      ),
      header: t("header"),
      subHeader: t("subHeader"),
    },
    {
      component: (
        <CardTypePicker
          nextStep={(type) => {
            setOrderType(type);
            nextStep();
          }}
        />
      ),
      header: t("header"),
      subHeader: t("order-types.subHeader"),
    },
    {
      component: (
        <SelectProfiles
          mobile
          nextStep={(selected) => {
            setSelectedProfiles(selected);
            nextStep();
          }}
        />
      ),
      header: t("header"),
      subHeader: t("profile.subHeader"),
    },
    {
      component: (
        <CardOrderDocuments
          profiles={selectedProfiles}
          nextStep={(reqId) => {
            setReqId(reqId);
            nextStep();
          }}
          mobile
        />
      ),
      header: t("header"),
      subHeader: t("documents.subHeader"),
    },
    {
      component: (
        <CardOrderUserInfoForm
          nextStep={(userInfo: any) => {
            setUserInfo(userInfo);
            nextStep();
          }}
        />
      ),
      header: t("header"),
      subHeader: t("userInfo.subHeader"),
    },
    {
      component: (
        <CardOrderAddressForm
          nextStep={(address: any) => {
            setAddress(address);
            nextStep();
          }}
        />
      ),
      header: t("header"),
      subHeader: t("address.subHeader"),
    },
    {
      component: (
        <PaymentDesription
          nextStep={() => nextStep()}
          deliveryCost={defaultDeliveryCost}
          issueCost={defaultIssueCost}
        />
      ),
      header: t("header"),
      subHeader: t("payment.subHeader"),
    },
    {
      component: (
        <PaymentCard
          priceInAgorot={defaultDeliveryCost * 100 + defaultIssueCost * 100}
          nextStep={(paymentInfo) => {
            if (!address || !userInfo) return;
            if (!selectedProfiles) return;
            if (!orderType) return;
            if (!reqId) return;
            sendOrder({
              address,
              reqId,
              userInfo,
              paymentInfo,
              orderType,
            }).then((rsp) => {
              console.log({ rsp });
              if (rsp) dispatch(changePopup(POPUPS.cardOrderSuccess));
            });
            // nextStep();
          }}
          onError={(err) => {
            console.log({ err });
            setStep((s) => s - 1);
          }}
        />
      ),
      header: t("header"),
      subHeader: t("payment.subHeader"),
    },
  ];

  const handleBackButton = () => {
    if (step > 0) setStep(step - 1);
    else history.goBack();
  };

  return (
    <IonPage
      className={classNames({ "mobile-page": true, "vendor-page": isVendor })}
      dir={appDirection}
    >
      <IonContent className="mobile-page-content" scrollY>
        <CardOrderSuccessPopup mobile />
        <div className="mobile-nfc-order-page">
          {/* <MobileBackIcon onClick={handleBackButton} /> */}
          <StepsCard
            pages={pages}
            step={step}
            mobile={true}
            onBack={handleBackButton}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OrderCardPageMobile;
