import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonList,
    IonItem,
    IonIcon,
    IonMenuToggle,
    IonLabel,
    IonNavLink,
    IonGrid,
    IonRow,
    IonCol,
    IonListHeader,
    IonImg,
    IonPopover,
    useIonViewWillEnter
} from '@ionic/react'


import './MainMenuDropDown.scss'
import { VERSION } from '../../../../store/Globals';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../..';

type MainMenuDropDown = {
    triggerId: string
}
const RavKavChargingDropDown: React.FC<MainMenuDropDown> = ({triggerId, children}) => {
    const appDirection = useSelector((state: RootState)=>state.app.direction)
    const [trigger, setTrigger] = useState(document.getElementById(triggerId))

    useEffect(()=>{
        setTrigger(document.getElementById(triggerId))
    },[triggerId])

    const onDismiss = () =>{
        if(trigger !== null){
            trigger.style.backgroundColor = ""
        }
    }
    
    const onPresent = () =>{
        if(trigger !== null){
            trigger.style.backgroundColor = "#fff"
        }
    }

    return (
        <IonPopover
        dir={appDirection}
        className="main-menu-dropdown"
        trigger={triggerId}
        showBackdrop={false}
        alignment="center"
        size="cover"
        onWillDismiss={onDismiss}
        onWillPresent={onPresent}
        dismissOnSelect={true}
        >
            <IonContent>
                <IonList lines="full" className="main-menu-dropdown-list">
                    {children}
                </IonList>
            </IonContent>
        </IonPopover>
    )
};

export default RavKavChargingDropDown
