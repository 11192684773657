import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonRow,
  IonText,
} from "@ionic/react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../..";
import { nfcActions } from "../../hooks/types";
import { changePopup } from "../../store/action-creators/App";
import { saveNfcAction } from "../../store/action-creators/Nfc";
import { POPUPS, ROUTES } from "../../store/Globals";

import "./ExchangeProfileCard.scss";
import { useTranslation } from "react-i18next";

const langConfigPath = "exchangeProfileCard";

type TExchangeProfileCard = {
  mobile?: boolean;
};

const ProfileCard = ({
  name,
  amount,
  mobile,
  onClick,
}: {
  name: string;
  amount: number;
  mobile?: boolean;
  onClick?: () => void;
}) => {
  return (
    <IonCard onClick={onClick}>
      <IonCardContent>
        <IonRow>
          <IonCol>
            <IonText>{name}</IonText>
          </IonCol>
          <IonCol>
            <IonText>{amount}₪</IonText>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

const ExchangeProfileCard: React.FC<TExchangeProfileCard> = ({ mobile }) => {
  const imprisoned = useSelector(
    (state: RootState) => state.nfc.card.imprisonedDetails
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  console.log("imprisoned", imprisoned);
  const imprisonedBalance =
    imprisoned?.SelectedContract.ImprisonedBalanceSum ||
    imprisoned?.CurrentInfo?.Units ||
    0;

  const labels = {
    valueText: t("valFromProfile", {
      profile: imprisoned?.SelectedContract?.Contract?.ProfileStr,
    }),
    value: (imprisonedBalance / 100).toFixed(2) + "₪",
  };

  return (
    <IonCard
      className={classNames({
        "exchange-profile-card": true,
        "desktop-page-card": !mobile,
        "mobile-page-card": mobile,
      })}
      data-is-mobile={mobile}
    >
      <IonCardHeader className="header">
        <IonText className="text">{labels.valueText}</IonText>
        <IonText className="text">{labels.value}</IonText>
      </IonCardHeader>
      <IonCardContent className="card-content">
        {imprisoned?.Options?.map((option) => (
          <ProfileCard
            onClick={() => {
              if (!imprisoned?.CurrentInfo) return;
              dispatch(
                saveNfcAction({
                  action: nfcActions.exchangeImprisoned,
                  data: {
                    contractIndex: imprisoned.SelectedContract.IndexOnSC,
                    selectedOption: option,
                  },
                })
              );
              dispatch(changePopup(POPUPS.clear));
              history.push(
                mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcRead
              );
            }}
            amount={option.Units / 100}
            name={option.ProfileStr}
            mobile={mobile}
          />
        ))}
      </IonCardContent>
    </IonCard>
  );
};

export default ExchangeProfileCard;
