import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "./orderChargeSummary.scss";
import { RootState } from "../../..";
import EditOrderChargeSelectedContractCard from "./EditOrderChargeSelectedContractCard";
import MoveToFlexiblePaymentCard from "./MoveToPaymentCard";
import SelectStartDateForDailyContractPopup from "../../pop-ups/SelectStartDateForDailyContractPopup";
import { flexibleSumValueETT, TContractDetails } from "../../../hooks/types";
import { IonButton, IonText } from "@ionic/react";
import { dateToOrtTimeStamp } from "../../../hooks/useCard";
import { customPOST } from "../../../services/customApi/fetch";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import EditOrderChargeSelectedProfileCard from "./EditOrderChargeSelectedProfileCard";

const langConfigPath = "paymentMethodCard";

type OrderChargeSummaryProps = {
  mobile?: boolean;
  contract?: TContractDetails;
  isRemote?: boolean;
  nextStep: (changedContract: TContractDetails) => void;
};

const schema = yup.object().shape({
  IsFound: yup.boolean().required(),
  NumberOfAllowedFutureDays: yup.number().required(),
});
async function getFutureMaxDate({
  contract,
  cardImage,
  profileId,
}: {
  contract: TContractDetails;
  cardImage?: any;
  profileId?: number;
}) {
  const rsp = await customPOST("contracts/futureDaysAllowed", {
    ett: contract.ETT,
    predefinedCode: contract.PredefinedCode,
    profileId,
    cardImage,
  });
  const { NumberOfAllowedFutureDays } = schema.validateSync(rsp.data);
  return NumberOfAllowedFutureDays;
}

const OrderChargeSummary: React.FC<OrderChargeSummaryProps> = ({
  mobile,
  contract,
  isRemote = false,
  nextStep,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const [changedContract, setChangedContract] = useState<
    TContractDetails | undefined
  >(contract);

  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  const [futureDaysAllowed, setFutureDaysAllowed] = useState<number>(0);
  useEffect(() => {
    if (!changedContract) return;
    getFutureMaxDate({
      contract: changedContract,
      profileId: changedContract.Profile,
    }).then((res) => {
      setFutureDaysAllowed(res);
    });
  }, [changedContract]);
  const changeStartDate = useCallback((startDate: Date) => {
    setChangedContract((c) => {
      if (!c) return;

      const FromDate = dateToOrtTimeStamp(startDate);
      const ToDate = dateToOrtTimeStamp(
        new Date(startDate.setMonth(startDate.getMonth() + 1))
      );
      console.log({ FromDate, ToDate });
      return {
        ...c,
        SpecificContractData: { ...c.SpecificContractData, FromDate, ToDate },
      };
    });
  }, []);

  const labels = useMemo(() => {
    return { pay: t("pay") };
  }, [t]);

  if (!changedContract) return <div></div>;
  return (
    <div dir={appDirection} className="order-charge-summary height-100">
      <div className="summary-content-container">
        {isRemote && (
          <EditOrderChargeSelectedProfileCard
            mobile={mobile}
            profile={changedContract.ProfileStr ?? ""}
          />
        )}

        <EditOrderChargeSelectedContractCard
          mobile={mobile}
          contract={changedContract}
        />
      </div>
      <div className="move-to-payment-container  width-100">
        {changedContract.ETT === flexibleSumValueETT ? (
          <MoveToFlexiblePaymentCard
            contract={changedContract}
            mobile
            onFinish={({ pay, value }) => {
              nextStep({
                ...changedContract,
                ETT: flexibleSumValueETT,
                PriceInAgorot: pay,
                SpecificContractData: {
                  ...changedContract.SpecificContractData,
                  Units: value,
                },
              });
            }}
          />
        ) : (
          <div className="flex justify-content-center">
            <IonButton
              className="button-primary"
              onClick={() => {
                nextStep(changedContract);
              }}
            >
              <IonText className="text">{labels.pay} </IonText>
              <IonText className="text ">
                &nbsp;{changedContract.PriceInAgorot / 100}
              </IonText>
              <IonText className="text">₪</IonText>
            </IonButton>
          </div>
        )}

        <SelectStartDateForDailyContractPopup
          contractName={changedContract.ETTStr ?? ""}
          mobile={mobile}
          changeStartDate={changeStartDate}
          futureDaysAllowed={futureDaysAllowed}
        />
      </div>
    </div>
  );
};

export default OrderChargeSummary;
