import { IonCard, IonContent, IonIcon, IonPage, IonText } from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import FAQItem from "../../../components/FAQItem";

import "./faqPage.scss";
import classNames from "classnames";
import { useMemo } from "react";
import { BackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.faq";

const DesktopFAQPage: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const faq = useMemo(
    () => [
      {
        header: t("ravkavService.header"),
        children: [
          {
            header: t("ravkavService.howToCharge.header"),
            content: t("ravkavService.howToCharge.content"),
          },
          {
            header: t("ravkavService.whereCanYouSeeReceipt.header"),
            content: t("ravkavService.whereCanYouSeeReceipt.content"),
          },
          {
            header: t("ravkavService.whatIsOrderCharge.header"),
            content: t("ravkavService.whatIsOrderCharge.content"),
          },
          {
            header: t("ravkavService.canYouAnonymousCharge.header"),
            content: t("ravkavService.canYouAnonymousCharge.content"),
          },
          {
            header: t("ravkavService.buyCardWithProfileDiscount.header"),
            content: t("ravkavService.buyCardWithProfileDiscount.content"),
          },
        ],
      },
      {
        header: t("contracts.header"),
        children: [
          {
            header: t("ravkavService.howToCharge.header"),
            content: t("ravkavService.howToCharge.content"),
          },
          {
            header: t("ravkavService.whereCanYouSeeReceipt.header"),
            content: t("ravkavService.whereCanYouSeeReceipt.content"),
          },
          {
            header: t("ravkavService.whatIsOrderCharge.header"),
            content: t("ravkavService.whatIsOrderCharge.content"),
          },
        ],
      },
    ],
    [t]
  );
  return (
    <IonPage className="desktop-page " dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-faq-page">
          <div className="desktop-title-container flex-direction-row">
            <BackIcon />
            <IonText className="desktop-header text">{t("header")}</IonText>
          </div>

          <IonCard className="desktop-page-card desktop-faq flex flex-direction-column">
            {faq.map((item, index) => (
              <div className="section" key={index}>
                <IonText className="section-header text font-size-18 weight-900">
                  {item.header}
                </IonText>
                <div>
                  {item.children.map((child, index) => (
                    <FAQItem header={child.header}>
                      <Trans>{child.content}</Trans>
                    </FAQItem>
                  ))}
                </div>
              </div>
            ))}
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DesktopFAQPage;
