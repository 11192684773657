import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonMenuToggle,
  IonLabel,
  IonNavLink,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonImg,
  IonPopover,
  IonInput,
  IonMenuButton,
} from "@ionic/react";

import contractIcon from "../../assets/icons-actions-contract.svg";
import calanderIcon from "../../assets/icons-actions-date.svg";
import moneyIcon from "../../assets/icons-actions-money.svg";
import cardIcon from "../../assets/icons-actions-order-card.svg";
import discountIcon from "../../assets/icons-actions-load-discount.svg";
import chargeIcon from "../../assets/icons-actions-load-card.svg";
import infoIcon from "../../assets/icons-actions-info.svg";
import moreServiceIcon from "../../assets/icons-actions-more-service.svg";
import feedbackIcon from "../../assets/icons-actions-reviews.svg";
import actionsIcon from "../../assets/icons-actions-actions.svg";

import "./MainMenu.scss";

import { useHistory } from "react-router";
import { ROUTES } from "../../store/Globals";
import { useDispatch, useSelector } from "react-redux";
import MainMenuItem from "./MainMenuItem";
import { useTranslation } from "react-i18next";
import RavKavChargingDropDown from "./dropdowns/RavKavChargingDropDown";
import { RootState } from "../..";
import MainMenuDropDown from "./dropdowns/MainMenuDropDown";
import ActionsInRavKavCardDropDown from "./dropdowns/ActionsInRavKavCardDropDown";
import { useEffect, useState } from "react";
import { isHiddenOnMobile } from "../../utils/helper-functions";

const langConfigPath = "mainMenu";

const MainMenu: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(isHiddenOnMobile());

  window.addEventListener("resize", () => {
    setHidden(isHiddenOnMobile());
  });

  return (
    <IonToolbar
      dir={appDirection}
      hidden={hidden}
      className="main-menu  flex ion-align-items-center"
    >
      <IonGrid>
        <IonRow className="main-menu-row">
          <MainMenuItem
            label={t(`${langConfigPath}.ravKavCardActions.label`)}
            icon={actionsIcon}
            itemId="rav-kav-card-actions-drop-down"
          />
          <ActionsInRavKavCardDropDown triggerId="rav-kav-card-actions-drop-down" />

          <span className="devider"></span>
          <MainMenuItem
            itemId="rav-kav-charging-drop-down"
            label={t(`${langConfigPath}.ravKavCharging.label`)}
            icon={chargeIcon}
          />
          <RavKavChargingDropDown triggerId="rav-kav-charging-drop-down" />

          <span className="devider"></span>
          <MainMenuItem
            label={t(`${langConfigPath}.ravKavCardOrdering.label`)}
            icon={cardIcon}
            page={ROUTES.desktopOrderCard + "?step=0"}
          />
          <span className="devider"></span>

          <MainMenuItem
            label={t(`${langConfigPath}.generalInfo.label`)}
            icon={infoIcon}
          />
          <span className="devider"></span>
          <MainMenuItem
            label={t(`${langConfigPath}.extraServices.label`)}
            icon={moreServiceIcon}
          />
          <span className="devider"></span>
          <MainMenuItem
            label={t(`${langConfigPath}.feedback.label`)}
            icon={feedbackIcon}
          />
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default MainMenu;
