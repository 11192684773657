import { IonButton, IonCard, IonContent, IonPage, IonText } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import AddCreditCardForm from "../../../components/forms/AddCreditCardForm";
import PaymentMethodCard from "../../../components/PaymentMethodCard";
import { useUserCreditCard } from "../../../hooks/useUserCreditCard";

import "./paymentMethodPage.scss";
import { formatDateShort } from "../../../utils/helper-functions";
import { BackIcon } from "../../../components/BackIcon";
import backIconWhite from "../../../assets/icons-arrow-back-white.svg";

const langConfigPath = "pages.mobile.paymentMethod";

const PaymentMethodPage: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const creditCard = useUserCreditCard();
  const userId = useSelector((state: RootState) => state.app.userName?._id);
  const [addChangePayment, setaddChangePayment] = useState(false);

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content  desktop-background-${vendor}`}
      >
        <div className="desktop-payment-method-page">
          <div className="desktop-title-container flex-direction-row flex">
            <BackIcon closeIcon={backIconWhite} />

            <IonText className="desktop-header text">{t("header")}</IonText>
          </div>

          <IonCard className="desktop-page-card card-500">
            {addChangePayment && userId ? (
              <AddCreditCardForm
                userId={userId}
                onFinished={() => history.push("/")}
              />
            ) : (
              <>
                {creditCard && (
                  <PaymentMethodCard
                    onClick={() => console.log("card")}
                    lastDigits={creditCard.ccNumber}
                    lastCharged={formatDateShort(creditCard.lastCharge)}
                  />
                )}

                <div className="flex justify-content-center width-100 mBotp20">
                  <IonButton
                    onClick={() => setaddChangePayment(true)}
                    className="button-primary"
                  >
                    {creditCard
                      ? t("changeCardButton.label")
                      : t("addCardButton.label")}
                  </IonButton>
                </div>
              </>
            )}
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PaymentMethodPage;
