import { combineReducers } from 'redux'
import appReducer from './reducers/AppReducer'
import { connectRouter } from 'connected-react-router'
import topMenuReducer from './reducers/TopMenuReducer'
import authReducer from './reducers/AuthReducer'
import nfcReducer from './reducers/NfcReducer'
import profileReducer from './reducers/profileReducer'
import userReducer from './reducers/UserReducer'

const createRootReducer = (history: any) =>combineReducers({
    router: connectRouter(history),
    app: appReducer,
    topMenu: topMenuReducer,
    auth: authReducer,
    nfc: nfcReducer,
    profile: profileReducer,
    user: userReducer
})
export default createRootReducer