import {
  IonHeader,
  IonIcon,
  IonMenuToggle,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { FC } from "react";
import smallMenuIcon from "../../assets/icons-small-menu.svg";
import "./VendorFooter.scss";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import plusIcon from "../../assets/icons-plus-2.svg";
import { ROUTES } from "../../store/Globals";

const langConfigPath = "topMenu.mobile.sideMenu";

export const VendorFooter: FC = () => {
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const history = useHistory();
  const location = useLocation();
  const lables = {
    moreActions: t("moreActions"),
    nonScanActions: t("nonScanActions"),
    newContract: t("newContract"),
  };

  const defaultAction = {
    label: lables.moreActions,
    icon: smallMenuIcon,
  };
  const newContractAction = {
    label: lables.newContract,
    icon: plusIcon,
  };

  return (
    <IonRow className="vendor-footer" slot="end">
      <IonHeader>
        <IonToolbar>
          <div className="flex flex-direction-row justify-content-space-around">
            <IonMenuToggle menu="mobile-side-menu">
              <div className="footer-action">
                <IonText className="bold link font-size-16">
                  {defaultAction.label}
                </IonText>
                <IonIcon
                  className="vendor_icon bold link size-20"
                  src={defaultAction.icon}
                />
              </div>
            </IonMenuToggle>
            {location.pathname === ROUTES.mobileScanResults && (
              <div
                className="footer-action"
                onClick={() => history.push(ROUTES.mobilePurchaseContract)}
              >
                <IonText className="bold link font-size-16">
                  {newContractAction.label}
                </IonText>
                <IonIcon
                  className="vendor_icon bold link size-20"
                  src={newContractAction.icon}
                />
              </div>
            )}
          </div>
        </IonToolbar>
      </IonHeader>
    </IonRow>
  );
};
