import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import "../store/Reducers";
import "./Home.css";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "..";
import discoantIcon from "../assets/icons-actions-load-discoant.svg";
import moneyIcon from "../assets/icons-actions-money.svg";
import dateIcon from "../assets/icons-actions-date.svg";
import loadCardIcon from "../assets/icons-actions-load-card.svg";
import cardAfarsIcon from "../assets/icons-actions-load-card-afar.svg";
import actionsIcon from "../assets/icons-actions-card.svg";
import infoIcon from "../assets/icons-actions-info.svg";
import moreServiceIcon from "../assets/icons-actions-more-service.svg";
import pangoIcon from "../assets/icons-logo-pango@3x.png";
import moovitIcon from "../assets/icons-logo-moovit@3x.png";
// import actionsIcon from "../assets/icons-actions-card.svg";
import { ROUTES } from "../store/Globals";
import { useTranslation } from "react-i18next";

const langConfigPath = "pages.desktop.home";

const Home: React.FC = () => {
  const history = useHistory();
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const { t } = useTranslation();

  const boxes = [
    {
      // profile loading
      icon: discoantIcon,
      title: t(`${langConfigPath}.card1.title`),
      description: t(`${langConfigPath}.card1.description`),
      button: t(`${langConfigPath}.card1.button_text`),
      action: t(`${langConfigPath}.card1.action_text`),
      handler: () => history.push(ROUTES.desktopProfiles),
    },
    {
      // refund and converting
      icon: moneyIcon,
      title: t(`${langConfigPath}.card2.title`),
      description: t(`${langConfigPath}.card2.description`),
      button: t(`${langConfigPath}.card2.button_text`),
      action: t(`${langConfigPath}.card2.action_text`),
      handler: () => history.push(ROUTES.desktopScanResults),
    },
    {
      // refund and converting
      icon: dateIcon,
      title: t(`${langConfigPath}.card3.title`),
      description: t(`${langConfigPath}.card3.description`),
      button: t(`${langConfigPath}.card3.button_text`),
      action: t(`${langConfigPath}.card3.action_text`),
      handler: () => history.push("/"),
    },
    {
      icon: loadCardIcon,
      title: t(`${langConfigPath}.card4.title`),
      description: t(`${langConfigPath}.card4.description`),
      button: t(`${langConfigPath}.card4.button_text`),
      action: t(`${langConfigPath}.card4.action_text`),
      handler: () => history.push(ROUTES.desktopPurchaseContract),
    },
    {
      icon: cardAfarsIcon,
      title: t(`${langConfigPath}.card5.title`),
      description: t(`${langConfigPath}.card5.description`),
      button: t(`${langConfigPath}.card5.button_text`),
      action: t(`${langConfigPath}.card5.action_text`),
      handler: () => history.push(ROUTES.desktopRemotePurchaseContract),
    },
    {
      icon: actionsIcon,
      title: t(`${langConfigPath}.card6.title`),
      description: t(`${langConfigPath}.card6.description`),
      button: t(`${langConfigPath}.card6.button_text`),
      action: t(`${langConfigPath}.card6.action_text`),
      handler: () => history.push(ROUTES.desktopOrderCard),
    },
  ];

  const bottom_cards = [
    {
      icon: infoIcon,
      title: t(`${langConfigPath}.card7.title`),
      // description: t(`${langConfigPath}.card2.description`),
      // button: t(`${langConfigPath}.card2.button_text`),
      action: [
        {
          text: t(`${langConfigPath}.card7.link1`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card7.link5`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card7.link2`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card7.link3`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card7.link4`),
          url: ROUTES.desktopPurchaseContract,
        },
      ],
    },
    {
      icon: moreServiceIcon,
      title: t(`${langConfigPath}.card8.title`),
      description: t(`${langConfigPath}.card2.description`),
      button: t(`${langConfigPath}.card2.button_text`),
      action: [
        {
          text: t(`${langConfigPath}.card8.link1`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card8.link5`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card8.link2`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card8.link3`),
          url: ROUTES.desktopPurchaseContract,
        },
        {
          text: t(`${langConfigPath}.card8.link4`),
          url: ROUTES.desktopPurchaseContract,
        },
      ],
      handler: () => {
        history.push("/");
      },
    },
  ];

  return (
    <IonPage className="desktop-page " dir={appDirection}>
      <IonContent scrollY className={`desktop-page-content `}>
        <div className="flex flex-direction-column width-100 ">
          <div
            className={`desktop-page-header-section heder-promo flex section-${vendor}`}
          >
            <div className="main-header-container">
              <IonText className="collapse-menu-header text  color-white font-size-32">
                {t(`${langConfigPath}.header.title`)}
              </IonText>
              <IonButton
                href={ROUTES.desktopLogIn}
                className="button-primary"
                type="submit"
              >
                {t(`${langConfigPath}.header.button_text`)}
              </IonButton>
              <div className="mobileOnly">
                <IonText className="font-size-16 color-white bold description">
                  {t(`${langConfigPath}.header.subtitle1`)}
                </IonText>
                <IonText className="font-size-16 color-white description">
                  {t(`${langConfigPath}.header.description1`)}
                </IonText>
                <br></br>
                <div className="aplications">
                  <IonButton className="app-btn" size="small">
                    <IonImg className="card-header-icon" src={pangoIcon} />
                    <p className="card-text">
                      {t(`${langConfigPath}.header.card1`)}
                    </p>
                  </IonButton>
                  <IonButton className="app-btn" size="small">
                    <IonImg className="card-header-icon" src={moovitIcon} />
                    <a className="card-text">
                      {t(`${langConfigPath}.header.card2`)}
                    </a>
                  </IonButton>
                </div>
                <br></br>
                <IonText className="font-size-16 color-white bold description">
                  {t(`${langConfigPath}.header.subtitle2`)}
                </IonText>
                <IonText className="font-size-16 color-white description">
                  {t(`${langConfigPath}.header.description2`)}
                </IonText>
                <br></br>
                <div className="aplications" dir="ltr">
                  <IonCard className="card">
                    <IonRow>
                      <IonIcon className="card-header-icon" src={dateIcon} />
                      <IonCol>
                        <IonRow>
                          <IonText>
                            {t(`${langConfigPath}.header.appStore1`)}
                          </IonText>
                        </IonRow>
                        <IonRow>
                          <IonText>
                            {t(`${langConfigPath}.header.appStore2`)}
                          </IonText>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                  <IonCard className="card">
                    <IonRow>
                      <IonIcon className="card-header-icon" src={dateIcon} />
                      <IonCol>
                        <IonRow>
                          <IonText>
                            {t(`${langConfigPath}.header.playStore1`)}
                          </IonText>
                        </IonRow>
                        <IonRow>
                          <IonText>
                            {t(`${langConfigPath}.header.playStore2`)}
                          </IonText>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </div>
              </div>
            </div>
          </div>

          <div className="flex page-container">
            <div className="home-cards">
              {boxes.map((box) => (
                <IonCard className="home-card text-align-center ">
                  <IonCardContent className="home-card-container flex flex-direction-column align-center justify-content-space-between height-100">
                    <div className="flex flex-direction-column align-center">
                      <IonIcon className="card-icon" src={box.icon} />
                      <IonText className="text weight-500 font-size-32 title">
                        {box.title}
                      </IonText>
                    </div>

                    <IonText className="font-size-18 description">
                      {box.description}
                    </IonText>

                    <div className="flex flex-direction-column align-center inside-oval">
                      <IonButton
                        className="button-primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          box.handler();
                        }}
                      >
                        {box.action}
                      </IonButton>

                      <IonButton fill="clear">{box.button}</IonButton>
                    </div>
                  </IonCardContent>
                  <div className="oval"></div>
                </IonCard>
              ))}
            </div>

            <div className=" bottom-cards">
              {bottom_cards.map((box) => {
                return (
                  <IonCard className="home-card text-align-center ">
                    <IonCardContent>
                      <div className="home-card-container flex flex-direction-column align-center">
                        <div className="flex">
                          <IonIcon
                            className="card-icon bottomIcon"
                            src={box.icon}
                          />
                          <IonText className="text weight-500 font-size-32 bottomTitle">
                            {box.title}
                          </IonText>
                        </div>
                        <div className="flex item-bottom">
                          {box.action.map((box) => {
                            return (
                              <div className="inline">
                                <IonButton href={box.url} fill="clear">
                                  {box.text}
                                </IonButton>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </IonCardContent>
                    <div className="oval-bottom"></div>
                  </IonCard>
                );
              })}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
