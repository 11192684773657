//app
export const CHANGE_SHOW_ON_BOARDING = "CHANGE_SHOW_ON_BOARDING";
export const CHANGE_APP_DIRECTION = "CHANGE_APP_DIRECTION";
export const CHANGE_POP_UP = "CHANGE_POP_UP";
export const CHANGE_LOGGED_USER_NAME = "CHANGE_LOGGED_USER_NAME";
export const CHANGE_SDK_RECEIVED_EVENT_DATA = "CHANGE_SDK_RECEIVED_EVENT_DATA";
export const CHANGE_VENDOR = "CHANGE_VENDOR";
export const CHANGE_APP_LOADED = "CHANGE_APP_LOADED";

//topbar
export const CHANGE_TOP_BAR_SEARCH_VALUE = "CHNAGE_TOP_BAR_SEARCH_VALUE";
export const CHANGE_PERSONAL_AREA__MOBILE_MENU_OPEN_STATE =
  "CHANGE_PERSONAL_AREA__MOBILE_MENU_OPEN_STATE";

// auth

// login
export const START_LOGIN = "START_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SAVE_LOGIN_FORM_DATA = "SAVE_LOGIN_FORM_DATA";

// validate otp
export const START_VALIDATE_OTP = "START_VALIDATE_OTP";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_ERROR = "VALIDATE_OTP_ERROR";

// register
export const START_REGISTER = "START_REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

//logout
export const START_LOG_OUT = "START_LOG_OUT";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_ERROR = "LOG_OUT_ERROR";

//nfc

//socket
export const CREATE_NFC_SOCKET_CONNECTION = "CREATE_NFC_SOCKET_CONNECTION";
export const CHANGE_NFC_SOCKET_CONNECTION_STATUS =
  "CHANGE_NFC_SOCKET_CONNECTION_STATUS";
export const CLOSE_NFC_SOCKET = "CLOSE_NFC_SOCKET";

export const CHANGE_NFC_CARD_STATUS = "CHANGE_NFC_CARD_STATUS";
export const CHANGE_NFC_READER_STATUS = "CHANGE_NFC_READER_STATUS";
export const CHANGE_NFC_SERVICE_STATUS = "CHANGE_NFC_SERVICE_STATUS";
export const CHANGE_SERVER_SOCKET_CONNECTION_STATUS =
  "CHANGE_SERVER_SOCKET_CONNECTION_STATUS";
export const REQUEST_NFC_CARD_WRITE = "REQUEST_NFC_CARD_WRITE";
export const SAVE_SOCKET_INSTANCE = "SAVE_SOCKET_INSTANCE";

export const START_GET_NFC_CARD_DETAILS = "START_GET_NFC_CARD_DETAILS";
export const GET_NFC_CARD_DETAILS_SUCCESS = "GET_NFC_CARD_DETAILS_SUCCESS";
export const GET_NFC_CARD_DETAILS_ERROR = "GET_NFC_CARD_DETAILS_ERROR";

//profile
export const CHANGE_SELECTED_PROFILE = "CHANGE_SELECTED_PROFILE";

export const CHANGE_CREATE_PROFILE_REQ_TYPE = "CHANGE_CREATE_PROFILE_REQ_TYPE";

//get all profiles list
export const START_GET_ALL_PROFILES_LIST = "START_GET_ALL_PROFILES_LIST";
export const GET_ALL_PROFILES_LIST_SUCCESS = "GET_ALL_PROFILES_LIST_SUCCESS";
export const GET_ALL_PROFILES_LIST_ERROR = "START_GET_ALL_PROFILES_LIST_ERROR";

//create profile
export const START_CREATE_PROFILE = "START_CREATE_PROFILE";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_ERROR = "CREATE_PROFILE_ERROR";

//upload or update files
export const START_UPLOAD_OR_UPDATE_FILES = "START_UPLOAD_OR_UPDATE_FILES";
export const UPLOAD_OR_UPDATE_FILES_SUCCESS = "UPLOAD_OR_UPDATE_FILES_SUCCESS";
export const UPLOAD_OR_UPDATE_FILES_ERROR = "UPLOAD_OR_UPDATE_FILES_ERROR";

//get user profiles
export const START_GET_USER_PROFILES = "START_GET_USER_PROFILES";
export const GET_USER_PROFILES_SUCCESS = "GET_USER_PROFILES_SUCCESS";
export const GET_USER_PROFILES_ERROR = "GET_USER_PROFILES_ERROR";

// get single profile
export const START_GET_SINGLE_PROFILE = "START_GET_SINGLE_PROFILE";
export const GET_SINGLE_PROFILE_SUCCESS = "GET_SINGLE_PROFILE_SUCCESS";
export const GET_SINGLE_PROFILE_ERROR = "GET_SINGLE_PROFILE_ERROR";

// profile request got approved

// user

// get user data
export const START_GET_USER_DATA = "START_GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";

// edit user data active/inactive
export const USER_DATA_EDIT_ACTIVATE = "USER_DATA_EDIT_ACTIVATE";
export const USER_DATA_EDIT_DEACTIVATE = "USER_DATA_EDIT_DEACTIVATE";

// update the user data in server
export const START_UPDATE_USER_DATA = "START_UPDATE_USER_DATA";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_ERROR = "UPDATE_USER_DATA_ERROR";

// card nfc service

// card status
export const NFC_CARD_CONNECTED = "NFC_CARD_CONNECTED";
export const NFC_CARD_DISCONNECTED = "NFC_CARD_DISCONNECTED";

// reader status
export const NFC_READER_CONNECTED = "NFC_READER_CONNECTED";
export const NFC_READER_DISCONNECTED = "NFC_READER_DISCONNECTED";

// nfc service
export const OPEN_NFC_SERVICE = "OPEN_NFC_SERVICE";
export const NFC_SERVICE_JOINED_ROOM = "NFC_SERVICE_JOINED_ROOM";

// card payload
export const START_GET_CARD_PAYLOAD = "START_GET_CARD_PAYLOAD";
export const GET_CARD_PAYLOAD_SUCCESS = "GET_CARD_PAYLOAD_SUCCESS";
export const GET_CARD_PAYLOAD_ERROR = "GET_CARD_PAYLOAD_ERROR";

// service socket
export const NFC_SOCKET_CONNECTED = "NFC_SOCKET_CONNECTED";
export const NFC_SOCKET_DISCONNECTED = "NFC_SOCKET_DISCONNECTED";

// server socketsaveCardPayloadsaveCardPayload
export const SERVER_SOCKET_CONNECTED = "SERVER_SOCKET_CONNECTED";
export const SERVER_SOCKET_DISCONNECTED = "SERVER_SOCKET_DISCONNECTED";

// update nfc multi
export const UPDATE_NFC_MULTI_SUCCESS = "UPDATE_NFC_MULTI_SUCCESS";
export const UPDATE_NFC_MULTI_ERROR = "UPDATE_NFC_MULTI_ERROR";

// start nfc multi
export const START_UPDATE_NFC_PROFILE = "START_UPDATE_NFC_PROFILE";
export const START_UPDATE_NFC_CONTRACT = "START_UPDATE_NFC_CONTRACT";
export const START_EXCHANGE_IMPRISONED = "START_EXCHANGE_IMPRISONED";
export const START_UPDATE_NFC_CONTRACT_REFUND =
  "START_UPDATE_NFC_CONTRACT_REFUND";

export const CHANGE_NFC_ACTIVE = "CHANGE_NFC_ACTIVE";

export const NFC_ACTION_DATA = "NFC_ACTION_DATA";
export const MARK_FOR_REFUND = "MARK_FOR_REFUND";
export const CARD_GREEN_LIST = "CARD_GREEN_LIST";
export const CARD_IMPRISONED_SUM = "CARD_IMPRISONED_SUM";
export const SET_RENEW_CONTRACT ="SET_RENEW_CONTRACT"
export const CLEAR_RENEW_CONTRACT ="CLEAR_RENEW_CONTRACT"

export const SAVE_CARD_PAYLOAD_INFO = "SAVE_CARD_PAYLOAD_INFO";
export const CLEAR_CARD_PAYLOAD_INFO = "CLEAR_CARD_PAYLOAD_INFO";
export const CLEAR_NFC_ERROR = "CLEAR_NFC_ERROR";
export const SAVE_CARD_HISTORY = "SAVE_CARD_HISTORY";
export const SAVE_CARD_CONTRACTS = "SAVE_CARD_CONTRACTS";

export const SAVE_VENDOR_USER_DATA = "SAVE_VENDOR_USER_DATA";
export const VENDOR_MULTI_START = "VENDOR_MULTI_START";
