import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  getPlatforms,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../..";
import connectNfcReaderIcon from "../../../../assets/icons-nfc-connect-reader.svg";
import oneIocn from "../../../../assets/icons-nfc-order-number-1.svg";
import twoIocn from "../../../../assets/icons-nfc-order-number-2.svg";
import threeIocn from "../../../../assets/icons-nfc-order-number-3.svg";
import fourIocn from "../../../../assets/icons-nfc-order-number-4.svg";
import fiveIocn from "../../../../assets/icons-nfc-order-number-5.svg";
import arrowOneIcon from "../../../../assets/icons-nfc-order-arrow-1.svg";
import arrowTwoIcon from "../../../../assets/icons-nfc-order-arrow-2.svg";
import arrowThreeIcon from "../../../../assets/icons-nfc-order-arrow-3.svg";
import arrowFourIcon from "../../../../assets/icons-nfc-order-arrow-4.svg";
import "./NfcOrderPage.scss";
import { useEffect, useRef, useState } from "react";
import { getOs } from "../../../../utils/helper-functions";
import { OS, ROUTES } from "../../../../store/Globals";
import {
  closeNfcConnection,
  createNfcConnection,
} from "../../../../store/action-creators/Nfc";
import { useNfc } from "../../../../hooks/nfc/UseNfc";

const langConfigPath = "pages.desktop.nfcOrder";

const NfcOrderPageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const { t } = useTranslation();
  const { serviceStatus, init: initNfc } = useNfc();
  const [platformDownloadLinks, setPlatformDownloadLinks] =
    useState<string[]>();
  const [platform, setPlatform] = useState<string>();
  const [secondPlatform, setSecondPlatform] = useState<string>("mac");
  const [thirdPlatform, setThirdPlatform] = useState<string>("mac");

  useEffect(() => {
    if (serviceStatus) {
      history.push(ROUTES.desktopNfcRead);
    }
  }, [serviceStatus, history]);

  useIonViewWillEnter(() => {
    const os = getOs();
    switch (os) {
      case OS.windows:
        setPlatform(t(`${langConfigPath}.downloadButton.windows`));
        setSecondPlatform(t(`${langConfigPath}.downloadButton.mac`));
        setThirdPlatform(t(`${langConfigPath}.downloadButton.linux`));
        setPlatformDownloadLinks(["", "", ""]);
        break;
      case OS.mac:
        setPlatform(t(`${langConfigPath}.downloadButton.mac`));
        setSecondPlatform(t(`${langConfigPath}.downloadButton.windows`));
        setThirdPlatform(t(`${langConfigPath}.downloadButton.linux`));
        setPlatformDownloadLinks(["", "", ""]);
        break;
      case OS.linux:
        setPlatform(t(`${langConfigPath}.downloadButton.linux`));
        setSecondPlatform(t(`${langConfigPath}.downloadButton.windows`));
        setThirdPlatform(t(`${langConfigPath}.downloadButton.mac`));
        setPlatformDownloadLinks(["", "", ""]);
        break;
      default:
        setPlatform(t(`${langConfigPath}.downloadButton.windows`));
        setSecondPlatform(t(`${langConfigPath}.downloadButton.mac`));
        setThirdPlatform(t(`${langConfigPath}.downloadButton.linux`));
        setPlatformDownloadLinks(["", "", ""]);
    }
  });

  const onDownload = () => {
    initNfc();
  };

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-nfc-order-page">
          <div className="desktop-title-container flex-direction-column">
            <IonText className="desktop-header text">
              {t(`${langConfigPath}.header`)}
            </IonText>
            <IonText className="desktop-sub-header text">
              {t(`${langConfigPath}.subHeader`)}
            </IonText>
          </div>

          <IonCard className="desktop-page-card">
            <IonCardContent className="desktop-card-content">
              <IonIcon
                className="connect-reader-icon"
                src={connectNfcReaderIcon}
              />

              <IonGrid className="order-grid">
                <IonRow className="order-row flex-direction-row-reverse">
                  <IonCol size="2" className="order-col">
                    <div className="flex ion-align-items-center">
                      <div className="icon-num">1</div>
                      <IonIcon
                        className="arrow-icon arrow-1"
                        src={arrowOneIcon}
                      />
                    </div>
                    <IonText className="text">
                      {t(`${langConfigPath}.steps.step1`)}
                    </IonText>
                  </IonCol>

                  <IonCol size="3" className="order-col">
                    <div className="flex ion-align-items-center">
                      <div className="icon-num">2</div>
                      <IonIcon
                        className="arrow-icon arrow-2"
                        src={arrowTwoIcon}
                      />
                    </div>
                    <IonText className="text">
                      {t(`${langConfigPath}.steps.step2`)}
                    </IonText>
                  </IonCol>

                  <IonCol className="order-col">
                    <div className="flex ion-align-items-center">
                      <div className="icon-num">3</div>
                      <IonIcon
                        className="arrow-icon arrow-3"
                        src={arrowThreeIcon}
                      />
                    </div>
                    <IonText className="text">
                      {t(`${langConfigPath}.steps.step3`)}
                    </IonText>
                  </IonCol>

                  <IonCol className="order-col">
                    <div className="flex ion-align-items-center">
                      <div className="icon-num">4</div>
                      <IonIcon
                        className="arrow-icon arrow-4"
                        src={arrowFourIcon}
                      />
                    </div>
                    <IonText className="text">
                      {t(`${langConfigPath}.steps.step4`)}
                    </IonText>
                  </IonCol>

                  <IonCol className="order-col">
                    <div className="flex ion-align-items-center">
                      <div className="icon-num">5</div>
                    </div>
                    <IonText className="text">
                      {t(`${langConfigPath}.steps.step5`)}
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <div className="contrast">
                <IonText className="bottom-text text">
                  {t(`${langConfigPath}.explanationMessage`)}
                </IonText>

                <IonButton className="button-primary" onClick={onDownload}>
                  {t(`${langConfigPath}.downloadButton.label`, { platform })}
                </IonButton>

                <div className="downloads-links-container">
                  {/* <IonRouterLink className="link text">
                    {t(`${langConfigPath}.secondDownloadLink`, {
                      secondPlatform,
                    })}
                  </IonRouterLink>
                  <IonRouterLink className="link text">
                    {t(`${langConfigPath}.thirdDownloadLink`, { thirdPlatform })}
                  </IonRouterLink> */}
                  <IonRouterLink className="link text" onClick={onDownload}>
                    {t(`${langConfigPath}.haveTheProgramLink`)}
                  </IonRouterLink>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NfcOrderPageDesktop;
