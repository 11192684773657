import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { customPOST } from "../services/customApi/fetch";
import { useTempCardPayload } from "./nfc/UseNfc";
import { chargeSchema } from "./types";
import { useSimpleQuery } from "./UseFetch";
import { formatDateShort } from "../utils/helper-functions";

const chargeArrSchema = yup.array(chargeSchema);

const getChargeHistory = async (params: {
  type: "contract";
  cardNumber?: number;
}) => {
  return await customPOST("payments/history", params);
};

export const useChargeHistory = (params: { type: "contract" }) => {
  const { tempCardPayload } = useTempCardPayload();

  const cardNumber = useMemo(() => {
    if (!tempCardPayload) return;
    return parseInt(tempCardPayload.SCNumber, 16);
  }, [tempCardPayload]);
  const { i18n } = useTranslation();
  const { data: chargeHistory, isLoading } = useSimpleQuery(
    () => getChargeHistory({ ...params, cardNumber }),
    (v) => chargeArrSchema.validate(v)
  );
  const chargeHistoryGrouped = useMemo(() => {
    const grouped = _.entries(
      _.groupBy(
        chargeHistory,
        (v) =>
          `${v.updatedAt.getFullYear()}.${v.updatedAt.getMonth()}.${v.updatedAt.getDate()}`
      )
    );
    const sorted = grouped.sort(([a], [b]) => b.localeCompare(a));
    const mapped = sorted.map(([dateStr, v]) => {
      const [year, monthIndex, date] = dateStr.split(".");
      const day = new Date(Number(year), Number(monthIndex), Number(date));
      const key = formatDateShort(day);
      return [key, v.reverse()] as const;
    });
    return mapped;
  }, [chargeHistory, i18n]);
  return { chargeHistory, chargeHistoryGrouped, isLoading };
};
