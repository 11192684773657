import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { customGET } from "../../services/customApi/fetch";
import {
  changeCardActiveStatus,
  getCardDetailsSuccess,
  nfcMultiError,
  nfcMultiSuccess,
  saveGreenList,
  startGetCardDetails,
  startVendorMulti,
} from "../../store/action-creators/Nfc";
import { POPUPS } from "../../store/Globals";
import { dispatchSdkEvent, sdkEventData } from "../../utils/helper-functions";
import {
  closeSessionOrt,
  commandResOrt,
  getCommands,
  placeProfileRequestOrt,
} from "../helpers";
import {
  ortCommands,
  sdkCommands,
  sdkEvents,
  RefundContractParams,
  SdkCommand,
  SdkEvent,
  TExchangeImprisonedActionPayload,
} from "../types";
import { changePopup } from "../../store/action-creators/App";
import {
  cardGreenList,
  checkCardBlocked,
} from "../../services/vendorApiMethods";

export const getFromSdk = ({ action }: { action: SdkEvent }) => {
  const isIos = !!window?.webkit?.messageHandlers;
  return new Promise((resolve, reject) => {
    const listener = ({ data: payload }: any) => {
      console.log("sdk event", payload);
      
      const { data, action: sdkAction }: { data: any; action: SdkEvent } =
        payload;
      if (sdkAction === action) {
        window.removeEventListener("message", listener);
        resolve(data);
      }
    };
    window.addEventListener("message", listener);
    console.log(`awaiting ${action} sdk event`);
    if (isIos) {
      setTimeout(() => {
        window.removeEventListener("message", listener);
        reject("timeout 10s");
      }, 10 * 1000);
    }
  });
};

const applyCommands = async ({
  commands,
  rspEvent,
  action = sdkCommands.applyCommands,
}: {
  commands: any[];
  action?: SdkCommand;
  rspEvent?: SdkEvent;
}) => {
  // post the msg to the sdk
  sendToSDK({ data: commands, action });
  // await the response event
  return await getFromSdk({ action: rspEvent ?? sdkEvents.cmdResults });
};
export const sendToSDK = ({
  action,
  data,
  error,
}: {
  action: SdkCommand;
  data?: any;
  error?: string;
}) => {
  dispatchSdkEvent(action, data, error);
};

export const sdkLog = (msg: string) => {
  sendToSDK({ action: sdkCommands.log, data: msg });
};

const cardInfo = async () => {
  const { commands } = await getCommands(ortCommands.readCard);
  const rsp = await applyCommands({
    commands,
    action: sdkCommands.readCard,
    rspEvent: sdkEvents.cardInfo,
  });

  return rsp;
};
const closeSDKSession = (params: { data?: any; error?: string } = {}) => {
  sendToSDK({
    action: sdkCommands.closeSession,
    ...params,
  });
};
export const useVendorSDK = () => {
  const appState = useSelector((state: RootState) => state.app);
  const cardNumber = useSelector(
    (state: RootState) => state.nfc.card.cardNumber
  );
  const vendor = appState.vendor;
  const dispatch = useDispatch();

  const getCardInfo = async () => {
    try {
      dispatch(startGetCardDetails());
      const rsp = await cardInfo();
      const { isBlackListed, isBlocked } = await checkCardBlocked(rsp);
      if (isBlocked) {
        dispatch(changePopup(POPUPS.nfcCardBlocked));
        closeSDKSession({ error: "nfc card blocked" });
        return;
      }
      if (isBlackListed) {
        //TODO: block card multi
      }
      cardGreenList(rsp).then((res) => {
        if (res) {
          if (!res || res.length === 0) return;
          dispatch(saveGreenList(res));
          dispatch(changePopup(POPUPS.greenList));
        }
      });
      //TODO:  check if card have imprisoned contracts

      dispatch(getCardDetailsSuccess(rsp));
      closeSDKSession();
    } catch {
      dispatch(changePopup(POPUPS.nfcReadFailed));
      closeSDKSession({ error: "nfc read failed" });
    }
    dispatch(changeCardActiveStatus(false));
    // closeSDKSession();
  };
  const exchangeImprisoned = (payload: TExchangeImprisonedActionPayload) => {
    //TODO: implement multi for vendor
  };

  const sdkMulti = async ({
    fetchCommands,
  }: {
    fetchCommands: (
      cardPayload: any
    ) => Promise<{ commands: any[]; SessionID: string } | undefined>;
  }) => {
    try {
      dispatch(startVendorMulti());
      const contractCardNumber = cardNumber;
      const cardPayload = await cardInfo();
      const currentCardNumber = parseInt((cardPayload as any).SCNumber, 16);
      if (currentCardNumber !== contractCardNumber)
        throw new Error("card number mismatch");
      const cmdsRsp = await fetchCommands(cardPayload);
      if (!cmdsRsp) throw new Error("unable to fetch commands");
      const { SessionID, commands } = cmdsRsp;
      const cmdRsp = await applyCommands({ commands });
      const cmdResultsRsp = await commandResOrt({
        results: cmdRsp as any,
        SessionID,
      });
      if (!cmdResultsRsp.IsValid || !cmdResultsRsp.IsSuccess) {
        throw new Error("close session ort error");
      }
      const closeRsp = await applyCommands({ commands: cmdResultsRsp });
      const closeOrtRsp = await closeSessionOrt({
        results: closeRsp as any,
        SessionID,
      });

      console.log("closeOrtRsp");
      console.log(closeOrtRsp);
      if (!closeOrtRsp.IsValid || !closeOrtRsp.IsSuccess) {
        throw new Error("close session ort error");
      }
      closeSDKSession();
      dispatch(nfcMultiSuccess({ payload: closeOrtRsp }));
      return closeOrtRsp;
    } catch (err) {
      dispatch(nfcMultiError({ payload: err }));
      if (err instanceof Error) {
        closeSDKSession({ error: err.message });
      } else {
        closeSDKSession({ error: "unknown error" });
      }
    }
  };

  const loadContract = async (payload: {
    contract: any;
    paymentUid?: string;
    GreenListConfirmationCode?: number;
  }) => {
    const fetchCommands = async (cardPayload: any) => {
      const rsp = await getCommands(ortCommands.loadContract, {
        cardPayload,
        ...payload,
      });
      if (!rsp) return;
      const { commands, SessionID } = rsp;
      return { commands, SessionID };
    };

    return await sdkMulti({ fetchCommands });
  };
  const loadProfile = async (reqId: string) => {
    const cardPayload = await cardInfo();
    const { ComplaintConfirmationId } = await placeProfileRequestOrt(
      reqId,
      cardPayload
    );
    const fetchCommands = async () => {
      const rsp = await getCommands(ortCommands.loadProfile, {
        ComplaintConfirmationId,
        cardPayload,
      });
      if (!rsp) return;
      const { commands, SessionID } = rsp;
      return { commands, SessionID };
    };

    const rsp = await sdkMulti({ fetchCommands });
    customGET(`profiles/markRequestExpired/${reqId}`);
    return rsp;
  };

  const refundContract = async (removeContractParams: RefundContractParams) => {
    const fetchCommands = async (cardPayload: any) => {
      const rsp = await getCommands(ortCommands.refundContract, {
        cardPayload,
        removeContractParams,
      });
      if (!rsp) {
        throw new Error("error getting commands for load contract");
      }
      const { commands, SessionID } = rsp;
      return { commands, SessionID };
    };

    return await sdkMulti({ fetchCommands });
  };

  useEffect(() => {
    if (appState.sdkEventData) {
      const { action, data }: sdkEventData = appState.sdkEventData;
      console.log({ action, data });

      switch (action) {
        case sdkEvents.cardStatus:
          dispatch(changeCardActiveStatus(data));
          break;
        case sdkEvents.cardInfo:
          window.postMessage({
            action,
            data: typeof data === "string" ? JSON.parse(data) : data,
          });
          break;
        case sdkEvents.cmdResults:
          window.postMessage({
            action,
            data: typeof data === "string" ? JSON.parse(data) : data,
          });
          break;
      }
    }
  }, [appState.sdkEventData]);

  return {
    loadProfile,
    exchangeImprisoned,
    getCardInfo,
    loadContract,
    refundContract,
  };
};
