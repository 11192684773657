import {
  IonCard,
  IonCol,
  IonItem,
  IonList,
  IonRow,
  IonText,
} from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import { Contract, TContractDetails } from "../../../../hooks/types";
import "./ContractInfoCard.scss";
import { ortTimestampToDate } from "../../../../hooks/useCard";
import { formatDateShort } from "../../../../utils/helper-functions";

type ContractInfoCardProps = {
  mobile?: boolean;
  contract?: TContractDetails;
  contractParent?: Contract;
};
const langConfigPath = "contractDetails";

const ContractInfoCard: React.FC<ContractInfoCardProps> = ({
  mobile = false,
  contract,
  contractParent,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const extended = useMemo(() => {
    const saleOrtDate = contractParent?.ExtendedInfo?.SaleDate;
    const renewSum = contractParent?.ExtendedInfo?.Sum2Renew;
    return {
      lastLoadAt: saleOrtDate
        ? formatDateShort(ortTimestampToDate(saleOrtDate))
        : "unknown",
      renewSum,
    };
  }, [contractParent]);

  const headers = useMemo(() => {
    const renewVal = (() => {
      if (contract?.ETT === 65) return t("startsAt", { amount: 30 });
      if (contractParent?.ExtendedInfo?.Sum2Renew)
        return (contractParent.ExtendedInfo.Sum2Renew / 100).toFixed(2) + "₪";
      return t("currNotSold");
    })();
    return {
      type: t("type"),
      code: t("code"),
      profile: t("profile"),
      loadedAt: t("loadedAt"),
      operator: t("operator"),
      renew: t("renew"),
      renewVal,
    };
  }, [t, contract, contractParent]);
  return (
    <IonCard
      className="contarct-info-card"
      dir={appDirection}
      data-is-mobile={mobile}
    >
      {!contract ? (
        <IonText className="text">no contarct</IonText>
      ) : (
        <IonList lines={mobile ? "full" : "none"}>
          <IonRow className="row">
            <IonCol className="col" size={mobile ? "12" : "2"}>
              <IonItem>
                <IonText className="pop-up-sub-text text">
                  {headers.type}
                </IonText>
                <IonText className="text bold">{contract.ETTStr}</IonText>
              </IonItem>
            </IonCol>
            <IonCol className="col" size={mobile ? "12" : "2"}>
              <IonItem>
                <IonText className="pop-up-sub-text text">
                  {headers.code}
                </IonText>
                <IonText className="text bold">{contract.ETT}</IonText>
              </IonItem>
            </IonCol>
            <IonCol className="col" size={mobile ? "12" : "2"}>
              <IonItem>
                <IonText className="pop-up-sub-text text">
                  {headers.profile}
                </IonText>
                <IonText className="text bold">{contract.ProfileStr}</IonText>
              </IonItem>
            </IonCol>
            <IonCol className="col" size={mobile ? "12" : "2"}>
              <IonItem>
                <IonText className="pop-up-sub-text text">
                  {headers.loadedAt}
                </IonText>
                <IonText className="text bold">{extended.lastLoadAt}</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="col" size={mobile ? "12" : "2"}>
              <IonItem>
                <IonText className="pop-up-sub-text text">
                  {headers.operator}
                </IonText>
                <IonText className="text bold">{contract.OperatorStr}</IonText>
              </IonItem>
            </IonCol>
            <IonCol className="col" size={mobile ? "12" : "2"}>
              <IonItem lines="none">
                <IonText className="pop-up-sub-text text">
                  {headers.renew}
                </IonText>
                <IonText className="text bold">{headers.renewVal}</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonList>
      )}
    </IonCard>
  );
};

export default ContractInfoCard;
