import { IonIcon, IonText } from "@ionic/react";
import { useState } from "react";
import arrowDown from "../../assets/icons-small-arrow-down.svg";
import arrowDownPango from "../../assets/icons-small-arrow-down_pango.svg";

import arrowUp from "../../assets/icons-small-arrow-up.svg";
import arrowUpPango from "../../assets/icons-small-arrow-up-pango.svg";
import "./faqItem.scss";
import { useSelector } from "react-redux";
import { RootState } from "../..";

type FAQItem = {
  header: JSX.Element | string;
  mobile?: boolean;
};

const FAQItem: React.FC<FAQItem> = ({ header, children, mobile }) => {
  const [expanded, setExpanded] = useState(false);
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const arrowUpIcon = vendor === "pango" ? arrowUpPango : arrowUp;
  const arrowDownIcon = vendor === "pango" ? arrowDownPango : arrowDown;

  return (
    <div className="faq-item">
      <div
        className="flex justify-content-space-between ion-align-items-center clickable"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <IonText className="text bold">{header}</IonText>
        <IonIcon
          slot="end"
          className="expand-icon"
          color="red"
          src={expanded ? arrowUpIcon : arrowDownIcon}
        />
      </div>

      {expanded && (
        <div className="content">
          <IonText className="text font-size-14">{children}</IonText>
        </div>
      )}
    </div>
  );
};

export default FAQItem;
