import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonMenuToggle,
  IonLabel,
  IonNavLink,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonImg,
  IonPopover,
  useIonViewWillEnter,
} from "@ionic/react";

import "./ActionsInRavKavCardDropDown.scss";
import { ROUTES, VERSION } from "../../../../store/Globals";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import MainMenuDropDown from "../MainMenuDropDown";
import { useHistory } from "react-router";

const langConfigPath = "mainMenu.ravKavCardActions.dropDown";

type ActionsInRavKavCardDropDown = {
  triggerId: string;
};
const ActionsInRavKavCardDropDown: React.FC<ActionsInRavKavCardDropDown> = ({
  triggerId,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <MainMenuDropDown triggerId={triggerId}>
      <IonItem
        onClick={() => {
          history.push("/desktop_nfc_order");
        }}
        className="item"
        button={true}
      >
        <IonLabel className="label text">
          {t(`${langConfigPath}.contractsAndLastActions.label`)}
        </IonLabel>
      </IonItem>

      <IonItem
        className="item"
        onClick={() => {
          history.push(ROUTES.desktopProfiles);
        }}
        button={true}
      >
        <IonLabel className="label text">
          {t(`${langConfigPath}.discountProfiles.label`)}
        </IonLabel>
      </IonItem>
    </MainMenuDropDown>
  );
};

export default ActionsInRavKavCardDropDown;
