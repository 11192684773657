import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonText,
} from "@ionic/react";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import vIcon from "../../assets/icons-actions-v-circle.svg";
import xIcon from "../../assets/icons-actions-x-circle-red.svg";
import inProgressIcon from "../../assets/icons-actions-in-process.svg";
import "./DiscountProfileCard.scss";
import { ProfileStatus } from "../../services/models/profile";

const langConfigPath = "discountProfileCard";

type TDiscountProfileCard = {
  name: string;
  onCheckFilesClick?: MouseEventHandler;
  onLoadProfile?: MouseEventHandler;
  onDeleteRequest?: MouseEventHandler;
  mobile?: boolean;
  confirmed?: boolean;
  isRegular?: boolean;
  profile_status?: string;
};

const DiscountProfileCard: React.FC<TDiscountProfileCard> = ({
  name,
  mobile,
  confirmed,
  onDeleteRequest,
  onLoadProfile,
  onCheckFilesClick,
  isRegular,
  profile_status,
}) => {
  const { t } = useTranslation();

  const deleteReqStr = t(`${langConfigPath}.deleteReq`);

  const returnStatusMessageOrIcon = (
    type: string,
    profile_status: string | undefined
  ) => {
    switch (profile_status) {
      case "new-request":
        return type === "text"
          ? t(`${langConfigPath}.status.confirmedState.inProgress`)
          : inProgressIcon;
        break;

      case "treatment-request":
        return type === "text"
          ? t(`${langConfigPath}.status.confirmedState.inProgress`)
          : inProgressIcon;
        break;

      case "returned-request":
        return type === "text"
          ? t(`${langConfigPath}.status.confirmedState.returned-request`)
          : vIcon;
        break;

      case "denied-request":
        return type === "text"
          ? t(`${langConfigPath}.status.confirmedState.notConfirmed`)
          : xIcon;
        break;

      case "approved-request":
        return type === "text"
          ? t(`${langConfigPath}.status.confirmedState.confirmed`)
          : vIcon;
        break;
    }
  };

  const fileTofix = (profile_status: string | undefined) => {
    return profile_status === "denied-request";
  };

  return (
    <IonCard className="discount-profile-card" data-is-mobile={mobile}>
      <IonCardContent>
        <div className="profile-name-container">
          <IonText className="text weight-500">
            {t(`${langConfigPath}.header`)}
          </IonText>
          <IonText>&nbsp;</IonText>
          <IonText className="text">{name}</IonText>
        </div>
        {onDeleteRequest && (
          <IonButton className="delete-button" onClick={onDeleteRequest}>
            {deleteReqStr}
          </IonButton>
        )}
      </IonCardContent>

      <div className="profile-status-container" hidden={isRegular}>
        <IonCardContent>
          <div>
            <IonText className="text weight-500">
              {t(`${langConfigPath}.status.header`)}
            </IonText>
            <div className="flex ion-align-items-center">
              <IonIcon
                className="status-icon"
                src={returnStatusMessageOrIcon("icon", profile_status)}
              />

              <IonText className="text">
                {returnStatusMessageOrIcon("text", profile_status)}
              </IonText>
            </div>
          </div>
          {fileTofix(profile_status) && (
            <IonButton
              className="button-secondary"
              hidden={confirmed}
              onClick={onCheckFilesClick}
            >
              {t(`${langConfigPath}.status.filesStatusButton.label`)}
            </IonButton>
          )}
          {profile_status === ProfileStatus.approved_request && onLoadProfile && (
            <IonButton className="button-secondary" onClick={onLoadProfile}>
              {t(`${langConfigPath}.status.load-profile.label`)}
            </IonButton>
          )}
        </IonCardContent>
      </div>
    </IonCard>
  );
};

export default DiscountProfileCard;
