import {
  IonButton,
  IonIcon,
  IonNavLink,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./GreenListPopup.scss";
import contractIcon from "../../../assets/icons-actions-green-contract.svg";
import pango_happy from "../../../assets/pango_happy.svg";

type TGreenListPopup = {
  mobile?: boolean;
};

const GreenListPopup: React.FC<TGreenListPopup> = ({ mobile = false }) => {
  const { t } = useTranslation("", { keyPrefix: "popUps.greenList" });
  const dispatch = useDispatch();
  const greenList = useSelector((state: RootState) => state.nfc.card.greenList);
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const numOfContracts = useMemo(() => greenList?.length ?? 0, [greenList]);
  const singleOrMulti = numOfContracts > 1 ? "multi" : "single";
  const labels = {
    header: (num: number) => t(`${singleOrMulti}.header`, { num }),
    text: t(`${singleOrMulti}.text`),
    btnText: t(`${singleOrMulti}.btnText`),
    moreInfo: t("moreInfo"),
  };
  const onOk = () => {
    dispatch(changePopup(POPUPS.clear));
  };

  const icon = vendor === "pango" ? pango_happy : contractIcon;

  return (
    <Popup popUpId={POPUPS.greenList} mobile={mobile}>
      <div className="pop-up" data-is-mobile={mobile}>
        <IonIcon className="pop-up-icon" src={icon} />
        <IonText className="text weight-500 info">
          {labels.header(numOfContracts)}
        </IonText>
        <IonText className="text text-align-center">{labels.text}</IonText>
        <IonButton className="main-blue-buttons" onClick={onOk}>
          {labels.btnText}
        </IonButton>
        {/* <IonRouterLink
          routerLink={ROUTES.home}
          onClick={() => dispatch(changePopup(POPUPS.clear))}
          className="text link text-align-center"
        >
          {labels.moreInfo}
        </IonRouterLink> */}
      </div>
    </Popup>
  );
};

export default GreenListPopup;
