import { AnyAction, Dispatch } from "redux";
import {
  GET_USER_DATA_SUCCESS,
  START_GET_USER_DATA,
  GET_USER_DATA_ERROR,
  USER_DATA_EDIT_ACTIVATE,
  USER_DATA_EDIT_DEACTIVATE,
  START_UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_ERROR,
  SAVE_VENDOR_USER_DATA,
} from "../actions";

//#get user data
export const startGetUserData = (): AnyAction => {
  return { type: START_GET_USER_DATA };
};

export const getUserDataSuccess = <T>(data: T) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_USER_DATA_SUCCESS, payload: data });
  };
};

export const getUserDataError = (error: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: GET_USER_DATA_ERROR, payload: error });
  };
};

//update user data
export const startUpdateUserData = (): AnyAction => {
  return { type: START_UPDATE_USER_DATA };
};

export const updateUserDataSuccess = <T>(data: T) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_USER_DATA_SUCCESS, payload: data });
  };
};

export const updateUserDataError = (error: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({ type: UPDATE_USER_DATA_ERROR, payload: error });
  };
};

export const changeUserEditModeState = (activate: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: activate ? USER_DATA_EDIT_ACTIVATE : USER_DATA_EDIT_DEACTIVATE,
    });
  };
};

export const saveVendorUserData = (data: any) => {
  return { type: SAVE_VENDOR_USER_DATA, payload: data };
};
