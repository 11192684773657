import { AnyAction } from 'redux'
import {
    CHANGE_TOP_BAR_SEARCH_VALUE,
    CHANGE_PERSONAL_AREA__MOBILE_MENU_OPEN_STATE
}
from '../actions'

const initialValues = {
    searchValue: '',
    personalAreamobileMenuOpenState: false
}


const topMenuReducer = (state=initialValues, {type, payload}: AnyAction) =>{
    switch(type){
        case CHANGE_TOP_BAR_SEARCH_VALUE:
            return {...state, searchValue: payload}
        case CHANGE_PERSONAL_AREA__MOBILE_MENU_OPEN_STATE:
            return {...state, personalAreamobileMenuOpenState: payload}
        default:
            return state
    }

}

export default topMenuReducer