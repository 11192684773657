import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS } from "../../../store/Globals";
import Popup from "../Popup";
import "./NfcLoadContractSuccessPopup.scss";
import contractIcon from "../../../assets/icons-90-px-payment-success.svg";
import pango_happy from "../../../assets/pango_happy.svg";

import { saveNfcAction } from "../../../store/action-creators/Nfc";
import { TContractDetails, nfcActions } from "../../../hooks/types";
import { RootState } from "../../..";

type NfcLoadContractSuccessPopupProps = {
  mobile?: boolean;
  routeOnDismiss?: string;
};

const NfcLoadContractSuccessPopup: React.FC<
  NfcLoadContractSuccessPopupProps
> = ({ mobile = false, routeOnDismiss }) => {
  const { t } = useTranslation("", { keyPrefix: "popUps.nfcLoadSuccess" });
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const contractData = useSelector((state: RootState) => state.app.popUp.props)
    ?.contract as TContractDetails;
  const dispatch = useDispatch();
  if (!contractData) return null;
  const labels = {
    header: t("header"),
    text: contractData.SpecificContractData.Units
      ? t("sumValueText", {
          sum: (contractData.SpecificContractData.Units ?? 0) / 100,
          profile: contractData.ProfileStr,
        })
      : t("text", {
          type: contractData.ETTStr,
          profile: contractData.ProfileStr,
        }),
    btnText: t("watchContract"),
  };
  const onOk = () => {
    dispatch(saveNfcAction({ action: nfcActions.readCard }));
    dispatch(changePopup(POPUPS.clear));
  };

  const icon = vendor === "pango" ? pango_happy : contractIcon;

  return (
    <Popup
      routeOnDismiss={routeOnDismiss}
      popUpId={POPUPS.nfcContractLoaded}
      mobile={mobile}
      cssClass="add-new-card-for-charge-pop-up-container"
    >
      <div className="top-contrast"></div>
      <div
        className="pop-up add-new-card-for-charge-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={icon} />
        <IonText className="text weight-500 info">{labels.header}</IonText>

        <IonText className="text flex flex-wrap">{labels.text}</IonText>
        <br />
        <IonButton className=" main-blue-buttons pop-up-botton" onClick={onOk}>
          {labels.btnText}
        </IonButton>
      </div>
    </Popup>
  );
};

export default NfcLoadContractSuccessPopup;
