import { IonContent, IonPage, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import { changePersonalAreaMobileMenuOpenState } from "../../../store/action-creators/TopMenu";
import "./PersonalAreaSelectionPage.scss";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.personalAreaSelection";

const PersonalAreaSelectionPage: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent className="mobile-page-content">
        <div className="personal-area-selection-page">
          <div className="mobile-title-container">
            <MobileBackIcon
              onClick={() => {
                history.goBack();
                dispatch(changePersonalAreaMobileMenuOpenState(false));
              }}
            />
            <IonText className="mobile-header text">
              {t(`${langConfigPath}.title`)}
            </IonText>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PersonalAreaSelectionPage;
