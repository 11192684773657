import { IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import {
  contractDecoratorTypes,
  flexibleSumValueETT,
  TContractDetails,
} from "../../../hooks/types";
import {
  TContractType,
  contractTypes,
  useContractsForLoading,
} from "../../../hooks/contracts/useContractsForLoading";
import "./selectContract.scss";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TrainContract from "./TrainContract";
import { TProfile } from "../../../hooks/useAllProfiles";
import { useTransportTypes } from "../../../hooks/contracts/useContractGreenList";
import { CenteredSpinner } from "../../Spinner";

const langConfigPath = "stepsSections.selectContract";

const isROundNum = (num: number) => {
  return num % 100 === 3 ? (num / 100).toFixed(2) : num / 100;
};
type TSelectContract = {
  profileType?: TProfile;
  buttonLabel?: string;
  nextStep: (contract: TContractDetails) => void;
  contractType?: TContractType;
  mobile?: boolean;
  isRemote?: boolean;
};

const ContractCard: FC<{ contract: TContractDetails; onClick: () => void }> = ({
  contract,
  onClick,
}) => {
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  const isFlexibleSum = useMemo(
    () => contract.ETT === flexibleSumValueETT,
    [contract]
  );
  const labels = useMemo(() => {
    if (contract.SpecificContractData.Units) {
      return {
        title: contract.ETTStr,
        valueTxt: isFlexibleSum
          ? t("sumFlexible")
          : t("sumDescription", {
              amount: isROundNum(contract.SpecificContractData.Units),
            }),
        price: isFlexibleSum
          ? t("contractDetails.startsAt", { amount: 30 })
          : contract.PriceInAgorot / 100 + " ₪",
      };
    }
    return {
      title: contract.ETTStr,
      valueTxt: contract.Description,
    };
  }, [contract, isFlexibleSum]);
  return (
    <div onClick={() => onClick()} className="select-contract-card clickable">
      <span>
        <div className="bold">{labels.title}</div>
        <div className="contract-description">{labels.valueTxt}</div>
      </span>
      <span className="bold">{labels.price}</span>
    </div>
  );
};

const SelectTransportType: FC<{
  selectType: (transportType: number) => void;
}> = ({ selectType }) => {
  const { transportTypes, isLoading } = useTransportTypes();
  // const appDirection = useSelector((state: RootState) => state.app.direction);
  if (isLoading) return <CenteredSpinner />;
  if (!transportTypes) return <div>no transport types</div>;
  return (
    <>
      {transportTypes.map((transportType) => (
        <IonCard
          key={transportType.key}
          onClick={() => selectType(+transportType.key)}
        >
          <IonCardContent>{transportType.value}</IonCardContent>
        </IonCard>
      ))}
    </>
  );
};

const SelectContract: React.FC<TSelectContract> = ({
  mobile,
  isRemote = false,
  contractType,
  nextStep,
  profileType,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const cardImage = useSelector((state: RootState) => state.nfc.card.data);
  const [transportationType, setTransportationType] = useState<number>();
  const isTransportTypeOptional =
    contractTypes.train === contractType ||
    contractTypes.storedValue === contractType ||
    contractTypes.singleRide === contractType;
  const { availableContracts, isLoading } = useContractsForLoading({
    contractType,
    isRemote,
    profileId: profileType?.id,
    transportationType,
    enabled: isTransportTypeOptional || !!transportationType,
  });
  if (isLoading) return <CenteredSpinner />;

  if (contractType === contractTypes.train)
    return (
      <TrainContract
        selectContract={nextStep}
        mobile={mobile}
        profileType={profileType}
        cardImage={cardImage}
      />
    );
  if (transportationType === undefined && !isTransportTypeOptional)
    return <SelectTransportType selectType={setTransportationType} />;

  return (
    <div
      dir={appDirection}
      className="select-contract flex flex-direction-column"
      data-is-mobile={mobile}
    >
      {availableContracts?.map((c, i) => (
        <ContractCard key={i} contract={c} onClick={() => nextStep(c)} />
      ))}
    </div>
  );
};

export default SelectContract;
