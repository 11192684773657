import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import i18 from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import heTranslation from "./utils/languages-config/hebrew.json";
import enTranslation from "./utils/languages-config/english.json";
import arbTranslation from "./utils/languages-config/arabic.json";
import rusTranslation from "./utils/languages-config/russian.json";
import detectionOptions from "./utils/languages-config/DetectionOptions.json";
import configureStore from "./store/ConfigureStore";
import { Provider } from "react-redux";

i18
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { ...detectionOptions },
    lng: "he",
    resources: {
      he: { translation: heTranslation },
      en: { translation: enTranslation },
      ar: { translation: arbTranslation },
      ru: { translation: rusTranslation },
    },
    // fallbackLng: "he",
    interpolation: { escapeValue: false },
  });

declare global {
  interface Window {
    Android: any;
    webkit: any;
    GetActionFromApp: (actionName: string, data: any) => void;
  }
}

export const store = configureStore({});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading...">
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
