import { AnyAction } from "redux";
import { ILogIn } from "../../services/models/auth";
import { IUser } from "../../services/models/users";
import {
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SAVE_LOGIN_FORM_DATA,
  START_VALIDATE_OTP,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_ERROR,
  START_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  START_LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_ERROR,
} from "../actions";

type initialValues = {
  logIn: {
    loading: boolean;
    data: any | null;
    error: string | null;
    savedFormData: ILogIn | null;
  };
  validateOTP: {
    loading: boolean;
    data: IUser | null;
    error: string | null;
  };
  register: {
    loading: boolean;
    data: any | null;
    error: string | null;
  };
  logout: {
    loading: boolean;
    data: any | null;
    error: string | null;
  };
};

const initialValues: initialValues = {

  logIn: {
    loading: false,
    data: null,
    error: null,
    savedFormData: null,
  },
  validateOTP: {
    loading: false,
    data: null,
    error: null,
  },
  register: {
    loading: false,
    data: null,
    error: null,
  },
  logout: {
    loading: false,
    data: null,
    error: null,
  },
};

const authReducer = (
  state: initialValues = initialValues,
  { type, payload }: AnyAction
) => {
  switch (type) {
    case START_LOGIN:
      return { ...state, logIn: { ...state.logIn, loading: true } };
    case LOGIN_SUCCESS:
      return {
        ...state,
        logIn: { ...state.logIn, loading: false, data: payload, error: null },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        logIn: { ...state.logIn, loading: false, error: payload },
      };
    case SAVE_LOGIN_FORM_DATA:
      return { ...state, logIn: { ...state.logIn, savedFormData: payload } };

    case START_VALIDATE_OTP:
      return { ...state, validateOTP: { ...state.validateOTP, loading: true } };
    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        validateOTP: {
          ...state.validateOTP,
          loading: false,
          data: payload,
          error: null,
        },
      };
    case VALIDATE_OTP_ERROR:
      return {
        ...state,
        validateOTP: { ...state.validateOTP, loading: false, error: payload },
      };

    case START_REGISTER:
      return { ...state, register: { ...state.register, loading: true } };
    case REGISTER_SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          data: payload,
          error: null,
        },
      };
    case REGISTER_ERROR:
      return {
        ...state,
        register: { ...state.register, loading: false, error: payload },
      };

    case START_LOG_OUT:
      return { ...state, logout: { ...state.logout, loading: true } };
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        logout: { ...state.logout, loading: false, data: payload, error: null },
      };
    case LOG_OUT_ERROR:
      return {
        ...state,
        logout: { ...state.logout, loading: false, error: payload },
      };

    default:
      return state;
  }
};

export default authReducer;
