import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../";
import "../OrderCardPage.scss";
import React, { useMemo } from "react";

const langConfigPath = "pages.desktop.order-card";
type TParams = {
  nextStep: () => void;
};

type StepProps = {
  title: string;
  description: string;
  icon: string;
};
const Step = ({ title, description, icon }: StepProps) => {
  return (
    <IonRow className="order-col">
      <div className="icon-num">{icon}</div>
      <IonCol className="flex flex-direction-column gap5">
        <IonText className="text font-size-16 weight-500">{title}</IonText>
        <IonText className="text font-size-14 weight-400">
          {description}
        </IonText>
      </IonCol>
    </IonRow>
  );
};

const OrderCardStepsDescriptionMobile: React.FC<TParams> = ({ nextStep }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  const steps = useMemo(
    () => [
      {
        icon: "1",
        title: t(`steps.step1.title`),
        description: t(`steps.step1.description`),
      },
      {
        icon: "2",
        title: t(`steps.step2.title`),
        description: t(`steps.step2.description`),
      },
      {
        icon: "3",
        title: t(`steps.step3.title`),
        description: t(`steps.step3.description`),
      },
      {
        icon: "4",
        title: t(`steps.step4.title`),
        description: t(`steps.step4.description`),
      },
      {
        icon: "5",
        title: t(`steps.step5.title`),
        description: t(`steps.step5.description`),
      },
    ],
    [t]
  );

  return (
    <IonCard dir={appDirection}>
      <IonCardContent>
        <IonGrid className="flex flex-direction-column gap20">
          {steps.map((step) => (
            <Step
              key={step.icon}
              icon={step.icon}
              title={step.title}
              description={step.description}
            />
          ))}
        </IonGrid>

        <div className="flex flex-direction-column">
          <IonButton onClick={nextStep}>{t(`start-order`)}</IonButton>
          <IonText className="link link-order">{t(`faq`)}</IonText>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default OrderCardStepsDescriptionMobile;
