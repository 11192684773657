import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import documentsSentSuccessIcon from "../../../assets/icons-documents-sent-success.svg";
import pango_happy from "../../../assets/pango_happy.svg";

import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";

import "./CardOrderSuccessPopup.scss";
import { RootState } from "../../..";

type TCardOrderSuccessPopup = {
  mobile?: boolean;
  cssClass?: string;
};
const langConfigPath = "popUps.orderCardSuccess";

const CardOrderSuccessPopup: React.FC<TCardOrderSuccessPopup> = ({
  cssClass,
  mobile = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const [route, setRoute] = useState("");

  const onClickConfirmButton = () => {
    setRoute(mobile ? ROUTES.mobileProfiles : ROUTES.desktopProfiles);
    dispatch(changePopup(POPUPS.clear));
  };
  const labels = {
    confirmBtn: t("confirmButton.label"),
    title: t("title"),
    text: t("text"),
  };
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const icon = vendor === "pango" ? pango_happy : documentsSentSuccessIcon;

  return (
    <Popup
      routeOnDismiss={route}
      popUpId={POPUPS.cardOrderSuccess}
      mobile={mobile}
      cssClass={` ${cssClass}`}
    >
      <div
        className="pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={icon} />
        <IonText className="text weight-500 info">{labels.title}</IonText>
        <IonText className="text popup-long-text">{labels.text}</IonText>

        <IonButton
          onClick={onClickConfirmButton}
          className=" main-blue-buttons pop-up-botton"
        >
          {labels.confirmBtn}
        </IonButton>
      </div>
    </Popup>
  );
};

export default CardOrderSuccessPopup;
