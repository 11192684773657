import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import "./TermsPage.scss";
import { BackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.desktop.terms";

const TermsPageDekstop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const { t } = useTranslation();

  const onBackButtonclick = () => {
    history.goBack();
  };

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-login-page">
          <div className="desktop-title-container flex-direction-row">
            <BackIcon onClick={onBackButtonclick} />
            <IonText className="desktop-header text">
              {t(`${langConfigPath}.header1`)}
            </IonText>
          </div>

          <IonCard className="desktop-page-card">
            <IonCardContent className="desktop-card-content">
              {t(`${langConfigPath}.terms_text`)}
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TermsPageDekstop;
