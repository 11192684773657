import { AnyAction } from "redux";
import { SdkEvent } from "../../hooks/types";
import {
  CHANGE_APP_DIRECTION,
  CHANGE_SHOW_ON_BOARDING,
  CHANGE_POP_UP,
  CHANGE_LOGGED_USER_NAME,
  CHANGE_SDK_RECEIVED_EVENT_DATA,
  CHANGE_VENDOR,
  CHANGE_APP_LOADED,
} from "../actions";
import { POPUPS } from "../Globals";

const allowedVendors = ["moovit", "pango"];

type TinitialValues = {
  direction: "rtl" | "ltr";
  onBoarding: boolean;
  popUp: {
    name: string;
    props?: any;
  };
  userName: {
    firstName: string;
    lastName: string;
    _id: string;
  } | null;
  sdkEventData: {
    action: SdkEvent;
    data: any;
  } | null;
  vendor: string | null;
  isVendor: boolean;
  isLoadingParams: boolean;
};

const initialValues: TinitialValues = {
  direction: "rtl",
  onBoarding: false,
  popUp: { name: POPUPS.clear },
  userName: null,
  sdkEventData: null,
  vendor: "moovit",
  isVendor: false,
  isLoadingParams: true,
};

const appReducer = (
  state = initialValues,
  { type, payload }: AnyAction
): TinitialValues => {
  switch (type) {
    case CHANGE_APP_DIRECTION:
      return { ...state, direction: payload };
    case CHANGE_SHOW_ON_BOARDING:
      return { ...state, onBoarding: payload };
    case CHANGE_POP_UP:
      if (payload?.name === state.popUp.name && !payload?.props) return state;
      return {
        ...state,
        popUp: { name: payload?.name ?? POPUPS.clear, props: payload?.props },
      };
    case CHANGE_LOGGED_USER_NAME:
      return { ...state, userName: payload };
    case CHANGE_SDK_RECEIVED_EVENT_DATA:
      return { ...state, sdkEventData: payload };
    case CHANGE_VENDOR:
      return {
        ...state,
        vendor: typeof payload === "string" ? payload : null,
        isVendor: allowedVendors.includes(payload),
      };
    case CHANGE_APP_LOADED:
      return { ...state, isLoadingParams: !payload };
    default:
      return state;
  }
};

export default appReducer;
