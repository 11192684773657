import { IonContent, IonIcon, IonPage, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "../../..";
import StepsCard from "../../../components/StepsCard";
import "./orderChargePage.scss";
import SelectContract from "../../../components/StepsCardSections/SelectContract";
import OrderChargeSummary from "../../../components/StepsCardSections/OrderChargeSummary";
import { TContractDetails } from "../../../hooks/types";
import { TContractType } from "../../../hooks/contracts/useContractsForLoading";
import SelectContractType from "../../../components/StepsCardSections/SelectContractType";
import SelectCard from "../../../components/StepsCardSections/SelectCard";
import { TProfile } from "../../../hooks/useAllProfiles";
import SelectAllProfile from "../../../components/StepsCardSections/SelectAllProfile";
import PaymentCard from "../../../components/PaymentCard";
import {
  CardLoadLocation,
  saveContractToGreenList,
} from "../../../hooks/contracts/useContractGreenList";
import { useTempCardPayload } from "../../../hooks/nfc/UseNfc";
import PaymentSuccessPopup from "../../../components/pop-ups/PaymentSuccessPopup";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import { useVendorUser } from "../../../hooks/useVendorUser";
import { SelectGreenListLocation } from "../../../components/StepsCardSections/SelectContract/GreenListLocation";
import VendorLoginErrorPopup from "../../../components/pop-ups/VendorLoginErrorPopup";
import searchParser from "../../../services/searchParser";
import classNames from "classnames";

const langConfigPath = "pages.mobile.orderCharge";

const PurchaseContractRemoteMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const isVendor = useSelector((state: RootState) => state.app.isVendor);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const { tempCardPayload } = useTempCardPayload();
  const { userDetails: vendorUserDetails } = useVendorUser({
    isCardRequired: false,
    enabled: true,
  });

  useEffect(() => {
    if (tempCardPayload) setCardNumber(parseInt(tempCardPayload.SCNumber, 16));
  }, [tempCardPayload]);
  const [cardNumber, setCardNumber] = useState<number>();
  const [profileType, setProfileType] = useState<TProfile>();
  const [contractType, setContractType] = useState<TContractType>();

  const [selectedContract, setSelectedContract] = useState<TContractDetails>();
  const [loadingLocation, setLoadingLocation] = useState<CardLoadLocation>();
  const [step, setStep] = useState(0);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("step", step.toString());
    if (step === 0) {
      setContractType(undefined);
      setSelectedContract(undefined);
    } else if (step === 1 && cardNumber) {
      searchParams.set("cardNumber", cardNumber.toString());
    } else if (step === 2 && profileType) {
      searchParams.set("profileId", profileType.id.toString());
      searchParams.set("profileName", profileType.name);
    } else if (step === 3 && contractType) {
      searchParams.set("contractType", contractType.toString());
    }
    history.replace({ search: searchParams.toString() });
  }, [step]);

  useEffect(() => {
    const params = searchParser.remoteContract(location.search);
    setStep(params.step);
    setCardNumber(params.cardNumber);
    setProfileType(params.profileType);
    setContractType(params.contractType);
  }, []);

  const nextStep = () =>
    setStep((s) => (s >= pages.length - 1 ? pages.length - 1 : s + 1));

  const pages = [
    {
      header: t("header"),
      subHeader: t("pages.selectCard.header"),
      component: (
        <SelectCard
          nextStep={(cardNumber: number) => {
            setCardNumber(cardNumber);
            nextStep();
          }}
          mobile
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.selectProfile.header"),
      component: (
        <SelectAllProfile
          nextStep={(profile: TProfile) => {
            setProfileType(profile);
            nextStep();
          }}
          buttonLabel={t("pages.selectProfile.button.label")}
          mobile
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.selectContract.header"),
      component: (
        <SelectContractType
          isRemote
          profileId={profileType?.id}
          nextStep={(contractType) => {
            setContractType(contractType);
            nextStep();
          }}
          mobile
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.selectContract.header"),
      component: (
        <SelectContract
          nextStep={(contract: TContractDetails) => {
            setSelectedContract(contract);
            nextStep();
          }}
          profileType={profileType}
          contractType={contractType}
          isRemote
          mobile
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.selectLocation.header"),
      component: (
        <SelectGreenListLocation
          nextStep={(loc) => {
            console.log({ loc });
            setLoadingLocation(loc);
            nextStep();
          }}
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.summary.header"),
      component: (
        <OrderChargeSummary
          contract={selectedContract}
          nextStep={(changedContract) => {
            setSelectedContract(changedContract);
            nextStep();
          }}
          mobile
          isRemote
        />
      ),
    },
    {
      header: t("pages.payment.header"),
      subHeader: t("pages.payment.subHeader", {
        contractName: selectedContract?.ETTStr,
      }),
      component:
        cardNumber && selectedContract && profileType ? (
          <PaymentCard
            priceInAgorot={selectedContract.PriceInAgorot}
            mobile
            purchaseDetails={{
              contract: selectedContract,
              ravKavNumber: cardNumber,
            }}
            nextStep={({ paymentUid, expiration }) => {
              saveContractToGreenList({
                cardNumber,
                contract: selectedContract,
                notes: "some optional notes",
                payment: {
                  confirmation: paymentUid,
                  expiration,
                },
                profileId: +profileType?.id,
                location: loadingLocation,
              }).then((v) => dispatch(changePopup(POPUPS.paymentSuccess)));
            }}
            onError={(err) => {
              console.log({ err });
              setStep((s) => s - 1);
            }}
          />
        ) : (
          <>something is wrong</>
        ),
    },
  ];

  const handleBackButton = () => {
    if (step > 0) setStep(step - 1);
    else history.goBack();
  };

  return (
    <IonPage
      className={classNames({ "mobile-page": true, "vendor-page": isVendor })}
      dir={appDirection}
    >
      <IonContent scrollY className="mobile-page-content">
        <PaymentSuccessPopup routeOnDismiss={ROUTES.home} />
        <VendorLoginErrorPopup mobile />
        <div className="width-100 mobile-order-charge-page">
          <StepsCard
            step={step}
            pages={pages}
            mobile
            onBack={handleBackButton}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PurchaseContractRemoteMobile;
