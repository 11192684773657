import React, { useState } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonMenuToggle,
  IonText,
  IonLabel,
} from "@ionic/react";
import closeIcon from "../../../../assets/icons-hamburger-x.svg";
import hebLangIcon from "../../../../assets/icons-language-heb-active.svg";
import rusLangIcon from "../../../../assets/icons-language-rus-active.svg";
import engLangIcon from "../../../../assets/icons-language-eng-active.svg";
import arbLangIcon from "../../../../assets/icons-language-arb-active.svg";
import hebLangIconInactive from "../../../../assets/icons-language-heb-inactive.svg";
import engLangIconInactive from "../../../../assets/icons-language-eng-inactive.svg";
import arbLangIconInactive from "../../../../assets/icons-language-arb-inactive.svg";
import rusLangIconInactive from "../../../../assets/icons-language-rus-inactive.svg";
import personalAreaIcon from "../../../../assets/icons-actions-personal-area-contrast.svg";
import pangoClose from "../../../../assets/pango_close.svg";
import moovitClose from "../../../../assets/moovit-close.svg";
import { DIRECTIONS, ROUTES, VERSION } from "../../../../store/Globals";
import "./SideMenuMobile.scss";
import { SideMenuMobileItem } from "./SideMenuMobileItem";
import { useHistory } from "react-router";
import { SideMenuMobileSubMenuToggle } from "./SideMenuMobileSubMenuToggle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import { changeAppDirection } from "../../../../store/action-creators/App";
import classNames from "classnames";
import { BackIcon } from "../../../BackIcon";

const langConfigPath = "topMenu.mobile.sideMenu";

type TSideMenuMobile = {
  menuId: string;
  contentId: string;
};

const languages = [
  {
    id: "he",
    activeIcon: hebLangIcon,
    inactiveIcon: hebLangIconInactive,

    dir: DIRECTIONS.rtl,
  },
  {
    id: "en",
    activeIcon: engLangIconInactive,
    inactiveIcon: engLangIconInactive,
    dir: DIRECTIONS.ltr,
  },
  {
    id: "ar",
    activeIcon: arbLangIcon,
    inactiveIcon: arbLangIconInactive,
    dir: DIRECTIONS.rtl,
  },
  {
    id: "ru",
    dir: DIRECTIONS.ltr,
    activeIcon: rusLangIcon,
    inactiveIcon: rusLangIconInactive,
  },
];

export const SideMenuMobile: React.FC<TSideMenuMobile> = ({
  menuId,
  contentId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("", { keyPrefix: langConfigPath });
  const isVendor = useSelector((state: RootState) => state.app.isVendor);
  const [supportSubMenuOpen, setSupportSubMenuOpen] = useState(false);
  const [ravKavChargingMenuOpen, setRavKavChargingMenuOpen] = useState(false);
  const [ravKavCardActionsMenuOpen, setRavKavCardActionsMenuOpen] =
    useState(false);
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const loggedUserName = useSelector((state: RootState) => state.app.userName);
  const labels = {
    moreActions: t("moreActions"),
    personalArea: {
      label: t("personalArea.label"),
      connectBtn: t("personalArea.connectButton"),
      loggedLabel: (firstName: string, lastName: string) =>
        `${firstName} ${lastName}`,
    },
    ravkavCardActions: t("ravKavCardActions.label"),
    contractsAndLastActions: t(
      "ravKavCardActions.dropDown.contractsAndLastActions.label"
    ),
    ravKavCharging: t("ravKavCharging.label"),
    cardCharging: t("ravKavCharging.dropDown.cardCharging.label"),
    orderRemoteCharging: t("ravKavCharging.dropDown.orderRemoteCharging.label"),
    discountProfiles: t("ravKavCardActions.dropDown.discountProfiles.label"),
    ravKavCardOrdering: t("ravKavCardOrdering.label"),
    generalInfo: t("generalInfo.label"),
    extraServices: t("extraServices.label"),
    feedback: t("feedback.label"),
    support: t("support.label"),
    qAndA: t("support.dropDown.q&a.label"),
    about: t("support.dropDown.about.label"),
    contactUs: t("support.dropDown.contact.label"),
    version: (VERSION: string | undefined) =>
      t("support.dropDown.version.label", { VERSION }),
  };

  const vendor = useSelector((state: RootState) => state.app.vendor);

  const backicon = vendor === "pango" ? pangoClose : moovitClose;

  return (
    <IonMenu
      className={classNames({
        "side-menu-mobile": true,
        "vendor-menu": isVendor,
      })}
      side={appDirection === DIRECTIONS.rtl ? "end" : "start"}
      menuId={menuId}
      contentId={contentId}
      dir={appDirection}
      swipeGesture={false}
    >
      <div>
        <div className="side-menu-mobile-toolbar flex ion-align-items-center">
          <IonGrid>
            {isVendor ? (
              <IonRow className="vendor-menu-header">
                <IonLabel className="font-size-20  vendor-header-text">
                  {labels.moreActions}
                </IonLabel>

                <IonMenuToggle>
                  <BackIcon
                    className="close-icon clickable"
                    onClick={() => {}}
                    closeIcon={backicon}
                  />
                </IonMenuToggle>
              </IonRow>
            ) : (
              <IonRow>
                <IonCol>
                  <IonMenuToggle>
                    <IonIcon className="close-icon clickable" src={closeIcon} />
                  </IonMenuToggle>
                </IonCol>
                <IonCol className="language-icons-container flex">
                  {languages.map((lang) => {
                    return (
                      <IonMenuToggle autoHide={false}>
                        <IonIcon
                          className="language-icon clickable"
                          src={
                            i18n.language === lang.id
                              ? lang.activeIcon
                              : lang.inactiveIcon
                          }
                          onClick={() => {
                            i18n.changeLanguage(lang.id);
                            dispatch(changeAppDirection(lang.dir));
                            window.location.reload();
                          }}
                        />
                      </IonMenuToggle>
                    );
                  })}
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </div>
      </div>
      <IonContent>
        <IonList className="side-menu-content">
          {!isVendor && (
            <div
              className="personal-area-item"
              onClick={() => {
                if (loggedUserName) history.push(ROUTES.mobileEditUserInfo);
              }}
            >
              <IonIcon className="icon" src={personalAreaIcon} />
              <IonText className="personal-area-item-text text">
                {labels.personalArea.label}
              </IonText>

              {loggedUserName === null ? (
                <IonMenuToggle autoHide={false}>
                  <IonText
                    className="connect-button text clickable"
                    onClick={() => {
                      if (loggedUserName === null) {
                        history.push(ROUTES.mobileLogIn);
                      }
                    }}
                  >
                    {labels.personalArea.connectBtn}
                  </IonText>
                </IonMenuToggle>
              ) : (
                <IonText className="connect-button text">
                  {labels.personalArea.loggedLabel(
                    loggedUserName.firstName,
                    loggedUserName.lastName
                  )}
                </IonText>
              )}
            </div>
          )}

          <SideMenuMobileSubMenuToggle
            onClick={() => {
              setRavKavChargingMenuOpen(!ravKavChargingMenuOpen);
            }}
            open={ravKavChargingMenuOpen}
            // icon={chargeIcon}
            label={labels.ravKavCharging}
          />

          <IonMenuToggle autoHide={false} hidden={!ravKavChargingMenuOpen}>
            <IonList
              className={`${ravKavChargingMenuOpen ? "sub-menu-open" : ""}`}
            >
              <SideMenuMobileItem
                subItem
                onClick={() => {
                  history.push(ROUTES.mobilePurchaseContract);
                }}
                label={labels.cardCharging}
              />
              <SideMenuMobileItem
                subItem
                onClick={() => {
                  history.push(ROUTES.mobileRemotePurchaseContract);
                }}
                label={labels.orderRemoteCharging}
              />
            </IonList>
          </IonMenuToggle>

          <SideMenuMobileSubMenuToggle
            onClick={() => {
              setRavKavCardActionsMenuOpen(!ravKavCardActionsMenuOpen);
            }}
            open={ravKavCardActionsMenuOpen}
            // icon={actionsIcon}
            label={labels.ravkavCardActions}
          />

          <IonMenuToggle autoHide={false} hidden={!ravKavCardActionsMenuOpen}>
            <IonList
              className={`${ravKavCardActionsMenuOpen ? "sub-menu-open" : ""}`}
            >
              <SideMenuMobileItem
                subItem
                onClick={() => history.push(ROUTES.mobileNfcRead)}
                label={labels.contractsAndLastActions}
              />
              <SideMenuMobileItem
                subItem
                onClick={() => history.push(ROUTES.mobileProfiles)}
                label={labels.discountProfiles}
              />
            </IonList>
          </IonMenuToggle>
          <IonMenuToggle>
            <SideMenuMobileItem
              onClick={() => {
                history.push({
                  pathname: ROUTES.mobileOrderCard,
                  search: "step=0",
                });
              }}
              // icon={cardIcon}
              label={labels.ravKavCardOrdering}
            />
            <SideMenuMobileItem
              onClick={() => {
                history.push("#");
              }}
              // icon={infoIcon}
              label={labels.generalInfo}
            />
            <SideMenuMobileItem
              onClick={() => {
                history.push("#");
              }}
              // icon={moreServiceIcon}
              label={labels.extraServices}
            />
            <SideMenuMobileItem
              onClick={() => {
                history.push("#");
              }}
              // icon={feedbackIcon}
              label={labels.feedback}
            />
          </IonMenuToggle>

          <SideMenuMobileSubMenuToggle
            onClick={() => {
              setSupportSubMenuOpen(!supportSubMenuOpen);
            }}
            // icon={supportIcon}
            label={labels.support}
            open={supportSubMenuOpen}
          />

          <IonMenuToggle autoHide={false}>
            <IonList
              className={`${supportSubMenuOpen ? "sub-menu-open" : ""}`}
              hidden={!supportSubMenuOpen}
            >
              <SideMenuMobileItem
                onClick={() => {
                  // history.push(ROUTES.mobileFAQ);
                }}
                subItem
                label={labels.contactUs}
              />
              <SideMenuMobileItem
                onClick={() => {
                  history.push(ROUTES.mobileFAQ);
                }}
                subItem
                label={labels.qAndA}
              />
              <SideMenuMobileItem
                onClick={() => {
                  history.push(ROUTES.mobileSupport);
                }}
                subItem
                label={labels.about}
              />
              {!isVendor && (
                <SideMenuMobileItem
                  style={{
                    "--background": "#161871",
                    color: "#fff",
                    paddingInline: 0,
                    "--padding-start": "2.5rem",
                  }}
                  subItem
                  label={labels.version(VERSION)}
                />
              )}
            </IonList>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default SideMenuMobile;
