import { IonCard, IonCardContent, IonText } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";

import "./editOrderChargeSelectedProfileCard.scss";
import { useTranslation } from "react-i18next";

const langConfigPath = "stepsSections.orderChargeSummary";

type Props = {
  mobile?: boolean;
  profile: string;
};

const EditOrderChargeSelectedProfileCard: React.FC<Props> = ({
  mobile,
  profile,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const labels = { profile: t("profile") };
  return (
    <IonCard
      dir={appDirection}
      className="edit-order-charge-selected-profile-card card"
      data-is-mobile={mobile}
    >
      <IonCardContent className="flex ion-align-items-center justify-content-space-between">
        <div className="flex">
          <IonText className="text font-size-18 weight-500">
            {labels.profile}
          </IonText>
          <IonText>&nbsp;</IonText>
          <IonText className="text font-size-18">{profile}</IonText>
        </div>
        {/* <IonIcon className="edit-icon" src={editIcon} /> */}
      </IonCardContent>
    </IonCard>
  );
};

export default EditOrderChargeSelectedProfileCard;
