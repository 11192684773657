import { IonIcon, IonText } from "@ionic/react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import calenderIcon from "../../../assets/icons-actions-calendar.svg";
import useDate from "../../../hooks/useDate";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS } from "../../../store/Globals";
import Popup from "../Popup";
import DateCard from "./DateCard";
import "./selectStartDateForDailyContractPopup.scss";

type TSelectStartDateForDailyContractPopup = {
  mobile?: boolean;
  changeStartDate: (date: Date) => void;
  contractName: string;
  futureDaysAllowed?: number;
};
const langConfigPath = "popUps.selectStartDateForDailyContract";

const SelectStartDateForDailyContractPopup: React.FC<
  TSelectStartDateForDailyContractPopup
> = ({ mobile = false, changeStartDate, contractName, futureDaysAllowed }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getDateFormatted, weekDays } = useDate();
  const datesArray = useMemo(
    () =>
      Array.from({ length: futureDaysAllowed ?? 0 })
        .fill(0)
        .map((_, i) => getDateFormatted(i)),
    [weekDays, futureDaysAllowed]
  );

  const clearPopup = useCallback(
    () => dispatch(changePopup(POPUPS.clear)),
    [dispatch]
  );

  return (
    <Popup popUpId={POPUPS.selectStartDateForDailyContract} mobile={mobile}>
      <div
        className="pop-up select-start-date-for-daily-contract-pop-up flex ion-align-items-center"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={calenderIcon} />
        <div className="text-container flex">
          <IonText className="pop-up-header text">
            {t(`${langConfigPath}.header`, { contractName })}
          </IonText>
        </div>
        <div className="date-cards-container grid">
          {datesArray.map((dateObj) => {
            return (
              <DateCard
                key={dateObj.date}
                label={dateObj.day}
                date={dateObj.date}
                mobile={mobile}
                onClick={() => {
                  changeStartDate(dateObj.dateObj);
                  clearPopup();
                }}
              />
            );
          })}
        </div>
      </div>
    </Popup>
  );
};

export default SelectStartDateForDailyContractPopup;
