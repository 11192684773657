import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useDate = () => {
  const { t } = useTranslation();

  const { nextThreeDays, weekDays } = useMemo(() => {
    const weekDays = t("dates.weekDays").split(",");
    const nextThreeDays = t("dates.nextDays").split(",");
    return { weekDays, nextThreeDays };
  }, [t]);

  /**
   * @param daysSinceToday - number of days ahead of today
   * @param lang - language for the day name
   * @returns date in format DD.MM.YYYY and the name of the day and instance of Date for later use
   */
  const getDateFormatted = (
    daysSinceToday: number = 0,
    getAllWeekDays = false
  ) => {
    const date = new Date();
    date.setDate(date.getDate() + daysSinceToday);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const dateString = `${formatDate(day)}.${formatDate(
      month + 1
    )}.${formatDate(year)}`;
    return {
      day: getAllWeekDays
        ? weekDays[date.getDay()]
        : getDayName(date.getDay(), daysSinceToday),
      date: dateString,
      dateObj: new Date(year, month, day, 3),
    };
  };

  const getDayName = (dayNumber: number, daysSinceToday: number) => {
    if (daysSinceToday === 0 || daysSinceToday === 1 || daysSinceToday === 2) {
      return nextThreeDays[daysSinceToday];
    }
    return weekDays[dayNumber];
  };

  const formatDate = (date: number) => {
    if (date < 10) {
      return `0${date}`;
    }
    return date.toString();
  };

  return { getDateFormatted, weekDays, nextThreeDays };
};

export default useDate;
