import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "../../..";
import StepsCard from "../../../components/StepsCard";
import "./orderChargePage.scss";
import SelectContract from "../../../components/StepsCardSections/SelectContract";
import OrderChargeSummary from "../../../components/StepsCardSections/OrderChargeSummary";
import { nfcActions, TContractDetails } from "../../../hooks/types";
import { TContractType } from "../../../hooks/contracts/useContractsForLoading";
import {
  clearGlobalContractForRenew,
  saveNfcAction,
} from "../../../store/action-creators/Nfc";
import { POPUPS, ROUTES } from "../../../store/Globals";
import SelectContractType from "../../../components/StepsCardSections/SelectContractType";
import { useVendorUser } from "../../../hooks/useVendorUser";
import PaymentCard from "../../../components/PaymentCard";
import PaymentSuccessPopup from "../../../components/pop-ups/PaymentSuccessPopup";
import { changePopup } from "../../../store/action-creators/App";
import VendorLoginErrorPopup from "../../../components/pop-ups/VendorLoginErrorPopup";
import classNames from "classnames";

const langConfigPath = "pages.mobile.orderCharge";

const PurchaseContractMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const isVendor = useSelector((state: RootState) => state.app.isVendor);

  const cardData = useSelector((state: RootState) => state.nfc.card.data);
  const cardNumber = useSelector(
    (state: RootState) => state.nfc.card.cardNumber
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    userDetails,
    isLoading: isVendorUserLoading,
    isWaitingForSdk,
  } = useVendorUser({
    isCardRequired: true,
    enabled: !!cardData,
  });

  useEffect(() => {
    if (!cardData && userDetails) return;
    console.log("cardData", cardData);
    console.log("userDetails", userDetails);

    // go to scan if no card is saved
    // dispatch(
    //   saveNfcAction({
    //     action: nfcActions.readCard,
    //     route: history.location.pathname,
    //   })
    // );
    // history.replace(ROUTES.mobileNfcRead);
  }, [cardData, dispatch, history, userDetails]);
  const [contractType, setContractType] = useState<TContractType>();

  const [selectedContract, setSelectedContract] = useState<TContractDetails>();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const [step, setStep] = useState(0);

  const contractForRenew = useSelector(
    (state: RootState) => state.nfc.renewContract
  );

  useEffect(() => {
    if (contractForRenew) {
      setSelectedContract(contractForRenew);
      dispatch(clearGlobalContractForRenew());
      setStep(2);
    }
  }, [contractForRenew]);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   searchParams.set("step", step.toString());
  //   if (step === 0) {
  //     setContractType(undefined);
  //     setSelectedContract(undefined);
  //     searchParams.delete("contractType");
  //   } else if (step === 1 && contractType) {
  //     searchParams.set("contractType", contractType);
  //   }
  //   history.replace({ search: searchParams.toString() });
  // }, [step]);
  // useEffect(() => {
  //   // if loading page with search params -> interpolate state
  //   const urlParams = new URLSearchParams(location.search);
  //   const step = urlParams.get("step");
  //   if (step !== null) setStep(0);
  // }, []);

  const pages = [
    {
      header: t("header"),
      subHeader: t("pages.selectContract.header"),
      component: (
        <SelectContractType
          nextStep={(contractType) => {
            setContractType(contractType);
            setStep((prev) => prev + 1);
          }}
          mobile
        />
      ),
    },
    {
      header: t("header"),
      subHeader:
        t("pages.selectContract.header") +
        ` (${t(`contractTypes.${contractType}`)})`,
      component: (
        <SelectContract
          nextStep={(contract) => {
            setSelectedContract(contract);
            setStep((prev) => prev + 1);
          }}
          contractType={contractType}
          mobile
        />
      ),
    },
    {
      header: t("header"),
      subHeader: t("pages.summary.header"),
      component: selectedContract ? (
        <OrderChargeSummary
          mobile
          contract={selectedContract}
          nextStep={(changedContract) => {
            setSelectedContract(changedContract);
            setStep((prev) => prev + 1);
          }}
        />
      ) : null,
    },
    {
      header: t("pages.payment.header"),
      subHeader: t("pages.payment.subHeader", {
        contractName: selectedContract?.ETTStr,
      }),
      component: (
        <PaymentCard
          mobile
          priceInAgorot={selectedContract?.PriceInAgorot ?? 0}
          purchaseDetails={{
            contract: selectedContract,
            ravKavNumber: cardNumber,
          }}
          nextStep={(payParams) => {
            if (!selectedContract) return;
            dispatch(
              saveNfcAction({
                action: nfcActions.loadContract,
                data: { contract: selectedContract, ...payParams },
              })
            );
            dispatch(changePopup(POPUPS.paymentSuccess));
          }}
          onError={(err) => {
            console.log({ err });
            setStep((s) => s - 1);
          }}
        />
      ),
    },
  ];

  const handleBackButton = () => {
    if (selectedContract && !contractType && step === 2) {
      history.goBack();
      return;
    }
    if (step > 0) setStep(step - 1);
    else history.goBack();
  };

  console.log({ isVendorUserLoading, isWaitingForSdk });

  // if (isVendorUserLoading || isWaitingForSdk)
  //   return (
  //     <div className="flex justify-content-center align-center height-100">
  //       <IonSpinner />
  //     </div>
  //   );

  return (
    <IonPage
      className={classNames({ "mobile-page": true, "vendor-page": isVendor })}
      dir={appDirection}
    >
      <PaymentSuccessPopup routeOnDismiss={ROUTES.mobileNfcRead} />
      <VendorLoginErrorPopup mobile />
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-order-charge-page width-100">
          <StepsCard
            step={step}
            pages={pages}
            mobile
            onBack={handleBackButton}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PurchaseContractMobile;
