import {
  IonButton,
  IonCol,
  IonIcon,
  IonImg,
  IonRow,
  IonText,
} from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import pangoLogo from "../../../assets/icons-logo-pango@3x.png";
import moovitLogo from "../../../assets/icons-logo-moovit@3x.png";
import "./MobileWhiteLabelRedirectPopup.scss";

type TNfcCardErrorPopup = {
  mobile?: boolean;
};
const langConfigPath = "popUps.whiteLabelRedirect";

const MobileWhiteLabelRedirectPopup: React.FC<TNfcCardErrorPopup> = ({
  mobile = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  const onOpenApp = (appUrl: string) => {
    window.open(appUrl, "_blank");
    dispatch(changePopup(POPUPS.clear));
  };
  const appUrls = useMemo(() => {
    return { pango: "", moovit: "" };
  }, []);
  const labels = useMemo(() => {
    return {
      header: t("header"),
      pango: t("pango"),
      moovit: t("moovit"),
    };
  }, [t]);

  return (
    <Popup
      popUpId={POPUPS.whiteLabelRedirect}
      mobile={mobile}
      routeOnDismiss={mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcOrder}
    >
      <div
        className="pop-up pango-white-label-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonText className="pop-up-header text">{labels.header}</IonText>

        <IonRow className="btns-row flex gap20">
          <div
            className="flex  flex-direction-column white-label-btn"
            onClick={() => onOpenApp(appUrls.pango)}
          >
            <IonImg className="vendor-icon" src={pangoLogo} />
            <IonText className="pop-up-half-button text">
              {labels.pango}
            </IonText>
          </div>
          <div
            className="flex flex-direction-column white-label-btn"
            onClick={() => onOpenApp(appUrls.moovit)}
          >
            <IonImg className="vendor-icon" src={moovitLogo} />
            <IonText className="pop-up-half-button text">
              {labels.moovit}
            </IonText>
          </div>
        </IonRow>
      </div>
    </Popup>
  );
};

export default MobileWhiteLabelRedirectPopup;
