import { AnyAction } from 'redux'
import { 
    CreateOrUpdateProfileType,
    CreateProfileResponse,
    GetProfilesResponse,
    GetSingleProfileResponse,
    Profile 
} from '../../services/models/profile'
import {
    CHANGE_SELECTED_PROFILE,
    START_CREATE_PROFILE,
    CREATE_PROFILE_SUCCESS,
    CREATE_PROFILE_ERROR,
    START_UPLOAD_OR_UPDATE_FILES,
    UPLOAD_OR_UPDATE_FILES_SUCCESS,
    UPLOAD_OR_UPDATE_FILES_ERROR,
    START_GET_USER_PROFILES,
    GET_USER_PROFILES_SUCCESS,
    GET_USER_PROFILES_ERROR,
    START_GET_SINGLE_PROFILE,
    GET_SINGLE_PROFILE_SUCCESS,
    GET_SINGLE_PROFILE_ERROR,
    START_GET_ALL_PROFILES_LIST,
    GET_ALL_PROFILES_LIST_SUCCESS,
    GET_ALL_PROFILES_LIST_ERROR,
    CHANGE_CREATE_PROFILE_REQ_TYPE
}
    from '../actions'

type initialValues = {
    selectedProfile: Profile | null,
    createProfileReqType: CreateOrUpdateProfileType | null,
    allprofilesList: {
        loading: boolean,
        data: Profile[] | null,
        error: string | null
    },
    createProfile: {
        loading: boolean,
        data: CreateProfileResponse | null,
        error: string | null
    },
    uploadOrUpdateFiles: {
        loading: boolean,
        data: any | null,
        error: string | null
    },
    getUserProfiles: {
        loading: boolean,
        data: GetProfilesResponse[] | null,
        error: string | null
    },
    getSingleProfile: {
        loading: boolean,
        data: GetProfilesResponse | null,
        error: string | null
    }

}

const initialValues: initialValues = {
    selectedProfile: null,
    createProfileReqType: null,
    allprofilesList: {
        loading: false,
        data: null,
        error: null
    },
    createProfile: {
        loading: false,
        data: null,
        error: null
    },
    uploadOrUpdateFiles: {
        loading: false,
        data: null,
        error: null
    },
    getUserProfiles: {
        loading: false,
        data: null,
        error: null
    },
    getSingleProfile: {
        loading: false,
        data: null,
        error: null
    }
}


const profileReducer = (state = initialValues, { type, payload }: AnyAction) => {
    switch (type) {
        case CHANGE_SELECTED_PROFILE:
            return { ...state, selectedProfile: payload }
        case CHANGE_CREATE_PROFILE_REQ_TYPE:
            return { ...state, createProfileReqType: payload }

        // create profile
        case START_CREATE_PROFILE:
            return { ...state, createProfile: { ...state.createProfile, loading: true } }
        case CREATE_PROFILE_SUCCESS:
            return {
                ...state, createProfile: {
                    ...state.createProfile, loading: false, data: payload, error: null
                }
            }
        case CREATE_PROFILE_ERROR:
            return {
                ...state, createProfile: {
                    ...state.createProfile, loading: false, error: payload
                }
            }

        // uplaod or update files
        case START_UPLOAD_OR_UPDATE_FILES:
            return { ...state, uploadOrUpdateFiles: { ...state.uploadOrUpdateFiles, loading: true } }
        case UPLOAD_OR_UPDATE_FILES_SUCCESS:
            return {
                ...state, uploadOrUpdateFiles: {
                    ...state.uploadOrUpdateFiles, loading: false, data: payload, error: null
                }
            }
        case UPLOAD_OR_UPDATE_FILES_ERROR:
            return {
                ...state, uploadOrUpdateFiles: {
                    ...state.uploadOrUpdateFiles, loading: false, error: payload
                }
            }

        // get user profiles
        case START_GET_USER_PROFILES:
            return { ...state, getUserProfiles: { ...state.getUserProfiles, loading: true } }
        case GET_USER_PROFILES_SUCCESS:
            return {
                ...state, getUserProfiles: {
                    ...state.getUserProfiles, loading: false, data: payload, error: null
                }
            }
        case GET_USER_PROFILES_ERROR:
            return {
                ...state, getUserProfiles: {
                    ...state.getUserProfiles, loading: false, error: payload
                }
            }

        // get single profile
        case START_GET_SINGLE_PROFILE:
            return { ...state, getSingleProfile: { ...state.getSingleProfile, loading: true } }
        case GET_SINGLE_PROFILE_SUCCESS:
            return {
                ...state, getSingleProfile: {
                    ...state.getSingleProfile, loading: false, data: payload, error: null
                }
            }
        case GET_SINGLE_PROFILE_ERROR:
            return {
                ...state, getSingleProfile: {
                    ...state.getSingleProfile, loading: false, error: payload
                }
            }
        //get all profiles list
        case START_GET_ALL_PROFILES_LIST:
            return { ...state, allprofilesList: { ...state.allprofilesList, loading: true } }
        case GET_ALL_PROFILES_LIST_SUCCESS:
            return {
                ...state, allprofilesList: {
                    ...state.allprofilesList, loading: false, data: payload, error: null
                }
            }
        case GET_ALL_PROFILES_LIST_ERROR:
            return {
                ...state, allprofilesList: {
                    ...state.allprofilesList, loading: false, error: payload
                }
            }

        default:
            return state
    }

}

export default profileReducer