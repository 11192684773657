import { IonButton, IonIcon, IonInput, IonItem, IonText } from "@ionic/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import arrowIcon from "../../../../assets/icons-small-arrow-left.svg";

import "./moveToPaymentCard.scss";
import { TContractDetails } from "../../../../hooks/types";
import { useConvertedSum } from "../../../../hooks/useConvertedSum";
import { useTranslation } from "react-i18next";

const langConfigPath = "stepsSections.orderChargeSummary.flexible";

type MoveToPaymentCard = {
  contract: TContractDetails;
  mobile?: boolean;
  onFinish: ({ pay, value }: { pay: number; value: number }) => void;
};

const isNumeric = (n: string) => !isNaN(Number(n));
const payMinAgorot = 3000;
const payMaxAgorot = 50000;
const MoveToFlexiblePaymentCard: React.FC<MoveToPaymentCard> = ({
  contract,
  mobile,
  onFinish,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const [payInAgorot, setPayInAgorot] = useState(payMinAgorot);
  const { convertedSumInAgorot, isConverting } = useConvertedSum({
    PriceInAgorot: payInAgorot,
    Profile: contract.Profile,
    debounceInMs: 200,
  });
  const updatePay = (p: string | number) => {
    setPayInAgorot(() => {
      if (typeof p === "number") return p * 100;
      if (!isNumeric(p)) return payMinAgorot;
      const pay = +p * 100;
      if (pay < payMinAgorot) return payMinAgorot;
      if (pay > payMaxAgorot) return payMaxAgorot;
      return pay;
    });
  };

  const labels = {
    pay: t("pay"),
    receive: t("receive"),
    converted:
      !isConverting && convertedSumInAgorot ? convertedSumInAgorot / 100 : "",
  };
  console.log("payInAgorot: ", payInAgorot);

  return (
    <div
      dir={appDirection}
      className="flex flex-direction-column justify-content-center ion-align-items-center move-to-payment-card "
      data-is-mobile={mobile}
    >
      <div className="width-100">
        <div className="flex ion-align-items-center justify-content-center">
          <div className="flex ion-align-items-center justify-content-center">
            <IonText className="text font-size-18">{labels.pay}</IonText>
            <IonItem fill="outline" className="pay-input" color="danger">
              <IonInput
                className="text font-size-24 weight-900"
                type="number"
                min={(payMinAgorot / 100).toString()}
                max={(payMaxAgorot / 100).toString()}
                value={payInAgorot / 100}
                onIonChange={(e) => {
                  console.log("v: ", (e as any).detail.value);
                  updatePay((e as any).detail.value);
                }}
              ></IonInput>
            </IonItem>
            <IonText className="text bold">₪</IonText>
          </div>

          <div>
            <IonIcon
              className="arrow-icon icon-pay"
              color="#ff9900;"
              src={arrowIcon}
            />
          </div>
          <div>
            <IonText className="text font-size-18">{labels.receive}</IonText>
            <IonText className="text font-size-24 weight-500">
              &nbsp;
              {labels.converted} &nbsp; ₪
            </IonText>
          </div>
        </div>
      </div>

      <IonButton
        className="button-primary"
        disabled={isConverting}
        onClick={() => {
          onFinish({ pay: payInAgorot, value: convertedSumInAgorot });
        }}
      >
        <IonText className="text pay-amount label">{labels.pay}</IonText>
        <IonText className="text pay-amount">&nbsp;{payInAgorot / 100}</IonText>
        <IonText className="text">₪</IonText>
      </IonButton>
    </div>
  );
};

export default MoveToFlexiblePaymentCard;
