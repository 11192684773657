import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonMenuToggle,
  IonLabel,
  IonNavLink,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonImg,
  IonPopover,
} from "@ionic/react";

import "./SupportDropDown.scss";
import { ITopMenuDropDown } from "..";
import SupportDropDownItem from "./SupportDropDownItem";
import { ROUTES, VERSION } from "../../../../store/Globals";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";

const langConfigPath = "topMenu.desktop.supportDropDown";

const SupportDropDown: React.FC<ITopMenuDropDown> = ({ triggerId }) => {
  const { t } = useTranslation();
  const appDirection = useSelector((state: RootState) => state.app.direction);

  return (
    <IonPopover
      dir={appDirection}
      className="support-dropdown"
      trigger={triggerId}
      // triggerAction="hover"
      dismissOnSelect={true}
      showBackdrop={false}
    >
      <IonContent>
        <IonList className="support-dropdown-list">
          <SupportDropDownItem
            href={"#"}
            text={t(`${langConfigPath}.contact.label`)}
          />
          <SupportDropDownItem
            href={ROUTES.desktopFAQ}
            text={t(`${langConfigPath}.q&a.label`)}
          />
          <SupportDropDownItem
            href={ROUTES.desktopSupport}
            text={t(`${langConfigPath}.about.label`)}
          />
          <SupportDropDownItem
            text={t(`${langConfigPath}.version.label`, { VERSION })}
          />
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default SupportDropDown;
