import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import nfcReadFailedIcon from "../../../assets/icons-nfc-read-failed.svg";
import nfcReadFailedMoovitIcon from "../../../assets/icons-nfc-read-failed-moovit.svg";
import nfcReadFailedDesktopIcon from "../../../assets/icons-nfc-read-failed-desktop.svg";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./NfcWriteFailed.scss";
import {
  clearNfcError,
  saveNfcAction,
} from "../../../store/action-creators/Nfc";
import { nfcActions } from "../../../hooks/types";
import { useMemo, useState } from "react";

type TNfcWriteFailedPopupProps = {
  mobile?: boolean;
  moovit?: boolean;
  cssClass?: string;
};
const langConfigPath = "popUps.nfcWriteFailed";

const NfcWriteFailedPopup: React.FC<TNfcWriteFailedPopupProps> = ({
  cssClass,
  mobile = false,
  moovit = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOk, setIsOk] = useState(false);

  const onOk = () => {
    setIsOk(true);
    dispatch(changePopup(POPUPS.clear));
  };

  const icomSrc = useMemo(() => {
    if (mobile) {
      if (moovit) return nfcReadFailedMoovitIcon;
      return nfcReadFailedIcon;
    }
    return nfcReadFailedDesktopIcon;
  }, [mobile, moovit]);

  return (
    <Popup
      popUpId={POPUPS.nfcWriteFailed}
      mobile={mobile}
      onDismiss={() => {
        console.log({ isOk });
        if (isOk) return;
        // dispatch(clearNfcError());
        dispatch(saveNfcAction({ action: nfcActions.readCard }));
      }}
      cssClass={cssClass}
      routeOnDismiss={mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcOrder}
    >
      <div
        className="pop-up nfc-read-failed-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={icomSrc} />

        <div className="text-container flex flex-direction-column">
          <IonText className="pop-up-header text">
            {t(`${langConfigPath}.header`)}
          </IonText>
          <IonText className="text sub-text">
            {t(`${langConfigPath}.text`)}
          </IonText>
        </div>

        <IonButton onClick={onOk} className="main-blue-buttons pop-up-botton">
          {t(`${langConfigPath}.tryAgainButton.label`)}
        </IonButton>
      </div>
    </Popup>
  );
};

export default NfcWriteFailedPopup;
