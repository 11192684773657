import {
  IonButton,
  IonCard,
  IonCheckbox,
  IonCol,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./selectProfiles.scss";
import { TProfile, useAllProfiles } from "../../../hooks/useAllProfiles";

const langConfigPath = "stepsSections.selectProfile";

type TSelectedProfiles = {
  profile1?: TProfile;
  profile2?: TProfile;
};

type TSelectProfiles = {
  nextStep: (profiles: TSelectedProfiles) => void;
  mobile?: boolean;
};

const SelectProfiles: React.FC<TSelectProfiles> = ({ mobile, nextStep }) => {
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const { isLoading, profiles, isError } = useAllProfiles();
  const [selected, setSelected] = useState<TSelectedProfiles>({});

  const selectProfile = (profile: TProfile) => {
    setSelected((prev) => {
      if (!prev.profile1) return { ...prev, profile1: profile };
      if (!prev.profile2) return { ...prev, profile2: profile };
      return { ...prev };
    });
  };
  const deselectProfile = (profile: TProfile) => {
    setSelected((prev) => {
      if (prev.profile1 && prev.profile1.id === profile.id)
        return { ...prev, profile1: undefined };
      if (prev.profile2 && prev.profile2.id === profile.id)
        return { ...prev, profile2: undefined };
      return { ...prev };
    });
  };
  const defaultProfile = profiles?.find(({ id }) => id === 1);
  return (
    <div className="select-profiles width-100">
      {defaultProfile && (
        <IonCard
          className={
            "height-100 clickable box-shadow" +
            (!selected.profile1 && !selected.profile2 ? " selected" : "")
          }
          onClick={() => {
            if (!selected.profile1 && !selected.profile2) return;
            setSelected({});
          }}
        >
          <IonRow className="padding-15">
            <IonCheckbox
              value={{}}
              checked={!selected.profile1 && !selected.profile2}
            ></IonCheckbox>
            <IonCol className="pRight15 flex flex-direction-column">
              <IonLabel className="bold font-size-18">
                {defaultProfile?.name}
              </IonLabel>
              <IonText className="font-size-14">{t("normalText")}</IonText>
            </IonCol>
          </IonRow>
        </IonCard>
      )}

      {isError ? (
        <div>error retrieving profiles</div>
      ) : (
        profiles
          ?.filter((v) => v.id !== 1)
          .map((profile) => {
            const checked =
              selected.profile1?.id === profile.id ||
              selected.profile2?.id === profile.id;
            return (
              <IonCard
                className={
                  "height-100 clickable box-shadow" +
                  (checked ? "selected" : "")
                }
                onClick={() =>
                  checked ? deselectProfile(profile) : selectProfile(profile)
                }
                key={profile.id}
              >
                <IonRow className="padding-15 flex gap10 align-center">
                  <IonCheckbox
                    key={profile.id}
                    value={profile}
                    checked={checked}
                    disabled={
                      !!(selected.profile1 && selected.profile2) && !checked
                    }
                  />
                  <IonLabel className="black-text font-size-18">
                    {profile.name}
                  </IonLabel>
                </IonRow>
              </IonCard>
            );
          })
      )}

      <div className="flex justify-content-center width-100">
        <IonButton
          className="main-blue-buttons space-down"
          onClick={() => nextStep(selected)}
        >
          {t("button.label")}
        </IonButton>
      </div>
    </div>
  );
};

export default SelectProfiles;
