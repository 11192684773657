import { IonCard, IonContent, IonPage, IonText } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import PersonalDetailsCard from "../../../components/PersonalDetailsCard";

import "./editUserInfoPage.scss";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.editUserInfo";

const EditUserInfoPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const { t } = useTranslation();
  const [editInfo, setEditInfo] = useState(false);

  const onBackButtonclick = () => {
    if (editInfo) setEditInfo(false);
    else history.goBack();
  };

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-edit-user-info-page">
          <div className="mobile-title-container">
            <MobileBackIcon onClick={onBackButtonclick} />
            <IonText className="mobile-header text">
              {t(`${langConfigPath}.header`)}
            </IonText>
          </div>

          <IonCard className="mobile-page-card">
            <PersonalDetailsCard mobile />
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditUserInfoPageMobile;
