import { useSelector } from "react-redux";
import { RootState } from "../..";
import useFetch from "../../hooks/UseFetch";
import {
  getUserDataError,
  getUserDataSuccess,
  startGetUserData,
  startUpdateUserData,
  updateUserDataError,
  updateUserDataSuccess,
} from "../../store/action-creators/User";
import { API_URL } from "../../store/Globals";
import { IUser } from "../models/users";

const useGetUserData = () => {
  const state = useSelector((state: RootState) => state.user.GetUserData);

  const fetchData = useFetch<IUser>(
    `${API_URL}/users/current_user`,
    startGetUserData,
    getUserDataSuccess,
    getUserDataError
  );

  const execute = (redirectForSucess?: string, redirectForError?: string) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        method: "GET",
      },
      redirectForSucess,
      redirectForError
    );
  };

  return {
    state: state,
    execute: execute,
  };
};

const useUpdateUserData = () => {
  const state = useSelector((state: RootState) => state.user.UpdateUserData);

  const fetchData = useFetch<IUser>(
    `${API_URL}/users`,
    startUpdateUserData,
    updateUserDataSuccess,
    updateUserDataError
  );

  const execute = (
    data: any,
    redirectForSucess?: string,
    redirectForError?: string
  ) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(data),
      },
      redirectForSucess,
      redirectForError
    );
  };

  return {
    state: state,
    execute: execute,
  };
};

const UserService = {
  useGetUserData,
  useUpdateUserData,
};

export default UserService;
