import { IonButton, IonList, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { useForm, FormProvider } from "react-hook-form";
import "./AddressForm.scss";
import FormInput from "../form-components/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REG_EXP } from "../../../store/Globals";

const langConfigPath = "forms.cardOrderAddress";

type TAddressForm = {
  mobile?: boolean;
  submit: (info: any) => void;
};

const AddressForm: React.FC<TAddressForm> = ({ mobile = false, submit }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  // const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const { t } = useTranslation();
  const errorMessages = {
    city: t(`${langConfigPath}.cityField.errorMessage`),
    street: t(`${langConfigPath}.streetField.errorMessage`),
    houseNumber: t(`${langConfigPath}.houseNumberField.errorMessage`),
    postalCode: t(`${langConfigPath}.postalCodeField.errorMessage`),
  };
  const labels = {
    city: t("cityField.placeholder"),
    street: t("streetField.placeholder"),
    houseNumber: t("houseNumberField.placeholder"),
    postalCode: t("postalCodeField.placeholder"),
    submitBtn: t("submitButton.label"),
    formHeader: t("formHeader"),
  };
  const formFields = ["city", "street", "houseNumber", "postalCode"];

  const schema = yup
    .object()
    .shape({
      city: yup
        .string()
        .required(errorMessages.city)
        .min(3, errorMessages.city)
        .max(15, errorMessages.city)
        .matches(REG_EXP.special_characters, errorMessages.city),
      street: yup
        .string()
        .required(errorMessages.street)
        .min(2, errorMessages.street)
        .max(15, errorMessages.street)
        .matches(REG_EXP.special_characters, errorMessages.street),
      houseNumber: yup
        .string()
        .required(errorMessages.houseNumber)
        .matches(REG_EXP.aprtNumber, errorMessages.houseNumber),
      postalCode: yup
        .string()
        .length(7, errorMessages.postalCode)
        .required(errorMessages.postalCode)
        .matches(REG_EXP.number, errorMessages.postalCode),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data: any) => {
    submit && submit(data);
  };

  return (
    <div className="form" data-is-mobile={mobile} dir={appDirection}>
      <IonText className="bold text">
        {t(`${langConfigPath}.formHeader`)}
      </IonText>
      <FormProvider {...methods}>
        <form
          noValidate
          // onError={yupResolver}
          className="register-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <IonList className="form-item-list">
            {formFields.map((fieldName) => (
              <FormInput
                key={fieldName}
                showRequired
                showV={true}
                name={fieldName}
                label={t(`${langConfigPath}.${fieldName}Field.placeholder`)}
              />
            ))}
          </IonList>

          <IonButton className="button-primary" type="submit">
            {t(`${langConfigPath}.submitButton.label`)}
          </IonButton>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddressForm;
