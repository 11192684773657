import { IonRadioGroup } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./selectAllProfile.scss";
import { TProfile, useAllProfiles } from "../../../hooks/useAllProfiles";
import ProfileCard from "../../ProfileCard";

// const langConfigPath = "stepsSections.selectProfile";

type TSelectAllProfile = {
  buttonLabel?: string;
  mobile?: boolean;
  nextStep: (profile: TProfile) => void;
};

const SelectAllProfile: React.FC<TSelectAllProfile> = ({
  // buttonLabel,
  mobile = false,
  nextStep,
}) => {
  const { t } = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState<TProfile>();
  const { isLoading, profiles } = useAllProfiles();

  return (
    <div
      className="select-all-profile"
      data-is-mobile={mobile}
    >
      {!isLoading &&
        profiles?.map((profile) => {
          return (
            <ProfileCard
              key={profile.id}
              label={profile.name}
              selected={profile.id === selectedProfile?.id}
              mobile={mobile}
              onClick={() => nextStep(profile)}
            />
          );
        })}
    </div>
  );
};

export default SelectAllProfile;
