import { IonButton, IonList } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../form-components/FormInput";
import { REG_EXP, ROUTES } from "../../../store/Globals";
import "./editUserInfoForm.scss";
import { IUser } from "../../../services/models/users";
import { formatDateShort } from "../../../utils/helper-functions";
import UserService from "../../../services/UserService";
import { changeUserEditModeState } from "../../../store/action-creators/User";
import { useEffect } from "react";
import { useHistory } from "react-router";

const langConfigPath = "forms.editUserInfo";

type EditUserInfoFormProps = {
  mobile?: boolean;
  user?: IUser;
};
const formFields = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "joiningDate",
];
const disabledFromFields = ["joiningDate"];
const EditUserInfoForm: React.FC<EditUserInfoFormProps> = ({
  mobile = false,
  user,
}) => {
  const dispatch = useDispatch();
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const isLoggedIn = useSelector((state: RootState) => state.user.Login);
  const history = useHistory();
  const editModeActive = useSelector(
    (state: RootState) => state.user.EditModeActive
  );
  const useUpdateUserData = UserService.useUpdateUserData();
  const updatingUser = useSelector(
    (state: RootState) => state.user.UpdateUserData
  );
  const { t } = useTranslation();

  const initialValues = {
    ...user,
    joiningDate: user?.createdAt ? formatDateShort(user?.createdAt) : "-",
  };
  useEffect(() => {
    if (!isLoggedIn)
      history.push(mobile ? ROUTES.mobileLogIn : ROUTES.desktopLogIn);
    return () => {
      dispatch(changeUserEditModeState(false));
    };
  }, []);
  const errorMessages = {
    chrecterErrorMessage: t(`${langConfigPath}.chrecterErrorMessage`),
    firstName: t(`${langConfigPath}.firstNameField.errorMessage`),
    lastName: t(`${langConfigPath}.lastNameField.errorMessage`),
    phoneNumber: t(`${langConfigPath}.phoneNumberField.errorMessage`),
    email: t(`${langConfigPath}.emailField.errorMessage`),
  };

  const schema = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .required(errorMessages.firstName)
        .min(2, errorMessages.firstName)
        .max(10, errorMessages.firstName)
        .matches(
          REG_EXP.special_characters,
          errorMessages.chrecterErrorMessage
        ),
      lastName: yup
        .string()
        .required(errorMessages.lastName)
        .min(2, errorMessages.lastName)
        .max(15, errorMessages.lastName)
        .matches(
          REG_EXP.special_characters,
          errorMessages.chrecterErrorMessage
        ),
      email: yup
        .string()
        .required(errorMessages.email)
        .max(50, errorMessages.email)
        .email(errorMessages.email),
      // .matches(REG_EXP.email, errorMessages.email),
      phoneNumber: yup
        .string()
        .required(errorMessages.phoneNumber)
        .min(10, errorMessages.phoneNumber)
        .max(10, errorMessages.phoneNumber)
        .matches(REG_EXP.phone, errorMessages.phoneNumber),
    })
    .required();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: any) => {
    if (!editModeActive) {
      dispatch(changeUserEditModeState(true));
      return;
    }
    console.log({ data });
    useUpdateUserData.execute(data);
    dispatch(changeUserEditModeState(false));
  };

  return (
    <div className="form" data-is-mobile={mobile} dir={appDirection}>
      <FormProvider {...methods}>
        <form
          noValidate
          className="edit-user-info-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <IonList className="form-item-list">
            {formFields.map((fieldName) => (
              <FormInput
                key={fieldName}
                readOnly={
                  disabledFromFields.includes(fieldName)
                    ? true
                    : !editModeActive
                }
                cssClass="user-info-form-item"
                showV={false}
                name={fieldName}
                label={t(`${langConfigPath}.${fieldName}Field.placeholder`)}
              />
            ))}
          </IonList>

          <div className="flex justify-content-center">
            <IonButton className="main-blue-buttons " type="submit">
              {editModeActive
                ? t(`${langConfigPath}.submitButton.label-edit`)
                : t(`${langConfigPath}.submitButton.label`)}
            </IonButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditUserInfoForm;
