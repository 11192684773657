import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../..";
import editIcon from "../../assets/icons-edit.svg";
import { saveCardPayloadInfo } from "../../store/action-creators/Nfc";
import { ROUTES } from "../../store/Globals";

import "./ravkavInfoCard.scss";

const langConfigPath = "ravkavInfoCard";

type TRavkavInfoCard = {
  name?: string;
  exp?: string;
  lastCharged?: string;
  mobile?: boolean;
  parent?: "load_card" | "user_card";
  cardNumberStr: string;
  onRename?: () => void;
  payload?: any;
};

const RavkavInfoCard: React.FC<TRavkavInfoCard> = ({
  name,
  exp,
  lastCharged,
  mobile,
  parent,
  onRename,
  cardNumberStr,
  payload,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const handleEditClick = () => {
    onRename?.();
  };
  const dispatch = useDispatch();
  const labels = {
    exp: cardNumberStr + " | " + t("expText", { name: name, exp: exp }),
    name,
    contractsAndActions: t("contractsAndActions"),
    chargeHistory: t("chargeHistory"),
    lastCharged: t("lastChargedText", { date: lastCharged }),
    orderCharge: t("orderChargeButton.label"),
  };
  const links = {
    contractsAndActions: mobile
      ? ROUTES.mobileScanResults
      : ROUTES.desktopScanResults,
    chargeHistory: mobile
      ? ROUTES.mobileChargeHistory
      : ROUTES.desktopChargeHistory,
    orderCharge: mobile
      ? ROUTES.mobileRemotePurchaseContract
      : ROUTES.desktopRemotePurchaseContract,
  };

  return (
    <IonCard className="ravkav-info-card card">
      <IonCardContent>
        <div className="flex ion-align-items-center">
          <IonText className="text font-size-18 weight-500">
            {labels.name}
          </IonText>
          {parent !== "load_card" && (
            <IonIcon
              className="edit-icon clickable"
              src={editIcon}
              onClick={handleEditClick}
            />
          )}
        </div>

        <div className="flex">
          <IonText className="text">{labels.exp}</IonText>
        </div>

        {parent !== "load_card" && payload && (
          <div className="flex">
            <IonRouterLink
              className="text link weight-500"
              routerLink={links.contractsAndActions}
              onClick={() => {
                dispatch(saveCardPayloadInfo(payload));
              }}
            >
              {labels.contractsAndActions}
            </IonRouterLink>
          </div>
        )}
      </IonCardContent>

      <div
        className={
          "has-bottom-border " +
          (parent !== "load_card" ? "background-color-light-contrast" : "")
        }
      >
        <IonCardContent
          className="flex justify-content-space-between 
          ion-align-items-center"
        >
          <IonText className={"text font-size-14 "}>
            <Trans>{labels.lastCharged}</Trans>
          </IonText>

          {parent !== "load_card" && (
            <IonButton
              className="button-primary"
              onClick={() => {
                dispatch(saveCardPayloadInfo(payload));
                history.push(links.orderCharge);
              }}
            >
              {labels.orderCharge}
            </IonButton>
          )}
        </IonCardContent>
      </div>
    </IonCard>
  );
};

export default RavkavInfoCard;
