import { IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import CardDetailsCard from "../../CardDetailsCard";
import AddNewCardForChargePopup from "../../pop-ups/AddNewCardForChargePopup";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS } from "../../../store/Globals";
import "./selectCard.scss";
import { useCards } from "../../../hooks/useCard";
import { formatDateShort } from "../../../utils/helper-functions";

const langConfigPath = "stepsSections.selectCard";

type SelectCardProps = {
  nextStep: (cardNumber: number) => void;
  buttonLabel?: string;
  mobile?: boolean;
};

const SelectCard: React.FC<SelectCardProps> = ({
  buttonLabel,
  nextStep,
  mobile,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cards, addCard, localCards } = useCards();

  return (
    <div
      dir={appDirection}
      className="select-card width-100 height-100 position-relative flex flex-direction-column justify-content-space-between"
      data-is-mobile={mobile}
    >
      <div className="detail-cards-container">
        {cards?.map(({ expiresAt, SCNumber, name, lastLoad }) => (
          <CardDetailsCard
            key={SCNumber}
            cardNumber={parseInt(SCNumber, 16)}
            name={name ?? parseInt(SCNumber, 16)}
            lastCharged={formatDateShort(lastLoad)}
            expirationDate={formatDateShort(expiresAt)}
            mobile
            onClick={() => nextStep(parseInt(SCNumber, 16))}
          />
        ))}
        {localCards.map(({ cardNumber }) => (
          <CardDetailsCard
            key={cardNumber}
            cardNumber={cardNumber}
            mobile
            onClick={() => nextStep(cardNumber)}
          />
        ))}
      </div>
      <IonButton
        onClick={() => {
          dispatch(changePopup(POPUPS.addNewCardForCharge));
        }}
        className="text card-button width-100"
      >
        {t(`${langConfigPath}.newCardButton.label`)}
      </IonButton>

      <AddNewCardForChargePopup
        mobile
        add={(c) => {
          addCard({ cardNumber: c.number });
          dispatch(changePopup(POPUPS.clear));
        }}
      />
    </div>
  );
};

export default SelectCard;
