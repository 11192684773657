import { API_URL } from "../../store/Globals";

const fetchWithErrors = async (
  input: RequestInfo,
  init?: RequestInit | undefined
) => {
  const rsp = await fetch(input, init);
  if (!rsp.ok) {
    try {
      console.error(await rsp.json());
    } catch {
      console.error(await rsp.text());
    }
    throw new Error("error in fetch");
  }
  const { status } = rsp;
  return { data: await rsp.json(), status };
  //   return { data: (await rsp.json()) as unknown, status };
};
export const customPOST = async <T = any>(
  input: string,
  payload: any,
  init?: Omit<RequestInit, "method" | "body"> | undefined
): Promise<{
  data: T;
  status: number;
}> => {
  const fetchOptions: RequestInit = {
    body: JSON.stringify(payload),
    method: "POST",
    credentials: "include",
    ...init,
    headers: {
      "Content-Type": "application/json",
      ...init?.headers,
    },
  };
  return await fetchWithErrors(`${API_URL}/${input}`, fetchOptions);
};
export const customGET = async (
  input: string,
  init?: Omit<RequestInit, "method" | "body"> | undefined
) => {
  const fetchOptions: RequestInit = {
    method: "GET",
    credentials: "include",
    ...init,
  };
  return await fetchWithErrors(`${API_URL}/${input}`, fetchOptions);
};

export const customDELETE = async (
  input: string,
  init?: Omit<RequestInit, "method" | "body"> | undefined
) => {
  const fetchOptions: RequestInit = {
    method: "DELETE",
    credentials: "include",
    ...init,
  };

  return await fetchWithErrors(`${API_URL}/${input}`, fetchOptions);
};

export const customPATCH = async (
  input: string,
  payload: any,
  init?: Omit<RequestInit, "method" | "body"> | undefined
) => {
  const fetchOptions: RequestInit = {
    body: JSON.stringify(payload),
    method: "PATCH",
    credentials: "include",
    ...init,
    headers: {
      "Content-Type": "application/json",
      ...init?.headers,
    },
  };
  return await fetchWithErrors(`${API_URL}/${input}`, fetchOptions);
};

export const changeLangApi = async (lang: string) => {
  return customGET(`auth/change-lang/${lang}`);
};
