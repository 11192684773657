import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./ProfileApprovedPopup.scss";
import contractIcon from "../../../assets/icons-actions-green-contract.svg";
import pango_happy from "../../../assets/pango_happy.svg";

import { GetProfilesResponse } from "../../../services/models/profile";
import { customGET } from "../../../services/customApi/fetch";
import { saveNfcAction } from "../../../store/action-creators/Nfc";
import { nfcActions } from "../../../hooks/types";

type TProfileApprovedPopup = {
  mobile?: boolean;
};

const getApprovedProfile = async () => {
  return await customGET("profiles/approvedProfiles");
};

const ProfileApprovedPopup: React.FC<TProfileApprovedPopup> = ({
  mobile = false,
}) => {
  const { t } = useTranslation("", { keyPrefix: "popUps.profileApproved" });
  const dispatch = useDispatch();
  const [route, setRoute] = useState<string>();
  const [request, setRequests] = useState<GetProfilesResponse>();
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const user = useSelector((state: RootState) => state.user.GetUserData);
  useEffect(() => {
    if (!user.data) return;
    getApprovedProfile()
      .then((v) => {
        setRequests(v.data);
        dispatch(changePopup(POPUPS.profileRequestApproved));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user]);

  const labels = {
    header: (profileName: string) => t(`header`, { profileName }),
    // text: t(`text`),
    btnText: t(`btnText`),
  };
  const onOk = () => {
    if (!request) return;
    setRoute(mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcRead);
    dispatch(
      saveNfcAction({
        action: nfcActions.loadProfile,
        data: { reqId: request._id },
      })
    );
    dispatch(changePopup(POPUPS.clear));
  };

  const icon = vendor === "pango" ? pango_happy : contractIcon;

  return (
    <Popup
      routeOnDismiss={route}
      popUpId={POPUPS.profileRequestApproved}
      mobile={mobile}
      cssClass="add-new-card-for-charge-pop-up-container"
    >
      <div className="top-contrast"></div>
      <div
        className="pop-up add-new-card-for-charge-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={contractIcon} />
        <IonText className="text weight-500 info">
          {labels.header(request?.profileName ?? "")}
        </IonText>
        {/* <IonText className="text popup-long-text">{labels.text}</IonText> */}
        <IonButton className=" main-blue-buttons pop-up-botton" onClick={onOk}>
          {labels.btnText}
        </IonButton>
      </div>
    </Popup>
  );
};

export default ProfileApprovedPopup;
