import { IonCardContent } from "@ionic/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import CardOrderPersonalInfoForm from "../../forms/CardOrderPersonalInfoForm";
import "./UserInfoForm.scss";

type TCardOrderUserInfoForm = {
  nextStep: (type: any) => void;
};

const CardOrderUserInfoForm: FC<TCardOrderUserInfoForm> = ({ nextStep }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  return (
    <div
      dir={appDirection}
      className="width-100 height-100 position-relative flex flex-direction-column"
    >
      <IonCardContent>
        <CardOrderPersonalInfoForm submit={nextStep} />
      </IonCardContent>
    </div>
  );
};

export default CardOrderUserInfoForm;
