import { API_URL } from "../../store/Globals";

export const convertSumByFrofile = async (payload: {
  PriceInAgorot: number;
  Profile: number;
}): Promise<{ Units: number }> => {
  const rsp = await fetch(`${API_URL}/card/convertSum`, {
    body: JSON.stringify(payload),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await rsp.json();
};

export const getCardInfo = async (
  cardImage: Exclude<any, undefined | null>
) => {
  const rsp = await fetch(`${API_URL}/card/getSCdata`, {
    body: JSON.stringify({ cardImage }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    credentials: "include",
  });
  return await await rsp.json();
};
export const getCardContracts = async (
  cardImage: Exclude<any, undefined | null>
) => {
  const rsp = await fetch(`${API_URL}/card/getContracts`, {
    body: JSON.stringify({ cardImage }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    credentials: "include",
  });
  return await await rsp.json();
};
export const getCardHistory = async (
  cardImage: Exclude<any, undefined | null>
) => {
  const rsp = await fetch(`${API_URL}/card/getLastActions`, {
    body: JSON.stringify({ data: cardImage }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    credentials: "include",
  });
  return await await rsp.json();
};
