import { 
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonText,
} from '@ionic/react';
import {  useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../..';
import PaymentMethodCard from '../../../components/PaymentMethodCard';
  
import './paymentMethodPage.scss';
import { MobileBackIcon } from '../../../components/BackIcon';

const langConfigPath = 'pages.mobile.paymentMethod'

const PaymentMethodPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState)=>state.app.direction)
  const {t} = useTranslation("", {keyPrefix: langConfigPath})


  return (
      <IonPage className="mobile-page" dir={appDirection}>
          <IonContent scrollY className="mobile-page-content">
              <div className="mobile-payment-method-page">
                  <div className="mobile-title-container">
                      <MobileBackIcon />
                      <IonText className="mobile-header text">{t("header")}</IonText>
                  </div>


                  <IonCard className="mobile-page-card">
                    <IonCardContent>
                      <PaymentMethodCard
                      mobile
                      lastDigits="1234"
                      lastCharged="02.01.2022"
                      />
                    </IonCardContent>

                    <div className="flex justify-content-center width-100">
                      <IonButton className="button-primary">
                        {t("changeCardButton.label")}
                      </IonButton>
                    </div>
                  </IonCard>
              </div>
          </IonContent>
      </IonPage>
  )
}

export default PaymentMethodPageMobile;
  