import {
  IonButton,
  IonCard,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./selectProfile.scss";
import { TProfile, useAllProfiles } from "../../../hooks/useAllProfiles";
import classNames from "classnames";
import { CenteredSpinner } from "../../Spinner";

const langConfigPath = "stepsSections.selectProfile";

type TSelectedProfiles = {
  profile1?: TProfile;
  profile2?: TProfile;
};

type TSelectProfile = {
  nextStep: (profiles: TSelectedProfiles) => void;
  mobile?: boolean;
};

const SelectProfile: React.FC<TSelectProfile> = ({ mobile, nextStep }) => {
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const { isLoading, profiles, isError } = useAllProfiles();
  const [selectedProfile, setSelectedProfile] = useState<TProfile>();
  if (isLoading) return <CenteredSpinner />;

  return (
    <div className="select-profile">
      <IonRadioGroup
        onIonChange={(e) => setSelectedProfile(e.detail.value)}
        value={selectedProfile}
      >
        {isError ? (
          <div>error retrieving profiles</div>
        ) : (
          profiles
            ?.filter((profile) => profile.id !== 1)
            .map((profile) => (
              <IonCard
                className={classNames({
                  "clickable profile-select-card": true,
                  selected: selectedProfile?.id === profile.id,
                })}
                onClick={() => setSelectedProfile(profile)}
                key={profile.id}
              >
                <IonRow className="padding-10 profile-list">
                  <IonRadio value={profile} className="profile-radio" />
                  <IonLabel className="pRight15 profile-name">
                    {profile.name}
                  </IonLabel>
                </IonRow>
              </IonCard>
            ))
        )}
      </IonRadioGroup>

      <div className="padding-15">
        <IonButton
          className="main-blue-buttons"
          disabled={!selectedProfile || isLoading}
          onClick={() => nextStep({ profile1: selectedProfile })}
        >
          {t("button.label")}
        </IonButton>
      </div>
    </div>
  );
};

export default SelectProfile;
