import { AnyAction, Dispatch } from 'redux'
import { CreateOrUpdateProfileType, Profile } from '../../services/models/profile'
import {
    CHANGE_SELECTED_PROFILE,
    START_CREATE_PROFILE,
    CREATE_PROFILE_SUCCESS,
    CREATE_PROFILE_ERROR,
    START_UPLOAD_OR_UPDATE_FILES,
    UPLOAD_OR_UPDATE_FILES_SUCCESS,
    UPLOAD_OR_UPDATE_FILES_ERROR,
    START_GET_USER_PROFILES,
    GET_USER_PROFILES_SUCCESS,
    GET_USER_PROFILES_ERROR,
    START_GET_SINGLE_PROFILE,
    GET_SINGLE_PROFILE_SUCCESS,
    GET_SINGLE_PROFILE_ERROR,
    START_GET_ALL_PROFILES_LIST,
    GET_ALL_PROFILES_LIST_SUCCESS,
    GET_ALL_PROFILES_LIST_ERROR,
    CHANGE_CREATE_PROFILE_REQ_TYPE
} from '../actions/index'
import { POPUPS } from '../Globals'
import { changePopup } from './App'

export const changeSelectedProfile = (profile: Profile): AnyAction => {
    return { type: CHANGE_SELECTED_PROFILE, payload: profile }
}

export const changeCreateProfileReqType = (type: CreateOrUpdateProfileType ): AnyAction => {
    return { type: CHANGE_CREATE_PROFILE_REQ_TYPE, payload: type }
}

//#region create profile
export const startCreateProfile = (): AnyAction => {
    return { type: START_CREATE_PROFILE }
}

export const createProfileSuccess = <T>(data: T) => {
    return(dispatch: Dispatch<any>)=>{
        dispatch({ type: CREATE_PROFILE_SUCCESS, payload: data })
        dispatch(changePopup(POPUPS.documentsSentSuccess))
    }
}

export const createProfileError = (error: string) => {
    return (dispatch: Dispatch<any>)=>{
        dispatch({ type: CREATE_PROFILE_ERROR, payload: error })
        dispatch(changePopup(POPUPS.documentsSentError))
    }
}
//#endregion create profile 

//#get all profiles list 
export const startGetAllProfilesList = (): AnyAction => {
    return { type: START_GET_ALL_PROFILES_LIST }
}

export const getAllProfilesListSuccess = <T>(data: T) => {
    return(dispatch: Dispatch<any>)=>{
        dispatch({ type: GET_ALL_PROFILES_LIST_SUCCESS, payload: data })
        //dispatch(changePopup(POPUPS.documentsSentSuccess))
    }
}

export const getAllProfilesListError = (error: string) => {
    return (dispatch: Dispatch<any>)=>{
        dispatch({ type: GET_ALL_PROFILES_LIST_ERROR, payload: error })
        //dispatch(changePopup(POPUPS.documentsSentError))
    }
}
//#get all profiles list

//#region upload or update files
export const startUploadOrUpdateFiles = (): AnyAction => {
    return { type: START_UPLOAD_OR_UPDATE_FILES }
}

export const uploadOrUpdateFilesSuccess = <T>(data: T) => {
    return(dispatch: Dispatch<any>)=>{
        dispatch({ type: UPLOAD_OR_UPDATE_FILES_SUCCESS, payload: data })
        dispatch(changePopup(POPUPS.documentsSentSuccess))
    }
}

export const uploadOrUpdateFilesError = (error: string) => {
    return(dispatch: Dispatch<any>)=>{
        dispatch({ type: UPLOAD_OR_UPDATE_FILES_ERROR, payload: error })
        dispatch(changePopup(POPUPS.documentsSentError))
    }
}
//#endregion upload or update files

//#region get user profiles
export const startGetUserProfiles = (): AnyAction => {
    return { type: START_GET_USER_PROFILES }
}

export const getUserProfilesSuccess = <T>(data: T) => {
    return { type: GET_USER_PROFILES_SUCCESS, payload: data }
}

export const getUserProfilesError = (error: string) => {
    return { type: GET_USER_PROFILES_ERROR, payload: error }
}
//#endregion get user profiles

//#region get single profile
export const startGetSingleProfile = (): AnyAction => {
    return { type: START_GET_SINGLE_PROFILE }
}

export const getSingleProfileSuccess = <T>(data: T) => {
    return { type: GET_SINGLE_PROFILE_SUCCESS, payload: data }
}

export const getSingleProfileError = (error: string) => {
    return { type: GET_SINGLE_PROFILE_ERROR, payload: error }
}
//#endregion get single profile