import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import RegisterForm from "../../../components/forms/RegisterForm";

import "./RegisterPage.scss";
import { ROUTES } from "../../../store/Globals";
import { useState } from "react";
import AddCreditCardForm from "../../../components/forms/AddCreditCardForm";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.register";

const RegisterPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const { t } = useTranslation();
  const [userId, setUserId] = useState();

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-register-page">
          <div className="mobile-title-container">
            <MobileBackIcon onClick={() => history.push(ROUTES.mobileLogIn)} />
            <IonText className="mobile-header text">
              {t(`${langConfigPath}.header`)}
            </IonText>
          </div>

          <IonCard className="mobile-page-card">
            <IonCardContent className="mobile-card-content flex">
              {!userId ? (
                <RegisterForm
                  mobile
                  onSuccess={(data) => {
                    setUserId(data._id);
                  }}
                />
              ) : (
                <AddCreditCardForm
                  userId={userId}
                  onFinished={() => history.push(ROUTES.mobileLogIn)}
                />
              )}
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPageMobile;
