import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonPage,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../..";
import attachCardIcon from "../../../../assets/icons-nfc-attach-card.svg";
import holdCardIcon from "../../../../assets/icons-nfc-hold-card.svg";
import nfcLinesBigIcon from "../../../../assets/icons-nfc-lines-big.svg";
import nfcLinesSmallIcon from "../../../../assets/icons-nfc-lines-small.svg";

import "./NfcReadPage.scss";
import { useEffect, useMemo, useState } from "react";
import NfcReadFailedPopup from "../../../../components/pop-ups/NfcReadFailed";
import { ROUTES } from "../../../../store/Globals";
import { useNfc } from "../../../../hooks/nfc/UseNfc";
import NfcWriteFailedPopup from "../../../../components/pop-ups/NfcWriteFailed";
import { nfcActions } from "../../../../hooks/types";

const langConfigPath = "pages.desktop.nfcRead";
const useIconChange = ({ cardStatus }: { cardStatus: boolean }) => {
  const [readingIconChange, setReadingIconChange] = useState(true);
  useEffect(() => {
    if (cardStatus) {
      const intervalId = setInterval(() => {
        setReadingIconChange((state) => !state);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [cardStatus]);
  return { readingIconChange };
};

const CardStatus = ({ status }: { status: boolean }) => {
  const { readingIconChange } = useIconChange({ cardStatus: status });
  const currentAction = useSelector((state: RootState) => state.nfc.nextAction);
  const isRead = useMemo(
    () =>
      currentAction.action === nfcActions.readCard ||
      currentAction.action === nfcActions.none,
    [currentAction]
  );
  const { t } = useTranslation();

  if (status) {
    return (
      <>
        <IonText className="text">
          {isRead
            ? t(`${langConfigPath}.inProgressMessage`)
            : t(`${langConfigPath}.writeInProgressMessage`)}
        </IonText>
        <div className="reading-card-icons-container">
          <IonIcon className="hold-card-icon" src={holdCardIcon} />
          {readingIconChange ? (
            <IonIcon className="nfc-lines-big" src={nfcLinesBigIcon} />
          ) : (
            <IonIcon className="nfc-lines-small" src={nfcLinesSmallIcon} />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <IonText className="text">{t(`${langConfigPath}.actionMessage`)}</IonText>
      <IonIcon className="attach-card-icon" src={attachCardIcon} />
    </>
  );
};

const NfcReadPageDesktop: React.FC = () => {
  const appState = useSelector((state: RootState) => state.app);
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const { t } = useTranslation();
  const { cardStatus, serviceStatus } = useNfc();

  useEffect(() => {
    if (!serviceStatus) {
      history.push(ROUTES.desktopNfcOrder);
    }
  }, [serviceStatus, history]);

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-nfc-read-page" dir={appState.direction}>
          <div className="desktop-title-container flex-direction-column">
            <IonText className="desktop-header text">
              {t(`${langConfigPath}.header`)}
            </IonText>
            <IonText className="desktop-sub-header text">
              {t(`${langConfigPath}.subHeader`)}
            </IonText>
          </div>

          <IonCard className="desktop-page-card">
            <div className="contrast"></div>
            <IonCardContent className="desktop-card-content">
              <NfcReadFailedPopup />
              <NfcWriteFailedPopup />
              <div className="main-content">
                <CardStatus status={cardStatus} />
              </div>

              <IonText className="text">
                {t(`${langConfigPath}.bottomMessage.text1`)}
              </IonText>
              <IonText className="text">
                {t(`${langConfigPath}.bottomMessage.text2`)}
              </IonText>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NfcReadPageDesktop;
