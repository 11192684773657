import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonList,
    IonItem,
    IonIcon,
    IonMenuToggle,
    IonLabel,
    IonNavLink,
    IonGrid,
    IonRow,
    IonCol,
    IonListHeader,
    IonImg,
    IonPopover,
    IonText
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ITopMenuDropDownItem } from '../..';
import { RootState } from '../../../../..';

import './SupportDropDownItem.scss'

const SupportDropDownItem: React.FC<ITopMenuDropDownItem> = ({text, href="/"}) => {
    const history = useHistory()
    const appDirection = useSelector((state: RootState)=>state.app.direction)

    return (
        <IonItem
        className="support-dropdown-item"
        lines="full"
        button={true}
        dir={appDirection}
        onClick={()=>{history.push(href)}}
        >
            <IonLabel className="item-label text">{text}</IonLabel>
        </IonItem>
    )
};

export default SupportDropDownItem
