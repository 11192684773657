import { IonButton, IonList } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../form-components/FormInput";
import "./addNewCardForChargeForm.scss";

const langConfigPath = "forms.addNewCardForCharge";

type TAddNewCardForChargeForm = {
  mobile?: boolean;
  add: (card: { number: number }) => void;
};

const AddNewCardForChargeForm: React.FC<TAddNewCardForChargeForm> = ({
  mobile = false,
  add,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();

  const errorMessages = {
    cardName: t(`${langConfigPath}.cardNameField.errorMessage`),
    short: t(`${langConfigPath}.cardNameField.tooShort`),
    long: t(`${langConfigPath}.cardNameField.tooLong`),
  };

  const schema = yup.object().shape({
    cardNumber: yup
      .number()
      .required(errorMessages.cardName)
      .min(10000000, errorMessages.short)
      .max(9999999999, errorMessages.long),
  });

  const methods = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { handleSubmit, watch } = methods;

  //@ts-ignore
  const cardNumberField = watch("cardNumber");

  return (
    <div className="form" data-is-mobile={mobile} dir={appDirection}>
      <FormProvider {...methods}>
        <form
          noValidate
          className="add-new-card-for-charge-form"
          onSubmit={handleSubmit((data) => {
            add({ number: data.cardNumber });
          })}
        >
          <IonList className="form-item-list">
            <FormInput
              showV={false}
              type="number"
              name="cardNumber"
              label={t(`${langConfigPath}.cardNameField.placeholder`)}
              showRequired
            />
            {
              //show error
              methods.formState.errors.cardNumber && (
                <div className="error-message">
                  {methods.formState.errors.cardNumber.message}
                </div>
              )
            }
          </IonList>

          <IonButton
            className="button-primary"
            type="submit"
            disabled={!cardNumberField}
          >
            {t(`${langConfigPath}.submitButton.label`)}
          </IonButton>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddNewCardForChargeForm;
