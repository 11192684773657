import { IonButton, IonList } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { useForm, FormProvider } from "react-hook-form";

import "./LoginForm.scss";
import authService from "../../../services/AuthService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../form-components/FormInput";
import { REG_EXP } from "../../../store/Globals";
import { saveLoginFormData } from "../../../store/action-creators/Auth";

const langConfigPath = "forms.login";

type LoginFormProps = {
  mobile?: boolean;
};

const LoginForm: React.FC<LoginFormProps> = ({ mobile = false }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const logIn = authService.logIn();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const errorMessages = {
    email: t(`${langConfigPath}.emailField.errorMessage`),
    phoneNumber: t(`${langConfigPath}.phoneNumberField.errorMessage`),
  };

  const schema = yup
    .object()
    .shape({
      phoneNumber: yup
        .string()
        .required(errorMessages.phoneNumber)
        .min(3, errorMessages.phoneNumber)
        .max(10, errorMessages.phoneNumber)
        .matches(REG_EXP.phone, errorMessages.phoneNumber),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: any) => {
    console.log({ data });
    logIn.execute(data);
    dispatch(saveLoginFormData(data));
  };

  return (
    <div
      className="form login-content"
      data-is-mobile={mobile}
      dir={appDirection}
    >
      <FormProvider {...methods}>
        <form
          noValidate
          className="login-form"
          onSubmit={handleSubmit(onSubmit, (err) => console.log({ err }))}
        >
          <IonList className="form-item-list">
            <FormInput
              name="phoneNumber"
              type="tel"
              label={t(`${langConfigPath}.phoneNumberField.placeholder`)}
            />
          </IonList>
          <div className="flex justify-content-center">
            <IonButton
              className="main-blue-buttons login-button maxButton"
              type="submit"
            >
              {t(`${langConfigPath}.submitButton.label`)}
            </IonButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LoginForm;
