import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonList,
    IonItem,
    IonIcon,
    IonMenuToggle,
    IonLabel,
    IonNavLink,
    IonGrid,
    IonRow,
    IonCol,
    IonListHeader,
    IonImg,
    IonPopover,
    IonText
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ITopMenuDropDownItem } from '../..';
import { RootState } from '../../../../..';
import arrowDownIcon from '../../../../../assets/icons-small-arrow-down-2.svg'

import './PersonalAreaDropDownItem.scss'

type PersonalAreaDropDownItem = {
    text: string,
    icon: string,
    onClick?: React.MouseEventHandler
}

const PersonalAreaDropDownItem: React.FC<PersonalAreaDropDownItem> = ({text, icon, onClick}) => {
    const history = useHistory()
    const appDirection = useSelector((state: RootState)=>state.app.direction)


    return (
        <IonItem
        className="personal-area-dropdown-item"
        lines="full"
        button={true}
        onClick={onClick}
        dir={appDirection}
        >
            <IonIcon className="item-icon" src={icon} />
            <IonLabel className="item-label text">{text}</IonLabel>
            {/* <IonIcon className="arrow-icon" src={arrowDownIcon} /> */}
        </IonItem>
    )
};

export default PersonalAreaDropDownItem
