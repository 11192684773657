import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonRouterLink,
  IonRow,
  IonText,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import documentsSentSuccessIcon from "../../../assets/icons-documents-sent-success.svg";
import pango_happy from "../../../assets/pango_happy.svg";

import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import { TProfile, useAllProfiles } from "../../../hooks/useAllProfiles";

import "./documentsSentSuccessPopup.scss";
import { profile } from "console";
import { type } from "os";

type DocumentsSentSuccessPopup = {
  mobile?: boolean;
  cssClass?: string;
  info?: string;
};
const langConfigPath = "popUps.documentsSentSuccess";

const DocumentsSentSuccessPopup: React.FC<DocumentsSentSuccessPopup> = ({
  info,
  cssClass,
  mobile = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [route, setRoute] = useState("");

  const onCreateNewProfile = () => {
    setRoute(mobile ? ROUTES.mobileProfiles : ROUTES.desktopProfiles);
    dispatch(changePopup(POPUPS.clear));
  };

  const onClickConfirmButton = () => {
    setRoute(mobile ? ROUTES.mobileProfiles : ROUTES.desktopProfiles);
    dispatch(changePopup(POPUPS.clear));
  };
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const icon = vendor === "pango" ? pango_happy : documentsSentSuccessIcon;

  return (
    <Popup
      routeOnDismiss={route}
      popUpId={POPUPS.documentsSentSuccess}
      mobile={mobile}
      cssClass={`documents-sent-success-pop-up-container ${cssClass}`}
    >
      <div
        className="pop-up documents-sent-success-pop-up flex ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={icon} />

        <IonText className="text weight-500 info">
          {info ? info : t(`${langConfigPath}.text`)}
        </IonText>

        <IonButton
          onClick={onClickConfirmButton}
          className="width-100 main-blue-buttons pop-up-botton"
        >
          {t(`${langConfigPath}.confirmButton.label`)}
        </IonButton>

        <IonText
          onClick={onCreateNewProfile}
          className="sub link weight-500 link-edit font-size-16"
        >
          {t(`${langConfigPath}.link.label`)}
        </IonText>
      </div>
    </Popup>
  );
};

export default DocumentsSentSuccessPopup;
