import { IonContent, IonPage, IonText } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import "./scanResultsPage.scss";
import { SCAN_CARD_RESULTS_TABS } from "../../../store/Globals";
import ScanResultsCard from "../../../components/ScanResultsCard";
import ChangeCardNamePopup from "../../../components/pop-ups/ChangeCardNamePopup";
import { useCardInfo } from "../../../hooks/useCard";
import GreenListPopup from "../../../components/pop-ups/GreenListPopup";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { formatDateShort } from "../../../utils/helper-functions";

const langConfigPath = "pages.desktop.scanResults";

const ScanResultsPageMobile: React.FC = () => {
  const isVendor = useSelector((state: RootState) => state.app.isVendor);
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();

  const {
    cardDetails,
    cardNumber,
    contracts,
    cardName,
    isLoadingContracts,
    isLoadingHistory,
    history: cardHistory,
  } = useCardInfo();

  const labels = useMemo(() => {
    let cardNameStr = cardName;
    if (!cardNameStr && cardDetails) {
      if (
        cardDetails.Environment.HolderIdNumber &&
        cardDetails.Environment.HolderIdNumber !== "0"
      )
        cardNameStr = t(`${langConfigPath}.header`, {
          cardName: cardDetails.Environment.HolderIdNumber,
        });
    }
    return {
      cardName: cardNameStr,
      cardExpires: t(`${langConfigPath}.date`, {
        expDate: cardDetails
          ? formatDateShort(cardDetails?.Environment.EnvEndDate)
          : "",
      }),
      profile1: cardDetails?.Environment.HolderProf1Str,
      profile1Date: t(`${langConfigPath}.date`, {
        expDate: cardDetails
          ? formatDateShort(cardDetails.Environment.HolderProf1Date)
          : "",
      }),
      profile2:
        cardDetails?.Environment.HolderProf2Str !==
        cardDetails?.Environment.HolderProf1Str
          ? cardDetails?.Environment.HolderProf2Str
          : "",
      profile2Date: t(`${langConfigPath}.date`, {
        expDate: cardDetails
          ? formatDateShort(cardDetails.Environment.HolderProf2Date)
          : "",
      }),
    };
  }, [cardDetails, cardName, t, langConfigPath]);
  return (
    <IonPage
      className={classNames({ "mobile-page": true, "vendor-page": isVendor })}
      dir={appDirection}
    >
      <GreenListPopup mobile />
      <IonContent className="mobile-page-content">
        <div className="mobile-scan-results-page height-100 width-100">
          <div className="mobile-title-container scan-results-title-container">
            <div className="mobile-header-container width-100 position-relative">
              {labels.cardName && (
                <>
                  <IonText className="mobile-header text card-name">
                    {labels.cardName}
                  </IonText>
                  <IonText className="mobile-sub-header text mobile-sub-header-blue-line"></IonText>
                </>
              )}
              <IonText className="mobile-sub-header text">{cardNumber}</IonText>
            </div>

            {labels.profile1 && (
              <div className="width-100 position-relative">
                <IonText className="text font-size-14 bold">
                  {labels.profile1}&nbsp;
                </IonText>
                <IonText className="text font-size-14 weight-300">
                  {labels.profile1Date}
                </IonText>
              </div>
            )}

            {labels.profile2 && (
              <div className="width-100 position-relative">
                <IonText className="text font-size-14">
                  {labels.profile2}&nbsp;
                </IonText>
                <IonText className="text font-size-14 weight-300">
                  {labels.profile2Date}
                </IonText>
              </div>
            )}
          </div>
          <ScanResultsCard
            initialTab={SCAN_CARD_RESULTS_TABS.contracts}
            mobile
            cardHistory={cardHistory}
            contracts={contracts}
            isContractsLoading={isLoadingContracts}
            isHistoryLoading={isLoadingHistory}
          />
          <ChangeCardNamePopup mobile />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ScanResultsPageMobile;
