import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonPage,
  IonText,
} from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import barcodekIcon from "../../../assets/top-card-url-qr.svg";
import { ROUTES } from "../../../store/Globals";

import "./supportPage.scss";
import { BackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.mobile.support";

const DesktopSupportPage: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  return (
    <IonPage className="desktop-page " dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-support-page">
          <div className="desktop-title-container flex-direction-row">
            <BackIcon />
            <IonText className="desktop-header text">{t("header")}</IonText>
          </div>

          <IonCard className="desktop-page-card desktop-support flex flex-direction-column justify-content-space-between">
            <IonCardContent className="flex flex-direction-column">
              <div className="flex gap20">
                <div className="flex flex-direction-column">
                  <IonText className="text color-dark-sub-text font-size-14">
                    {t("programVersion")}
                  </IonText>
                  <IonText className="text">1.23.1</IonText>
                </div>

                <div className="flex flex-direction-column">
                  <IonText className="text color-dark-sub-text font-size-14">
                    {t("appVersion")}
                  </IonText>
                  <IonText className="text">1.213.2</IonText>
                </div>
              </div>

              <div className="flex flex-direction-column">
                <IonText className="text color-dark-sub-text font-size-14">
                  {t("operator")}
                </IonText>
                <IonText className="text">
                  <Trans>{t("operatorInfo")}</Trans>
                </IonText>
              </div>

              <div className="flex">
                <IonText className="text font-size-14">
                  <Trans>{t("extraInfo")}</Trans>
                </IonText>

                <IonImg
                  className="barcode_image"
                  src={barcodekIcon}
                  alt="barcode_image"
                />
              </div>

              <IonList lines="full" className="link-list flex">
                <IonItem
                  onClick={() => {
                    history.push(ROUTES.home);
                  }}
                >
                  <IonText className="link  mTop15">
                    {t("links.website")}
                  </IonText>
                </IonItem>

                <IonItem
                  onClick={() => {
                    history.push(ROUTES.desktopFAQ);
                  }}
                >
                  <IonText className="link  mTop15">{t("links.faq")}</IonText>
                </IonItem>

                <IonItem
                  onClick={() => {
                    history.push(ROUTES.home);
                  }}
                >
                  <IonText className="link  mTop15">
                    {t("links.contact")}
                  </IonText>
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DesktopSupportPage;
