import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonList,
    IonItem,
    IonIcon,
    IonMenuToggle,
    IonLabel,
    IonNavLink,
    IonGrid,
    IonRow,
    IonCol,
    IonListHeader,
    IonImg,
    IonPopover,
    IonInput,
    IonCard,
    IonCardContent,
    IonSearchbar,
    IonModal,
    useIonViewWillLeave,
    IonFooter,
    IonButton,
    IonPage,
    useIonViewWillEnter,
    useIonViewDidEnter,
} from '@ionic/react';

import searchArrowIcon from '../../../../assets/icons-go-search-arrow.svg'
import backArrow from '../../../../assets/icons-back.svg'
import searchIcon from '../../../../assets/icons-search.svg'

import { useEffect, useState } from 'react';
import './MobileSearchBar.scss'
import { useDispatch, useSelector } from 'react-redux';
import { changeTopMenuSearchValue } from '../../../../store/action-creators/TopMenu';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../../store/Globals';
import { RootState } from '../../../..';
import { getSearchJson, SearchObject } from '../../../../utils/search-config';

const langConfigPath = 'topMenu.mobile.search'

type MobileSearchBar = {
    onClose: React.MouseEventHandler | any
    open: boolean
}

const MobileSearchBar: React.FC<MobileSearchBar> = ({onClose, open}) => {
    const dispatch = useDispatch()
    const appDirection = useSelector((state: RootState)=>state.app.direction)
    const history = useHistory()
    const {t, i18n} = useTranslation()
    const [autoCompleteList, setAutoCompleteList] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [searchJson, setSearchJson] = useState<SearchObject[]>()

    useEffect(()=>{
        setSearchJson(getSearchJson(i18n.language))
    },[i18n.language])

    const onInputChange = (inputValue: string) =>{
        if(inputValue === ''){
            setAutoCompleteList([])
            setSearchValue(inputValue)
            return
        }
        if(searchJson !== undefined){
            let results = searchJson.filter(x=>x.title.includes(inputValue))
                                    .map(x=>{return x.title})
                                    
            setAutoCompleteList(results)
        }
        setSearchValue(inputValue)
    }

    const onAutoCompleteClick = (inputValue: string) =>{
        setAutoCompleteList([])
        dispatch(changeTopMenuSearchValue(inputValue))
        if(history.location.pathname !== ROUTES.mobileSearchResults){
            history.push(ROUTES.mobileSearchResults)
        }
        setSearchValue('')
        onClose()

    }

    const highlightAutoCompleteMatch = (option: string) =>{
        const parts = option.split(searchValue)
        return(
            <span>{parts[0]}<strong>{searchValue}</strong>{parts[1]}</span>
        )
    }

    return (
        <IonModal
        dir={appDirection}
        onDidDismiss={()=>{setSearchValue('')}}
        className="mobile-search-bar"
        isOpen={open}
        >
            <IonPage>
                <IonHeader>
                    <IonToolbar className="mobile-search-toolbar flex ion-align-items-center">
                        <IonGrid>
                            <IonRow className=" ion-align-items-center">

                            <IonCol
                                size="1"
                                >
                                    <IonIcon
                                    className="back-arrow"
                                    src={backArrow}
                                    onClick={e=>{
                                        setAutoCompleteList([])
                                        onClose(e)
                                    }}
                                    />
                                </IonCol>



                                <IonCol
                                className=""
                                size="9.5"
                                >
                                    <IonInput
                                    value={searchValue}
                                    className="search-input text"
                                    onIonChange={(e)=>{
                                        if (e.detail.value === undefined) return;
                                        onInputChange(e.detail.value!)
                                    }} 
                                    />
                                </IonCol>

                                <IonCol size="1.5">
                                    <IonIcon
                                    className="search-icon"
                                    src={searchIcon}
                                    />
                                </IonCol>
                               
                            </IonRow>
                        </IonGrid>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList lines="none">
                        { autoCompleteList.map((item, index)=>{
                                return(
                                    <IonItem
                                    className="search-auto-complete-item"
                                        key={Math.random()}
                                        button={true} 
                                        onClick={()=>{onAutoCompleteClick(item)}}
                                        >
                                        <IonLabel className="item-label text">{highlightAutoCompleteMatch(item)}</IonLabel>
                                        <IonIcon className="item-icon" src={searchArrowIcon} />
                                    </IonItem>
                                )
                            })}
                    </IonList>
                </IonContent>
                <IonFooter className="search-button-container flex ion-justify-content-center">
                    <IonButton
                     className="button-primary text"
                     onClick={(e)=>
                        {
                            setAutoCompleteList([])
                            dispatch(changeTopMenuSearchValue(searchValue))
                            history.push(ROUTES.mobileSearchResults)
                            onClose(e)
                        }}
                     >
                         {t(`${langConfigPath}.searchButton.label`)}
                    </IonButton>
                </IonFooter>
            </IonPage>
        </IonModal>
    )
};

export default MobileSearchBar;
