import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  useIonViewWillLeave,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import LoginForm from "../../../components/forms/LogInForm";
import LoginErrorPopup from "../../../components/pop-ups/LoginErrorPopup";
import LoginOTPPopup from "../../../components/pop-ups/LoginOTPPopup";
import MoovitWhiteLabelPopup from "../../../components/pop-ups/MoovitWhiteLabelPopup";
import PangoWhiteLabelPopup from "../../../components/pop-ups/PangoWhiteLabelPopup";
import pangoWhiteLabelButtonIcon from "../../../assets/icons-logo-pango.webp";
import moovitWhiteLabelButtonIcon from "../../../assets/icons-logo-moovit.webp";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";

import "./LoginPage.scss";

const langConfigPath = "pages.mobile.login";

const LoginPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useIonViewWillLeave(() => {
    dispatch(changePopup(POPUPS.clear));
  });

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-login-page">
          <div className="mobile-title-container section-moovit flex">
            <IonText className="mobile-header text">
              {t(`${langConfigPath}.header1`)}
            </IonText>
            <IonText className="mobile-sub-header text">
              {t(`${langConfigPath}.header2`)}
            </IonText>
          </div>

          <IonCard className="mobile-page-card">
            <div className="bottom-contrast"></div>

            <IonCardContent className="mobile-card-content flex justify-content-center ion-align-items-center">
              <LoginForm mobile />

              <PangoWhiteLabelPopup mobile />
              <MoovitWhiteLabelPopup mobile />

              <IonButton
                onClick={() => {
                  history.push(ROUTES.mobileNfcRead);
                }}
                className="button-secondary"
              >
                {t(`${langConfigPath}.enterAsGuestButton.label`)}
              </IonButton>

              <div className="register-link-container flex">
                <IonText className="text">
                  {t(`${langConfigPath}.registerLink.text`)}
                </IonText>
                &nbsp;&nbsp;
                <IonRouterLink
                  className="link text"
                  routerLink={ROUTES.mobileRegister}
                >
                  {t(`${langConfigPath}.registerLink.link`)}
                </IonRouterLink>
              </div>

              <IonGrid class="white-label-container">
                <IonRow>
                  <IonCol className="col">
                    <IonButton
                      className="white-label-button"
                      onClick={() => {
                        dispatch(changePopup(POPUPS.pangoWhiteLabel));
                      }}
                    >
                      <div className="white-label-button-content flex flex-direction-column ion-align-items-center">
                        <IonImg
                          className="white-label-icon"
                          src={pangoWhiteLabelButtonIcon}
                          alt=""
                        />
                        <IonText className="white-label-text text">
                          {t(`${langConfigPath}.whiteLabel.pango.label`)}
                        </IonText>
                      </div>
                    </IonButton>
                  </IonCol>

                  <IonCol className="col">
                    <IonButton
                      className="white-label-button"
                      onClick={() => {
                        dispatch(changePopup(POPUPS.moovitWhiteLabel));
                      }}
                    >
                      <div className="white-label-button-content flex flex-direction-column ion-align-items-center">
                        <IonImg
                          className="white-label-icon"
                          src={moovitWhiteLabelButtonIcon}
                          alt=""
                        />
                        <IonText className="white-label-text text">
                          {t(`${langConfigPath}.whiteLabel.moovit.label`)}
                        </IonText>
                      </div>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <div className="bottom-line"></div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPageMobile;
