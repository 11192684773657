import { IonCardContent } from "@ionic/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { TOrderType } from "../../../pages/dekstop/OrderCardPage/types";
import AddressForm from "../../forms/AddressForm";
import "./UserInfoForm.scss";

type TCardOrderAddressForm = {
  nextStep: (type: TOrderType) => void;
  mobile?: boolean;
};

const CardOrderAddressForm: FC<TCardOrderAddressForm> = ({
  nextStep,
  mobile,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  return (
    <div dir={appDirection} className="width-100 height-100">
      <IonCardContent>
        <AddressForm submit={nextStep} mobile={mobile} />
      </IonCardContent>
    </div>
  );
};

export default CardOrderAddressForm;
