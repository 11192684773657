export const cardOrderTypes = {
  newCard: "new-card",
  slowRestore: "slow-restore",
  anonymous: "anonymous",
  fastRestore: "fast-restore",
} as const;
export type TOrderType = (typeof cardOrderTypes)[keyof typeof cardOrderTypes];
export type TCardType = {
  type: TOrderType;
  price: number;
};
