import { IonContent, IonList, IonPopover } from "@ionic/react";
import personalDetailsIcon from "../../../../assets/icons-actions-personal-details.svg";
import loadingHistoryIcon from "../../../../assets/icons-actions-loading-history.svg";
import ravKavCardIcon from "../../../../assets/icons-actions-card.svg";
import walletIcon from "../../../../assets/icons-actions-payment-2.svg";
import logOffIcon from "../../../../assets/icons-actions-exit.svg";
import "./PersonalAreaDropDown.scss";
import { ITopMenuDropDown } from "..";
import PersonalAreaDropDownItem from "./PersonalAreaDropDownItem";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import authService from "../../../../services/AuthService";
import { ROUTES } from "../../../../store/Globals";
import { useHistory } from "react-router";

const langConfigPath = "topMenu.desktop.personalAreaDropDown";

const PersonalAreaDropDown: React.FC<ITopMenuDropDown> = ({ triggerId }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();
  const logout = authService.logOut();
  const history = useHistory();

  return (
    <IonPopover
      dir={appDirection}
      className="personal-area-dropdown"
      trigger={triggerId}
      // triggerAction="hover"
      showBackdrop={false}
      dismissOnSelect={true}
    >
      <IonContent>
        <IonList className="personal-area-dropdown-list">
          <PersonalAreaDropDownItem
            text={t(`${langConfigPath}.personalDetails.label`)}
            icon={personalDetailsIcon}
            onClick={() => history.push("/desktop_edit_user_info")}
          />
          <PersonalAreaDropDownItem
            text={t(`${langConfigPath}.chargingHistory.label`)}
            icon={loadingHistoryIcon}
            onClick={() => history.push("/desktop_charge_history")}
          />
          <PersonalAreaDropDownItem
            text={t(`${langConfigPath}.ravKavCards.label`)}
            icon={ravKavCardIcon}
            onClick={() => history.push("/desktop_ravkav_cards")}
          />
          <PersonalAreaDropDownItem
            text={t(`${langConfigPath}.paymentMethods.label`)}
            icon={walletIcon}
            onClick={() => history.push(ROUTES.desktopPaymentMethod)}
          />
          <PersonalAreaDropDownItem
            text={t(`${langConfigPath}.logOff.label`)}
            icon={logOffIcon}
            onClick={() => {
              logout.execute(ROUTES.desktopLogIn, ROUTES.desktopLogIn);
            }}
          />
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default PersonalAreaDropDown;
