import { IonContent, IonPage, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import ExchangeProfileCard from "../../../components/ExchangeProfileCard";
import { useCardEnv } from "../../../hooks/useCard";

import "./ExchangeProfilePage.scss";
import { BackIcon } from "../../../components/BackIcon";

const langConfigPath = "exchangeProfile";

const ExchangeProfilePageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const { cardNumber, cardName } = useCardEnv();
  const history = useHistory();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const labels = {
    cardName: cardName,
    action: t("convertProfile"),
    cardNumber,
  };
  return (
    <IonPage
      className="desktop-page desktop-exchange-profile-page-container"
      dir={appDirection}
    >
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-exchange-profile-page">
          <div className="desktop-title-container flex-direction-column">
            <div className="main-header-container">
              <BackIcon />
              <IonText className="desktop-header text">{labels.action}</IonText>
            </div>
            <IonText className="desktop-sub-header text">
              {`${labels.cardName ? `${labels.cardName} | ` : ""}${
                labels.cardNumber
              }`}
            </IonText>
          </div>

          <ExchangeProfileCard />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ExchangeProfilePageDesktop;
