import React, { useEffect, useRef, useState } from "react";
import useFetch from "../../hooks/UseFetch";
import { API_URL } from "../../store/Globals";
import { ILogIn, IRegister, IValidateOTP } from "../models/auth";
import { IUser } from "../models/users";
import {
  startLogin,
  loginSuccess,
  loginError,
  startLValidateOTP,
  validateOTPSuccess,
  validateOTPError,
  startRegister,
  registerError,
  registerSuccess,
  startLogout,
  logoutSuccess,
  logoutError,
} from "../../store/action-creators/Auth";
import { useSelector } from "react-redux";
import { RootState } from "../..";

const useLogIn = () => {
  const state = useSelector((state: RootState) => state.auth.logIn);

  const fetchData = useFetch<any>(
    `${API_URL}/auth/login`,
    startLogin,
    loginSuccess,
    loginError
  );

  const execute = (data: ILogIn) => {
    fetchData({
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    });
  };
  const logIn = {
    state: state,
    execute: execute,
  };

  return logIn;
};

const useValidateOTP = () => {
  const state = useSelector((state: RootState) => state.auth.validateOTP);

  const fetchData = useFetch<IUser>(
    `${API_URL}/auth/login/validateOTP`,
    startLValidateOTP,
    validateOTPSuccess,
    validateOTPError
  );

  const execute = (
    data: IValidateOTP,
    redirectForSucess?: string,
    redirectForError?: string
  ) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),
      },
      redirectForSucess,
      redirectForError
    );
  };
  const validateOTP = {
    state: state,
    execute: execute,
  };

  return validateOTP;
};

const useRegister = () => {
  const state = useSelector((state: RootState) => state.auth.register);

  const fetchData = useFetch<any>(
    `${API_URL}/auth/signup`,
    startRegister,
    registerSuccess,
    registerError
  );

  const execute = (
    data: IRegister,
    redirectForSucess?: string,
    redirectForError?: string
  ) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      },
      redirectForSucess,
      redirectForError
    );
  };
  const register = {
    state: state,
    execute: execute,
  };

  return register;
};

const useLogOut = () => {
  const state = useSelector((state: RootState) => state.auth.logout);

  const fetchData = useFetch<any>(
    `${API_URL}/auth/logout`,
    startLogout,
    logoutSuccess,
    logoutError
  );

  const execute = (redirectForSucess?: string, redirectForError?: string) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        method: "POST",
      },
      redirectForSucess,
      redirectForError
    );
  };
  const logout = {
    state: state,
    execute: execute,
  };

  return logout;
};

const authService = {
  logIn: useLogIn,
  validateOTP: useValidateOTP,
  register: useRegister,
  logOut: useLogOut,
};

export default authService;
