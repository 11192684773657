import { AnyAction } from 'redux'
import {
    CHANGE_TOP_BAR_SEARCH_VALUE,
    CHANGE_PERSONAL_AREA__MOBILE_MENU_OPEN_STATE
} from '../actions/index'

export const changeTopMenuSearchValue = (searchValue: string): AnyAction =>{
    return {type: CHANGE_TOP_BAR_SEARCH_VALUE, payload: searchValue}
}

export const changePersonalAreaMobileMenuOpenState = (open: boolean): AnyAction =>{
    return {type: CHANGE_PERSONAL_AREA__MOBILE_MENU_OPEN_STATE, payload: open}
}