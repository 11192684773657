import { IonButton, IonCard, IonCardContent, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import { flexibleSumValueETT, TContractDetails } from "../../../../hooks/types";
import { ortTimestampToDate } from "../../../../hooks/useCard";
import { changePopup } from "../../../../store/action-creators/App";
import { POPUPS } from "../../../../store/Globals";

import "./editOrderChargeSelectedContractCard.scss";
import { formatDateShort } from "../../../../utils/helper-functions";

const langConfigPath = "stepsSections.orderChargeSummary";

type TEditOrderChargeSelectedContractCard = {
  mobile?: boolean;
  contract: TContractDetails;
};

const EditOrderChargeSelectedContractCard: React.FC<
  TEditOrderChargeSelectedContractCard
> = ({ mobile, contract }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { i18n, t } = useTranslation("", { keyPrefix: langConfigPath });
  const dispatch = useDispatch();

  const labels = {
    contractStart: contract.SpecificContractData.FromDate
      ? formatDateShort(
          ortTimestampToDate(contract.SpecificContractData.FromDate)
        )
      : "",
    contract: t("contract"),
    sumValueText: t("sumValueText"),
    contractStartText: t("contractStartDate"),
    changeStartDate: t("changeStartDate"),
    continueRides: t("continueRides"),
    continueRidesMinutes: t("continueRidesMinutes", {
      minutes: contract.SpecificContractData.RestrictDurationInMinutes
        ? contract.SpecificContractData.RestrictDurationInMinutes
        : 0,
    }),
    remark: t("remark"),
    predefined: t("predefined"),
    flexible: t("flexible.label"),
    startFrom: t("flexible.startFrom", { minSum: 30 }),
  };

  return (
    <IonCard
      dir={appDirection}
      className="edit-order-charge-selected-contract-card card"
      data-is-mobile={mobile}
    >
      <IonCardContent>
        <div className="flex ion-align-items-center justify-content-space-between">
          <div className="flex">
            <IonText className="text font-size-18 bold">
              {labels.contract}&nbsp;&nbsp;
            </IonText>
            <IonText className="text font-size-18">{contract.ETTStr}</IonText>
          </div>
        </div>
        <div className="line"></div>

        <div className="price-container flex ion-align-items-center justify-content-space-between">
          <div className="flex flex-direction-column">
            <IonText className="text bold">{contract.ETTStr}</IonText>
            <IonText className="text sub-text">
              {contract.ContractType === "SumValue" &&
                (contract.ETT === flexibleSumValueETT
                  ? labels.flexible
                  : `${labels.sumValueText} ${contract.PriceInAgorot / 100}`)}
            </IonText>
          </div>
          <IonText className="text font-size-18 weight-900">
            {contract.ETT === flexibleSumValueETT
              ? labels.startFrom
              : `${contract.PriceInAgorot / 100} ₪`}
          </IonText>
        </div>
        {contract.SpecificContractData.FromDate && (
          <div className="flex ion-align-items-center justify-content-space-between">
            <div className="flex flex-direction-column">
              <IonText className="text sub-text">
                {labels.contractStartText}
              </IonText>
              <IonText className="text bold">{labels.contractStart}</IonText>
            </div>

            <IonButton
              className="button-secondary"
              onClick={() => {
                dispatch(changePopup(POPUPS.selectStartDateForDailyContract));
              }}
            >
              {labels.changeStartDate}
            </IonButton>
          </div>
        )}
        {contract.SpecificContractData.RestrictDurationInMinutes && (
          <div className="flex ion-align-items-center justify-content-space-between">
            <div className="flex flex-direction-column">
              <IonText className="text sub-text">
                {labels.continueRides}
              </IonText>
              <IonText className="text bold">
                {labels.continueRidesMinutes}
              </IonText>
            </div>
          </div>
        )}

        {contract.Remark && (
          <div className="notes-container flex flex-direction-column">
            <IonText className="text sub-text">{labels.remark}</IonText>
            <IonText className="text">{contract.Remark}</IonText>
          </div>
        )}

        <div className="sharing-code-container flex flex-direction-column">
          <IonText className="text sub-text">{labels.predefined}</IonText>
          <IonText className="text">{contract.PredefinedCode}</IonText>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default EditOrderChargeSelectedContractCard;
