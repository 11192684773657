import { customPOST } from "../services/customApi/fetch";
import { OrtCommand, Vendors } from "./types";

export const getCommands = async (cmdType: OrtCommand, data?: any) => {
  const payload = { cmdType, data };
  const rsp = await customPOST("card/getCommands", payload);
  if (!rsp.data?.commands) {
    throw new Error("No commands returned");
  }
  return rsp.data;
};
export const commandResOrt = async ({
  results,
  SessionID,
}: {
  results: any[];
  SessionID: string;
}) => {
  const payload = { results, SessionID };
  const rsp = await customPOST("card/commandResults", payload);
  return rsp.data;
};

export const placeProfileRequestOrt = async (
  reqId: string,
  cardPayload: any
): Promise<{ ComplaintConfirmationId: string }> => {
  const payload = { reqId, cardPayload };
  const rsp = await customPOST("profiles/placeProfileRequest", payload);
  return rsp.data;
};
export const closeSessionOrt = async ({
  results,
  SessionID,
}: {
  results: any[];
  SessionID: string;
}) => {
  const payload = { results, SessionID };
  const rsp = await customPOST("card/closeSession", payload);
  return await rsp.data;
};

export const isMoovit = (vendor: string | null) => {
  return vendor === "moovit" || vendor === "responsive";
};
