import { 
IonBackButton,
IonButton,
IonButtons,
IonCheckbox,
IonContent,
IonFooter,
IonGrid,
IonHeader,
IonIcon,
IonImg,
IonModal,
IonPage,
IonRouterLink,
IonRow,
IonText,
IonTitle,
IonToolbar, 
useIonViewWillEnter
} from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../..';

import logo from '../../../assets/logo.png'
import { StorageService } from '../../../services/LocalStorageService';
import { changeShowOnBoarding } from '../../../store/action-creators/App';
import { ROUTES, STORAGE_KEYS } from '../../../store/Globals';

import './OnBoardingPage.scss';


const langConfigPath = 'pages.mobile.onBoarding'

const OnBoardingPageMobile: React.FC = () => {
  const [open, setOpen] = useState(true)
  const [checkedTermsAndConditions, setCheckedTermsAndConditions] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const appDirection = useSelector((state: RootState)=>state.app.direction)

  const handleClose = () =>{
      StorageService.set(STORAGE_KEYS.onBoarding, 'true')
      dispatch(changeShowOnBoarding(false))
      setOpen(false)
      history.push(ROUTES.home)
  }

  const onCheck = () =>{
    setCheckedTermsAndConditions(true)
  }

  return (
    <IonModal
        isOpen={open}
        dir={appDirection}
        >
        <IonPage className="mobile-on-boarding-page">
            <IonHeader className="logo-header">
                <IonImg className="logo" src={logo} alt=""/>
            </IonHeader>

            <IonContent className="content">
                <IonGrid>
                    <IonRow className="justify-content-center">
                        <IonText className="header1 text">{t(`${langConfigPath}.header1`)}</IonText>                 
                    </IonRow>

                    <IonRow className="justify-content-center">
                        <IonText className="header2 text">{t(`${langConfigPath}.header2`)}</IonText>
                    </IonRow>

                    <IonRow className="justify-content-center">
                        <IonText className="main-text text">{t(`${langConfigPath}.mainText`)}</IonText>
                    </IonRow>
                </IonGrid>

                <div className="user-agreement flex justify-content-center ion-align-items-center">
                    <IonCheckbox
                    className="checkbox"
                    onIonChange={onCheck}
                    />
                    <IonText className="main-label flex text">
                        {t(`${langConfigPath}.userAgreement.text`)}
                        &nbsp;   {/* empty space */}
                        <IonRouterLink className="link text">
                            {t(`${langConfigPath}.userAgreement.link`)}
                        </IonRouterLink>
                    </IonText>
                    
                </div>
            </IonContent>
            
            {/* <IonFooter className="footer">
                <IonGrid>
                    <IonRow className="justify-content-center"> 
                        <IonButton
                        disabled={!checkedTermsAndConditions}
                        onClick={handleClose}
                        className="close-button button-primary text">
                            {t(`${langConfigPath}.confirmButton`)}
                        </IonButton>
                    </IonRow>
                </IonGrid>

            </IonFooter> */}
        </IonPage>
    </IonModal>
  )
}

export default OnBoardingPageMobile;
