import { AnyAction } from "redux";
import { SdkEvent } from "../../hooks/types";
import { StorageService } from "../../services/LocalStorageService";
import {
  CHANGE_APP_DIRECTION,
  CHANGE_SHOW_ON_BOARDING,
  CHANGE_POP_UP,
  CHANGE_LOGGED_USER_NAME,
  CHANGE_SDK_RECEIVED_EVENT_DATA,
  CHANGE_VENDOR,
  CHANGE_APP_LOADED,
} from "../actions/index";
import { STORAGE_KEYS, TPopup } from "../Globals";

export const changeAppDirection = (direction: string): AnyAction => {
  StorageService.set(STORAGE_KEYS.appDirection, direction);
  return { type: CHANGE_APP_DIRECTION, payload: direction };
};

export const changeShowOnBoarding = (show: boolean): AnyAction => {
  return { type: CHANGE_SHOW_ON_BOARDING, payload: show };
};

export const changePopup = (name: TPopup, props?: any) => {
  return { type: CHANGE_POP_UP, payload: { name, props } };
};

export const changeLoggedUserName = (
  name: { firstName: string; lastName: string } | null
): AnyAction => {
  return { type: CHANGE_LOGGED_USER_NAME, payload: name };
};

export const changeSdkReceivedEventData = (eventData: {
  action: SdkEvent | string;
  data: any;
}): AnyAction => {
  return { type: CHANGE_SDK_RECEIVED_EVENT_DATA, payload: eventData };
};

export const changeVendor = (vendor: string | null): AnyAction => {
  return { type: CHANGE_VENDOR, payload: vendor };
};

export const changeAppLoaded = (isFinishedLoad: boolean): AnyAction => {
  return { type: CHANGE_APP_LOADED, payload: isFinishedLoad };
};
