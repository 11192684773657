import { 
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonSearchbar,
    IonText,
    IonTitle,
    IonToolbar 
    } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../..';
    
import clearIcon from '../../assets/icons-hamburger-x.svg'
import searchIcon from '../../assets/icons-search.svg'
import { changeTopMenuSearchValue } from '../../store/action-creators/TopMenu';
import { getSearchJson, SearchObject } from '../../utils/search-config';

import './SearchResultCard.scss';
import SearchResultsCardItem from './SearchResultCardItem';
import {createSelector} from 'reselect';

// memoizing the search value selector
const selectSearchValue = createSelector(
  (state: RootState) => state.topMenu.searchValue,
  (searchValue) => searchValue
)       

type SearchResultsCard = {
    mobile?: boolean
}

const SearchResultsCard: React.FC<SearchResultsCard> = ({mobile=false}) => {
    const searchValue = useSelector(selectSearchValue)
    const appDirection = useSelector((state: RootState)=>state.app.direction)
    const [searchJson, setSearchJson] = useState<SearchObject[]>()
    const [results, setResults] = useState<SearchObject[]>() 
    const history = useHistory()
    const dispatch = useDispatch()
    const {i18n} = useTranslation()

    useEffect(()=>{
       setSearchJson(getSearchJson(i18n.language))
       
    },[i18n.language])


    useEffect(()=>{
       
        if(searchJson !== undefined){
            let results = searchJson.filter(x=>x.title.includes(searchValue) ||
                                               x.description.includes(searchValue))                                       
            setResults(results)
        }

    },[searchValue, searchJson])


    return (
    <IonCard
    dir={appDirection}
    className="search-results-card"
    data-is-mobile={mobile}
    >
        <div className="card-header-container">
            <IonCardHeader className="card-header"></IonCardHeader>
            <IonSearchbar
            value={searchValue}
            placeholder=""
            searchIcon={searchIcon}
            clearIcon={clearIcon}
            className="search-bar text"
            onIonChange={(e)=>{dispatch(changeTopMenuSearchValue(e.detail.value!))}}
            />
        </div>
        <IonCardContent className="card-content-container">
            <IonList className="card-content">
                {
                    results !== undefined &&
                    results.map((item, index)=>{
                        return(
                            <SearchResultsCardItem
                            key={Math.random()}
                            mobile={mobile}
                            result={item}
                            />
                        )
                    })
                }
            </IonList>
        </IonCardContent>
    </IonCard>
    )
}

export default SearchResultsCard;
