import { IonContent } from "@ionic/react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import "./PaymentSuccessLanding.scss";
import * as yup from "yup";

const paymentStatusSchema = yup.object({
  result: yup.string().oneOf(["success", "error", "cancel"]).required(),
  txId: yup.string().required(),
  method: yup.string().required(),
  uniqueID: yup.string().required(),
});

type PayParams = yup.InferType<typeof paymentStatusSchema>;

export const PaymentSuccessLanding = () => {
  const location = useLocation();

  const sendPayParams = (v: PayParams) => window?.parent.postMessage(v);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const obj: { [key: string]: string } = {};
    for (const [key, value] of params.entries()) {
      obj[key] = value;
    }
    const payParams = paymentStatusSchema.validateSync(obj);
    if (payParams.txId) sendPayParams(payParams);
  }, []);
  return <IonContent></IonContent>;
};
