import { IonCard, IonContent, IonIcon, IonPage, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import RavkavCards from "../../../components/RavkavCards";

import "./desktopRavkavCardsPageDesktop.scss";
import { BackIcon } from "../../../components/BackIcon";
import backIconWhite from "../../../assets/icons-arrow-back-white.svg";
const langConfigPath = "ravkavCardsCard";

const DesktopRavkavCardsPageDesktop: React.FC = () => {
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const appDirection = useSelector((state: RootState) => state.app.direction);

  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const labels = {
    header: t("header"),
  };
  return (
    <IonPage
      className="desktop-page desktop-scan-results-page-container"
      dir={appDirection}
    >
      <IonContent
        scrollY
        className={`desktop-page-content  desktop-background-${vendor}`}
      >
        <div className="desktop-ravkav-cards-page card-700">
          <div className="desktop-title-container flex-direction-row">
            <BackIcon closeIcon={backIconWhite} />

            <IonText className="desktop-header text">{labels.header}</IonText>
          </div>

          <IonCard className="desktop-page-card padding-15">
            <RavkavCards mobile={false} />
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DesktopRavkavCardsPageDesktop;
