import { FC, useEffect } from "react";
import { customPOST } from "../../../services/customApi/fetch";
import * as yup from "yup";
import { useSimpleQuery } from "../../../hooks/UseFetch";
import { TContractDetails } from "../../../hooks/types";
import { isHiddenOnMobile } from "../../../utils/helper-functions";
import { dateToOrtTimeStamp } from "../../../hooks/useCard";

const msgSchema = yup.object({
  result: yup.string().oneOf(["success", "error", "cancel"]).required(),
  txId: yup.string().required(),
  method: yup.string().required(),
  uniqueID: yup.string().required(),
  cardMask: yup.string().required(),
});
const linkSchema = yup
  .object({
    link: yup.string().required(),
    txId: yup.string().required(),
  })
  .required();

type SinglePayParams = {
  amount: number;
  ccToken?: string;
  cardExpiration?: string;
  purchaseDetails?: {
    ravKavNumber?: number;
    contract?: TContractDetails;
  };
  onFinish: (paymentParams: {
    expiration: string;
    paymentUid: string;
    last4Digits: string;
    topcardId: string;
  }) => void;
  onError?: (error: any) => void;
};
const getSinglePayLink = async (
  params: Omit<SinglePayParams, "onFinish"> & { cvvRequired: boolean }
) => {
  return await customPOST("payments/pay", params);
};

const getJ5PayLink = async (
  params: Omit<SinglePayParams, "onFinish"> & { cvvRequired: boolean }
) => {
  return await customPOST("payments/verify", params);
};

const useGetPayLink = (params: Omit<SinglePayParams, "onFinish">) => {
  // const isVendor = useSelector((state: RootState) => state.app.isVendor);
  const isMobile = isHiddenOnMobile();

  const { data } = useSimpleQuery(
    async () => {
      const { data: link, status } = await getSinglePayLink({
        ...params,
        cvvRequired: !isMobile,
      });
      const search = new URLSearchParams(link.split("?")[1]);
      return { data: { link, txId: search.get("txId") }, status };
    },
    (link) => linkSchema.validate(link)
  );
  return data ?? { link: "", txId: "" };
};

const useGetJ5PayLink = (params: Omit<SinglePayParams, "onFinish">) => {
  // const isVendor = useSelector((state: RootState) => state.app.isVendor);
  const isMobile = isHiddenOnMobile();

  const { data } = useSimpleQuery(
    async () => {
      const { data: link, status } = await getJ5PayLink({
        ...params,
        cvvRequired: !isMobile,
      });
      const search = new URLSearchParams(link.split("?")[1]);
      return { data: { link, txId: search.get("txId") }, status };
    },
    (link) => linkSchema.validate(link)
  );
  return data ?? { link: "", txId: "" };
};

const usePaymentStatus = ({
  txId,
  onFinish,
  onError,
}: {
  txId: string;
  onFinish: (paymentParams: {
    expiration: string;
    paymentUid: string;
    last4Digits: string;
    topcardId: string;
  }) => void;
  onError?: (error: any) => void;
}) => {
  useEffect(() => {
    const abortControl = new AbortController();
    window.addEventListener(
      "message",
      async (ev) => {
        try {
          const { data } = ev;
          const {
            txId: recievedTxId,
            result,
            method,
            uniqueID,
            cardMask,
          } = await msgSchema.validate(data);
          if (txId === recievedTxId) {
            if (result !== "success") {
              throw new Error(
                `transaction failed, ${method} ${result} ${uniqueID} ${cardMask}`
              );
            }
            console.log("transaction successful");
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 14);

            onFinish({
              last4Digits: cardMask,
              paymentUid: txId,
              // TODO: fix expiration
              expiration: dateToOrtTimeStamp(expiration),
              topcardId: uniqueID,
            });
          }
          console.log({ txId, recievedTxId, result, method });
        } catch (err) {
          onError?.(err);
        }
      },
      { signal: abortControl.signal }
    );
    return () => abortControl.abort();
  }, [txId]);
};

export const J5PaymentForm: FC<SinglePayParams> = (params) => {
  const { link, txId } = useGetJ5PayLink(params);
  usePaymentStatus({
    txId,
    onFinish: params.onFinish,
    onError: params.onError,
  });

  return <iframe src={link} title="verify-iframe" className="iframe" />;
};

export const SinglePaymentForm: FC<SinglePayParams> = (params) => {
  const { link, txId } = useGetPayLink(params);
  usePaymentStatus({
    txId,
    onFinish: params.onFinish,
    onError: params.onError,
  });

  return <iframe src={link} title="verify-iframe" className="iframe" />;
};
