import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonText,
  useIonViewWillLeave,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import waveIcon from "../../../assets/icons-cancel-and-refund-page-wave.svg";

import "./CancelAndRefundPage.scss";

const langConfigPath = "pages.desktop.cancelAndRefund";

const CancelAndRefundPage: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-cancel-and-refund-page flex flex-direction-column ion-align-items-center">
          <IonIcon className="icon" />

          <IonText className="desktop-header text">
            {t(`${langConfigPath}.header`)}
          </IonText>
          <IonText className="text">{t(`${langConfigPath}.text-1`)}</IonText>
          <IonText className="text">{t(`${langConfigPath}.text-2`)}</IonText>

          <IonButton
            className="button-primary"
            onClick={() => {
              history.push(ROUTES.desktopNfcOrder);
            }}
          >
            {t(`${langConfigPath}.goToScanButton.label`)}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CancelAndRefundPage;
