import { IonCard, IonCardContent, IonText } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { TContractType } from "../../../hooks/contracts/useContractsForLoading";
import "./selectContractType.scss";
import { useContractTypes } from "../../../hooks/contracts/useContractGreenList";
import { CenteredSpinner } from "../../Spinner";

type TSelectContractType = {
  nextStep: (
    contract: TContractType,
    params?: { isTrain?: boolean; isEilat?: boolean }
  ) => void;
  mobile?: boolean;
  profileId?: number;
  isRemote?: boolean;
};

const SelectContractType: React.FC<TSelectContractType> = ({
  mobile,
  nextStep,
  profileId,
  isRemote,
}) => {
  const cardImage = useSelector((state: RootState) => state.nfc.card.data);
  const { contractTypes, isLoading } = useContractTypes({
    ...(isRemote
      ? { profileId }
      : {
          cardImage: cardImage ? cardImage : undefined,
        }),
  });
  const appDirection = useSelector((state: RootState) => state.app.direction);
  if (isLoading) return <CenteredSpinner />;

  if (!contractTypes) return <div>no contract types</div>;
  return (
    <div
      dir={appDirection}
      className="flex flex-direction-column select-contract-type"
    >
      {contractTypes.map(({ id: contractTypeId, name: contractTypeName }) => (
        <IonCard
          key={contractTypeId}
          onClick={() => nextStep(contractTypeId)}
          className="card"
        >
          <IonCardContent className="card-content ">
            <IonText className="text font-size-18">{contractTypeName}</IonText>
          </IonCardContent>
        </IonCard>
      ))}
    </div>
  );
};

export default SelectContractType;
