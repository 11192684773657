import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonText,
} from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import AddCreditCardForm from "../../../components/forms/AddCreditCardForm";
import RegisterForm from "../../../components/forms/RegisterForm";
import { ROUTES } from "../../../store/Globals";

import "./RegisterPage.scss";
import { BackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.desktop.register";

const RegisterPageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const { t } = useTranslation();
  const [userId, setUserId] = useState("");

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-register-page">
          <div className="desktop-title-container">
            <div className="desktop-header-container width-100 position-relative">
              <BackIcon onClick={() => history.push(ROUTES.desktopLogIn)} />
              <IonText className="desktop-header text absolute-center main-title">
                {t(`${langConfigPath}.header`)}
              </IonText>
            </div>
            <div className="sub-header-container">
              <IonText className="desktop-sub-header text">
                {t(`${langConfigPath}.subHeader`)}
              </IonText>
            </div>
          </div>

          <IonCard className="desktop-page-card">
            <IonCardContent className="desktop-card-content">
              {!userId ? (
                <RegisterForm
                  onSuccess={(data) => {
                    setUserId(data._id);
                  }}
                />
              ) : (
                <AddCreditCardForm
                  userId={userId}
                  onFinished={() => history.push(ROUTES.desktopLogIn)}
                />
              )}
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPageDesktop;
