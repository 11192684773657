import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { POPUPS, ROUTES } from "../../store/Globals";
import RavkavInfoCard from "../RavkavInfoCard";
import emptyIcon from "../../assets/icons-empty.svg";
import { TSavedRavkavCard, useCards } from "../../hooks/useCard";
import ChangeCardNamePopup from "../pop-ups/ChangeCardNamePopup";
import { useDispatch } from "react-redux";
import { changePopup } from "../../store/action-creators/App";
import { formatDateShort } from "../../utils/helper-functions";

type TProps = {
  mobile?: boolean;
  parent?: "load_card" | "user_card";
};
const langConfigPath = "ravkavCardsCard";

const RavkavCards: React.FC<TProps> = ({ mobile, parent }) => {
  const { cards, refetch } = useCards();

  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardForRename, setCardForRename] = useState<TSavedRavkavCard>();

  const labels = {
    cardNum: (SCNumber: string) =>
      t("card.num", { num: parseInt(SCNumber, 16).toString() }),
    expires: (d?: Date) =>
      d ? t("card.expDate", { date: formatDateShort(d) }) : t("unknownDate"),
    lastCharge: (d?: Date) =>
      d ? t("card.lastCharge", { date: formatDateShort(d) }) : t("unknownDate"),
    noCards: t("noCardsFoundText"),
    toScan: t("goToScanButton.label"),
  };
  return (
    <>
      {cards?.map((card) => (
        <RavkavInfoCard
          mobile={mobile}
          parent={parent}
          key={card._id}
          payload={card.payload}
          name={card.name || labels.cardNum(card.SCNumber)}
          cardNumberStr={labels.cardNum(card.SCNumber)}
          exp={labels.expires(card.expiresAt)}
          lastCharged={labels.expires(card.lastLoad)}
          onRename={() => {
            setCardForRename(card);
            dispatch(changePopup(POPUPS.changeCardName));
          }}
        />
      ))}
      <ChangeCardNamePopup card={cardForRename} onFinish={refetch} />
      {cards?.length === 0 && (
        <>
          <div className="flex flex-direction-column ion-align-items-center">
            <IonIcon className="empty-icon" src={emptyIcon} />
            <IonText className="text font-size-18 weight-500 text-align-center">
              {labels.noCards}
            </IonText>
          </div>

          <div className="flex justify-content-center position-fixed width-100">
            <IonButton
              className="button-primary"
              onClick={() => {
                history.push(ROUTES.mobileNfcRead);
              }}
            >
              {labels.toScan}
            </IonButton>
          </div>
        </>
      )}
    </>
  );
};

export default RavkavCards;
