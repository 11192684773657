import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonText,
} from "@ionic/react";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import expandIcon from "../../assets/icons-small-arrow-down.svg";
import { Charge } from "../../hooks/types";
import "./chargeHistoryCard.scss";
import { formatDateLong } from "../../utils/helper-functions";

const langConfigPath = "chargeHistoryCard";

type ChargeHistoryCardParams = {
  mobile?: boolean;
  charge: Charge;
};

const ChargeHistoryCard: React.FC<ChargeHistoryCardParams> = ({ charge }) => {
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const [showDetails, setShowDetails] = useState(false);
  const labels = useMemo(() => {
    return {
      receipt: t("detailLabels.showReceiptButton.label"),
      contract: t("detailLabels.contract"),
      payment: t("detailLabels.payment"),
      createdStr: t("detailLabels.created"),
      cardNumber: t("detailLabels.cardNumber"),
      footerCardNumber: t("footerCardNumber"),
      charged: t("charged", {
        amount: ((charge.amount || 0) / 100).toFixed(2),
      }),
      contractTitle: charge?.contract?.ETTStr,
      contractDesc: charge?.contract?.Description,
      amount: ((charge.amount || 0) / 100).toFixed(2),
      ravkavCardNumber: charge.ravKavNumber,
      creditCardNumber: charge.ccNumber
        ? "xxxx xxxx xxxx " + charge.ccNumber
        : "",
      created: formatDateLong(charge.createdAt),
      updated: formatDateLong(charge.updatedAt),
    };
  }, [t, charge]);
  return (
    <IonCard
      button
      className={classNames({
        "charge-history-card": true,
        card: true,
        "rotate-icon": showDetails,
      })}
      onClick={() => {
        setShowDetails(!showDetails);
      }}
    >
      <IonCardContent>
        <div className="flex justify-content-space-between ion-align-items-center">
          <div className="price-and-expand-icon-container flex ion-align-items-center gap20">
            <IonText className="text font-size-18 weight-500">
              {labels.contractTitle}
            </IonText>
            {showDetails && charge?.invoice && (
              <IonButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (charge?.invoice) window.open(charge?.invoice, "_blank");
                }}
              >
                {labels.receipt}
              </IonButton>
            )}
          </div>
          <div className="flex flex-direction-row ion-align-items-center">
            <div className="text font-size-18 weight-900" hidden={showDetails}>
              <span className="text font-size-18 weight-500">₪</span>
              {labels.amount}
            </div>
            <IonIcon className="expand-icon" src={expandIcon} />
          </div>
        </div>

        {showDetails && (
          <div className="details-container flex flex-direction-row justify-content-space-around">
            <div className="flex flex-direction-column">
              <IonText className="text color-dark-sub-text font-size-14">
                {labels.contract}
              </IonText>
              <IonText className="text">{labels.contractTitle}</IonText>
              <IonText className="text font-size-12 color-sub-text">
                {labels.contractDesc}
              </IonText>
            </div>

            <div className="flex flex-direction-column">
              <IonText className="text color-dark-sub-text font-size-14">
                {labels.payment}
              </IonText>
              <IonText className="text">{labels.charged}</IonText>
              <IonText className="text font-size-12 color-sub-text">
                {labels.creditCardNumber}
              </IonText>
            </div>

            <div className="flex flex-direction-column">
              <IonText className="text color-dark-sub-text font-size-14">
                {labels.createdStr}
              </IonText>
              <IonText className="text">{labels.created}</IonText>
            </div>

            <div className="flex flex-direction-column">
              <IonText className="text color-dark-sub-text font-size-14">
                {labels.cardNumber}
              </IonText>
              <IonText className="text">{labels.ravkavCardNumber}</IonText>
            </div>
          </div>
        )}
      </IonCardContent>
      <div
        className="flex ion-align-items-center justify-content-space-between 
          has-bottom-border background-color-light-contrast"
      >
        <div className="flex">
          <IonText className="text font-size-14">
            {labels.footerCardNumber}
          </IonText>
          <IonText className="text font-size-14">
            &nbsp;{labels.ravkavCardNumber}
          </IonText>
        </div>
        <IonText className="text font-size-14">{labels.created}</IonText>
      </div>
    </IonCard>
  );
};

export default ChargeHistoryCard;
