import { OS } from "../../store/Globals";
import { store } from "../../index";
import { history } from "../../store/ConfigureStore";
import { getPlatforms } from "@ionic/core";
import { SdkCommand, SdkEvent, sdkEvents } from "../../hooks/types";
type TOs = (typeof OS)[keyof typeof OS] | undefined;
export const getOs = (): TOs => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.includes("Windows")) return OS.windows;
  if (userAgent.includes("Mac")) return OS.mac;
  if (userAgent.includes("Linux")) return OS.linux;
};

export const getPlatform = () => {
  if (
    getPlatforms().indexOf("android") !== -1 &&
    getPlatforms().indexOf("mobileweb") === -1
  ) {
    return "android";
  } else if (
    getPlatforms().indexOf("ios") !== -1 &&
    getPlatforms().indexOf("mobileweb") === -1
  )
    return "ios";
  else {
    return "web";
  }
};

export const isHiddenOnMobile = () => {
  // if(ScreenOrientation && ScreenOrientation.type.includes('portrait') && window.innerWidth < 576)
  //   return true

  // else if( ScreenOrientation && ScreenOrientation.type.includes('landscape') && window.innerHeight < 576)
  //   return true
  if (window.innerWidth < 576) return true;
  //576
  else if (window.innerHeight < 576) return true;
};

export const fetchData = async (
  url: string,
  onStart: Function,
  onSuccess: Function,
  onError: Function,
  options?: object,
  redirectOnSuccess?: string,
  redirectOnError?: string
) => {
  try {
    store.dispatch(onStart());
    const res = await fetch(url, options);
    if (res.status !== 200) {
      throw await res.text();
    }
    const data = await res.json();

    if (redirectOnSuccess) {
      history.push(redirectOnSuccess);
    }
    store.dispatch(onSuccess(data));
  } catch (error: any) {
    if (error instanceof Error) {
      error = error.message;
    }
    if (redirectOnError) {
      history.push(redirectOnError);
    }
    store.dispatch(onError(error));
  }
};

export type sdkEventData = {
  action: SdkEvent;
  data: any;
};

// window.webkit = {}
// window.webkit.messageHandlers = {}
// window.webkit.messageHandlers.observer = {
//   postMessage: (data: any) => {
//     console.log("ios sdk event", data);
//   },
// };

export const dispatchSdkEvent = (
  action: SdkCommand,
  data?: any,
  error?: string
) => {
  console.log("starting sdk event", action);
  console.log("data", data);
  const msgJSON = JSON.stringify({
    action,
    data,
    error: error ? "" : undefined,
  });
  if (window && window.Android) {
    // Call Android interface
    console.log("is android sdk");
    window.Android.postMessage(msgJSON);
  } else if (window?.webkit?.messageHandlers) {
    console.log("is ios sdk");
    // Call iOS interface

    try {
      window.webkit.messageHandlers.observer.postMessage(msgJSON);
    } catch (err) {
      console.error(err);
    }
  } else {
    // No Android or iOS interface found
    console.log("No native APIs found.");
  }
};

export const formatDateShort = (dateInp?: Date | string) => {
  if (!dateInp) return "";
  const date = typeof dateInp === "string" ? new Date(dateInp) : dateInp;
  if (!(date instanceof Date)) return "";
  return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${date.getFullYear()}`;
};

export const formatDateLong = (dateInp?: Date | string) => {
  if (!dateInp) return "";
  const date = typeof dateInp === "string" ? new Date(dateInp) : dateInp;
  if (!(date instanceof Date)) return "";
  return `${date.getHours()}:${date.getMinutes()} ${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}`;
};

export const checkIfEventIsOfTypeSdkEvent = (action: string) => {
  const eventTypes: string[] = Object.values(sdkEvents);

  if (eventTypes.includes(action)) {
    return true;
  }
  return false;
};
