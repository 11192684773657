import { AnyAction } from "redux";
import { IUser } from "../../services/models/users";
import {
  START_GET_USER_DATA,
  GET_USER_DATA_ERROR,
  GET_USER_DATA_SUCCESS,
  USER_DATA_EDIT_ACTIVATE,
  USER_DATA_EDIT_DEACTIVATE,
  START_UPDATE_USER_DATA,
  UPDATE_USER_DATA_ERROR,
  UPDATE_USER_DATA_SUCCESS,
  SAVE_CARD_PAYLOAD_INFO,
  CLEAR_CARD_PAYLOAD_INFO,
  SAVE_VENDOR_USER_DATA,
  LOG_OUT_SUCCESS,
} from "../actions";

type VendorUser = {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  ccToken: string;
  ccExpires: string;
  ccNumber: string;
  lastChargeDate?: Date;
};

type initialValuesType = {
  GetUserData: {
    loading: boolean;
    data?: IUser;
    error: string | null;
  };
  UpdateUserData: {
    loading: boolean;
    data?: any;
    error: string | null;
  };
  vendorUserData?: VendorUser;
  Login: boolean;
  EditModeActive: boolean;
  savedCardPayload?: any;
};

const initialValues: initialValuesType = {
  GetUserData: {
    loading: false,
    error: null,
  },
  UpdateUserData: {
    loading: false,
    data: null,
    error: null,
  },
  Login: false,
  EditModeActive: false,
};

const userReducer = (
  state: initialValuesType = initialValues,
  { type, payload }: AnyAction
): initialValuesType => {
  switch (type) {
    case START_GET_USER_DATA:
      return { ...state, GetUserData: { ...state.GetUserData, loading: true } };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        GetUserData: {
          ...state.GetUserData,
          loading: false,
          data: payload,
          error: null,
        },
        Login: true,
      };
    case GET_USER_DATA_ERROR:
      return {
        ...state,
        GetUserData: { ...state.GetUserData, loading: false, error: payload },
        Login: false,
      };
    case USER_DATA_EDIT_ACTIVATE:
      return { ...state, EditModeActive: true };
    case USER_DATA_EDIT_DEACTIVATE:
      return { ...state, EditModeActive: false };
    case START_UPDATE_USER_DATA:
      return {
        ...state,
        UpdateUserData: { ...state.UpdateUserData, loading: true },
      };
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        UpdateUserData: {
          ...state.UpdateUserData,
          loading: false,
          data: payload,
          error: null,
        },
      };
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        Login: false,
      };
    case SAVE_CARD_PAYLOAD_INFO:
      return {
        ...state,
        savedCardPayload: payload,
      };
    case CLEAR_CARD_PAYLOAD_INFO:
      return {
        ...state,
        savedCardPayload: undefined,
      };
    case UPDATE_USER_DATA_ERROR:
      return {
        ...state,
        UpdateUserData: {
          ...state.UpdateUserData,
          loading: false,
          error: payload,
        },
      };
    case SAVE_VENDOR_USER_DATA:
      return {
        ...state,
        vendorUserData: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
