import { AnyAction } from "redux";
import { Socket } from "socket.io-client";
import {
  nfcActions,
  CardEvent,
  Contract,
  GreenContract,
  TLoadContractActionPayload,
  RefundContractParams,
  ExchangeParams,
  TExchangeImprisonedActionPayload,
} from "../../hooks/types";
import {
  CHANGE_NFC_CARD_STATUS,
  GET_NFC_CARD_DETAILS_SUCCESS,
  START_GET_NFC_CARD_DETAILS,
  GET_NFC_CARD_DETAILS_ERROR,
  CREATE_NFC_SOCKET_CONNECTION,
  CLOSE_NFC_SOCKET,
  NFC_SOCKET_CONNECTED,
  NFC_SOCKET_DISCONNECTED,
  CHANGE_NFC_READER_STATUS,
  SERVER_SOCKET_CONNECTED,
  SERVER_SOCKET_DISCONNECTED,
  START_UPDATE_NFC_PROFILE,
  UPDATE_NFC_MULTI_SUCCESS,
  UPDATE_NFC_MULTI_ERROR,
  START_UPDATE_NFC_CONTRACT,
  CHANGE_NFC_ACTIVE,
  START_UPDATE_NFC_CONTRACT_REFUND,
  CHANGE_NFC_SERVICE_STATUS,
  NFC_ACTION_DATA,
  CARD_GREEN_LIST,
  CARD_IMPRISONED_SUM,
  SAVE_CARD_CONTRACTS,
  SAVE_CARD_HISTORY,
  CLEAR_NFC_ERROR,
  VENDOR_MULTI_START,
  CLEAR_RENEW_CONTRACT,
  SET_RENEW_CONTRACT,
} from "../actions";
import { TContractDetails } from "../../hooks/types";

type TNfcState = {
  connected: boolean;
  active: boolean;
  readerStatus: boolean;
  renewContract: TContractDetails | null;
  card: {
    status: boolean;
    id: number | null;
    isLoading: boolean;
    data: { SCImage: any; SCNumber: string } | null;
    error: string | null;
    cardNumber?: number;
    greenList?: GreenContract[];
    imprisonedDetails?: ExchangeParams;
    isBlocked?: boolean;
    contracts?: Contract[];
    history?: CardEvent[];
  };
  nextAction: {
    isFinished: boolean;
    isLoading: boolean;
    routeOnFinish?: string;
  } & (
    | {
        action: (typeof nfcActions)["none"];
      }
    | {
        action: (typeof nfcActions)["exchangeImprisoned"];
        data: TExchangeImprisonedActionPayload;
      }
    | {
        action: (typeof nfcActions)["readCard"];
      }
    | {
        action: (typeof nfcActions)["loadContract"];
        data: TLoadContractActionPayload;
      }
    | {
        action: (typeof nfcActions)["loadProfile"];
        data: { reqId: string };
      }
    | {
        action: (typeof nfcActions)["refundContract"];
        data: RefundContractParams;
      }
  );
  lastAction: {
    isLoading: boolean;
    data: any;
    error: string | null;
    action: (typeof nfcActions)[keyof typeof nfcActions];
  };

  socket: {
    isLoading: boolean;
    serverConnected: boolean;
    serviceConnected: boolean;
    instance: Socket | null;
    error: string | null;
  };
};

const initialValues: TNfcState = {
  active: false,
  connected: false,
  readerStatus: false,
  renewContract: null,
  card: {
    id: null,
    isLoading: false,
    data: null,
    error: null,
    status: false,
  },
  nextAction: {
    action: nfcActions.readCard,
    isFinished: false,
    isLoading: false,
  },
  lastAction: {
    action: nfcActions.none,
    isLoading: false,
    data: null,
    error: null,
  },
  socket: {
    serverConnected: false,
    serviceConnected: false,
    isLoading: false,
    instance: null,
    error: null,
  },
};

const nfcReducer = (
  state = initialValues,
  { type, payload }: AnyAction
): TNfcState => {
  switch (type) {
    case CLEAR_RENEW_CONTRACT:
      return { ...state, renewContract: null };
    case SET_RENEW_CONTRACT:
      return { ...state, renewContract: payload };
    case CHANGE_NFC_ACTIVE:
      return { ...state, active: payload };
    case CREATE_NFC_SOCKET_CONNECTION:
      return { ...state, socket: { ...state.socket, isLoading: true } };
    case CLOSE_NFC_SOCKET:
      return {
        ...state,
        socket: {
          ...state.socket,
          isLoading: false,
          serverConnected: false,
          serviceConnected: false,
          instance: null,
        },
      };
    case SERVER_SOCKET_CONNECTED:
      return {
        ...state,
        socket: {
          ...state.socket,
          serverConnected: true,
          instance: payload,
        },
      };
    case NFC_ACTION_DATA: {
      const { data, action, route } = payload;
      return {
        ...state,
        nextAction: {
          action,
          data,
          isFinished: false,
          isLoading: false,
          routeOnFinish: route,
        },
        lastAction: {
          action: nfcActions.none,
          isLoading: false,
          data: null,
          error: null,
        },
      };
    }
    case NFC_SOCKET_CONNECTED:
      return {
        ...state,
        connected: state.socket.serverConnected,
        socket: {
          ...state.socket,
          serviceConnected: true,
          isLoading: false,
        },
      };
    case CHANGE_NFC_SERVICE_STATUS:
      return {
        ...state,
        connected: state.socket.serverConnected,
        socket: {
          ...state.socket,
          serviceConnected: payload,
          isLoading: false,
        },
      };
    case SERVER_SOCKET_DISCONNECTED:
      return {
        ...state,
        connected: false,
        socket: {
          ...state.socket,
          serverConnected: false,
        },
        card: { ...state.card, data: null },
      };
    case NFC_SOCKET_DISCONNECTED:
      return {
        ...state,
        connected: false,
        socket: {
          ...state.socket,
          serviceConnected: false,
        },
        card: { ...state.card, data: null },
      };

    case CHANGE_NFC_READER_STATUS:
      return {
        ...state,
        readerStatus: payload,
        card: { ...state.card, data: null },
      };
    case CHANGE_NFC_CARD_STATUS:
      return {
        ...state,
        card: {
          ...state.card,
          status: !!payload,
          ...(payload && {
            data: undefined,
            contracts: undefined,
            history: undefined,
          }),
        },
        nextAction: {
          ...state.nextAction,
          isFinished: false,
          isLoading: false,
        },
      };
    case VENDOR_MULTI_START:
      return {
        ...state,
        card: { ...state.card, data: null },
        lastAction: { ...state.lastAction, isLoading: true },
        nextAction: {
          ...state.nextAction,
          isFinished: false,
          isLoading: true,
        },
      };
    case START_UPDATE_NFC_PROFILE ||
      START_UPDATE_NFC_CONTRACT ||
      START_UPDATE_NFC_CONTRACT_REFUND:
      console.log(
        "START_UPDATE_NFC_PROFILE || START_UPDATE_NFC_CONTRACT || START_UPDATE_NFC_CONTRACT_REFUND"
      );

      return {
        ...state,
        card: { ...state.card, data: null },
        lastAction: { ...state.lastAction, isLoading: true },
        nextAction: {
          ...state.nextAction,
          isFinished: false,
          isLoading: true,
        },
      };
    case UPDATE_NFC_MULTI_SUCCESS:
      return {
        ...state,
        active: false,
        lastAction: {
          ...state.lastAction,
          isLoading: false,
          data: payload,
          action: state.nextAction.action,
        },
        nextAction: {
          ...state.nextAction,
          isLoading: false,
          isFinished: true,
        },
      };
    case UPDATE_NFC_MULTI_ERROR:
      return {
        ...state,
        active: false,
        lastAction: {
          ...state.lastAction,
          isLoading: false,
          error: payload,
          action: state.nextAction.action,
        },
        nextAction: { ...state.nextAction, isFinished: true, isLoading: false },
      };
    case START_GET_NFC_CARD_DETAILS:
      return {
        ...state,
        card: {
          ...state.card,
          data: null,
          isLoading: true,
          imprisonedDetails: undefined,
          greenList: undefined,
          history: undefined,
          contracts: undefined,
        },
        nextAction: {
          ...state.nextAction,
          isLoading: true,
        },
      };
    case GET_NFC_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        active: false,
        card: {
          ...state.card,
          isLoading: false,
          data: payload,
          cardNumber: parseInt(payload.SCNumber, 16),
        },
        nextAction: { ...state.nextAction, isFinished: true, isLoading: false },
      };
    case GET_NFC_CARD_DETAILS_ERROR:
      return {
        ...state,
        active: false,
        card: {
          ...state.card,
          isLoading: false,
          error: payload,
          isBlocked: payload.isBlocked,
        },
        nextAction: { ...state.nextAction, isFinished: true, isLoading: false },
        lastAction: {
          ...state.lastAction,
          isLoading: false,
          error: payload,
          action: state.nextAction.action,
        },
      };
    case CARD_GREEN_LIST:
      return { ...state, card: { ...state.card, greenList: payload } };
    case CARD_IMPRISONED_SUM:
      return { ...state, card: { ...state.card, imprisonedDetails: payload } };
    case SAVE_CARD_CONTRACTS:
      return { ...state, card: { ...state.card, contracts: payload } };
    case SAVE_CARD_HISTORY:
      return { ...state, card: { ...state.card, history: payload } };
    case CLEAR_NFC_ERROR:
      return {
        ...state,
        card: { ...state.card, error: null },
        nextAction: {
          ...state.nextAction,
          isFinished: false,
          isLoading: false,
        },
        lastAction: { ...state.lastAction, error: null },
      };
    default:
      return state;
  }
};

export default nfcReducer;
