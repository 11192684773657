import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonText,
} from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import nfcLinesBigIcon from "../../../assets/icons-nfc-lines-big.svg";
import nfcLinesSmallIcon from "../../../assets/icons-nfc-lines-small.svg";
import ravKavImage from "../../../assets/rav-kav-card.webp";
import ravKavImagePango from "../../../assets/rav-kav-card-pango.svg";
import NfcReadFailedPopup from "../../../components/pop-ups/NfcReadFailed";
import attachCardIcon from "../../../assets/icons-nfc-attach-card.svg";

import attachCardIconPango from "../../../assets/icons-nfc-attach-card-pango.svg";
import "./NfcReadPage.scss";
import { useNfc } from "../../../hooks/nfc/UseNfc";
import { POPUPS } from "../../../store/Globals";
import { isMoovit } from "../../../hooks/helpers";
import { changePopup } from "../../../store/action-creators/App";
import NfcWriteFailedPopup from "../../../components/pop-ups/NfcWriteFailed";
import { nfcActions } from "../../../hooks/types";

const langConfigPath = "pages.mobile.nfcRead";

const useIconChange = ({ cardStatus }: { cardStatus: boolean }) => {
  const [readingIconChange, setReadingIconChange] = useState(true);

  useEffect(() => {
    if (cardStatus) {
      const intervalId = setInterval(() => {
        setReadingIconChange((state) => !state);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [cardStatus]);
  return { readingIconChange };
};

const NfcReadPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const isAppLoading = useSelector(
    (state: RootState) => state.app.isLoadingParams
  );
  const isVendor = useSelector((state: RootState) => state.app.isVendor);
  const dispatch = useDispatch();
  const attachCard = isMoovit(vendor) ? attachCardIcon : attachCardIconPango;
  const ravKavIcon = isMoovit(vendor) ? ravKavImage : ravKavImagePango;

  const { cardDetails, cardStatus, currentAction } = useNfc();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const { readingIconChange } = useIconChange({ cardStatus });
  useEffect(() => {
    if (!isVendor && !isAppLoading)
      dispatch(changePopup(POPUPS.whiteLabelRedirect));
  }, [dispatch, isVendor, isAppLoading]);

  const isRead = useMemo(() => {
    if (currentAction.action === nfcActions.readCard || nfcActions.none) {
      return true;
    }
    return false;
  }, [currentAction]);

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-nfc-read-page">
          <div className="mobile-title-container">
            <IonText className="mobile-header text">{t("header")}</IonText>
          </div>

          <IonCard className="mobile-page-card">
            <div className="bottom-contrast"></div>
            <NfcWriteFailedPopup mobile />
            <NfcReadFailedPopup cssClass="fail-pop-up" mobile />
            <IonCardContent className="mobile-card-content flex justify-content-center ion-align-items-center">
              <div className="main-content-conatiner">
                <div className="main-content">
                  <IonText className="text" hidden={!cardDetails.status}>
                    {isRead
                      ? t("inProgressMessage")
                      : t("writeInProgressMessage")}
                  </IonText>

                  {!cardStatus ? (
                    <IonIcon className="nfc-lines-big" src={attachCard} />
                  ) : (
                    <>
                      <IonIcon
                        className={
                          isMoovit(vendor)
                            ? "hold-card-icon"
                            : "nfc-lines-big up-and-down-animation"
                        }
                        src={attachCard}
                      />{" "}
                      {isMoovit(vendor) ? (
                        readingIconChange ? (
                          <IonIcon
                            className="nfc-lines-big"
                            src={nfcLinesBigIcon}
                          />
                        ) : (
                          <IonIcon
                            className="nfc-lines-small"
                            src={nfcLinesSmallIcon}
                          />
                        )
                      ) : null}
                    </>
                  )}
                </div>
                <IonImg className="rav-kav-image" src={ravKavIcon} alt="" />
              </div>

              <IonText className="text bold">
                {t("bottomMessage.text-1")}
              </IonText>
              <IonText className="text">
                <Trans>{t("bottomMessage.text-2")}</Trans>
              </IonText>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NfcReadPageMobile;
