import {
  nfcActions,
  TLoadContractActionPayload,
  RefundContractParams,
  GreenContract,
  TExchangeImprisonedActionPayload,
  ExchangeParams,
  TContractDetails,
} from "../../hooks/types";
import {
  CLOSE_NFC_SOCKET,
  CREATE_NFC_SOCKET_CONNECTION,
  REQUEST_NFC_CARD_WRITE,
  CHANGE_NFC_CARD_STATUS,
  GET_NFC_CARD_DETAILS_SUCCESS,
  START_GET_NFC_CARD_DETAILS,
  GET_NFC_CARD_DETAILS_ERROR,
  OPEN_NFC_SERVICE,
  START_GET_CARD_PAYLOAD,
  NFC_SOCKET_CONNECTED,
  NFC_SOCKET_DISCONNECTED,
  START_UPDATE_NFC_PROFILE,
  START_UPDATE_NFC_CONTRACT,
  UPDATE_NFC_MULTI_ERROR,
  UPDATE_NFC_MULTI_SUCCESS,
  CHANGE_NFC_ACTIVE,
  START_UPDATE_NFC_CONTRACT_REFUND,
  NFC_ACTION_DATA,
  MARK_FOR_REFUND,
  CARD_GREEN_LIST,
  CARD_IMPRISONED_SUM,
  START_EXCHANGE_IMPRISONED,
  SAVE_CARD_PAYLOAD_INFO,
  CLEAR_CARD_PAYLOAD_INFO,
  CLEAR_NFC_ERROR,
  SAVE_CARD_HISTORY,
  SAVE_CARD_CONTRACTS,
  VENDOR_MULTI_START,
  SET_RENEW_CONTRACT,
  CLEAR_RENEW_CONTRACT,
} from "../actions";
export const socketEvents = {
  createOrJoinRoom: "create or join",
  connection: "connection",
  log: "log",
  test: "test",
  card: "card",
  readCard: "card.read",
  roomCreated: "created",
  roomJoined: "joined",
  CardPayload: "card-payload",
  initCardPayload: "init-card-payload",
  initEraseContract: "card.erase-contract",
  initLoadContract: "card.load-contract",
  initLoadProfile: "card.load-profile",
  initExchangeImprisoned: "card.exchange-imprisoned",
  sessionClosed: "session.closed",
  cardConnected: "card.connected",
  cardDisconnected: "card.disconnected",
  readerConnected: "reader.connected",
  readerDisconnected: "reader.disconnected",
  serviceDisconnected: "service.disconnected",
  cardGreenList: "card.green-list",
  cardImprisonedSum: "card.imprisonedSum",
} as const;

export const createNfcConnection = () => {
  return { type: CREATE_NFC_SOCKET_CONNECTION };
};

export const closeNfcConnection = () => {
  return { type: CLOSE_NFC_SOCKET };
};

export const nfcSocketConnected = (socket: any) => {
  return { type: NFC_SOCKET_CONNECTED, payload: socket };
};
export const nfcSocketDisonnected = () => {
  return { type: NFC_SOCKET_DISCONNECTED };
};

export const requestCardWrite = () => {
  return { type: REQUEST_NFC_CARD_WRITE };
};

export const changeCardActiveStatus = (status: boolean) => {
  return { type: CHANGE_NFC_CARD_STATUS, payload: status };
};

//#region get card details
export const startGetCardDetails = () => {
  return { type: START_GET_NFC_CARD_DETAILS };
};

export const getCardDetailsSuccess = (details: any) => {
  return { type: GET_NFC_CARD_DETAILS_SUCCESS, payload: details };
};

export const getCardDetailsError = (error: string) => {
  return { type: GET_NFC_CARD_DETAILS_ERROR, payload: error };
};

//#endregion get card details

export const startVendorMulti = () => {
  return { type: VENDOR_MULTI_START };
};

export const startRefundContract = (payload: RefundContractParams) => {
  return { type: START_UPDATE_NFC_CONTRACT_REFUND, payload };
};
export const startLoadProfile = (payload: { reqId: string }) => {
  return { type: START_UPDATE_NFC_PROFILE, payload };
};
export const startLoadContract = (payload: {
  contract: any;
  paymentUid?: string;
}) => {
  return { type: START_UPDATE_NFC_CONTRACT, payload };
};
export const startExchangeImprisoned = (
  payload: TExchangeImprisonedActionPayload
) => {
  return { type: START_EXCHANGE_IMPRISONED, payload };
};
export const nfcMultiError = ({ payload }: { payload: any }) => {
  return {
    type: UPDATE_NFC_MULTI_ERROR,
    payload,
  };
};
export const nfcMultiSuccess = ({ payload }: { payload: any }) => {
  return {
    type: UPDATE_NFC_MULTI_SUCCESS,
    payload,
  };
};

export const changeNfcActive = (active: boolean) => {
  return { type: CHANGE_NFC_ACTIVE, payload: active };
};

export const saveNfcAction = (
  payload:
    | { route?: string } & (
        | {
            action: (typeof nfcActions)["loadContract"];
            data: TLoadContractActionPayload;
          }
        | {
            action: (typeof nfcActions)["refundContract"];
            data: RefundContractParams;
          }
        | {
            action: (typeof nfcActions)["exchangeImprisoned"];
            data?: TExchangeImprisonedActionPayload;
          }
        | {
            action: (typeof nfcActions)["loadProfile"];
            data: { reqId: string };
          }
        | {
            action: (typeof nfcActions)["none"];
          }
        | {
            action: (typeof nfcActions)["readCard"];
            route?: string;
          }
      )
) => {
  return { type: NFC_ACTION_DATA, payload };
};

export const startGetCardPayload = () => {
  return { type: START_GET_CARD_PAYLOAD };
};
export const getCardPayloadSuccess = <T>(payload: T) => {
  return { type: START_GET_CARD_PAYLOAD, payload };
};
export const getCardPayloadError = (error: string) => {
  return { type: START_GET_CARD_PAYLOAD, payload: error };
};
export const openNfcService = () => {
  return { type: OPEN_NFC_SERVICE };
};

export const markForRefund = (payload: {
  cardNumber: number;
  ContractSlotId: number;
}) => {
  return { type: MARK_FOR_REFUND, payload };
};

export const saveGreenList = (greenListcontracts: GreenContract[]) => {
  return { type: CARD_GREEN_LIST, payload: greenListcontracts };
};

export const saveImprisonedSum = (ImprisonedSumDetails: ExchangeParams) => {
  return { type: CARD_IMPRISONED_SUM, payload: ImprisonedSumDetails };
};

export const saveCardPayloadInfo = (payload: any) => {
  return { type: SAVE_CARD_PAYLOAD_INFO, payload };
};
export const saveCardHistory = (payload: any) => {
  return { type: SAVE_CARD_HISTORY, payload };
};
export const saveCardContracts = (payload: any) => {
  return { type: SAVE_CARD_CONTRACTS, payload };
};
export const clearCardPayloadInfo = () => {
  return { type: CLEAR_CARD_PAYLOAD_INFO };
};
export const clearNfcError = () => {
  return { type: CLEAR_NFC_ERROR };
};
export const setGlobalContractForRenew = (payload: TContractDetails) => {
  return { type: SET_RENEW_CONTRACT, payload };
};
export const clearGlobalContractForRenew = () => {
  return { type: CLEAR_RENEW_CONTRACT };
};
