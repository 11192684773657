import { AnyAction, Dispatch, Middleware } from "redux";
import { io, Socket } from "socket.io-client";
import { RootState } from "../..";
import {
  RefundContractParams,
  TExchangeImprisonedActionPayload,
} from "../../hooks/types";
import { getCardPayloadFromSocket } from "../../utils/nfc";
import { changePopup } from "../action-creators/App";
import {
  saveGreenList,
  saveImprisonedSum,
  socketEvents,
} from "../action-creators/Nfc";
import {
  CHANGE_NFC_CARD_STATUS,
  CHANGE_NFC_SOCKET_CONNECTION_STATUS,
  CREATE_NFC_SOCKET_CONNECTION,
  CLOSE_NFC_SOCKET,
  NFC_SOCKET_CONNECTED,
  OPEN_NFC_SERVICE,
  SERVER_SOCKET_CONNECTED,
  START_GET_CARD_PAYLOAD,
  CHANGE_NFC_READER_STATUS,
  GET_NFC_CARD_DETAILS_SUCCESS,
  GET_NFC_CARD_DETAILS_ERROR,
  START_UPDATE_NFC_PROFILE,
  UPDATE_NFC_MULTI_SUCCESS,
  UPDATE_NFC_MULTI_ERROR,
  START_UPDATE_NFC_CONTRACT,
  START_GET_NFC_CARD_DETAILS,
  START_UPDATE_NFC_CONTRACT_REFUND,
  CHANGE_NFC_SERVICE_STATUS,
  START_EXCHANGE_IMPRISONED,
} from "../actions";
import { API_URL, POPUPS } from "../Globals";

export const nfcSocketMiddleware: Middleware<{}, any, Dispatch<AnyAction>> = (
  storeApi
) => {
  const dispatchMultSuccess = <T>(payload: T) => {
    storeApi.dispatch({
      type: UPDATE_NFC_MULTI_SUCCESS,
      payload,
    });
  };
  const dispatchMultError = <T>(payload: T) => {
    storeApi.dispatch({
      type: UPDATE_NFC_MULTI_ERROR,
      payload,
    });
  };
  const socket = io(`${API_URL}`, {
    autoConnect: false,
  });

  const getCardPayload = async () => {
    try {
      if (!socket || !(socket instanceof Socket)) {
        throw new Error("socket is not initiated");
      }
      storeApi.dispatch({
        type: START_GET_NFC_CARD_DETAILS,
      });
      socket.once(
        socketEvents.cardGreenList,
        ({ greenList }: { greenList: any }) => {
          if (!greenList || greenList.length === 0) return;
          console.log({ greenList });
          storeApi.dispatch(saveGreenList(greenList));
          storeApi.dispatch(changePopup(POPUPS.greenList));
        }
      );
      socket.once(
        socketEvents.cardImprisonedSum,
        ({ imprisoned }: { imprisoned: any }) => {
          console.log({ imprisoned });
          storeApi.dispatch(saveImprisonedSum(imprisoned));
          storeApi.dispatch(changePopup(POPUPS.exchange));
        }
      );
      const state: RootState = storeApi.getState();
      const user = state.user.GetUserData.data;

      const payload = await getCardPayloadFromSocket(socket, user?._id);
      storeApi.dispatch({
        type: GET_NFC_CARD_DETAILS_SUCCESS,
        payload,
      });
    } catch (err) {
      storeApi.dispatch({
        type: GET_NFC_CARD_DETAILS_ERROR,
        payload: err,
      });
    }
  };
  const makeMultiRequest = async (
    event: (typeof socketEvents)[keyof typeof socketEvents],
    eventPayload: any
  ) => {
    try {
      const asyncPayload = new Promise((resolve, reject) => {
        socket.once(socketEvents.sessionClosed, (event) => {
          if (event.error) reject(event.error);
          resolve(event);
        });
      });
      socket.emit(event, eventPayload);
      const payload = await asyncPayload;
      dispatchMultSuccess(payload);
    } catch (err) {
      dispatchMultError(err);
    }
  };

  const loadProfile = async (reqId: string) => {
    return await makeMultiRequest(socketEvents.initLoadProfile, {
      reqId,
    });
  };
  const refundContract = async (refundParams: RefundContractParams) => {
    return await makeMultiRequest(socketEvents.initEraseContract, refundParams);
  };

  const exchangeImprisoned = async ({
    contractIndex,
    selectedOption,
  }: TExchangeImprisonedActionPayload) => {
    return await makeMultiRequest(socketEvents.initExchangeImprisoned, {
      contractIndex,
      selectedOption,
    });
  };
  const loadContarct = async (params: any) => {
    return await makeMultiRequest(socketEvents.initLoadContract, {
      ...params,
      cardNumber: storeApi.getState().nfc.card.cardNumber,
    });
  };
  return (next) => (action) => {
    switch (action.type) {
      case CREATE_NFC_SOCKET_CONNECTION:
        if (socket !== undefined) {
          console.log("closing open socket");
          socket.close();
        }
        try {
          console.log("created connection");
          socket.connect();
          socket.emit("set-meta", { clientType: "web" });
        } catch (error) {
          console.log(error);
        }
        socket.on("connect", () => {
          storeApi.dispatch({
            type: SERVER_SOCKET_CONNECTED,
            payload: socket,
          });
        });

        socket.on(socketEvents.cardConnected, ({ SCNumber }) => {
          storeApi.dispatch({
            type: CHANGE_NFC_CARD_STATUS,
            payload: SCNumber,
          });
        });
        socket.on(socketEvents.cardDisconnected, () => {
          storeApi.dispatch({
            type: CHANGE_NFC_CARD_STATUS,
            payload: false,
          });
        });
        socket.on(socketEvents.readerConnected, () =>
          storeApi.dispatch({
            type: CHANGE_NFC_READER_STATUS,
            payload: true,
          })
        );
        socket.on(socketEvents.readerDisconnected, () =>
          storeApi.dispatch({
            type: CHANGE_NFC_READER_STATUS,
            payload: false,
          })
        );
        socket.on(socketEvents.serviceDisconnected, () =>
          storeApi.dispatch({
            type: CHANGE_NFC_SERVICE_STATUS,
            payload: false,
          })
        );

        socket.on("joined", () =>
          storeApi.dispatch({
            type: NFC_SOCKET_CONNECTED,
          })
        );
        break;

      case CLOSE_NFC_SOCKET:
        console.log("closed socket");
        if (socket !== undefined) socket.close();
        storeApi.dispatch({
          type: CHANGE_NFC_SOCKET_CONNECTION_STATUS,
          payload: false,
        });
        storeApi.dispatch({
          type: CHANGE_NFC_CARD_STATUS,
          payload: false,
        });
        break;

      case OPEN_NFC_SERVICE:
        if (socket.connected)
          window.open(`topcard://roomId=${socket.id}`, "_blank");
        else
          socket.once("connect", () => {
            window.open(`topcard://roomId=${socket.id}`, "_blank");
          });
        break;

      case START_GET_CARD_PAYLOAD:
        getCardPayload();
        break;
      case START_UPDATE_NFC_PROFILE:
        loadProfile(action.payload.reqId);
        break;
      case START_UPDATE_NFC_CONTRACT:
        loadContarct(action.payload);
        break;
      case START_EXCHANGE_IMPRISONED:
        exchangeImprisoned(action.payload);
        break;
      case START_UPDATE_NFC_CONTRACT_REFUND:
        refundContract(action.payload);
        break;
    }

    return next(action);
  };
};
