import { IonText, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import "./AddCreditCardForm.scss";
import { CenteredSpinner } from "../../Spinner";
import { customGET } from "../../../services/customApi/fetch";

const langConfigPath = "forms.register";

type TAddCreditCardForm = {
  mobile?: boolean;
  userId: string;
  onFinished?: () => void;
};
const msgSchema = yup.object({
  result: yup.string().required(),
  txId: yup.string().required(),
  method: yup.string().required(),
  uniqueID: yup.string().required(),
  cardMask: yup.string().required(),
});

const getCreditCardTokenPageUrl = async (userId: string) => {
  const rsp = await customGET(`payments/setPaymentMethod/${userId}`);
  return rsp.data;
};

const useCreditCardTokenPage = ({ userId }: { userId: string }) => {
  const [tokenPageUrl, setTokenPageUrl] = useState("");
  useEffect(() => {
    getCreditCardTokenPageUrl(userId).then((url) => setTokenPageUrl(url));
  }, [userId]);
  const search = new URLSearchParams(tokenPageUrl.split("?")[1]);
  return { tokenPageUrl, txId: search.get("txId") };
};

const AddCreditCardForm: React.FC<TAddCreditCardForm> = ({
  onFinished,
  userId,
}) => {
  const { t } = useTranslation("", { keyPrefix: "forms.paymentMethod" });
  const { tokenPageUrl, txId } = useCreditCardTokenPage({ userId });
  const labels = { addLater: t("later") };

  useEffect(() => {
    const abortControl = new AbortController();
    window.addEventListener(
      "message",
      (ev) => {
        const { data } = ev;
        msgSchema
          .validate(data)
          .then(
            ({ txId: recievedTxId, result, method, uniqueID, cardMask }) => {
              if (txId === recievedTxId) {
                console.log("transaction successful");
                onFinished && onFinished();
              }
              console.log({ txId, recievedTxId, result, method });
            }
          )
          .catch((err) => {
            // console.error(err);
          });
      },
      { signal: abortControl.signal }
    );
    return () => abortControl.abort();
  }, [txId]);
  if (!tokenPageUrl) return <CenteredSpinner />;
  return (
    <>
      <iframe className="iframe" src={tokenPageUrl} title="tokenPageUrl" />
      <IonRow className="flex justify-content-center align-items-center m-bottom-2">
        <IonText
          className="link clickable under_line"
          onClick={() => {
            onFinished && onFinished();
          }}
        >
          {labels.addLater}
        </IonText>
      </IonRow>
    </>
  );
};

export default AddCreditCardForm;
