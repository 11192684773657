import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonMenuToggle,
  IonLabel,
  IonNavLink,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonImg,
  IonPopover,
  useIonViewWillEnter,
} from "@ionic/react";

import "./RavKavChargingDropDown.scss";
import { ROUTES, VERSION } from "../../../../store/Globals";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import MainMenuDropDown from "../MainMenuDropDown";
import { useHistory } from "react-router";
const langConfigPath = "mainMenu.ravKavCharging.dropDown";

type RavKavChargingDropDown = {
  triggerId: string;
};
const RavKavChargingDropDown: React.FC<RavKavChargingDropDown> = ({
  triggerId,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <MainMenuDropDown triggerId={triggerId}>
      <IonItem
        className="item"
        button={true}
        onClick={() =>
          history.push({
            pathname: ROUTES.desktopPurchaseContract,
            search: "?step=0",
          })
        }
      >
        <IonLabel className="label text">
          {t(`${langConfigPath}.cardCharging.label`)}
        </IonLabel>
      </IonItem>
      <IonItem
        className="item"
        button={true}
        onClick={() =>
          history.push({
            pathname: ROUTES.desktopRemotePurchaseContract,
            search: "?step=0",
          })
        }
      >
        <IonLabel className="label text">
          {t(`${langConfigPath}.orderRemoteCharging.label`)}
        </IonLabel>
      </IonItem>
    </MainMenuDropDown>
  );
};

export default RavKavChargingDropDown;
