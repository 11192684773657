import { IonText } from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./ExpandedContractArea.scss";
import { RootState } from "../../../..";
import HistoryCard from "../../HistoryCard";
import ContractInfoCard from "../../../pop-ups/CancelAndRefundPopup/ContractInfoCard";
import { Contract } from "../../../../hooks/types";

const langConfigPath = "scanResultsCard.contracts.expanded";

type ExpandedContract = {
  expanded: boolean;
  contract: Contract;
  indexOnSC?: number;
  mobile?: boolean;
};

const ExpandedContract: React.FC<ExpandedContract> = ({
  expanded,
  contract,
  indexOnSC,
  mobile = false,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const cardHistory = useSelector((state: RootState) => state.nfc.card.history);
  const contractHistory = useMemo(
    () => cardHistory?.filter((ev) => ev.ContractIndex === indexOnSC),
    [cardHistory, indexOnSC]
  );
  const { t } = useTranslation();

  return (
    <div
      dir={appDirection}
      className="contract-card-expanded-area"
      data-is-mobile={mobile}
      hidden={!expanded}
    >
      {contractHistory && contractHistory.length >= 0 && (
        <div className="history-container">
          <IonText className="text section-header">
            {t(`${langConfigPath}.history`)}
          </IonText>
          <div className="history-cards-container">
            {contractHistory.map((ev) => (
              <HistoryCard
                key={ev.ContractIndex + ev.EventDateTime}
                cardEv={ev}
                mobile={mobile}
                mini={!mobile}
              />
            ))}
          </div>
        </div>
      )}
      {mobile && <div className="line"></div>}

      <div className="extra-detilas-container">
        <IonText className="text section-header">
          {t(`${langConfigPath}.extraDetails`)}
        </IonText>
        <ContractInfoCard
          mobile={mobile}
          contract={contract.Contract}
          contractParent={contract}
        />
      </div>
    </div>
  );
};

export default ExpandedContract;
