import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import arrowDown from "../../../assets/icons-small-arrow-down.svg";
import arrowUp from "../../../assets/icons-small-arrow-up.svg";
import "./ContractCard.scss";
import ExpandedContract from "./ExpandedContractArea";
import { ortTimestampToDate, parsePrice } from "../../../hooks/useCard";
import { type Contract, type TContractDetails } from "../../../hooks/types";
import { useTranslation } from "react-i18next";
import { formatDateShort } from "../../../utils/helper-functions";

type TContractCard = {
  mobile?: boolean;
  contractDetails: TContractDetails;
  contract?: Contract;
  actionText?: string;
  indexOnSC?: number;
  loadGreen?: () => void;
  refund?: () => void;
  renew?: () => void;
  renewSum?: number;
  convert?: () => void;
};
const langConfigPath = "scanResultsCard.contracts.card";

const ContractCard: React.FC<TContractCard> = (props) => {
  const {
    mobile = false,
    contractDetails,
    indexOnSC,
    contract,
    loadGreen,
    refund,
    renew,
    convert,
  } = props;
  const actions = { loadGreen, refund, renew, convert };

  const appDirection = useSelector((state: RootState) => state.app.direction);
  const [expanded, setExpanded] = useState(false);
  const isExpandalbe = !!indexOnSC;
  const { t } = useTranslation("", {
    keyPrefix: langConfigPath,
  });
  const labels = {
    refund: t("refund"),
    renew: (price: number) => t("renew", { price }),
    convert: t("convert"),
    loadGreen: t("load"),
  };

  if (!contractDetails) return <div></div>;

  return (
    <IonCard
      dir={appDirection}
      className="contract-card"
      data-is-mobile={mobile}
    >
      <IonCardContent
        className="contract-card-content position-relative z-index-0"
        onClick={() => {
          if (isExpandalbe) {
            setExpanded((v) => !v);
          }
        }}
      >
        <div className="header-container flex-direction-column">
          <div className="flex">
            {isExpandalbe && (
              <IonIcon
                className="contract-state-icon"
                src={expanded ? arrowUp : arrowDown}
              />
            )}
            <IonText className="contract-card-header text">
              {contractDetails.Description || contractDetails.ETTStr}
            </IonText>
          </div>
          {contractDetails.Remark ? (
            <IonText className="text font-size-12 description">
              {contractDetails.Remark}
            </IonText>
          ) : null}
        </div>
        <IonRow>
          {actions.refund && (
            <IonButton
              className="button-secondary"
              onClick={(e) => {
                if (!actions.refund) return;
                e.stopPropagation();
                actions.refund();
              }}
              size="small"
              {...(mobile ? {} : {})}
            >
              {labels.refund}
            </IonButton>
          )}
          {actions.renew && !!contract?.ExtendedInfo?.Sum2Renew  && (
            <IonButton
              className="button-secondary btn-pango"
              onClick={(e) => {
                if (!actions.renew) return;
                e.stopPropagation();
                actions.renew();
              }}
            >
              {labels.renew(contract.ExtendedInfo.Sum2Renew / 100)}
            </IonButton>
          )}
          {actions.loadGreen && (
            <IonButton
              className="button-secondary"
              onClick={(e) => {
                if (!actions.loadGreen) return;
                e.stopPropagation();
                actions.loadGreen();
              }}
            >
              {labels.loadGreen}
            </IonButton>
          )}
          {actions.convert && (
            <IonButton
              className="button-secondary"
              onClick={(e) => {
                if (!actions.convert) return;
                e.stopPropagation();
                actions.convert();
              }}
            >
              {labels.convert}
            </IonButton>
          )}
        </IonRow>
      </IonCardContent>
      {isExpandalbe && contract && (
        <ExpandedContract
          expanded={expanded}
          mobile={mobile}
          contract={contract}
          indexOnSC={indexOnSC}
        />
      )}
      <ContractFooter contract={contractDetails} />
    </IonCard>
  );
};

export default ContractCard;
const ContractFooter = ({ contract }: { contract: TContractDetails }) => {
  const { t } = useTranslation("", {
    keyPrefix: langConfigPath,
  });
  const lables = {
    summedValue: t("summedValue"),
    profile: t("profile"),
  };
  const { first, second } = (() => {
    if (contract.SpecificContractData.Units) {
      const units = contract.SpecificContractData.Units;
      const priceStr = parsePrice(units);
      const first = units ? `${lables.summedValue} ${priceStr} ₪` : "";
      const second = `${lables.profile} ${contract.ProfileStr}`;
      return { first, second };
    }
    if (contract.SpecificContractData.FromDate) {
      const first = contract.ETTStr;
      const { FromDate, ToDate } = contract.SpecificContractData ?? {};
      if (!FromDate || !ToDate) return { first, second: "" };

      const startDate = formatDateShort(ortTimestampToDate(FromDate));
      const endDate = formatDateShort(ortTimestampToDate(ToDate));
      const second = `${startDate} - ${endDate}`;
      return { first, second };
    }
    return { first: "", second: "" };
  })();

  return (
    <div className="date-container">
      <IonText className="date text">{first}</IonText>
      <IonText className="date text">{second}</IonText>
    </div>
  );
};
