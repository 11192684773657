import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonHeader,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import TopMenu from "./components/TopMenu";
import SideMenuMobile from "./components/TopMenu/mobile-menus/SideMenuMobile";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./store/ConfigureStore";
import PersonalAreaSelectionPage from "./pages/mobile/PersonalAreaSelectionPage";
import { ROUTES, STORAGE_KEYS } from "./store/Globals";
import MainMenu from "./components/MainMenu";
import "./App.scss";
import SearchResultsPageDekstop from "./pages/dekstop/SearchResultsPage";
import { RootState } from ".";
import SearchResultsPageMobile from "./pages/mobile/SearchResultsPage";
import OnBoardingPageMobile from "./pages/mobile/OnBoardingPage";
import { StorageService } from "./services/LocalStorageService";
import {
  changeAppDirection,
  changeAppLoaded,
  changeSdkReceivedEventData,
  changeShowOnBoarding,
  changeVendor,
} from "./store/action-creators/App";
import { useEffect } from "react";
import LoginPageDesktop from "./pages/dekstop/LogInPage";
import LoginPageMobile from "./pages/mobile/LoginPage";
import RegisterPageDesktop from "./pages/dekstop/RegisterPage";
import RegisterPageMobile from "./pages/mobile/RegisterPage";
import NfcOrderPageDesktop from "./pages/dekstop/nfc-screens/NfcOrderPage";
import NfcReadPageDesktop from "./pages/dekstop/nfc-screens/NfcReadPage";
import NfcReadPageMobile from "./pages/mobile/NfcReadPage";
import { getPlatforms } from "@ionic/core";
import CancelAndRefundPage from "./pages/dekstop/CancelAndRefundPage";
import ProfilesPageDesktop from "./pages/dekstop/ProfilesPage";
import ScanResultsPageDesktop from "./pages/dekstop/ScanResultsPage";
import ExchangeProfilePageDesktop from "./pages/dekstop/ExchangeProfilePage";
import {
  checkIfEventIsOfTypeSdkEvent,
  isHiddenOnMobile,
} from "./utils/helper-functions";
import ProfilesPageMobile from "./pages/mobile/ProfilesPage";
import CreateProfilePageMobile from "./pages/mobile/CreateProfilePage";
import CreateProfilePageDesktop from "./pages/dekstop/CreateProfilePage";
import ReUplaodFilesPageMobile from "./pages/mobile/ReUploadFilesPage";
import ReUplaodFilesPageDesktop from "./pages/dekstop/ReUploadFilesPage";
import ScanResultsPageMobile from "./pages/mobile/ScanResultsPage";
import CancelAndRefundPageMobile from "./pages/mobile/CancelAndRefundPage";
import ExchangeProfilePageMobile from "./pages/mobile/ExchangeProfilePage";
import EditUserInfoPageMobile from "./pages/mobile/EditUserInfoPage";
import ChargeHistoryPageMobile from "./pages/mobile/ChargeHistoryPage";
import RavkavCardsPageMobile from "./pages/mobile/RavkavCardsPage";
import PaymentMethodPageMobile from "./pages/mobile/PaymentMethodPage";
import SupportPageMobile from "./pages/mobile/SupportPage";
import FAQPageMobile from "./pages/mobile/FAQPage";
import PersonalDetailsDeskTop from "./pages/dekstop/PersonalDetailsPage";
import TermsPageDekstop from "./pages/dekstop/TermsPage";
import ChargeHistoryPageDesktop from "./pages/dekstop/ChargeHistoryPage";
import DesktopRavkavCardsPageDesktop from "./pages/dekstop/DesktopRavkavCardsPage/";
import PurchaseContractMobile from "./pages/mobile/PurchaseContractPage";
import OrderCardPageDesktop from "./pages/dekstop/OrderCardPage";
import PurchaseContractDesktop from "./pages/dekstop/PurchaseContractPage";
import PurchaseContractRemoteDesktop from "./pages/dekstop/PurchaseContractRemotePage";
import PurchaseContractRemoteMobile from "./pages/mobile/PurchaseContractRemotePage";
import CardServiceTest from "./pages/dekstop/TestCardService";
import OrderCardPageMobile from "./pages/mobile/OrderCardPage";
import { VendorFooter } from "./components/VendorFooter";
import DesktopFAQPage from "./pages/dekstop/DesktopFAQPage";
import DesktopSupportPage from "./pages/dekstop/DesktopSupportPage";
import "./App.scss";
import { PaymentSuccessLanding } from "./components/PaymentSuccessLanding";
import LoginErrorPopup from "./components/pop-ups/LoginErrorPopup";
import LoginOTPPopup from "./components/pop-ups/LoginOTPPopup";
import PaymentMethodPage from "./pages/dekstop/PaymentMethodPage";
import ProfileApprovedPopup from "./components/pop-ups/ProfileApprovedPopup";
import NfcDeleteContractSuccessPopup from "./components/pop-ups/NfcDeleteContractSuccess";
import NfcCardBlockedPopup from "./components/pop-ups/NfcCardBlocked";
import NfcLoadContractSuccessPopup from "./components/pop-ups/NfcLoadContractSuccess";
import MobileWhiteLabelRedirectPopup from "./components/pop-ups/MobileWhiteLabelRedirectPopup";
import classNames from "classnames";
import { getI18n, useTranslation } from "react-i18next";
import { changeLangApi } from "./services/customApi/fetch";
import HomeMobile from "./pages/HomeMobile";

setupIonicReact();

const App: React.FC = () => {
  const appState = useSelector((state: RootState) => state.app);
  const isVendor = appState.isVendor;
  const vendor = appState.vendor;
  const dispatch = useDispatch();
  const isMobile = isHiddenOnMobile();
  const { i18n } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const checkOnBoarding = () => {
      const result = StorageService.exists(STORAGE_KEYS.onBoarding);
      dispatch(changeShowOnBoarding(!result));
    };

    const checkLang = () => {
      const lang = params.get("lang");
      if (lang) {
        console.log({ lang });
        if (lang === "he" || lang === "en" || lang === "ar" || lang === "ru") {
          changeLangApi(lang);
          if (lang === "ar" || lang === "he")
            dispatch(changeAppDirection("rtl"));
          else dispatch(changeAppDirection("ltr"));
          StorageService.set(STORAGE_KEYS.language, lang);
          getI18n().changeLanguage(lang);
        }
        return;
      }
      // if lang already in state no need to check storage
      // if (appState.language) return;
      const savedLang = StorageService.get(STORAGE_KEYS.language);
      if (savedLang) {
        changeLangApi(savedLang);
        if (savedLang === "ar" || savedLang === "he") {
          dispatch(changeAppDirection("rtl"));
        } else {
          dispatch(changeAppDirection("ltr"));
        }
        getI18n().changeLanguage(savedLang, (err, t) => {
          if (err) return console.log("something went wrong loading", err);
          console.log(t("key"));
        });
      }
    };
    const checkVendor = () => {
      // if vendor specified in url change it

      if (!isMobile) return;
      const vendor = params.get("vendor");
      console.log({ vendor });

      if (vendor) {
        StorageService.set(STORAGE_KEYS.vendor, vendor);
        dispatch(changeVendor(vendor));
        return;
      }
      // if vendor already in state no need to check storage
      if (appState.isVendor) return;

      // if vendor not in state check storage
      const savedVendor = StorageService.get(STORAGE_KEYS.vendor);

      if (savedVendor) dispatch(changeVendor(savedVendor));
    };
    checkOnBoarding();
    checkVendor();
    checkLang();
    dispatch(changeAppLoaded(true));
  }, []);

  useEffect(() => {
    history.listen((location) => {
      console.log("pathname", location.pathname);
      console.log("search", location.search);
    });
    if (window) {
      // window.webkit = { messageHandlers: {} };
      window.GetActionFromApp = (actionName: string, data: any) => {
        console.log(actionName, data);

        if (actionName && checkIfEventIsOfTypeSdkEvent(actionName)) {
          dispatch(changeSdkReceivedEventData({ action: actionName, data }));
        }
      };
    }
  }, []);
  return (
    <IonApp
      className={classNames("app-wrapper", vendor, {
        "is-vendor": isVendor,
        "font-he": i18n.language === "he",
        "font-ar": i18n.language === "ar",
        "font-en": i18n.language === "en",
        "font-ru": i18n.language === "ru",
      })}
    >
      <IonReactRouter history={history}>
        {(!isVendor || vendor === "responsive") &&
          history.location.pathname !== "/paymentSuccess" && (
            <IonHeader>
              <TopMenu />
              <MainMenu />
            </IonHeader>
          )}

        <SideMenuMobile
          menuId="mobile-side-menu"
          contentId="mobile-side-menu-content"
        />
        {/* <LoginForm mobile={isMobile} /> */}
        <LoginErrorPopup mobile={isMobile} />
        <ProfileApprovedPopup mobile={isMobile} />
        <LoginOTPPopup mobile={isMobile} />
        <NfcDeleteContractSuccessPopup mobile={isMobile} />
        <NfcLoadContractSuccessPopup mobile={isMobile} />
        <MobileWhiteLabelRedirectPopup mobile />
        <NfcCardBlockedPopup mobile={isMobile} />
        <IonRouterOutlet dir={appState.direction} id="mobile-side-menu-content">
          <Route exact path="/paymentSuccess">
            <PaymentSuccessLanding />
          </Route>
          {!isMobile ? (
            // desktop routes
            <>
              <Route exact path="/test">
                <CardServiceTest />
              </Route>
              <Route exact path={ROUTES.home}>
                <Home />
              </Route>
              <Route exact path={ROUTES.desktopSearchResults}>
                <SearchResultsPageDekstop />
              </Route>
              <Route exact path={ROUTES.desktopLogIn}>
                <LoginPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopRegister}>
                <RegisterPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopNfcOrder}>
                <NfcOrderPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopOrderCard}>
                <OrderCardPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopPurchaseContract}>
                <PurchaseContractDesktop />
              </Route>
              <Route exact path={ROUTES.desktopRemotePurchaseContract}>
                <PurchaseContractRemoteDesktop />
              </Route>
              <Route exact path={ROUTES.desktopNfcRead}>
                <NfcReadPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopTerms}>
                <TermsPageDekstop />
              </Route>
              <Route exact path={ROUTES.desktopChargeHistory}>
                <ChargeHistoryPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopRavkavCards}>
                <DesktopRavkavCardsPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopCancelAndRefund}>
                <CancelAndRefundPage />
              </Route>
              <Route exact path={ROUTES.desktopScanResults}>
                <ScanResultsPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopExchangeProfile}>
                <ExchangeProfilePageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopEditUserInfo}>
                <PersonalDetailsDeskTop />
              </Route>
              <Route exact path={ROUTES.desktopProfiles}>
                <ProfilesPageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopCreateNewProfile}>
                <CreateProfilePageDesktop />
              </Route>
              <Route exact path={ROUTES.desktopSmsNotApprovedDocsPopUp}>
                <ProfilesPageDesktop />
              </Route>

              <Route exact path={ROUTES.desktopPaymentMethod}>
                <PaymentMethodPage />
              </Route>

              <Route exact path={ROUTES.desktopFAQ}>
                <DesktopFAQPage />
              </Route>

              <Route exact path={ROUTES.desktopSupport}>
                <DesktopSupportPage />
              </Route>

              <Route exact path={ROUTES.desktopUploadFiles}>
                <ReUplaodFilesPageDesktop />
              </Route>
            </>
          ) : (
            //mobile routes
            <>
              <Route exact path={ROUTES.home}>
                <HomeMobile />
              </Route>

              <Route exact path={ROUTES.onBoarding}>
                {appState.onBoarding ? (
                  <OnBoardingPageMobile />
                ) : (
                  <Redirect to={ROUTES.home} />
                )}
              </Route>

              <Route exact path={ROUTES.personalAreaSelection}>
                <PersonalAreaSelectionPage />
              </Route>

              <Route exact path={ROUTES.mobileSearchResults}>
                <SearchResultsPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileRemotePurchaseContract}>
                <PurchaseContractRemoteMobile />
              </Route>
              <Route exact path={ROUTES.mobileLogIn}>
                <LoginPageMobile />
              </Route>

              <Route exact path={ROUTES.mobileRegister}>
                <RegisterPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileNfcRead}>
                <NfcReadPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileProfiles}>
                <ProfilesPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileCreateNewProfile}>
                <CreateProfilePageMobile />
              </Route>
              <Route exact path={ROUTES.mobileUploadFiles}>
                <ReUplaodFilesPageMobile />
              </Route>
              <Route exact path={ROUTES.mobilePurchaseContract}>
                <PurchaseContractMobile />
              </Route>
              <Route exact path={ROUTES.mobileScanResults}>
                <ScanResultsPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileCancelAndRefund}>
                <CancelAndRefundPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileExchangeProfile}>
                <ExchangeProfilePageMobile />
              </Route>
              <Route exact path={ROUTES.mobileOrderCard}>
                <OrderCardPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileEditUserInfo}>
                <EditUserInfoPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileChargeHistory}>
                <ChargeHistoryPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileRavkavCards}>
                <RavkavCardsPageMobile />
              </Route>
              <Route exact path={ROUTES.mobilePaymentMethod}>
                <PaymentMethodPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileSupport}>
                <SupportPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileFAQ}>
                <FAQPageMobile />
              </Route>
              <Route exact path={ROUTES.mobileSmsNotApprovedDocsPopUp}>
                <ProfilesPageMobile />
              </Route>
              <Route exact path="/">
                {getPlatforms().indexOf("mobileweb") !== -1 ? (
                  <Redirect to="/" />
                ) : (
                  <Redirect to={ROUTES.mobileNfcRead} />
                )}
              </Route>
            </>
          )}
        </IonRouterOutlet>
        {appState.isVendor && <VendorFooter />}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
