import { useEffect, useState } from "react";
import { TContractType } from "../contracts/useContractsForLoading";
import { customGET, customPOST } from "../../services/customApi/fetch";
import { useSelector } from "react-redux";

type TSaveParams = {
  contract: { PredefinedCode: number; ETT: number; SellType: number };
  location?: CardLoadLocation;
  payment: { confirmation: string; expiration: string };
  cardNumber: number;
  profileId: number;
  notes?: string;
};

export const saveContractToGreenList = async (payload: TSaveParams) => {
  const rsp = await customPOST("card/addTogreenlist", payload);
  return await rsp.data;
};

type ContractTypesParams = {
  profileId?: number;
  cardImage?: Record<string, any>;
};

function getContractTypes(params: ContractTypesParams) {
  if (!params) return customGET("contracts/types");
  const { profileId, cardImage } = params;
  return customPOST("contracts/types", { profileId, cardImage });
}

export const useContractTypes = (ContractTypesParams: ContractTypesParams) => {
  const [contractTypes, setContractTypes] = useState<
    {
      id: TContractType;
      name: string;
    }[]
  >();
  const [isLoading, setIsLoading] = useState(false);
  const card = useSelector((state: any) => state.nfc.card);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!ContractTypesParams?.cardImage && !ContractTypesParams?.profileId)
      return;
    setIsLoading(true);
    getContractTypes(ContractTypesParams)
      .then(({ data }) => setContractTypes(data))
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [card, ContractTypesParams.cardImage, ContractTypesParams.profileId]);
  if (isLoading) return { isLoading, contractTypes: undefined };
  return { isLoading, contractTypes };
};

export const useTransportTypes = () => {
  const [transportTypes, setTransportTypes] = useState<
    {
      key: string;
      value: string;
    }[]
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    customGET("contracts/transportationTypes")
      .then(({ data }) => setTransportTypes(data))
      .catch(() => setError(false))
      .finally(() => setIsLoading(false));
  }, []);
  if (isLoading) return { isLoading, transportTypes: undefined };
  return { isLoading, transportTypes };
};

const cityListMode = {
  branches: 1,
  stores: 2,
  branchesAndStores: 3,
} as const;
type CityListMode = (typeof cityListMode)[keyof typeof cityListMode];

const getGreenListCities = async ({
  mode,
}: {
  mode: CityListMode;
}): Promise<GreenListCity[]> => {
  const response = await customPOST("contracts/greenListCities", {
    mode,
  });
  return response.data;
};
const getGreenListStations = async ({
  mode,
  cityId,
}: {
  mode: CityListMode;
  cityId: number;
}): Promise<CardLoadLocation[]> => {
  const response = await customPOST("contracts/greenListPlaces", {
    mode,
    cityId,
  });
  return response.data;
};

export type GreenListCity = {
  CityId: number;
  CityName: string;
};
export type CardLoadLocation = {
  PlaceId: number;
  CityId: number;
  PlaceType: number;
  PlaceName: string;
};

export const useGreenListCities = () => {
  const [cities, setCities] = useState<GreenListCity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getGreenListCities({ mode: cityListMode.branchesAndStores })
      .then((data) => setCities(data))
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, []);
  return { cities, isLoading, error };
};
export const useGreenListLocations = ({ city }: { city?: GreenListCity }) => {
  const [locations, setLocations] = useState<CardLoadLocation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!city?.CityId) return;
    setIsLoading(true);
    getGreenListStations({
      mode: cityListMode.branchesAndStores,
      cityId: city.CityId,
    })
      .then((data) => setLocations(data))
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [city]);
  return { locations, isLoading, error };
};
