import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { useTempCardPayload } from "../nfc/UseNfc";
import { TContractDetails } from "../types";
import { customPOST } from "../../services/customApi/fetch";

export const contractTypes = {
  storedValue: 1,
  monthly: 2,
  daily: 3,
  train: 4,
  singleRide: 5,
  yearly: 6,
  semesterly: 7,
} as const;
export type TContractType = (typeof contractTypes)[keyof typeof contractTypes];

export type TContractForLoadingParams = TGetContractForLoadingParams & {
  isRemote?: boolean;
};
export type TGetContractForLoadingParams = {
  contractType?: number;
  cardImage?: any;
  profileId?: number;
  transportationType?: number;
  enabled?: boolean;
};
const fetchAvailableContract = async (
  params: TGetContractForLoadingParams
): Promise<TContractDetails[]> => {
  const rsp = await customPOST("contracts", params);
  return await rsp.data;
};

export const useContractsForLoading = ({
  contractType,
  isRemote,
  profileId,
  transportationType,
  enabled,
}: TContractForLoadingParams) => {
  const cardData = useSelector((state: RootState) => state.nfc.card.data);
  const { tempCardPayload } = useTempCardPayload();

  const [availableContracts, setAvailableContracts] =
    useState<TContractDetails[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  useEffect(() => {
    if (!enabled) return;
    if (!contractType) return;
    const cardImage = cardData ?? tempCardPayload;
    if (!profileId && !cardImage) return;

    setIsLoading(true);
    fetchAvailableContract({
      cardImage: isRemote ? tempCardPayload : cardImage,
      contractType,
      profileId,
      transportationType,
    })
      .then((contracts) => {
        if (Array.isArray(contracts)) setAvailableContracts(contracts);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, [contractType, transportationType]);

  return { availableContracts, isLoading, error };
};
