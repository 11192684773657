import { IonButton, IonList } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "../form-components/FormInput";
import "./ChangeCardNameForm.scss";

const langConfigPath = "forms.changeCardName";

type TChangeCardNameForm = {
  mobile?: boolean;
  cardName?: string;
  onSubmit?: (name: string) => void;
};

const ChangeCardNameForm: React.FC<TChangeCardNameForm> = ({
  mobile = false,
  cardName = "",
  onSubmit: onFinish,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  const { t } = useTranslation();

  const errorMessages = {
    cardName: t(`${langConfigPath}.cardNameField.errorMessage`),
  };

  const schema = yup.object().shape({
    cardName: yup.string().required(errorMessages.cardName),
  });

  const methods = useForm({
    defaultValues: { cardName },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: any) => {
    onFinish?.(data.cardName);
  };

  return (
    <div className="form" data-is-mobile={mobile} dir={appDirection}>
      <FormProvider {...methods}>
        <form
          noValidate
          className="change-card-name-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <IonList className="form-item-list">
            <FormInput
              showV={false}
              name="cardName"
              label={t(`${langConfigPath}.cardNameField.placeholder`)}
            />
          </IonList>

          <IonButton className="button-primary" type="submit">
            {t(`${langConfigPath}.submitButton.label`)}
          </IonButton>
        </form>
      </FormProvider>
    </div>
  );
};

export default ChangeCardNameForm;
