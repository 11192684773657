import { useMemo, useState } from "react";
import { IonButton, IonSelect, IonSelectOption } from "@ionic/react";
import {
  CardLoadLocation,
  GreenListCity,
  useGreenListCities,
  useGreenListLocations,
} from "../../../../hooks/contracts/useContractGreenList";
import "./GreenListLocation.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type GreenListLocationProps = {
  mobile?: boolean;
  nextStep: (location?: CardLoadLocation) => void;
};

export const SelectGreenListLocation: React.FC<GreenListLocationProps> = (
  props
) => {
  const { nextStep, mobile } = props;
  const [city, setCity] = useState<GreenListCity>();
  const [location, setLocation] = useState<CardLoadLocation>();
  const { cities, isLoading: isCitiesLoading } = useGreenListCities();
  const { isLoading, locations } = useGreenListLocations({ city });
  const [isLocationNeeded, setIsLocationNeeded] = useState(true);
  const { t } = useTranslation("", {
    keyPrefix: "stepsSections.selectLocation",
  });
  const labels = useMemo(() => {
    return {
      city: t("city"),
      location: t("location"),
      continue: t("continue"),
      locationNotNeeded: t("locationNotNeeded"),
      locationNeeded: t("locationNeeded"),
    };
  }, [t]);

  return (
    <div className="green-select-location">
      <div>
        <div className="flex justify-content-center gap20 padding-10 align-center">
          <span className="flex gap10">
            <input
              id="locationNotNeeded"
              type="radio"
              name="location"
              onChange={(e) => setIsLocationNeeded(!e.target.checked)}
            />
            <label className="text" htmlFor="locationNotNeeded">
              {labels.locationNotNeeded}
            </label>
          </span>
          <span className="flex gap10">
            <input
              id="locationNeeded"
              type="radio"
              defaultChecked
              name="location"
              onChange={(e) => setIsLocationNeeded(e.target.checked)}
            />
            <label className="text" htmlFor="locationNeeded">
              {labels.locationNeeded}
            </label>
          </span>
        </div>
        <IonSelect
          placeholder={labels.city}
          onIonChange={(e) => setCity(e.detail.value)}
          disabled={!isLocationNeeded}
          className="green-location-select"
        >
          {!isCitiesLoading &&
            cities?.map((city) => (
              <IonSelectOption key={city.CityId} value={city}>
                {city.CityName}
              </IonSelectOption>
            ))}
        </IonSelect>
        {city && (
          <IonSelect
            placeholder={labels.location}
            onIonChange={(e) => setLocation(e.detail.value)}
            disabled={!isLocationNeeded}
            className="green-location-select"
          >
            {!isLoading &&
              locations?.map((location) => (
                <IonSelectOption key={location.PlaceId} value={location}>
                  {location.PlaceName}
                </IonSelectOption>
              ))}
          </IonSelect>
        )}
      </div>
      <button
        disabled={isLocationNeeded && (!city || !location)}
        onClick={() => nextStep(location)}
        className={classNames({
          "select-location-btn": true,
          disabled: isLocationNeeded && (!city || !location),
        })}
      >
        {labels.continue}
      </button>
    </div>
  );
};
