import { useEffect, useState } from "react";
import { customGET } from "../services/customApi/fetch";

export type TProfile = {
  id: number;
  name: string;
};
const getProfiles = async () => {
  const rsp = await customGET("profiles");
  return rsp.data as TProfile[];
};
export const useAllProfiles = () => {
  const [profiles, setProfiles] = useState<TProfile[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getProfiles()
      .then((v) => {
        setProfiles(v);
      })
      .catch((err) => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return {
    ...(isLoading
      ? { isLoading, profiles: undefined, isError }
      : { isLoading, profiles, isError }),
  };
};
