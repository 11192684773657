import { IonButton, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import { TSavedRavkavCard, useRenameCard } from "../../../hooks/useCard";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS } from "../../../store/Globals";
import ChangeCardNameForm from "../../forms/ChangeCardNameForm";
import Popup from "../Popup";
import "./ChangeCardNamePopup.scss";

type TChangeCardNamePopup = {
  mobile?: boolean;
  card?: TSavedRavkavCard;
  onFinish?: () => void;
};
const langConfigPath = "popUps.changeCardName";

const ChangeCardNamePopup: React.FC<TChangeCardNamePopup> = ({
  mobile,
  card,
  onFinish: onFinishPopup,
}) => {
  const user = useSelector((state: RootState) => state.user.GetUserData.data);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clear = () => {
    dispatch(changePopup(POPUPS.clear));
  };
  const onCancel = () => {
    clear();
  };
  const onFinish = () => {
    onFinishPopup?.();
    clear();
  };
  const { isRenaming, rename } = useRenameCard({ onFinish });

  return (
    <Popup
      popUpId={POPUPS.changeCardName}
      mobile={mobile}
      cssClass="change-card-name-pop-up-container"
    >
      <div className="top-contrast"></div>
      <div
        className="pop-up change-card-name-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonText className="pop-up-header text">
          {t(`${langConfigPath}.header`)}
        </IonText>

        <ChangeCardNameForm
          mobile={mobile}
          cardName={card?.name}
          onSubmit={(name) => {
            if (!card) return;
            if (!user) return;
            rename({ cardId: card?._id, name });
          }}
        />

        <IonButton
          onClick={onCancel}
          disabled={isRenaming}
          className="button-secondary pop-up-button"
        >
          {t(`${langConfigPath}.cancelButton.label`)}
        </IonButton>
      </div>
    </Popup>
  );
};

export default ChangeCardNamePopup;
