import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../..";
import { changePopup } from "../../store/action-creators/App";
import {
  clearCardPayloadInfo,
  clearNfcError,
  saveNfcAction,
} from "../../store/action-creators/Nfc";
import { POPUPS, ROUTES } from "../../store/Globals";
import { isHiddenOnMobile } from "../../utils/helper-functions";
import { nfcActions } from "../types";
import { useNfcSocket } from "./UseNfcSocket";
import { useVendorSDK } from "./useVendorSDK";
import { customDELETE, customPOST } from "../../services/customApi/fetch";

const deleteProfileRequest = async (reqId: string) => {
  const rsp = await customDELETE(`profiles/deleteProfileRequest/${reqId}`);
  return rsp.data;
};

const j4 = (paymentId: string) => {
  return customPOST("payments/capture", { paymentId });
};

export const useNfc = () => {
  const history = useHistory();
  const nfcState = useSelector((state: RootState) => state.nfc);
  const appState = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const isMobile = isHiddenOnMobile();

  const isVendor = appState.isVendor;
  // const isVendor = false;
  const vendorSDK = useVendorSDK();
  const socket = useNfcSocket();

  const init = () => {
    socket.init();
  };
  // useEffect(() => {
  //   init();
  // }, []);
  useEffect(() => {
    const {
      getCardInfo,
      loadContract,
      refundContract,
      exchangeImprisoned,
      loadProfile,
    } = isVendor ? vendorSDK : socket;
    if (appState.isLoadingParams) return;
    const cardStatus = nfcState.card.status || nfcState.active;
    console.log({ nextAction: nfcState.nextAction });
    const { isFinished, isLoading, routeOnFinish } = nfcState.nextAction;

    const { error } = nfcState.lastAction;
    const isIos = !!window?.webkit?.messageHandlers;
    // const isIos = true;
    if (isLoading && !cardStatus && !isIos) {
      dispatch(changePopup(POPUPS.nfcCardError));
      return;
    }
    const errorPopus = [
      POPUPS.nfcCardError,
      POPUPS.nfcReadFailed,
      POPUPS.nfcWriteFailed,
    ];
    if (errorPopus.includes(appState.popUp.name as any)) {
      console.log("popup open: " + appState.popUp.name);

      return;
    }
    if (isLoading) {
      console.log("loading");
      return;
    }
    if (isFinished) {
      console.log("finished");
      if (error) {
        if (error === "blocked") {
          // card blocked popup
          dispatch(changePopup(POPUPS.nfcCardBlocked));
          return;
        } else if (nfcState.lastAction.action === nfcActions.readCard)
          dispatch(changePopup(POPUPS.nfcReadFailed));
        else dispatch(changePopup(POPUPS.nfcWriteFailed));

        dispatch(clearNfcError());
        return;
      }
      // action finished and successful
      if (nfcState.nextAction.action === nfcActions.refundContract) {
        dispatch(changePopup(POPUPS.nfcRefundSuccess));
        return;
      }
      if (nfcState.nextAction.action === nfcActions.loadContract) {
        if (nfcState.nextAction.data.topcardId)
          j4(nfcState.nextAction.data.topcardId);
        dispatch(
          changePopup(POPUPS.nfcContractLoaded, {
            contract: nfcState.nextAction.data.contract,
          })
        );
        return;
      }
      if (nfcState.nextAction.action === nfcActions.readCard) {
        console.log("read card success");
        if (routeOnFinish) history.push(routeOnFinish);
        else
          history.push(
            isMobile ? ROUTES.mobileScanResults : ROUTES.desktopScanResults
          );
        dispatch(
          saveNfcAction({ action: nfcActions.readCard, route: undefined })
        );
        return;
      }

      dispatch(saveNfcAction({ action: nfcActions.readCard }));
      return;
    }
    if (!cardStatus && !isIos) {
      console.log("no card");
      return;
    }
    const { action } = nfcState.nextAction;
    console.log("taking nfc action");

    try {
      /** take action */
      if (appState.popUp.name) dispatch(changePopup(POPUPS.clear));
      if (action === nfcActions.readCard || action === nfcActions.none)
        getCardInfo();
      if (action === nfcActions.loadContract) {
        loadContract(nfcState.nextAction.data);
      }
      if (action === nfcActions.refundContract)
        refundContract(nfcState.nextAction.data);
      if (action === nfcActions.exchangeImprisoned)
        exchangeImprisoned(nfcState.nextAction.data);
      if (action === nfcActions.loadProfile) {
        if (nfcState.nextAction.data.reqId) {
          loadProfile(nfcState.nextAction.data.reqId);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [nfcState, appState.isLoadingParams]);

  useEffect(() => {
    if (
      nfcState.nextAction.action === nfcActions.loadProfile &&
      nfcState.nextAction.isFinished &&
      !nfcState.lastAction.error
    ) {
      const { reqId } = nfcState.nextAction.data;
      deleteProfileRequest(reqId);
    }
  }, [nfcState.nextAction]);

  return {
    init,
    currentAction: nfcState.nextAction,
    cardStatus: nfcState.card.status || nfcState.active,
    cardDetails: nfcState.card,
    serviceStatus: nfcState.socket.serviceConnected,
  };
};

export const useTempCardPayload = () => {
  const tempCardPayload = useSelector(
    (state: RootState) => state.user.savedCardPayload
  );
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (tempCardPayload) dispatch(clearCardPayloadInfo());
    };
  }, [dispatch, tempCardPayload]);

  return { tempCardPayload };
};
