import { IonButton, IonText } from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./uploadDocuments.scss";
import {
  GetProfilesResponse,
  ProfileDocForRequest,
  ProfileStatus,
} from "../../../services/models/profile";
import { RootState } from "../../..";
import UploadDocumentCard from "../../UploadDocumentCard";
import { POPUPS } from "../../../store/Globals";
import { changePopup } from "../../../store/action-creators/App";
import { customGET, customPOST } from "../../../services/customApi/fetch";

const langConfigPath = "stepsSections.uploadDocuments";

type ReUploadDocumentsProps = {
  mobile?: boolean;
  nextStep?: (reqId: string) => void;
};
type TProfileFile = {
  Description: string;
  FileName: string;
  IsDigitalCertificateAllowed: boolean;
  IsRequired: boolean;
  ProfileId: number;
};
type SelectedFiles = {
  id: string;
  data: ProfileDocForRequest;
  isMandatory: boolean;
};

type TProfileInfo = GetProfilesResponse & {
  files: TProfileFile[];
};

const getProfileFiles = async (profileId: string) => {
  const rsp = await customGET(`profiles/files/${profileId}`);
  return rsp.data;
};

const updateProfileRequest = async (payload: any) => {
  return customPOST("profiles/updateFilesProfileReq", { payload });
};

const useSelectedProfile = () => {
  const selectedProfile = useSelector(
    (state: RootState) => state.profile.selectedProfile
  );
  const [profile, setProfile] = useState<TProfileInfo>();
  useEffect(() => {
    if (selectedProfile)
      getProfileFiles(selectedProfile.request_type).then(({ files }) => {
        setProfile({
          files,
          ...selectedProfile,
        });
      });
  }, [selectedProfile]);

  return { profile };
};

const useSelectFiles = () => {
  const [selectedFiles, setSelectedFiles] = useState<SelectedFiles[]>([]);

  const fileExists = useCallback(
    (id: string) => selectedFiles.some((v) => v.id === id),
    [selectedFiles]
  );
  const upsertFile = (
    id: string,
    file: ProfileDocForRequest,
    isMandatory: boolean
  ) => {
    setSelectedFiles((prev) => {
      const item = prev.find(({ id: inId }) => inId === id);
      if (!item) return [...prev, { id, data: file, isMandatory }];
      item.data = file;
      return [...prev];
    });
  };

  return { selectedFiles, fileExists, upsertFile };
};

const ReUploadDocuments: React.FC<ReUploadDocumentsProps> = ({
  mobile,
  nextStep,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { profile } = useSelectedProfile();
  const dispatch = useDispatch();

  const { fileExists, selectedFiles, upsertFile } = useSelectFiles();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const handleSendButton = async () => {
    if (!profile) return;
    const docs = selectedFiles.map((doc) => doc.data);
    const validOldFiles = profile.docs.filter((doc) =>
      docs.every((updatedDoc) => updatedDoc.doc_type !== doc.doc_type)
    );
    console.log({ docs, validOldFiles });
    if (!profile) return;
    updateProfileRequest({
      ...profile,
      status: ProfileStatus.returned_request,
      docs: [...validOldFiles, ...docs],
    })
      .then((rsp) => {
        dispatch(changePopup(POPUPS.documentsSentSuccess));
        nextStep && nextStep(rsp.data._id);
      })
      .catch((err) => dispatch(changePopup(POPUPS.documentsSentError)));
  };

  const handleFileSelected = useCallback(
    (
      data: string,
      id: string,
      file: TProfileFile,
      profile: TProfileInfo,
      originalFileName?: string
    ) => {
      const fileData = {
        profileId: profile.request_type,
        ortDocName: file.Description,
        url: data,
        doc_type: file.FileName,
        originalFileName,
        fileExtension:
          originalFileName?.split(".")[originalFileName?.split(".").length - 1],
      };
      upsertFile(id, fileData, file.IsRequired);
    },
    []
  );

  const isFileSelected = ({
    file,
    profile,
  }: {
    file: TProfileFile;
    profile: TProfileInfo;
  }) => {
    const id = profile.request_type + file.FileName;
    return fileExists(id);
  };

  const DocumentCard = ({
    file,
    profile,
  }: {
    file: TProfileFile;
    profile: TProfileInfo;
  }) => {
    const header = file.Description;
    const info =
      "" + (file.IsRequired ? " *" : "") + file.IsDigitalCertificateAllowed
        ? ` (${t("files.digital")})`
        : "";
    const id = profile.request_type + file.FileName;
    const savedFile = profile.docs.find((v) => v.doc_type === file.FileName);

    return (
      <UploadDocumentCard
        header={header}
        state={savedFile ? (savedFile.status ? "success" : "error") : "regular"}
        info={savedFile ? savedFile.comments : info}
        fileSelected={isFileSelected({ file, profile })}
        onFileSelected={(data, originalFileName) => {
          handleFileSelected(data, id, file, profile, originalFileName);
        }}
        mobile={mobile}
      />
    );
  };

  const isAllrequiredFilesUploaded = useMemo(() => {
    return profile?.files.every((f) => {
      const savedFile = profile.docs.find((v) => v.doc_type === f.FileName);
      if (f.IsRequired)
        return (
          fileExists(profile.request_type + f.FileName) || savedFile?.status
        );
      return true;
    });
  }, [profile, fileExists, selectedFiles]);

  return (
    <div
      className="upload-documents width-100 position-relative"
      dir={appDirection}
    >
      {profile && (
        <>
          <div className="upload-documents-header">
            <div className="line"></div>
            <IonText className="text">{profile.profileName}</IonText>
            <div className="line"></div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {profile.files?.map((file) => (
              <DocumentCard key={file.FileName} file={file} profile={profile} />
            ))}
          </div>
        </>
      )}

      <div className="flex justify-content-center width-100">
        <IonButton
          disabled={!isAllrequiredFilesUploaded}
          className="main-blue-buttons space-down"
          onClick={handleSendButton}
        >
          {t(`button.label`)}
        </IonButton>
      </div>
    </div>
  );
};

export default ReUploadDocuments;
