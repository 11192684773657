import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import DocumentsSentErrorPopup from "../../../components/pop-ups/DocumentsSentErrorPopup";
import DocumentsSentSuccessPopup from "../../../components/pop-ups/DocumentsSentSuccessPopup";
import ReUploadDocuments from "../../../components/StepsCardSections/ReUploadDocuments";
import { ROUTES } from "../../../store/Globals";

import "./reUploadFilesPage.scss";
import { BackIcon } from "../../../components/BackIcon";

const langConfigPath = "pages.desktop.reUploadFiles";

const ReUplaodFilesPageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const history = useHistory();
  const { t } = useTranslation();

  const handleBackButton = () => {
    history.push(ROUTES.desktopProfiles);
  };

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div
          className="desktop-reupload-files-page height-100"
          style={{ width: "600px" }}
        >
          <div className="desktop-title-container">
            <div className="desktop-header-container width-100 position-relative">
              <BackIcon onClick={handleBackButton} />

              <IonText className="desktop-header text absolute-center main-title">
                {t(`${langConfigPath}.header`)}
              </IonText>
            </div>
            <div className="sub-header-container">
              <IonText className="desktop-sub-header text">
                {t(`${langConfigPath}.subHeader`)}
              </IonText>
            </div>
          </div>

          <IonCard className="desktop-page-card ">
            <IonCardContent className="desktop-card-content reupload-files-card-content">
              <DocumentsSentSuccessPopup />
              <DocumentsSentErrorPopup
                info={t(`${langConfigPath}.errorPopupText`)}
              />
              <ReUploadDocuments />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReUplaodFilesPageDesktop;
