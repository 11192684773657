import { IonContent, IonList, IonPopover } from "@ionic/react";

import hebLangIcon from "../../../../assets/icons-language-heb-active.svg";
import rusLangIcon from "../../../../assets/icons-language-rus-active.svg";
import engLangIcon from "../../../../assets/icons-language-eng-active.svg";
import arbLangIcon from "../../../../assets/icons-language-arb-active.svg";
import hebLangIconInactive from "../../../../assets/icons-language-heb-inactive.svg";
import engLangIconInactive from "../../../../assets/icons-language-eng-inactive.svg";
import arbLangIconInactive from "../../../../assets/icons-language-arb-inactive.svg";
import rusLangIconInactive from "../../../../assets/icons-language-rus-inactive.svg";

import "./LanguagesDropDown.scss";
import { ITopMenuDropDown } from "..";
import LanguagesDropDownItem from "./LanguagesDropDownItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeAppDirection } from "../../../../store/action-creators/App";
import { DIRECTIONS } from "../../../../store/Globals";
import { RootState } from "../../../..";

const langConfigPath = "topMenu.desktop.langDropDown";

const LanguagesDropDown: React.FC<ITopMenuDropDown> = ({ triggerId }) => {
  const { t, i18n } = useTranslation();
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const langs = {
    he: "he",
    en: "en",
    ar: "ar",
    ru: "ru",
  };
  const labels = {
    he: t(`${langConfigPath}.he.label`),
    en: t(`${langConfigPath}.en.label`),
    ar: t(`${langConfigPath}.arb.label`),
    ru: t(`${langConfigPath}.rus.label`),
  };
  return (
    <IonPopover
      dir={appDirection}
      className="languages-dropdown"
      trigger={triggerId}
      showBackdrop={false}
      dismissOnSelect={true}
    >
      <IonContent>
        <IonList className="languages-dropdown-list">
          <LanguagesDropDownItem
            text={labels.he}
            icon={
              i18n.language === langs.he ? hebLangIcon : hebLangIconInactive
            }
            lang={langs.he}
            dir={DIRECTIONS.rtl}
          />
          <LanguagesDropDownItem
            text={labels.en}
            icon={
              i18n.language === langs.en ? engLangIcon : engLangIconInactive
            }
            lang={langs.en}
            dir={DIRECTIONS.ltr}
          />
          <LanguagesDropDownItem
            text={labels.ar}
            icon={
              i18n.language === langs.ar ? arbLangIcon : arbLangIconInactive
            }
            lang={langs.ar}
            dir={DIRECTIONS.rtl}
          />
          <LanguagesDropDownItem
            text={labels.ru}
            icon={
              i18n.language === langs.ru ? rusLangIcon : rusLangIconInactive
            }
            lang={langs.ru}
            dir={DIRECTIONS.ltr}
          />
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default LanguagesDropDown;
