import { IonContent, IonIcon, IonPage, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import PersonalDetailsCard from "../../../components/PersonalDetailsCard";
import { ROUTES } from "../../../store/Globals";
import "./PersonalDetailsPage.scss";
import { BackIcon } from "../../../components/BackIcon";
import backIconWhite from "../../../assets/icons-arrow-back-white.svg";

const langConfigPath = "pages.desktop.personalDetails";

const PersonalDetailsDeskTop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-personal-details-page">
          <div className="desktop-title-container">
            <div className="width-100 position-relative desktop-header-container">
              <BackIcon
                closeIcon={backIconWhite}
                onClick={() => history.push(ROUTES.desktopNfcOrder)}
              />

              <IonText className="desktop-header text absolute-center main-title">
                {t(`${langConfigPath}.header`, { cardName: "1234567" })}
              </IonText>
            </div>
          </div>

          <PersonalDetailsCard />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PersonalDetailsDeskTop;
