import { getPlatforms } from "@ionic/react";
import { FileType } from "../models/profile";
import { Chooser } from "@awesome-cordova-plugins/chooser";

class FileService {
  static getAllowedTypes = (types: FileType[] | string[], seperator = ",") => {
    let allowedTypes = "";
    types.forEach((x) => {
      allowedTypes = allowedTypes + `${x}${seperator}`;
    });
    return allowedTypes.slice(0, -1);
  };

  static chooseFile = (
    inputRef?: React.MutableRefObject<HTMLInputElement | null>,
    allowedTypes?: string,
    onSelected?: (result: string) => {} | void
  ) => {
    //web
    // if(getPlatforms().indexOf('mobileweb') !== -1){
    if (
      getPlatforms().indexOf("mobileweb") !== -1 ||
      getPlatforms().indexOf("desktop") !== -1
    ) {
      if (inputRef !== undefined && inputRef.current !== null) {
        inputRef.current.click();
      }
    }
    //native
    else {
      Chooser.getFile(allowedTypes)
        .then((file) => {
          if (onSelected !== undefined && file !== undefined) {
            onSelected(file.dataURI!);
          }
        })
        .catch((error: any) => console.error(error));
    }
  };

  static getBase64 = (
    file: Blob,
    onSuccess: (result: string | ArrayBuffer | null) => {} | void,
    onError?: (e: ProgressEvent<FileReader>) => {} | void
  ) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onSuccess(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
      if (onError !== undefined) {
        onError(error);
      }
    };
  };
}

export default FileService;
