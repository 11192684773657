import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
} from "@ionic/react";
import classNames from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import {
  cardOrderTypes,
  TCardType,
  TOrderType,
} from "../../../pages/dekstop/OrderCardPage/types";
import "./SelectCardOrderType.scss";

type TCardTypePicker = {
  nextStep: (type: TCardType) => void;
};

const langConfigPath = "pages.desktop.order-card.order-types";
export const cardTypesList: TCardType[] = [
  { type: cardOrderTypes.newCard, price: 0 },
  { type: cardOrderTypes.anonymous, price: 10 },
  { type: cardOrderTypes.fastRestore, price: 10 },
];
type TOrderTypeCard = {
  price: number;
  type: TOrderType;
  value: any;
  onClick: () => void;
};
const OrderTypeCard: FC<TOrderTypeCard> = ({ price, type, onClick, value }) => {
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const labels = {
    title: t(`${type}.title`),
    price: price ? `${price}₪` : t("free"),
    description: t(`${type}.description`),
  };
  return (
    <IonCard
      onClick={onClick}
      class="clickable  black-text box-shadow  order-type-card m0"
    >
      <IonRow className="p0">
        <IonRadio
          className="form-radio height-100 card-type-radio"
          value={value}
        />
        <IonCol>
          <IonRow className="p0">
            <IonLabel className="order-type-title bold">
              {labels.title}&nbsp;&nbsp;&nbsp;{labels.price}
            </IonLabel>
            <IonText className="order-type-description">
              {labels.description}
            </IonText>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};
const CardTypePicker: FC<TCardTypePicker> = ({ nextStep }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();
  const [selectedOrderType, setSelectedOrderType] = useState<TCardType>();
  const [selected, setSelected] = useState<string>();

  const handleOptionChange = (event: any) => {
    console.log({ test: event.detail.value });
    setSelected(event.detail.value);
  };

  return (
    <div dir={appDirection} className="padding-20">
      <IonRadioGroup
        className="flex flex-direction-column gap10"
        value={selected}
        onIonChange={handleOptionChange}
        allowEmptySelection
      >
        {cardTypesList.map(({ price, type }, key) => (
          <OrderTypeCard
            key={key}
            price={price}
            type={type}
            value={`ion-rb-${key}`}
            onClick={() => {
              setSelectedOrderType({ price, type });
              setSelected(`ion-rb-${key}`);
            }}
          />
        ))}
      </IonRadioGroup>

      <IonButton
        className="main-blue-buttons space-down"
        onClick={() => {
          if (!selectedOrderType) return;
          nextStep(selectedOrderType);
        }}
        disabled={!selectedOrderType}
      >
        {t("stepsSections.selectCard.button.label")}
      </IonButton>
    </div>
  );
};

export default CardTypePicker;
