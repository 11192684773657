import { useEffect, useRef, useState } from "react";
import { convertSumByFrofile } from "../services/CardService";

export const useConvertedSum = ({
  PriceInAgorot,
  Profile,
  debounceInMs = 100,
}: {
  PriceInAgorot: number;
  Profile: number;
  debounceInMs?: number;
}) => {
  const [convertedSumInAgorot, setConvertedSum] = useState(0);
  const [isConverting, setIsConverting] = useState(false);

  useEffect(() => {
    setIsConverting(true);
    const convertTimeoutId = setTimeout(() => {
      convertSumByFrofile({
        PriceInAgorot,
        Profile,
      }).then((v) => {
        setConvertedSum(v.Units);
        setIsConverting(false);
      });
    }, debounceInMs);
    return () => {
      setIsConverting(false);
      clearTimeout(convertTimeoutId);
    };
  }, [PriceInAgorot, Profile]);
  return { convertedSumInAgorot, isConverting };
};
type TConvertParams = {
  from: number | string;
  to: number | string;
  fromSum: number;
};
const convertBack = async ({ from, fromSum }: Omit<TConvertParams, "to">) => {
  const convertToRsp = await convertSumByFrofile({
    PriceInAgorot: 100,
    Profile: Number(from),
  });
  const ratio = 100 / convertToRsp.Units;
  return fromSum * ratio;
};
const convertFromTo = async ({ from, fromSum, to }: TConvertParams) => {
  const normalSum = await convertBack({ from, fromSum });
  return await convertSumByFrofile({
    PriceInAgorot: normalSum * 100,
    Profile: Number(to),
  });
};
export const useConvertSumFromTo = (params: TConvertParams) => {
  const [converted, setConverted] = useState<number>();
  useEffect(() => {
    convertFromTo(params).then((v) => {
      setConverted(v.Units / 100);
    });
  }, []);
  return { converted };
};
