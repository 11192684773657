import { 
    IonButton,
    IonIcon,
    IonImg,
    IonModal,
    IonText
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../..';
import whoRUIcon from '../../../assets/icons-who-r-u.svg'
import { changePopup } from '../../../store/action-creators/App';
import { POPUPS, ROUTES } from '../../../store/Globals';
import Popup from '../Popup';
import './PangoWhiteLabelPopup.scss';
import pangoLogo from '../../../assets/pango-logo-pop-up.webp'

type PangoWhiteLabelPopup = {
    mobile?: boolean
}
const langConfigPath = 'popUps.pangoWhiteLabel'

const PangoWhiteLabelPopup: React.FC<PangoWhiteLabelPopup> = ({mobile=false}) => {
    
    const appDirection = useSelector((state: RootState)=>state.app.direction)
    const history = useHistory()
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const onContinue = () =>{
        window.location.assign('https://l.pango.co.il')
        dispatch(changePopup(POPUPS.clear))
        
    }
    const onCancel = () =>{
        dispatch(changePopup(POPUPS.clear))
    }
    

    return (
        <Popup popUpId={POPUPS.pangoWhiteLabel} mobile={mobile}>
            <div 
            className="pop-up pango-white-label-pop-up flex ion-align-items-center"
            data-is-mobile={mobile}
            >
                <div className="text-container flex flex-direction-column">
                    <IonText className="text flex text1 ion-align-items-center">
                        {t(`${langConfigPath}.header.text1`)}
                        <IonImg className="logo" src={pangoLogo}  alt=""/>
                    </IonText>
                    <IonText className="text">
                        {t(`${langConfigPath}.header.text2`)}
                    </IonText>
                </div>
                <div className="buttons-container flex flex-direction-column">
                    <IonButton
                    className="button-primary pop-up-button"
                    onClick={onContinue}
                    >
                        {t(`${langConfigPath}.continueButton.label`)}
                    </IonButton>
                    <IonButton
                    className="button-secondary pop-up-button"
                    onClick={onCancel}
                    >
                        {t(`${langConfigPath}.cancelButton.label`)}
                    </IonButton>
                </div>
            </div>
        </Popup>
    )
}

export default PangoWhiteLabelPopup
    