import React from "react";
import { IonItem, IonIcon, IonLabel } from "@ionic/react";
import arrowLeft from "../../../../../assets/icons-small-arrow-left.svg";
import arrowUp from "../../../../../assets/icons-small-arrow-up.svg";
import arrowDown from "../../../../../assets/icons-small-arrow-down.svg";

import iconMenuPango from "../../../../../assets/icon-menu-pango.svg";
import "./SideMenuMobileSubMenuToggle.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../..";

type SideMenuMobileSubMenuToggle = {
  icon?: string | undefined;
  label: string;
  onClick?: React.MouseEventHandler | undefined;
  open?: boolean | undefined;
};

export const SideMenuMobileSubMenuToggle: React.FC<
  SideMenuMobileSubMenuToggle
> = ({ icon, label, onClick, open }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);

  return (
    <div
      className={`${open ? "sub-menu-button-open-container menu-mobile" : ""}`}
    >
      <div
        dir={appDirection}
        key={open?.toString() + Math.random().toString()}
        className={`side-menu-mobile-sub-menu-toggle
            ${open ? "sub-menu-button-open" : ""}
            `}
        onClick={onClick}
      >
        {icon && <IonIcon className="icon" src={icon} />}
        {vendor === "pango" && <IonIcon className="icon" src={iconMenuPango} />}

        <IonLabel className="label text">{label}</IonLabel>
        <IonIcon
          data-is-open={open}
          className="arrow-toggle"
          src={open ? arrowUp : arrowDown}
        />
      </div>
    </div>
  );
};

export default SideMenuMobileSubMenuToggle;
