import React from "react";
import { IonItem, IonIcon, IonLabel } from "@ionic/react";

import "./SideMenuMobileItem.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../..";
import iconMenuPango from "../../../../../assets/icon-menu-pango.svg";

type SideMenuMobileItem = {
  icon?: string | undefined;
  label: string;
  onClick?: React.MouseEventHandler | undefined;
  subItem?: boolean | undefined;
  style?: object | undefined;
};

export const SideMenuMobileItem: React.FC<SideMenuMobileItem> = ({
  icon,
  label,
  onClick,
  subItem,
  style,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);

  return (
    <div
      dir={appDirection}
      style={style}
      className={`side-menu-mobile-item
        ${subItem ? "sub-menu-item" : ""}
        `}
      onClick={onClick}
    >
      {icon && <IonIcon className="icon" src={icon} />}
      {vendor === "pango" && <IonIcon className="icon" src={iconMenuPango} />}

      <IonLabel className="label text">{label}</IonLabel>
    </div>
  );
};

export default SideMenuMobileItem;
