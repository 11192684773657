import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import useFetch from "../../hooks/UseFetch";
import {
  startCreateProfile,
  createProfileSuccess,
  createProfileError,
  startUploadOrUpdateFiles,
  uploadOrUpdateFilesSuccess,
  uploadOrUpdateFilesError,
  startGetUserProfiles,
  getUserProfilesSuccess,
  getUserProfilesError,
  startGetSingleProfile,
  getSingleProfileSuccess,
  getSingleProfileError,
  startGetAllProfilesList,
  getAllProfilesListSuccess,
  getAllProfilesListError,
} from "../../store/action-creators/profile";
import { API_URL } from "../../store/Globals";
import {
  CreateProfileRequest,
  CreateProfileResponse,
  GetProfilesResponse,
  GetSingleProfileResponse,
  UploadOrUpdateFilesRequest,
} from "../models/profile";

const useGetAllProfilesList = () => {
  const state = useSelector(
    (state: RootState) => state.profile.allprofilesList
  );

  const fetchData = useFetch<any>(
    `${API_URL}/profiles/getFullInfo`,
    startGetAllProfilesList,
    getAllProfilesListSuccess,
    getAllProfilesListError
  );

  const execute = (redirectForSucess?: string, redirectForError?: string) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
      },
      redirectForSucess,
      redirectForError
    );
  };
  const getProfilesList = {
    state: state,
    execute: execute,
  };

  return getProfilesList;
};
const useCreateProfile = () => {
  const state = useSelector((state: RootState) => state.profile.createProfile);

  const fetchData = useFetch<CreateProfileResponse>(
    `${API_URL}/profiles/createProfileRequest`,
    startCreateProfile,
    createProfileSuccess,
    createProfileError
  );

  const execute = (
    data: CreateProfileRequest,
    redirectForSucess?: string,
    redirectForError?: string
  ) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      },
      redirectForSucess,
      redirectForError
    );
  };
  const createProfile = {
    state: state,
    execute: execute,
  };

  return createProfile;
};

const useUploadOrUpdateFiles = () => {
  const state = useSelector(
    (state: RootState) => state.profile.uploadOrUpdateFiles
  );

  const fetchData = useFetch<any>(
    `${API_URL}/profiles/updateFilesProfileReq`,
    startUploadOrUpdateFiles,
    uploadOrUpdateFilesSuccess,
    uploadOrUpdateFilesError
  );

  const execute = (
    payload: UploadOrUpdateFilesRequest,
    redirectForSucess?: string,
    redirectForError?: string
  ) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ payload }),
      },
      redirectForSucess,
      redirectForError
    );
  };
  const uploadOrUpdateFiles = {
    state: state,
    execute: execute,
  };

  return uploadOrUpdateFiles;
};

const useGetUserProfiles = () => {
  const state = useSelector(
    (state: RootState) => state.profile.getUserProfiles
  );
  const dispatch = useDispatch();

  const fetchData = useFetch<GetProfilesResponse[]>(
    `${API_URL}/profiles/userProfiles`,
    startGetUserProfiles,
    getUserProfilesSuccess,
    getUserProfilesError
  );

  const clear = () => {
    dispatch(getUserProfilesSuccess([]));
  };

  const execute = (
    body: { reqType: "profiles" | "cards" },
    redirectForSucess?: string,
    redirectForError?: string
  ) => {
    fetchData(
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ ...body }),
      },
      redirectForSucess,
      redirectForError
    );
  };
  const getUserProfiles = {
    state: state,
    execute: execute,
    clear: clear,
  };

  return getUserProfiles;
};

const useGetSingleProfile = () => {
  const state = useSelector(
    (state: RootState) => state.profile.getSingleProfile
  );

  const fetchData = useFetch<GetSingleProfileResponse>(
    `${API_URL}/profile/GetUserSingleProfile`,
    startGetSingleProfile,
    getSingleProfileSuccess,
    getSingleProfileError
  );

  const execute = (
    id: string,
    redirectForSucess?: string,
    redirectForError?: string
  ) => {
    fetchData(
      { params: [{ name: "Request_Id", value: id }] },
      redirectForSucess,
      redirectForError
    );
  };
  const getSingleProfile = {
    state: state,
    execute: execute,
  };

  return getSingleProfile;
};

const ProfileService = {
  createProfile: useCreateProfile,
  uploadOrUpdateFiles: useUploadOrUpdateFiles,
  getUserProfiles: useGetUserProfiles,
  getSingleProfile: useGetSingleProfile,
  getAllProfilesList: useGetAllProfilesList,
};

export default ProfileService;
