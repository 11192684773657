import { IonCard, IonCardContent, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../..";

import "./paymentMethodCard.scss";

const langConfigPath = "paymentMethodCard";

type PaymentMethodCard = {
  lastDigits: string;
  lastCharged?: string;
  mobile?: boolean;
  selected?: boolean;
  onClick?: () => void;
};

const PaymentMethodCard: React.FC<PaymentMethodCard> = ({
  mobile,
  lastDigits,
  lastCharged,
  onClick,
  selected,
}) => {
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  return (
    <IonCard
      className={`payment-method-card card${selected ? " selected" : ""}`}
      onClick={onClick}
    >
      <IonCardContent className="flex flex-direction-column">
        <IonText className="text">
          {t("lastDigits", { lastDigits: lastDigits })}
        </IonText>
        <IonText className="text font-size-12 color-sub-text">
          {lastCharged
            ? t("lastChargedText", { date: lastCharged })
            : t("noLastChargedText")}
        </IonText>
      </IonCardContent>
    </IonCard>
  );
};

export default PaymentMethodCard;
