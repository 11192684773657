import { IonContent, IonPage, IonText } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import ExchangeProfileCard from "../../../components/ExchangeProfileCard";
import "./exchangeProfilePage.scss";
import { useCardEnv } from "../../../hooks/useCard";
import { useTranslation } from "react-i18next";
import { MobileBackIcon } from "../../../components/BackIcon";

const langConfigPath = "exchangeProfile";

const ExchangeProfilePageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { cardNumber, cardName } = useCardEnv();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const labels = {
    cardName: cardName,
    action: t("convertProfile"),
    cardNumber,
  };

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-exchange-profile-page height-100 width-100">
          <div className="mobile-title-container flex">
            <div className="flex width-100 position-relative">
              <MobileBackIcon />
              <IonText className="mobile-header text absolute-center">
                {labels.action}
              </IonText>
            </div>
            <div>
              <IonText className="mobile-header mobile-sub-header text font-size-14">
                {labels.action}&nbsp;| &nbsp;
              </IonText>
              <IonText className="mobile-header mobile-sub-header text font-size-14">
                {labels.cardNumber}
              </IonText>
            </div>
          </div>

          <ExchangeProfileCard mobile />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ExchangeProfilePageMobile;
