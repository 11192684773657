import { 
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonText,
} from '@ionic/react';
import classNames from 'classnames';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../../..';

import './dateCard.scss';
  
const langConfigPath = 'cardDetailsCard'

type CardDetailsCard = {
  label: string,
  date: string,
  mobile?: boolean,
  cssClass?: object,
  selected?: boolean
  onClick?: MouseEventHandler
}

const DateCard: React.FC<CardDetailsCard> = ({cssClass, mobile=false,
                                              selected, label,
                                              date, onClick}) => {
  const appDirection = useSelector((state: RootState)=>state.app.direction)
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslation()


  return (
  <IonCard
  dir={appDirection}
  className={cssClass+" "+ classNames({
    "card": true,
    "date-card": true, 
    "flex": true, 
    "flex-direction-column": true,
    "justify-content-center": true,
    "ion-align-items-center": true,
    "clickable": true,
    "selected": selected
  })}
  data-is-mobile={mobile}
  onClick={onClick}
  >
      <IonCardContent className="text-container flex flex-direction-column">
          <IonText className="text-align-center text weight-500">{label}</IonText>
          <IonText className="text-align-center text">{date}</IonText>
      </IonCardContent>
      
  </IonCard>
  )
}

export default DateCard
