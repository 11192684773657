import { IonButton, IonText } from "@ionic/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { nfcActions, type Contract } from "../../../hooks/types";
import { parsePrice } from "../../../hooks/useCard";
import { changePopup } from "../../../store/action-creators/App";
import { saveNfcAction } from "../../../store/action-creators/Nfc";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./CancelAndRefundPopup.scss";
import ContractInfoCard from "./ContractInfoCard";

type TCancelAndRefundPopup = {
  mobile?: boolean;
  contract?: Contract;
};
const langConfigPath = "popUps.cancelAndrefund";

const CancelAndRefundPopup: React.FC<TCancelAndRefundPopup> = ({
  mobile = false,
  contract,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [routeOnDismiss, setRouteOnDismiss] = useState<string>();
  const readRoute = useMemo(
    () => (mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcRead),
    [mobile]
  );
  const labels = {
    header: t(`${langConfigPath}.header`),
    text: t(`${langConfigPath}.text`),
    refundAmountHeader: t(`${langConfigPath}.refundAmountHeader`),
    confirmCancel: t(`${langConfigPath}.confirmCancelButton.label`),
  };
  const refundSum = useMemo(() => {
    return contract?.CancellationData?.Sum2CancelInAgorot ?? 0;
  }, [contract]);
  if (!contract) return null;

  const onConfirmCancel = () => {
    if (!contract) return;
    dispatch(
      saveNfcAction({
        action: nfcActions.refundContract,
        data: {
          ContractSlotId: contract.IndexOnSC,
          PrevEtt: contract.Contract.ETT,
          Units2Sub: contract.CancellationData?.Units2Cancel ?? 0,
        },
      })
    );
    setRouteOnDismiss(readRoute);
    dispatch(changePopup(POPUPS.clear));
  };

  return (
    <Popup
      popUpId={POPUPS.cancelAndRefund}
      mobile={mobile}
      routeOnDismiss={routeOnDismiss}
      cssClass="cancel-and-refund-pop-up-container --color-blue--light-contrast"
      fullScreen
    >
      {!mobile && <div className="top-contrast"></div>}
      <div
        className="pop-up cancel-and-refund-pop-up flex ion-align-items-center flex-direction-column justify-space-around height-100"
        data-is-mobile={mobile}
      >
        <IonText className="pop-up-header text">{labels.header}</IonText>
        <IonText className="text text-center">{labels.text}</IonText>
        <ContractInfoCard
          mobile={mobile}
          contractParent={contract}
          contract={contract?.Contract}
        />
        <IonText className="refund-amount-header text">
          {labels.refundAmountHeader}
        </IonText>
        <IonText className="refund-amount text color-dark-blue">
          {parsePrice(refundSum)} ₪
        </IonText>
        <IonButton
          onClick={onConfirmCancel}
          className="button-primary pop-up-button"
        >
          {labels.confirmCancel}
        </IonButton>
      </div>
    </Popup>
  );
};

export default CancelAndRefundPopup;
