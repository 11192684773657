import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonText,
} from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import DiscountProfileCard from "../../../components/DiscountProfileCard";
import DocumentsSentErrorPopup from "../../../components/pop-ups/DocumentsSentErrorPopup";
import {
  CreateOrUpdateProfileTypeEnum,
  ProfileStatus,
} from "../../../services/models/profile";
import ProfileService from "../../../services/ProfileService";
import { changePopup } from "../../../store/action-creators/App";
import {
  changeCreateProfileReqType,
  changeSelectedProfile,
  createProfileError,
  getSingleProfileSuccess,
} from "../../../store/action-creators/profile";
import { POPUPS, ROUTES } from "../../../store/Globals";
import SignInBeforeContinuingPopup from "../../../components/pop-ups/SignInBeforeContinuingPopup";

import "./profilesPage.scss";
import { saveNfcAction } from "../../../store/action-creators/Nfc";
import { nfcActions } from "../../../hooks/types";
import { useCardEnv } from "../../../hooks/useCard";
import classNames from "classnames";
import { MobileBackIcon } from "../../../components/BackIcon";
import { useVendorUser } from "../../../hooks/useVendorUser";

const langConfigPath = "pages.mobile.profiles";

const ProfilesPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const user = useSelector((state: RootState) => state.user);
  const { cardNumber, cardName, cardDetails } = useCardEnv();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userProfiles = ProfileService.getUserProfiles();
  const vendorAuth = useVendorUser({
    enabled: true,
    isCardRequired: false,
  });
  const getAllProfilesList = ProfileService.getAllProfilesList();
  const isVendor = useSelector((state: RootState) => state.app.isVendor);

  useEffect(() => {
    getAllProfilesList.execute();
  }, [i18n.language]);

  useEffect(() => {
    if (user.GetUserData.loading || userProfiles.state.loading) return;
    if (isVendor && !user.GetUserData.data) return;
    userProfiles.execute({ reqType: "profiles" });
    if (history.location.pathname.includes("sms")) {
      dispatch(createProfileError(""));
    } else if (!user.Login) {
      dispatch(changePopup(POPUPS.signInBeforeContinuingPopup));
    }
  }, [user.GetUserData.data]);

  useEffect(() => {
    if (userProfiles.state.loading) return;
    const profiles = userProfiles.state.data as any[];
    if (!profiles || !Array.isArray(profiles)) return;
    console.log("profiles", profiles);

    if (profiles.some((p) => p.status === ProfileStatus.denied_request)) {
      dispatch(changePopup(POPUPS.documentsSentError));
    }
  }, [userProfiles.state, vendorAuth.userDetails]);

  const handelDocumentStatusClick = (profile: any) => {
    dispatch(changeSelectedProfile(profile));
    dispatch(getSingleProfileSuccess(profile));
    userProfiles.clear();
    history.push(ROUTES.mobileUploadFiles);
  };

  const labels = {
    profile1Name: cardDetails?.Environment.HolderProf1Str,
    profile2Name:
      cardDetails?.Environment.HolderProf1Str ===
      cardDetails?.Environment.HolderProf2Str
        ? null
        : cardDetails?.Environment.HolderProf2Str,
  };

  return (
    <IonPage
      className={classNames({ "mobile-page": true, "vendor-page": isVendor })}
      dir={appDirection}
    >
      <IonContent scrollY className="mobile-page-content">
        <DocumentsSentErrorPopup
          mobile
          info={t(`popUps.documentsSentError.text`)}
        />

        <SignInBeforeContinuingPopup mobile />

        <div className="mobile-profiles-page">
          <div className="mobile-step-headers-container">
            <MobileBackIcon />
            <div className="mobile-step-headers">
              <IonText className="mobile-header text">
                {t(`${langConfigPath}.header`)}
              </IonText>
              <IonText className="mobile-sub-header text">
                <IonText>{t(`${langConfigPath}.subHeader`)}</IonText>
                <IonText className=" text mobile-sub-header-blue-line"></IonText>
                {cardNumber && (
                  <>
                    {cardName && (
                      <IonText className="mobile-sub-header text">
                        {cardName}
                      </IonText>
                    )}

                    <IonText className="mobile-sub-header text">
                      {cardNumber}
                    </IonText>
                  </>
                )}
              </IonText>
            </div>
          </div>

          <IonCard className="mobile-page-card">
            <IonCardContent className="mobile-card-content">
              {labels.profile1Name && (
                <DiscountProfileCard
                  key={Math.random()}
                  name={labels.profile1Name}
                  isRegular
                  mobile
                />
              )}
              {labels.profile2Name && (
                <DiscountProfileCard
                  key={Math.random()}
                  name={labels.profile2Name}
                  isRegular
                  mobile
                />
              )}
              {!userProfiles.state.loading &&
              userProfiles.state.data?.length > 0 ? (
                <>
                  {userProfiles.state.data.map((profile: any) => {
                    if (profile?.from === "ort") {
                      return (
                        <DiscountProfileCard
                          key={Math.random()}
                          name={profile.profileName}
                          isRegular
                        />
                      );
                    }
                    return (
                      <DiscountProfileCard
                        key={Math.random()}
                        onCheckFilesClick={() => {
                          handelDocumentStatusClick(profile);
                        }}
                        onLoadProfile={() => {
                          dispatch(
                            saveNfcAction({
                              action: nfcActions.loadProfile,
                              data: { reqId: profile._id },
                            })
                          );
                          history.push(ROUTES.mobileNfcRead);
                        }}
                        name={profile.profileName}
                        profile_status={profile?.status}

                        // mobile
                      />
                    );
                  })}
                </>
              ) : (
                <div> </div>
              )}
            </IonCardContent>
            <div className="mobile-card-action-button-container">
              <IonButton
                disabled={
                  userProfiles.state.loading ||
                  userProfiles.state.data?.length >= 2
                }
                className="main-blue-buttons space-down"
                onClick={() => {
                  dispatch(
                    changeCreateProfileReqType(
                      CreateOrUpdateProfileTypeEnum.update_profile
                    )
                  );
                  history.push(ROUTES.mobileCreateNewProfile);
                }}
              >
                {t(`${langConfigPath}.createNewProfileButton.label`)}
              </IonButton>
            </div>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProfilesPageMobile;
