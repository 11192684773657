import { IonActionSheet } from '@ionic/react';

import personalDetailsIcon from '../../../../assets/icons-actions-personal-details.svg'
import loadingHistoryIcon from '../../../../assets/icons-actions-loading-history.svg'
import ravKavCardIcon from '../../../../assets/icons-actions-card.svg'
import walletIcon from '../../../../assets/icons-actions-payment-2.svg'
import securityIcon from '../../../../assets/icons-actions-security.svg'
import logOffIcon from '../../../../assets/icons-actions-exit.svg'

import './PersonalAreaMobileMenu.scss'
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { changePersonalAreaMobileMenuOpenState } from '../../../../store/action-creators/TopMenu';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../..';
import authService from '../../../../services/AuthService';
import { ROUTES } from '../../../../store/Globals';

const langConfigPath = 'topMenu.mobile.personalAreaMenu'

const PersonalAreaMobileMenu: React.FC = () => {
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const topMenuState = useSelector((state: RootState)=>state.topMenu)
    const appDirection = useSelector((state: RootState)=>state.app.direction)
    const logout = authService.logOut()

    return (
        <IonActionSheet
        // mode='ios'
        data-dir={appDirection}
        isOpen={topMenuState.personalAreamobileMenuOpenState}
        backdropDismiss={false}
        onDidDismiss={() => dispatch(changePersonalAreaMobileMenuOpenState(false))}
        cssClass="personal-area-mobile-menu"
        buttons={[{
          text: t(`${langConfigPath}.personalDetails.label`),
          icon: personalDetailsIcon,
          handler: () => {
           history.push(ROUTES.mobileEditUserInfo);
          }
        }, {
          text: t(`${langConfigPath}.chargingHistory.label`),
          icon: loadingHistoryIcon,
          handler: () => {
           history.push(ROUTES.mobileChargeHistory);
          }
        }, {
          text: t(`${langConfigPath}.ravKavCards.label`),
          icon: ravKavCardIcon,
          handler: () => {
           history.push(ROUTES.mobileRavkavCards);
          }
        }, {
          text: t(`${langConfigPath}.paymentMethods.label`),
          icon: walletIcon,
          handler: () => {
           history.push(ROUTES.mobilePaymentMethod);
          }
        }, {
          text: t(`${langConfigPath}.logOff.label`),
          icon: logOffIcon,
          handler: () => {
            logout.execute(ROUTES.mobileLogIn, ROUTES.mobileLogIn)
          }
        },
        {}
       ]}
      >
      </IonActionSheet>
    )
};

export default PersonalAreaMobileMenu;
