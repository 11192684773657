import { IonContent, IonPage } from "@ionic/react";
import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import DocumentsSentErrorPopup from "../../../components/pop-ups/DocumentsSentErrorPopup";
import DocumentsSentSuccessPopup from "../../../components/pop-ups/DocumentsSentSuccessPopup";
import StepsCard from "../../../components/StepsCard";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import "./createProfilePage.scss";
import SelectProfile from "../../../components/StepsCardSections/SelectProfile";
import { TProfiles } from "../../dekstop/OrderCardPage";
import CardOrderDocuments from "../../../components/StepsCardSections/CardOrderDocuments";

const langConfigPath = "pages.mobile.createProfile";

export const CreateProfileNavigationContext = createContext<any>([]);

const CreateProfilePageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { selectedProfile } = useSelector((state: RootState) => state.profile);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [selectedProfiles, setSelectedProfiles] = useState<TProfiles>();

  const nextStep = () => setStep((prev) => prev + 1);
  const pages = [
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.subHeader`),
      component: (
        <SelectProfile
          mobile
          nextStep={(profiles) => {
            setSelectedProfiles(profiles);
            nextStep();
          }}
        />
      ),
    },
    {
      header: t(`${langConfigPath}.header`),
      subHeader: t(`${langConfigPath}.pages.uploadDocuments.header`),
      component: (
        <CardOrderDocuments
          profiles={selectedProfiles}
          nextStep={() => {
            dispatch(changePopup(POPUPS.documentsSentSuccess));
          }}
          isCreateProfile
          mobile
        />
      ),
    },
  ];

  const handleBackButton = () => {
    if (step !== 0) {
      setStep(step - 1);
    } else {
      history.push(ROUTES.mobileProfiles);
    }
  };

  return (
    <IonPage className="mobile-page" dir={appDirection}>
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-create-profile-page width-100">
          <StepsCard
            step={step}
            pages={pages}
            cssClass="create-profile-steps-card"
            mobile={true}
            onBack={handleBackButton}
          />

          <DocumentsSentSuccessPopup
            mobile
            info={
              selectedProfile && selectedProfile.files.length
                ? ""
                : t(`${langConfigPath}.popupMsgSuccess`)
            }
          />
          <DocumentsSentErrorPopup
            mobile
            info={t(`${langConfigPath}.errorPopupText`)}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateProfilePageMobile;
