
export type SearchObject = {
    title: string,
    description: string,
    route:{
        desktop: string,
        mobile: string
    }
}

export const getSearchJson = (lang: string) =>{

    switch(lang){
        case 'he':
            return require('./hebrew.json')
        case 'en':
            return  require('./english.json')
    }
}