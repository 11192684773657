import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonList,
    IonItem,
    IonIcon,
    IonMenuToggle,
    IonLabel,
    IonNavLink,
    IonGrid,
    IonRow,
    IonCol,
    IonListHeader,
    IonImg,
    IonPopover,
    IonInput,
    IonCard,
    IonCardContent,
    IonSearchbar,
} from '@ionic/react';
import exitIcon from '../../../../assets/icons-hamburger-x.svg'
import searchArrowIcon from '../../../../assets/icons-go-search-arrow.svg'
import React, { ReactElement, useEffect, useState } from 'react';
import './TopMenuSearchBar.scss'
import { useDispatch, useSelector } from 'react-redux';
import { changeTopMenuSearchValue } from '../../../../store/action-creators/TopMenu';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../../store/Globals';
import { RootState } from '../../../..';
import { useTranslation } from 'react-i18next';
import { getSearchJson, SearchObject } from '../../../../utils/search-config';
import jsonQuery from 'json-query'


type TopMenuSearchBar = {
    className: boolean | string | undefined
    onClose: React.MouseEventHandler | any
}


const TopMenuSearchBar: React.FC<TopMenuSearchBar> = ({className, onClose}) => {
    const {i18n} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const appDirection = useSelector((state: RootState)=>state.app.direction)
    const [autoCompleteList, setAutoCompleteList] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState<string>("")
    const [searchJson, setSearchJson] = useState<SearchObject[]>()

    useEffect(()=>{
        setSearchJson(getSearchJson(i18n.language))
    },[i18n.language])


    const onInputChange = (inputValue: string) =>{
        if(inputValue === ""){
            setAutoCompleteList([])
            setSearchValue(inputValue)
            return
        }
        if(searchJson !== undefined){
            let results = searchJson.filter(x=>x.title.includes(inputValue))
                                    .map(x=>{return x.title})
                                    
            setAutoCompleteList(results)
        }

        setSearchValue(inputValue)
    }

    const onAutoCompleteClick = (inputValue: string) =>{
        setAutoCompleteList([])
        dispatch(changeTopMenuSearchValue(inputValue))
        if(history.location.pathname !== ROUTES.desktopSearchResults){
            history.push(ROUTES.desktopSearchResults)
        }
        setSearchValue('')
        onClose()

    }

    const highlightAutoCompleteMatch = (option: string) =>{
        const parts = option.split(searchValue);
        return(
            <span>{parts[0]}<strong>{searchValue}</strong>{parts[1]}</span>
        )
    }
    const handleEnterPress = (key: string) =>{
        if(key === 'Enter'){
            dispatch(changeTopMenuSearchValue(searchValue))
            setSearchValue('')
            if(history.location.pathname !== ROUTES.desktopSearchResults){
                history.push(ROUTES.desktopSearchResults)
            }
            onClose()
        }
    }

    return (
        <div
        id="search-bar-container"
        className={`search-bar-container ${className}`}
        dir={appDirection}
        >
            <IonIcon className="exit-icon clickable" src={exitIcon} onClick={onClose}/>

            <IonInput 
            className="search-bar" 
            value={searchValue} 
            onIonChange={(e)=>{onInputChange(e.detail.value!)}}
            onKeyDown={(e)=>{handleEnterPress(e.key)}}
            >
            </IonInput>

            <IonPopover
            dir={appDirection}
            className={`search-auto-complete ${autoCompleteList.length===0 && "ion-hide"}`}
            trigger="search-bar-container" 
            size="cover" 
            showBackdrop={false} 
            dismissOnSelect={true} 
            // backdropDismiss={false}
            keyboardClose={false}
            >
                <IonContent>
                    <IonList className="search-auto-complete-list" lines="none">
                        {
                            autoCompleteList.map((item, index)=>{
                                return(
                                    <IonItem
                                    className="search-auto-complete-item"
                                     key={Math.random()}
                                     button={true} 
                                     onClick={()=>{onAutoCompleteClick(item)}}
                                     >
                                        <IonLabel className="item-lable text">{highlightAutoCompleteMatch(item)}</IonLabel>
                                        <IonIcon className="item-icon" src={searchArrowIcon} />
                                    </IonItem>
                                )
                            })
                        }
                    </IonList>
                </IonContent>
            </IonPopover>
        </div>
    )
};

export default TopMenuSearchBar;
