import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonItem,
  IonSearchbar,
} from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import trainIcon from "../../../../assets/icons-train.svg";
import train1 from "../../../../assets/train1.svg";
import train2 from "../../../../assets/train2.svg";
import { close } from "ionicons/icons";
import "./trainContract.scss";
import { customGET, customPOST } from "../../../../services/customApi/fetch";
import { TProfile } from "../../../../hooks/useAllProfiles";
import { CenteredSpinner } from "../../../Spinner";

const langConfigPath = "stepsSections.selectContract.trainContract";

type TrainContractProps = {
  mobile?: boolean;
  profileType?: TProfile;
  cardImage?: any;
  selectContract: (contract: any) => void;
};

type Station = {
  StationId: number;
  StationName: string;
};

const getTrainStations = async ({
  srcStationId,
  filter,
}: {
  srcStationId?: number;
  filter?: string;
}): Promise<Station[]> => {
  const rsp = srcStationId
    ? await customPOST("contracts/trainDestStations", {
        srcStationId,
      })
    : await customGET("contracts/trainSrcStations");
  if (filter)
    return rsp.data.filter((v: Station) => v.StationName.includes(filter));
  return rsp.data;
};

const getTrainContract = async (params: {
  srcStationId: number;
  dstStationId: number;
  profileId?: number;
  cardImage?: any;
}) => {
  const rsp = await customPOST("contracts/trainRideContracts", params);
  return rsp.data;
};

const SearchFrame: React.FC<{ onClose: () => void }> = ({ children }) => {
  return <div>{children}</div>;
};

const useTrainContracts = ({
  srcStation,
  dstStation,
  profileId,
  cardImage,
}: {
  srcStation?: Station;
  dstStation?: Station;
  profileId?: number;
  cardImage?: any;
}) => {
  const [trainContracts, setTrainContracts] =
    useState<{ ContractId: number; ContractName: string }[]>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!srcStation || !dstStation) return;
    setIsLoading(true);
    getTrainContract({
      srcStationId: srcStation.StationId,
      dstStationId: dstStation.StationId,
      profileId,
      cardImage,
    })
      .then((data) => {
        setIsLoading(false);
        setTrainContracts(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [srcStation, dstStation]);
  return { isLoading, trainContracts };
};

const useTrainStations = ({
  srcStation,
  isActive,
}: {
  srcStation?: Station;
  isActive?: boolean;
}) => {
  const [trainStations, setTrainStations] = useState<Station[]>();
  const [filteredStations, setFilteredStations] = useState<Station[]>();
  const [filterValue, setFilterValue] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isActive === false) return;
    setIsLoading(true);
    getTrainStations({ srcStationId: srcStation?.StationId })
      .then((data) => {
        setIsLoading(false);
        if (filterValue)
          setFilteredStations(
            data
              .filter((v) => v.StationName.includes(filterValue))
              .sort(
                (a, b) =>
                  a.StationName.indexOf(filterValue) -
                  b.StationName.indexOf(filterValue)
              )
          );
        setTrainStations(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [srcStation, isActive]);
  const filter = (filterValue: string) => {
    setFilterValue(filterValue);
    if (isLoading || !trainStations) return;
    setFilteredStations(
      trainStations
        .filter((v) =>
          // StationName comes with rtl unicode mark
          v.StationName.replace(/\u200f/gi, "").includes(filterValue)
        )
        .sort(
          (a, b) =>
            a.StationName.indexOf(filterValue) -
            b.StationName.indexOf(filterValue)
        )
    );
  };

  if (isLoading)
    return { isLoading, trainStations: undefined, filter, filterValue };

  return {
    isLoading,
    stations: filteredStations ?? trainStations,
    filter,
    filterValue,
  };
};

const TrainContract: React.FC<TrainContractProps> = ({
  selectContract,
  profileType,
  cardImage,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", { keyPrefix: langConfigPath });
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const labels = useMemo(() => {
    return {
      srcStation: t("searchExitPlaceholder"),
      dstStation: t("searchDestinationPlaceholder"),
      noContractsFound: t("noContractsFound"),
      contracts: t("contracts"),
    };
  }, [t]);
  const [isSrcSearchActive, setIsSrcSearchActive] = useState(false);
  const [isDstSearchActive, setIsDstSearchActive] = useState(false);

  const [srcStation, setSrcStation] = useState<Station>();
  const [dstStation, setDstStation] = useState<Station>();
  const src = useTrainStations({});
  const dst = useTrainStations({ srcStation, isActive: !!srcStation });
  const { trainContracts, isLoading: isTrainContractsLoading } =
    useTrainContracts({
      srcStation,
      dstStation,
      profileId: +(profileType?.id ?? 0),
      cardImage,
    });

  const train1Icon = vendor === "pango" ? train1 : trainIcon;
  const train2Icon = vendor === "pango" ? train2 : trainIcon;

  return (
    <div dir={appDirection} className="select-train-contract">
      <div className="search-wrapper">
        <IonSearchbar
          placeholder={srcStation?.StationName ?? labels.srcStation}
          value={isSrcSearchActive ? src.filterValue : srcStation?.StationName}
          onIonChange={(e) => {
            const filterValue = e.detail.value ?? "";
            if (!filterValue) setSrcStation(undefined);
            src.filter(filterValue);
          }}
          onClick={() => {
            setIsSrcSearchActive((v) => !v);
          }}
          onIonClear={(e) => {
            setSrcStation(undefined);
            src.filter("");
          }}
          searchIcon={train1Icon}
          onFocus={() => {
            setIsDstSearchActive(false);
          }}
        />
        <div className="search-results-container">
          {isSrcSearchActive &&
            src.stations?.map((station) => (
              <div
                key={station.StationId}
                className="train-station-search-result"
                onClick={() => {
                  setSrcStation(station);
                  setDstStation(undefined);
                  dst.filter("");
                  setIsSrcSearchActive(false);
                }}
              >
                {station.StationName}
              </div>
            ))}
        </div>

        {!isSrcSearchActive && <div className="line-between-train-icons"></div>}

        <IonSearchbar
          placeholder={dstStation?.StationName ?? labels.dstStation}
          value={isDstSearchActive ? dst.filterValue : dstStation?.StationName}
          onIonChange={(e) => {
            const filterValue = e.detail.value ?? "";
            if (!filterValue) setDstStation(undefined);
            dst.filter(filterValue);
          }}
          onClick={() => {
            setIsDstSearchActive((v) => !v);
          }}
          disabled={!srcStation}
          searchIcon={train2Icon}
          onFocus={(e) => {
            setIsSrcSearchActive(false);
          }}
        />
        <div className="search-results-container">
          {isDstSearchActive &&
            dst.stations?.map((station) => (
              <div
                key={station.StationId}
                className="train-station-search-result"
                onClick={() => {
                  setDstStation(station);
                  setIsDstSearchActive(false);
                }}
              >
                {station.StationName}
              </div>
            ))}
        </div>
      </div>
      <div className="results-train">
        {isTrainContractsLoading && <CenteredSpinner />}
        {trainContracts?.length === 0
          ? labels.noContractsFound
          : trainContracts?.map((contract) => (
              <div onClick={() => selectContract(contract)}>
                {contract.ContractName}
              </div>
              // <TrainContractOptionCard
              //   key={contract.ContractId}

              //   contract={contract}
              // />
            ))}
      </div>
    </div>
  );
};

export default TrainContract;
