import { IonCard, IonCardContent, IonText } from "@ionic/react";
import classNames from "classnames";
import { MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";

import "./ProfileCard.scss";

type TProfileCard = {
  label: string;
  mobile?: boolean;
  cssClass?: object;
  selected?: boolean;
  onClick?: MouseEventHandler;
};

const ProfileCard: React.FC<TProfileCard> = ({
  label,
  cssClass,
  mobile = false,
  selected,
  onClick,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  return (
    <IonCard
      dir={appDirection}
      className={classNames({
        "clickable profile-card": true,
        cssClass: !!cssClass,
        selected: selected,
      })}
      data-is-mobile={mobile}
      onClick={onClick}
    >
      <IonCardContent className="text-container flex flex-direction-column">
        <IonText className="card-details-header text font-size-18">
          {label}
        </IonText>
      </IonCardContent>
    </IonCard>
  );
};

export default ProfileCard;
