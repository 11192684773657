import { IonIcon, IonModal } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import xIcon from "../../../assets/icons-x.svg";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS } from "../../../store/Globals";
import backIcon from "../../../assets/icons-arrow-back.svg";
import "./Popup.scss";
import classNames from "classnames";

type Popup = {
  popUpId: string;
  mobile?: boolean;
  cssClass?: string;
  routeOnDismiss?: string;
  onDismiss?: () => void;
  fullScreen?: boolean;
};

const Popup: React.FC<Popup> = ({
  popUpId,
  mobile,
  cssClass,
  routeOnDismiss,
  children,
  onDismiss,
  fullScreen = false,
}) => {
  const appState = useSelector((state: RootState) => state.app);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <IonModal
      onDidDismiss={() => {
        if (routeOnDismiss !== undefined && routeOnDismiss !== "") {
          history.push(routeOnDismiss);
        }
        if (appState.popUp.name !== "" && appState.popUp.name === popUpId) {
          dispatch(changePopup(POPUPS.clear));
        }
      }}
      data-is-mobile={mobile}
      className={classNames(cssClass, {
        "pop-up-container": true,
        "pop-up-full-screen": fullScreen && mobile,
      })}
      isOpen={appState.popUp.name === popUpId}
      dir={appState.direction}
    >
      {fullScreen && mobile ? (
        <IonIcon
          className="pop-up-back clickable"
          src={backIcon}
          onClick={() => {
            if (onDismiss !== undefined) onDismiss();
            dispatch(changePopup(POPUPS.clear));
          }}
        />
      ) : (
        <IonIcon
          className="pop-up-close clickable"
          src={xIcon}
          onClick={() => {
            if (onDismiss !== undefined) onDismiss();
            dispatch(changePopup(POPUPS.clear));
          }}
        />
      )}
      {children}
    </IonModal>
  );
};

export default Popup;
