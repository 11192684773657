import { IonButton, IonIcon, IonModal, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dispatch } from "redux";
import { RootState } from "../../..";
import whoRUIcon from "../../../assets/icons-who-r-u.svg";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";
import Popup from "../Popup";
import "./LoginErrorPopup.scss";

type LoginErrorPopup = {
  mobile?: boolean;
};
const langConfigPath = "popUps.loginError";

const LoginErrorPopup: React.FC<LoginErrorPopup> = ({ mobile = false }) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const [route, setRoute] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onRegister = () => {
    setRoute(mobile ? ROUTES.mobileRegister : ROUTES.desktopRegister);
    dispatch(changePopup(POPUPS.clear));
  };

  const onEnterAsGuest = () => {
    setRoute(mobile ? ROUTES.mobileNfcRead : ROUTES.desktopNfcOrder);
    dispatch(changePopup(POPUPS.clear));
  };

  return (
    <Popup popUpId={POPUPS.loginError} mobile={mobile} routeOnDismiss={route}>
      <div
        className="pop-up login-error-pop-up flex ion-align-items-center"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={whoRUIcon} />
        <div className="text-container flex">
          <IonText className="pop-up-header text">
            {t(`${langConfigPath}.text1`)}
          </IonText>
          <IonText className="pop-up-header text">
            {t(`${langConfigPath}.text2`)}
          </IonText>
        </div>
        <div className="buttons-container flex flex-direction-column">
          <IonButton
            className="button-primary pop-up-button"
            onClick={onRegister}
          >
            {t(`${langConfigPath}.registerButton.label`)}
          </IonButton>
          <IonButton
            className="button-secondary pop-up-button"
            onClick={onEnterAsGuest}
          >
            {t(`${langConfigPath}.enterAsGuestButton.label`)}
          </IonButton>
        </div>
      </div>
    </Popup>
  );
};

export default LoginErrorPopup;
