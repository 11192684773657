import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonRouterLink,
  IonText,
  useIonViewWillLeave,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import LoginForm from "../../../components/forms/LogInForm";
import LoginErrorPopup from "../../../components/pop-ups/LoginErrorPopup";
import LoginOTPPopup from "../../../components/pop-ups/LoginOTPPopup";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS, ROUTES } from "../../../store/Globals";

import "./LoginPage.scss";
import { useEffect } from "react";
import classNames from "classnames";

const langConfigPath = "pages.desktop.login";

const LoginPageDesktop: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const vendor = useSelector((state: RootState) => state.app.vendor);
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useIonViewWillLeave(() => {
    dispatch(changePopup(POPUPS.clear));
  });

  useEffect(() => {
    if (user.Login) {
      history.push(ROUTES.desktopEditUserInfo);
    }
  }, [user.Login]);

  return (
    <IonPage className="desktop-page" dir={appDirection}>
      <IonContent
        scrollY
        className={`desktop-page-content desktop-background-${vendor}`}
      >
        <div className="desktop-login-page">
          <div className="desktop-title-container flex-direction-column">
            <IonText className="desktop-header text small">
              {t(`${langConfigPath}.header1`)}
            </IonText>
            <IonText className="desktop-sub-header text">
              {t(`${langConfigPath}.header2`)}
            </IonText>
          </div>

          <IonCard className="desktop-page-card">
            <IonCardContent className="desktop-card-content">
              <LoginForm />
              <LoginErrorPopup />
              <LoginOTPPopup />

              <IonButton
                className="button-secondary maxButton"
                onClick={() => {
                  history.push(ROUTES.desktopNfcOrder);
                }}
              >
                {t(`${langConfigPath}.enterAsGuestButton.label`)}
              </IonButton>

              <div
                className={classNames("flex flex-direction-row-reverse", {
                  "flex-direction-row gap10": appDirection === "ltr",
                })}
              >
                <IonText className="text" dir={appDirection}>
                  {t(`${langConfigPath}.registerLink.text`)}
                </IonText>

                <IonRouterLink
                  routerLink={ROUTES.desktopRegister}
                  className="link text create_account"
                >
                  {t(`${langConfigPath}.registerLink.link`)}
                </IonRouterLink>
              </div>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPageDesktop;
