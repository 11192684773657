import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonText,
} from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../..";
import "./cancelAndRefundPage.scss";
import waveIcon from "../../../assets/icons-mobile-wave.svg";
import { ROUTES } from "../../../store/Globals";

const langConfigPath = "pages.mobile.cancelAndRefund";

const CancelAndRefundPageMobile: React.FC = () => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  const handleClick = () => {
    history.push(ROUTES.mobileNfcRead);
  };

  return (
    <IonPage
      className="mobile-page mobile-cancel-and-refund-page-container"
      dir={appDirection}
    >
      <IonContent scrollY className="mobile-page-content">
        <div className="mobile-cancel-and-refund-page height-100 width-100">
          <IonIcon className="wave-icon width-100 position-fixed" />

          <div
            className="content-container flex flex-direction-column
               height-100 ion-align-items-center justify-content-center"
          >
            <IonText className="text color-white font-size-20 weight-500">
              {t("header")}
            </IonText>

            <IonText className="text color-white font-size-14">
              <Trans>{t("text-1")}</Trans>
            </IonText>

            <IonText className="text color-white font-size-14">
              <Trans>{t("text-2")}</Trans>
            </IonText>

            <IonButton className="button-primary" onClick={handleClick}>
              {t("goToScanButton.label")}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CancelAndRefundPageMobile;
