import classNames from 'classnames'
import { useState } from 'react'
import { AnyAction, Dispatch } from 'redux'
import { ILogIn } from '../../services/models/auth'
import { IUser } from '../../services/models/users'
import {
    START_LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SAVE_LOGIN_FORM_DATA,
    START_VALIDATE_OTP,
    VALIDATE_OTP_SUCCESS,
    VALIDATE_OTP_ERROR,
    START_REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    START_LOG_OUT,
    LOG_OUT_SUCCESS,
    LOG_OUT_ERROR
} from '../actions/index'
import { POPUPS } from '../Globals'
import { changeLoggedUserName, changePopup } from './App'
//#region login
export const startLogin = (): AnyAction =>{
    return {type: START_LOGIN}
}

export const loginSuccess = <T>(data: T)=>{
    return (dispatch: Dispatch<any>)=>{
        dispatch({type: LOGIN_SUCCESS, payload: data})
        dispatch(changePopup(POPUPS.loginOTP))
        
    }
}

export const loginError = (error: string) =>{
    return (dispatch: Dispatch<any>)=>{
        dispatch({type: LOGIN_ERROR, payload: error})
        dispatch(changePopup(POPUPS.loginError))
    }
}

export const saveLoginFormData = (data: ILogIn):AnyAction =>{
    return {type: SAVE_LOGIN_FORM_DATA, payload: data}
}
//#endregion

//#region validate OTP
export const startLValidateOTP = (): AnyAction =>{
    return {type: START_VALIDATE_OTP}
}

export const validateOTPSuccess = (data: any) =>{
    return (dispatch: Dispatch<any>)=>{
        dispatch({type: VALIDATE_OTP_SUCCESS, payload: data})
        dispatch(changeLoggedUserName({firstName: data.firstName,
                                       lastName:data.lastName}))
        dispatch(changePopup(POPUPS.clear))
    }
}

export const validateOTPError = (error: string) =>{
    return (dispatch: Dispatch<any>)=>{
        dispatch({type: VALIDATE_OTP_ERROR, payload: error})
    }
}
//#endregion

//#region register
export const startRegister = (): AnyAction =>{
    return {type: START_REGISTER}
}

export const registerSuccess = <T>(data: T):AnyAction =>{
    return {type: REGISTER_SUCCESS, payload: data}
}

export const registerError = (error: string): AnyAction =>{
    return {type: REGISTER_ERROR, payload: error}
}
//#endregion

//#region logout
export const startLogout = (): AnyAction =>{
    return {type: START_LOG_OUT}
}

export const logoutSuccess = <T>(data: T) =>{
    return (dispatch: Dispatch<any>)=>{
        dispatch({type: LOG_OUT_SUCCESS, payload: data})
        dispatch(changeLoggedUserName(null))
    }
}

export const logoutError = (error: string): AnyAction =>{
    return {type: LOG_OUT_ERROR, payload: error}
}
//#endregion