import { IonButton, IonList, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { useForm, FormProvider } from "react-hook-form";
import "./CardOrderPersonalInfoForm.scss";
import FormInput from "../form-components/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REG_EXP } from "../../../store/Globals";

const langConfigPath = "forms.newCardUserDetails";

type TCardOrderPersonalInfoForm = {
  mobile?: boolean;
  submit: (info: any) => void;
};

const CardOrderPersonalInfoForm: React.FC<TCardOrderPersonalInfoForm> = ({
  mobile = false,
  submit,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);

  const { t } = useTranslation("", { keyPrefix: langConfigPath });

  const errorMessages = {
    firstName: t("firstNameField.errorMessage"),
    lastName: t("lastNameField.errorMessage"),
    personalId: t("ownerIdField.errorMessage"),
    birthDate: t("ownerBirthDateField.errorMessage"),
    chrecterErrorMessage: t("firstNameField.chrecterErrorMessage"),
  };

  const labels = {
    firstName: t("firstNameField.placeholder"),
    lastName: t("lastNameField.placeholder"),
    birthDate: t("ownerBirthDateField.placeholder"),
    personalId: t("ownerIdField.placeholder"),
    submitBtn: t("submitButton.label"),
    formHeader: t("formHeader"),
  };

  const schema = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .required(errorMessages.firstName)
        .min(2, errorMessages.firstName)
        .max(10, errorMessages.firstName)
        .matches(
          REG_EXP.special_characters,
          errorMessages.chrecterErrorMessage
        ),
      lastName: yup
        .string()
        .required(errorMessages.lastName)
        .min(2, errorMessages.lastName)
        .max(10, errorMessages.lastName)
        .matches(REG_EXP.special_characters, errorMessages.lastName),
      birthDate: yup
        .date()
        .typeError(errorMessages.birthDate)
        .max(new Date(), errorMessages.birthDate)
        .required(errorMessages.birthDate),
      personalId: yup
        .string()
        // .length(9, errorMessages.personalId)
        .required(errorMessages.personalId)
        .matches(/^[0-9]{3,20}$/, errorMessages.personalId),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: any) => {
    submit && submit(data);
  };

  return (
    <div className="form order-card" data-is-mobile={mobile} dir={appDirection}>
      <IonText className=" text text-center">{labels.formHeader}</IonText>

      <FormProvider {...methods}>
        <form
          noValidate
          className="register-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <IonList className="form-item-list">
            <FormInput
              name="firstName"
              label={labels.firstName}
              showRequired
              type="text"
              // showV={false}
            />

            <FormInput
              name="lastName"
              label={labels.lastName}
              showRequired
              // showV={false}
            />
            <div className="dateform">
              <FormInput
                name="birthDate"
                type="date"
                label={labels.birthDate}
                showRequired
                // cssClass="form-item.item-has-value-form-item-label"
              />
            </div>
            <FormInput
              name="personalId"
              label={labels.personalId}
              showRequired
            />
          </IonList>
          <IonButton className="button-primary" type="submit">
            {labels.submitBtn}
          </IonButton>
        </form>
      </FormProvider>
    </div>
  );
};

export default CardOrderPersonalInfoForm;
