// export type FileType = "/*" | "image/png" | "image/jpeg" | "application/pdf" 
export type FileType = "/*" | "image/png" | "image/jpeg" | "application/pdf" | "application/msword" | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

export type CreateProfileRequest = {
    type: CreateOrUpdateProfileType,
    cardNumber?: string
    request_type: string
    profileName:string
    docs: ProfileDocForRequest[]
}
export type ProfileDocForRequest = {
    url: string 
    doc_type: string,
    originalFileName?:string |undefined,
    fileExtension?:string |undefined,
    ortDocName:string,
    profileId?:number,
}

export type CreateProfileResponse = GetProfilesResponse

export enum ProfileStatus  {
    new_request = "new-request",
    treatment_request = "treatment-request",
    returned_request = "returned-request",
    denied_request = "denied-request",
    approved_request = "approved-request",
}

export type GetProfilesResponse = {
    type: CreateOrUpdateProfileType,
    status: ProfileStatus,
    cardNumber:string,
    profileName:string,
    _id: string,
    request_type: number,
    docs: [
        {
            updatedAt: string,
            comments: string,
            status: boolean,
            _id: string,
            url:string,
            doc_type: string,
            ortDocName:string,
            originalFileName?:string |undefined,
            fileExtension?:string |undefined,
        }
    ],
    userId: string,
    createdAt: string,
    updatedAt: string,
    __v: number
}


export type GetProfilesResponseORT = {
    from: string,
    request_type: string
    
}

export type GetSingleProfileResponse = {
    request_id: string
    docs: GetSingleProfileDoc[]
}

export type GetSingleProfileDoc = {
    _id: string
    url: string,
    doc_type: string,
    status: boolean,
    comments: string,
    updatedAt?:any,
    originalFileName?:string |undefined,
    fileExtension?:string |undefined,
    ortDocName:string
}

// export enum DocsStatus {
//     PENDING=1,
//     REJECT=2,
//     APPROVE=3,
// }


export type UploadOrUpdateFilesRequest = {
    payload:any
}

export type Profile = {
    name: string
    type: string
    profileName:string
    files: ProfileDocument[] | null
    description?: string
}

export type ProfileDocument = {
    ortDocName: string
    name: string
    type: string
    doc_type:string
    mandatory: boolean
    description: string
    options?: ProfileDocumentOption[]
}
export type ProfileDocumentOption = {
    name: string
    type: string
    description: string
}

export enum CreateOrUpdateProfileTypeEnum  {
    create_new_profile = 1,
    update_profile = 2,
    new_card = 3
}

export type CreateOrUpdateProfileType =  CreateOrUpdateProfileTypeEnum.update_profile | CreateOrUpdateProfileTypeEnum.create_new_profile
   

