import { IonCard, IonCardContent, IonText } from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { CardEvent } from "../../../hooks/types";
import { ortTimestampToDate } from "../../../hooks/useCard";

import "./HistoryCard.scss";
import { formatDateLong } from "../../../utils/helper-functions";

type THistoryCard = {
  mobile?: boolean;
  mini?: boolean;
  cardEv: CardEvent;
};

const HistoryCard: React.FC<THistoryCard> = ({
  mobile = false,
  mini,
  cardEv,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation("", {
    keyPrefix: "historyCard",
  });
  const labels = useMemo(() => {
    const dateStr = cardEv.EventDateTime
      ? formatDateLong(ortTimestampToDate(cardEv.EventDateTime))
      : "";
    return {
      date: dateStr,
      title:
        cardEv.ContractType === 0
          ? cardEv.EventTypeStr
          : t("in", {
              event: cardEv.EventTypeStr,
              contract: cardEv.ContractTypeStr,
            }),
      details:
        cardEv.ContractType === 0
          ? ""
          : t("line", {
              line: cardEv.Line,
              operator: cardEv.OperatorStr,
              station: cardEv.Station.Description,
            }),
    };
  }, [t]);

  return (
    <IonCard
      dir={appDirection}
      className="history-card"
      data-is-mobile={mobile}
      data-is-mini={mini}
    >
      <IonCardContent className="history-card-content">
        <div className="text-container">
          <IonText className="history-card-header text">{labels.title}</IonText>
          <IonText className="description text">{labels.details}</IonText>
        </div>
      </IonCardContent>
      <div className="date-container">
        <IonText className="date text">{labels.date}</IonText>
      </div>
    </IonCard>
  );
};

export default HistoryCard;
