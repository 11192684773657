const {
  REACT_APP_API_URL,
  REACT_APP_OTP_EXP,
  REACT_APP_NFC_SOCKET_API_URL,
  REACT_APP_VERSION,
  REACT_APP_SITE_NAME,
} = process.env;

export const VERSION = REACT_APP_VERSION;
export const OTP_EXP = REACT_APP_OTP_EXP;
export const API_URL = REACT_APP_API_URL || "http://localhost:3000";
// export const API_URL = "http://192.168.1.181:3000";
// export const API_URL = 'http://10.0.0.129:3000'
export const NFC_SOCKET_API_URL = REACT_APP_NFC_SOCKET_API_URL;

export const DIRECTIONS = {
  ltr: "ltr",
  rtl: "rtl",
};

const SITE_NAME = "";

export const ROUTES = {
  // desktop routes ****/
  home: `${SITE_NAME}/`,

  desktopOrderCard: `${SITE_NAME}/desktop_order_card`,
  desktopNfcOrder: `${SITE_NAME}/desktop_nfc_order`,

  // auth
  desktopLogIn: `${SITE_NAME}/desktop_login`,
  desktopRegister: `${SITE_NAME}/desktop_register`,

  // static
  desktopFAQ: `${SITE_NAME}/desktop_faq`,
  desktopTerms: `${SITE_NAME}/desktop_terms`,
  desktopSearchResults: `${SITE_NAME}/desktop_search_results`,
  desktopSupport: `${SITE_NAME}/desktop_support`,

  // personal zone
  desktopChargeHistory: `${SITE_NAME}/desktop_charge_history`,
  desktopRavkavCards: `${SITE_NAME}/desktop_ravkav_cards`,
  desktopEditUserInfo: `${SITE_NAME}/desktop_edit_user_info`,

  // read card
  desktopScanResults: `${SITE_NAME}/desktop_scan_results`,
  desktopNfcRead: `${SITE_NAME}/desktop_nfc_read`,

  // profiles
  desktopProfiles: `${SITE_NAME}/desktop_profiles`,
  desktopCreateNewProfile: `${SITE_NAME}/desktop_create_profile`,
  desktopUploadFiles: `${SITE_NAME}/desktop_upload_files`,
  desktopSmsNotApprovedDocsPopUp: `${SITE_NAME}/desktop_sms_not_approved_docs`,

  // payments
  desktopPaymentMethod: `${SITE_NAME}/desktop_payment_method`,

  // card
  desktopOrderCharge: `${SITE_NAME}/desktop_order_charge`,
  desktopExchangeProfile: `${SITE_NAME}/desktop_exchange_profile`,
  desktopCancelAndRefund: `${SITE_NAME}/desktop_cancel_and_refund`,
  desktopPurchaseContract: `${SITE_NAME}/desktop_purchase_contract`,
  desktopRemotePurchaseContract: `${SITE_NAME}/desktop_remote_purchase_contract`,

  // mobile routes ****/
  // auth
  onBoarding: `${SITE_NAME}/on_boarding`,
  mobileLogIn: `${SITE_NAME}/mobile_login`,
  mobileRegister: `${SITE_NAME}/mobile_register`,

  personalAreaSelection: `${SITE_NAME}/personal_area_selection`,

  // static
  mobileSupport: `${SITE_NAME}/mobile_support`,
  mobileFAQ: `${SITE_NAME}/mobile_faq`,
  mobileSearchResults: `${SITE_NAME}/mobile_search_results`,

  // read card
  mobileNfcRead: `${SITE_NAME}/mobile_nfc_read`,
  mobileScanResults: `${SITE_NAME}/mobile_scan_results`,

  // personal zone
  mobileChargeHistory: `${SITE_NAME}/mobile_charge_history`,
  mobileRavkavCards: `${SITE_NAME}/mobile_ravkav_cards`,
  mobileEditUserInfo: `${SITE_NAME}/mobile_edit_user_info`,
  // payments
  mobilePaymentMethod: `${SITE_NAME}/mobile_payment_method`,

  // card
  mobileOrderCard: `${SITE_NAME}/mobile_order_card`,
  mobileOrderCharge: `${SITE_NAME}/mobile_order_charge`,
  mobileExchangeProfile: `${SITE_NAME}/mobile_exchange_profile`,
  mobileCancelAndRefund: `${SITE_NAME}/mobile_cancel_and_refund`,
  mobilePurchaseContract: `${SITE_NAME}/mobile_purchase_contract`,
  mobileRemotePurchaseContract: `${SITE_NAME}/mobile_remote_purchase_contract`,

  // profiles
  mobileProfiles: `${SITE_NAME}/mobile_profiles`,
  mobileCreateNewProfile: `${SITE_NAME}/mobile_create_profile`,
  mobileUploadFiles: `${SITE_NAME}/mobile_upload_files`,
  mobileSmsNotApprovedDocsPopUp: `${SITE_NAME}/mobile_sms_not_approved_docs`,
};

export const STORAGE_KEYS = {
  onBoarding: "onBoarding",
  appDirection: "appDirection",
  vendor: "vendor",
  language: "language",
  cardImage: "cardImage",
} as const;

export const REG_EXP = {
  phone: /^[0-9]{3,10}$/,
  special_characters: /^[a-zA-Zא-ת\s'"]{2,15}$/,
  name: /^[a-zA-Z\u0590-\u05fe]+$/,
  number: /^[0-9]+$/,
  aprtNumber: /^[0-9]+(\/[0-9]+){0,1}$/,
  email:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
};

export const POPUPS = {
  clear: "",
  loginError: "loginError",
  vendorLoginError: "vendorLoginError",
  loginOTP: "loginOTP",
  pangoWhiteLabel: "pangoWhiteLabel",
  moovitWhiteLabel: "moovitWhiteLabel",
  whiteLabelRedirect: "whiteLabelRedirect",
  nfcReadFailed: "nfcReadFailed",
  nfcWriteFailed: "nfcWriteFailed",
  exchange: "exchange",
  nfcCardError: "nfcCardError",
  cancelAndRefund: "cancelAndRefund",
  renewContract: "renewContract",
  changeCardName: "changeCardName",
  documentsSentSuccess: "documentsSentSuccess",
  documentsSentError: "documentsSentError",
  addNewCardForCharge: "addNewCardForCharge",
  selectStartDateForDailyContract: "selectStartDateForDailyContract",
  signInBeforeContinuingPopup: "signInBeforeContinuingPopup",
  greenList: "greenList",
  cardOrderSuccess: "cardOrderSuccess",
  paymentSuccess: "paymentSuccess",
  loginOTPError: "loginOTPError",
  nfcRefundSuccess: "nfcRefundSuccess",
  profileRequestApproved: "profileRequestApproved",
  nfcCardBlocked: "nfcCardBlocked",
  nfcContractLoaded: "nfcContractLoaded",
} as const;
export type TPopup = (typeof POPUPS)[keyof typeof POPUPS];

export const OS = {
  windows: "Windows",
  mac: "Mac",
  linux: "Linux",
} as const;

export const SCAN_CARD_RESULTS_TABS = {
  history: "history",
  contracts: "contracts",
} as const;

export const VENDORS = {
  moovit: "moovit",
  pango: "pango",
} as const;
