import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import classNames from "classnames";
import { MouseEventHandler, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import leftArrow from "../../assets/icons-small-arrow-left.svg";

import "./cardDetailsCard.scss";

const langConfigPath = "ravkavCardsCard.card";

type TCardDetailsCard = {
  name?: string;
  expirationDate?: string;
  lastCharged?: string;
  mobile?: boolean;
  cssClass?: object;
  selected?: boolean;
  cardNumber: number;
  onClick?: MouseEventHandler;
};

const CardDetailsCard: React.FC<TCardDetailsCard> = ({
  name,
  cssClass,
  mobile = false,
  selected,
  cardNumber,
  expirationDate,
  lastCharged,
  onClick,
}) => {
  const appDirection = useSelector((state: RootState) => state.app.direction);
  const { t } = useTranslation();

  const labels = useMemo(() => {
    let secondaryTitle = cardNumber.toString();
    if (expirationDate) {
      secondaryTitle =
        secondaryTitle +
        " | " +
        t(`cardDetailsCard.expText`, { exp: expirationDate });
    }
    return {
      mainTitle: name || t(`${langConfigPath}.num`, { num: cardNumber }),
      secondaryTitle,
    };
  }, [cardNumber, expirationDate, t]);

  return (
    <IonCard
      dir={appDirection}
      className={
        cssClass +
        " " +
        classNames({
          "card-details-card flex flex-direction-column": true,
          selected: selected,
        })
      }
      data-is-mobile={mobile}
      onClick={onClick}
    >
      <IonRow
        className={classNames({
          "justify-content-space-between": true,
          "width-100": mobile,
        })}
      >
        <IonCardContent className="text-container">
          <IonRow className="card-details-header text font-size-18">
            {labels.mainTitle}
          </IonRow>
          <IonRow className="text font-size-16">{labels.secondaryTitle}</IonRow>
        </IonCardContent>
        <IonIcon
          src={leftArrow}
          className={classNames("arrow-icon", {
            "rotate-180": appDirection === "ltr",
          })}
        />
      </IonRow>

      {lastCharged && (
        <div className="last-charged-container">
          <IonText className="font-size-14">
            {t(`cardDetailsCard.lastChargedText`, { date: lastCharged })}
          </IonText>
        </div>
      )}
    </IonCard>
  );
};

export default CardDetailsCard;
