import * as yup from "yup";

export const Vendors = { moovit: "moovit", pango: "pango" } as const;

export const ortCommands = {
  readCard: "read-card",
  cmdResults: "cmnds-results",
  loadContract: "load-contract",
  refundContract: "refund-contract",
  blockCard: "block-card",
  loadProfile: "load-profile",
} as const;
export const sdkCommands = {
  log: "log",
  readCard: "read-card",
  applyCommands: "apply-commands",
  closeSession: "close-session",
  getUserInfo: "get-user-info",
} as const;
export const sdkEvents = {
  cardStatus: "card-status",
  cmdResults: "cmnds-results",
  loadContract: "load-contract",
  cardInfo: "card-info",
  userInfo: "user-info",
} as const;
export const nfcActions = {
  readCard: "read-card",
  loadContract: "load-contract",
  refundContract: "refund-contract",
  exchangeImprisoned: "exchange-imprisoned",
  loadProfile: "load-profile",
  none: "",
} as const;

export type TNfcAction = (typeof nfcActions)[keyof typeof nfcActions];
export type TLoadContractActionPayload = {
  contract: TContractDetails;
  payForFlexible?: number;
  GreenListConfirmationCode?: number;
  paymentUid?: string;
  topcardId?: string;
};

export type TExchangeImprisonedActionPayload = {
  selectedOption: any;
  contractIndex: number;
};

export const flexibleSumValueETT = 65;
type TFreeTimeContractData = {
  DurationUnitType?: number;
  DurationUnitCount?: number;
  FromDate?: string;
  ToDate?: string;
};
type TSumValueContractData = {
  Units?: number;
  RestrictDurationInMinutes?: number;
};

export const contractDecoratorTypes = {
  SumValue: "SumValue",
  FreeTime: "FreeTime",
  Unknown: "",
} as const;

export const freeTimeETTs = [20, 21, 22, 23, 24, 25, 26] as const;
export const sumValueETTs = [60, 61, 62, 63, 64, 65] as const;

export type TContractDetails = {
  MakatProduct: number;
  Profile: number;
  ContractCode: number;
  PredefinedCode: number;
  Cluster: number;
  Operator: number;
  PriceInAgorot: number;
  PriceInAgorotByCash: number;
  Description: string;
  Remark: string;
  ETTStr?: string;
  ClusterStr?: string;
  OperatorStr?: string;
  ProfileStr?: string;
  SellType: number;
  ContractType?: string;
  FareCode: number;
  FareCodeDescription: string;
  ETT: number;
} & {
  SpecificContractData: TFreeTimeContractData & TSumValueContractData;
};
export type Contract = {
  IsContractValid: boolean;
  IsExpired: boolean;
  IsGoingToExpire: boolean;
  IsMarked2Delete: boolean;
  IsDeleted: boolean;
  ImprisonedBalanceSum: number;
  IsImprisonedBalance: boolean;
  IndexOnSC: number;
  CancellationData?: {
    IsPossible2Cancel: boolean;
    Units2Cancel: number;
    Sum2CancelInAgorot: number;
  };
  ExtendedInfo?: {
    IsPossible2Convert2Better: boolean;
    IsPossible2Renew: boolean;
    Sum2Renew: number;
    SaleDate: string;
  };
  Contract: TContractDetails;
};

export type GreenContract = {
  GreenListConfirmationCode: number;
  GreenListRequestId: string;
  Contract: TContractDetails;
  PlaceId: number;
  PlaceTypeRef: number;
  CityId: number;
  J5_ConfirmationId: string;
  J5_Expiration: string;
  SpecData_Notes: string;
  isLoadingPlaceSpecified: number;
};
export type ExchangeParams = {
  SelectedContract: Contract;
  CurrentInfo: {
    ProfileId: number;
    ProfileStr: string;
    ExpirationDate: string;
    Units: number;
    IsImprisonedBalance: number;
  };
  NumberOfOptions: number;
  Options: {
    ProfileStr: string;
    ProfileId: number;
    ExpirationDate: string;
    Units: number;
  }[];
};
export type ImprisonedSumDetails = {
  SessionID: string;
  IsThereContract2Transfer: boolean;
  Profile2Transfer: number;
  Units2Transfer: number;
  NumberOfCommands: number;
  Commands: any[];
};
export type CardEvent = {
  EventType: number;
  EventDateTime: string;
  Line: string;
  Operator: number;
  ContractType: number;
  ContractIndex: number;
  AdditionalInfo: {
    IsMaavar: boolean;
    IsFirstUse: boolean;
    IsCompletion: boolean;
    Units2Take: number;
    PriceCode: number;
  };
  ContractTypeStr: string;
  OperatorStr: string;
  EventTypeStr: string;
  Station: { ID: number; Description: string };
};

export type RefundContractParams = {
  ContractSlotId: number;
  Units2Sub: number;
  PrevEtt: number;
};

export type SdkEvent = (typeof sdkEvents)[keyof typeof sdkEvents];
export type SdkCommand = (typeof sdkCommands)[keyof typeof sdkCommands];
export type OrtCommand = (typeof ortCommands)[keyof typeof ortCommands];

export const chargeSchema = yup.object({
  _id: yup.string().required(),
  amount: yup.number().required(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
  ccToken: yup.string().optional(),
  cgUid: yup.string().optional(),
  invoice: yup.string().optional().nullable(),
  ccNumber: yup.string().optional(),
  ravKavNumber: yup.number().optional(),
  ccExpired: yup.string().optional(),
  j4: yup.object({ capturedAt: yup.date() }).optional(),
  j5: yup.object({ verifiedAt: yup.date() }).optional(),
});
export type Charge = yup.InferType<typeof chargeSchema> & {
  contract?: TContractDetails;
};
