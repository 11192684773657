import { IonButton, IonIcon, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changePopup } from "../../../store/action-creators/App";
import { POPUPS } from "../../../store/Globals";
import Popup from "../Popup";
import "./PaymentSuccessPopup.scss";
import contractIcon from "../../../assets/icons-90-px-payment-success.svg";
import pango_happy from "../../../assets/pango_happy.svg";
import { useMemo } from "react";
import { RootState } from "../../..";

type TPaymentSuccessPopup = {
  mobile?: boolean;
  routeOnDismiss?: string;
};

const PaymentSuccessPopup: React.FC<TPaymentSuccessPopup> = ({
  mobile = false,
  routeOnDismiss,
}) => {
  const { t } = useTranslation("", { keyPrefix: "popUps.paymentSuccess" });
  const dispatch = useDispatch();
  const vendor = useSelector((state: RootState) => state.app.vendor);

  const labels = useMemo(() => {
    return {
      header: t("recieveSuccess"),
      btnText: t("ok"),
    };
  }, [t]);
  const onOk = () => {
    dispatch(changePopup(POPUPS.clear));
  };

  const icon = vendor === "pango" ? pango_happy : contractIcon;

  return (
    <Popup
      routeOnDismiss={routeOnDismiss}
      popUpId={POPUPS.paymentSuccess}
      mobile={mobile}
      cssClass="add-new-card-for-charge-pop-up-container"
    >
      <div className="top-contrast"></div>
      <div
        className="pop-up add-new-card-for-charge-pop-up flex
             ion-align-items-center flex-direction-column"
        data-is-mobile={mobile}
      >
        <IonIcon className="pop-up-icon" src={icon} />
        <IonText className="text weight-500 info">{labels.header}</IonText>
        <IonButton className=" main-blue-buttons pop-up-botton" onClick={onOk}>
          {labels.btnText}
        </IonButton>
      </div>
    </Popup>
  );
};

export default PaymentSuccessPopup;
